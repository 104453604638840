import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import './Workers.css';

const Workers = () => {
  const { t } = useTranslation();
  const [workers, setWorkers] = useState([]);
  const [month, setMonth] = useState(moment().format('YYYY-MM'));
  const [selectedWorker, setSelectedWorker] = useState(null);

  useEffect(() => {
    fetchWorkersData();
  }, [month]);

  const fetchWorkersData = async () => {
    try {
      const token = sessionStorage.getItem('token');

      // 1. Fetch all users that are mechanics or receptionists
      const usersResponse = await axios.get(`${API_BASE_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const allWorkers = usersResponse.data;
      const filteredWorkers = allWorkers.filter(
        (worker) => worker.role === 'mechanic' || worker.role === 'receptionist'
      );

      // 2. For each worker, fetch work logs and tickets for the selected month
      const workersSummary = await Promise.all(
        filteredWorkers.map(async (worker) => {
          // Fetch check-in and check-out logs for the user
          const logsResponse = await axios.get(`${API_BASE_URL}/api/work-logs`, {
            params: { userId: worker.id, month },
            headers: { Authorization: `Bearer ${token}` },
          });
          const workLogs = logsResponse.data;

          // Calculate hours worked by summing the difference between check-in and check-out, and compute daily breakdown
          let totalSeconds = 0;
          let dailyHours = {};
          for (let i = 0; i < workLogs.length; i++) {
            if (workLogs[i].type === 'check-in' && workLogs[i + 1] && workLogs[i + 1].type === 'check-out') {
              const checkIn = moment(workLogs[i].timestamp);
              const checkOut = moment(workLogs[i + 1].timestamp);
              const diffSeconds = checkOut.diff(checkIn, 'seconds');
              totalSeconds += diffSeconds;
              // Agrupa por data do check-in
              const day = moment(workLogs[i].timestamp).format('YYYY-MM-DD');
              dailyHours[day] = (dailyHours[day] || 0) + diffSeconds / 3600;
              i++; // pula o próximo, pois já foi utilizado
            }
          }
          const totalHours = (totalSeconds / 3600).toFixed(2);

          // Calcula os dias úteis do mês (segunda a sexta)
          const startOfMonth = moment(month, 'YYYY-MM').startOf('month');
          const endOfMonth = moment(month, 'YYYY-MM').endOf('month');
          let workingDaysList = [];
          for (let m = startOfMonth.clone(); m.isBefore(endOfMonth); m.add(1, 'days')) {
            if (m.isoWeekday() < 6) { // segunda a sexta
              workingDaysList.push(m.format('YYYY-MM-DD'));
            }
          }
          const daysWorkedCount = Object.keys(dailyHours).length;
          const daysNotWorked = workingDaysList.length - daysWorkedCount;
          // Lista dos dias úteis que não tiveram trabalho
          const daysNotWorkedList = workingDaysList.filter(day => !dailyHours.hasOwnProperty(day));

          let extraData = {};
          if (worker.role === 'mechanic') {
            // Fetch the mechanic's tickets for the month
            const ticketsResponse = await axios.get(`${API_BASE_URL}/api/tickets`, {
              params: { mechanicId: worker.id, month },
              headers: { Authorization: `Bearer ${token}` },
            });
            const tickets = ticketsResponse.data;
            // Filtra os tickets para garantir que apenas os tickets com mechanic_id igual ao worker.id sejam considerados
            const mechanicTickets = tickets.filter(ticket => ticket.mechanic_id === worker.id);
            // Mapeia os tickets filtrados para o formato desejado
            const workedTickets = mechanicTickets.map(ticket => ({
              ticketNumber: ticket.id,
              plate: ticket.car ? ticket.car.plate : 'N/A',
              createdAt: ticket.created_at ? moment(ticket.created_at).format('YYYY-MM-DD') : 'N/A',
              issueDescription: ticket.issue_description || ''
            }));
            extraData = {
              workedTickets,
              ticketsCount: mechanicTickets.length,
            };
          } else if (worker.role === 'receptionist') {
            // Fetch tickets created by the receptionist for the month
            const ticketsResponse = await axios.get(`${API_BASE_URL}/api/tickets`, {
              params: { creatorId: worker.id, month },
              headers: { Authorization: `Bearer ${token}` },
            });
            const tickets = ticketsResponse.data;
            extraData = {
              ticketsCreated: tickets.length,
            };
          }

          return {
            ...worker,
            totalHours,
            daysNotWorked,
            dailyHours,
            daysNotWorkedList,
            workingDaysList,
            ...extraData,
          };
        })
      );

      setWorkers(workersSummary);
    } catch (error) {
      console.error('Error fetching worker data:', error);
      toast.error(t('Error fetching worker data.'));
    }
  };

  if (selectedWorker) {
    return (
      <div className="worker-details">
        <h2>{t('Worker Details')}</h2>
        <p><strong>{t('Name')}:</strong> {selectedWorker.name}</p>
        <p>
          <strong>{t('Role')}:</strong>{' '}
          {selectedWorker.role === 'mechanic'
            ? t('Mechanic')
            : selectedWorker.role === 'receptionist'
            ? t('Receptionist')
            : selectedWorker.role}
        </p>
        <p><strong>{t('Total Hours Worked')}:</strong> {selectedWorker.totalHours}</p>

        {/* Seção de dias não trabalhados */}
        <h3>{t('Working Days Not Worked')}</h3>
        {selectedWorker.daysNotWorkedList.length > 0 ? (
          <ul className="days-not-worked-list">
            {selectedWorker.daysNotWorkedList.map((day) => (
              <li key={day}>{day}</li>
            ))}
          </ul>
        ) : (
          <p>{t('None')}</p>
        )}

        {/* Seção específica para mecânicos */}
        {selectedWorker.role === 'mechanic' && (
          <div className="mechanic-info">
            <h3>{t('Mechanic Information')}</h3>
            <div className="info-section">
              <h4>{t('Tickets Worked On')}</h4>
              {selectedWorker.workedTickets && selectedWorker.workedTickets.length > 0 ? (
                <ul className="tickets-worked-list">
                  {selectedWorker.workedTickets.map((ticket) => (
                    <li key={ticket.ticketNumber}>
                      <strong>{t('Ticket')}:</strong> {ticket.ticketNumber} - <strong>{t('Plate')}:</strong> {ticket.plate} - <strong>{t('Created At')}:</strong> {ticket.createdAt}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{t('None')}</p>
              )}
            </div>
            <div className="info-section">
              <h4>{t('Total Tickets')}</h4>
              <p>{selectedWorker.ticketsCount}</p>
            </div>
          </div>
        )}

        {/* Seção específica para recepcionistas */}
        {selectedWorker.role === 'receptionist' && (
          <div className="receptionist-info">
            <h3>{t('Receptionist Information')}</h3>
            <div className="info-section">
              <h4>{t('Tickets Created')}</h4>
              <p>{selectedWorker.ticketsCreated}</p>
            </div>
          </div>
        )}

        <button onClick={() => setSelectedWorker(null)}>{t('Back')}</button>
      </div>
    );
  }

  return (
    <div className="workers-page">
      <h1>{t('Workers Summary')}</h1>
      <div className="filter-container">
        <label htmlFor="month">{t('Select month')}: </label>
        <input
          type="month"
          id="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        />
      </div>
      <table className="workers-table">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Role')}</th>
            <th>{t('Hours Worked')}</th>
            <th>{t('Days Not Worked')}</th>
            <th>{t('Details')}</th>
          </tr>
        </thead>
        <tbody>
          {workers.map((worker) => (
            <tr key={worker.id} onClick={() => setSelectedWorker(worker)} style={{ cursor: 'pointer' }}>
              <td>{worker.name}</td>
              <td>{worker.role === 'mechanic' ? t('Mechanic') : worker.role === 'receptionist' ? t('Receptionist') : worker.role}</td>
              <td>{worker.totalHours}</td>
              <td>{worker.daysNotWorked}</td>
              <td>
                {worker.role === 'mechanic' ? (
                  <div>
                    <strong>{t('Tickets')}:</strong>{' '}
                    {worker.workedTickets && worker.workedTickets.length > 0
                      ? worker.workedTickets.map(ticket => ticket.ticketNumber).join(', ')
                      : t('None')}
                  </div>
                ) : worker.role === 'receptionist' ? (
                  <div>
                    <strong>{t('Tickets Created')}:</strong> {worker.ticketsCreated}
                  </div>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default Workers;