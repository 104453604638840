import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import itLocale from '@fullcalendar/core/locales/it';
import enLocale from '@fullcalendar/core/locales/en-gb';
import 'moment/locale/it';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import './Calendar.css';
import { FaPaperPlane } from 'react-icons/fa';
import API_BASE_URL from '../config';
import Modal from 'react-modal';
import CalendarModalContent from './CalendarModalContent';
import BookingMechanicModalContent from './BookingMechanicModalContent.js';
import { toast, ToastContainer } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';
import useOutsideClick from '../hooks/useOutsideClick.js';
import countryOptions from '../components/CountryOptions';

Modal.setAppElement('#root');

function Calendar() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const mapStatus = (status) => {
    switch (status) {
      case 1:
        return { translation: t('Not Assigned'), className: 'not-assigned' };
      case 2:
        return { translation: t('Pending'), className: 'pending' };
      case 3:
        return { translation: t('Working'), className: 'working' };
      case 4:
        return { translation: t('Finished'), className: 'finished' };
      case 6:
        return { translation: t('reservation'), className: 'reservation' };
      default:
        return { translation: t('Unknown'), className: 'unknown' };
    }
  };
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [locale, setLocale] = useState('it');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('showAppointments');
  const [bookingMechanicModalIsOpen, setBookingMechanicModalIsOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [mechanicBookings, setMechanicBookings] = useState([]);
  const role = sessionStorage.getItem("role");
  const [infoBox, setInfoBox] = useState(null);
  const infoBoxRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const calendarEl = calendarRef.current?.el; // Onde o FullCalendar monta o DOM
  const screenWidth = calendarEl?.offsetWidth || window.innerWidth;
  const [dueDateEvents, setDueDateEvents] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [appointmentToEdit, setAppointmentToEdit] = useState(null);
  const [bookingToEdit, setBookingToEdit] = useState(null);
  const [selectedMechanic, setSelectedMechanic] = useState(null);
  const [mechanics, setMechanics] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ticketOption, setTicketOption] = useState('new');

  // Exemplo: buscar mecânicos via API (já implementado)
  useEffect(() => {
    const fetchMechanics = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/mechanics`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
          setMechanics(data);
        }
      } catch (error) {
        console.error('Erro ao buscar mecânicos:', error);
      }
    };

    fetchMechanics();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
  
    if (params.get('booking') === 'mechanic') {
      setSelectedFilter('showMechanicWork');
  
      const fromTicket = params.get('fromTicket');
  
      if (fromTicket) {
        const fetchTicketData = async () => {
          try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/api/tickets/${fromTicket}/details`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            if (!response.ok) throw new Error('Erro ao buscar ticket.');
  
            const data = await response.json();
  
            setNewEvent({
              name: data.clientName || '',
              phone: data.clientPhone || '',
              car_brand: data.carBrand || '',
              car_model: data.carModel || '',
              car_plate: data.carPlate || '',
              car_year: data.carYear || '',
              car_mileage: data.carMileage || '',
              description: data.issue_description || '',
              short_description: '',
              start_time: null,
              duration: '',
              color: '#007bff',
              ticketId: fromTicket,
              ticketOption: 'existing',
            });

            setSelectedTicket({
              value: fromTicket,
              label: `Ticket #${fromTicket}`,
              clientName: data.clientName,
              clientPhone: data.clientPhone,
              clientPhoneCountry: data.clientPhoneCountry,
              carBrand: data.carBrand,
              carModel: data.carModel,
              carYear: data.carYear,
              carPlate: data.carPlate,
              carMileage: data.carMileage,
              issue_description: data.issue_description,
            });
            
            setTicketOption('existing');
  
            // Aguarde o carregamento dos mecânicos antes de setar
            const interval = setInterval(() => {
              if (mechanics.length > 0) {
                const mechanic = mechanics.find(m => m.id === data.assignedMechanicId);
                if (mechanic) setSelectedMechanic(mechanic);
                clearInterval(interval);
              }
            }, 100);
            setBookingMechanicModalIsOpen(true);
          } catch (error) {
            console.error('Erro ao buscar dados do ticket:', error);
          }
        };
  
        fetchTicketData();
      }
    }
  }, [location, mechanics]);

  // Handler para clique no nome do mecânico
  const handleMechanicClick = (mechanic) => {
    setSelectedMechanic(mechanic);
    // Opcional: se os bookings já estiverem carregados, filtre-os:
    // Por exemplo, se os bookings estiverem armazenados em "mechanicBookings",
    // você pode filtrar os eventos para o FullCalendar.
  };

  // Filtra os bookings para o mecânico selecionado
  const filteredBookings = selectedMechanic
    ? mechanicBookings.filter((booking) => booking.mechanic_id === selectedMechanic.id)
    : mechanicBookings;


  useOutsideClick(infoBoxRef, () => {
    // Só fecha se o popup estiver aberto
    if (isPopupVisible) {
      setIsPopupVisible(false);
    }
  });

  // Função para enviar o lembrete via WhatsApp
  const handleWhatsAppReminder = () => {
    if (!infoBox || !infoBox.phone || !infoBox.name || !infoBox.start) {
      toast.error('Informazioni insufficienti per inviare il promemoria.');
      return;
    }
  
    const appointmentTime = moment(infoBox.start).format('HH:mm');
    const appointmentDate = moment(infoBox.start).locale('it').format('LL');
  
    const message = `Gentile Cliente, desideriamo informarLa che il Suo appuntamento è stato fissato per il ${appointmentDate} alle ${appointmentTime}. Qualora non Le fosse possibile partecipare, La preghiamo di contattarci al più presto per concordare una nuova data. Cordiali saluti, Il Team di 3BAuto.`;
  
    // Mapeia a sigla para o dial code usando a mesma lista de countryOptions
    const countryOption = countryOptions.find(
      (option) => option.value.toLowerCase() === (infoBox.phone_country || '').toLowerCase()
    );
    const dialCode = countryOption ? countryOption.dialCode : infoBox.phone_country || '';
  
    // Remove caracteres não numéricos (exceto o sinal de +, se preferir, ou mantenha o +)
    const formattedDialCode = dialCode.replace(/\D/g, '');
    const formattedPhone = infoBox.phone.replace(/\D/g, '');
    const phone = `${formattedDialCode}${formattedPhone}`;
  
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phone}?text=${encodedMessage}`;
  
    window.open(whatsappUrl, '_blank');
  };

  // Excluir agendamento
  const handleDeleteAppointment = async (appointmentId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/appointments/${appointmentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
  
      if (response.ok) {
        setAppointments((prev) => prev.filter((appt) => appt.id !== appointmentId));
        setEvents((prevEvents) => prevEvents.filter((event) => event.id !== appointmentId));
        toast.success('Agendamento excluído com sucesso.');
        // Fecha o popup
        setIsPopupVisible(false);
      } else {
        toast.error('Erro ao excluir agendamento.');
      }
    } catch (error) {
      console.error('Erro ao excluir agendamento:', error);
      toast.error('Erro ao excluir agendamento.');
    }
  };

  useEffect(() => {
    if (infoBox?.id) {
      const updatedEvent = events.find((event) => event.id === infoBox.id);
      if (updatedEvent) {
        const updatedInfoBox = {
          ...infoBox,
          name: updatedEvent.title || ' ',
          phone: updatedEvent.title.match(/\((.*?)\)/)?.[1] || ' ',
          start: updatedEvent.start,
          end: updatedEvent.end,
          description: updatedEvent.description || ' ',
          short_description: updatedEvent.short_description || '',
        };
  
        // Atualiza apenas se houver diferença para evitar loop
        if (JSON.stringify(infoBox) !== JSON.stringify(updatedInfoBox)) {
          setInfoBox(updatedInfoBox);
        }
      }
    }
  }, [events]);

  const [newEvent, setNewEvent] = useState({
    name: '',
    phone: '',
    car_brand: '',
    car_model: '',
    car_plate: '',
    car_year: '',
    car_mileage: '',
    description: '',
    short_description: '',
    start_time: null,
    duration: '',
    color: '#007bff',
  });

  const languageOptions = {
    en: enLocale,
    pt: ptLocale,
    it: itLocale,
  };

  useEffect(() => {
    const preferredLanguage = sessionStorage.getItem('preferred_language') || 'en';
    setLocale(preferredLanguage);
    i18n.changeLanguage(preferredLanguage);
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/appointments`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar agendamentos.');
        }
  
        const data = await response.json();
  
        const formattedAppointments = data.map((event) => {
          let color = event.color || '#007bff';
        
          if (moment(event.start_time).isBefore(moment(), 'day')) {
            color = event.ticket_active === '1' ? '#d3d3d3' : '#ff0000';
          }
        
          return {
            id: event.id,
            title: event.name,
            start: moment.tz(event.start_time, 'Europe/Rome').format(),
            end: moment.tz(event.end_time, 'Europe/Rome').format(),
            color,
            description: event.description || '',
            short_description: event.short_description || '',
            phone: event.phone,
            phone_country: event.phone_country,
            car_brand: event.car_brand,
            car_model: event.car_model,
            car_plate: event.car_plate,
            car_year: event.car_year,
            car_mileage: event.car_mileage,
            ticket_active: event.ticket_active
          };
        });
  
        setAppointments(formattedAppointments); // Atualiza o estado
      } catch (error) {
        console.error('Erro ao buscar agendamentos:', error);
      }
    };
  
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/mechanics/bookings`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar agendamentos.');
        }
  
        const bookingsData = await response.json();
  
        const formattedBookings = bookingsData.map((booking) => ({
          id: `booking-${booking.id}`,
          title: booking.mechanic_name,
          start: moment.tz(booking.start, 'Europe/Rome').format(),
          end: moment.tz(booking.end, 'Europe/Rome').format(),
          className: booking.className,
          mechanic_id: booking.mechanic_id,
          mechanic_name: booking.mechanic_name,
          car_brand: booking.car_brand,
          car_model: booking.car_model,
          car_plate: booking.car_plate,
          color: booking.color || '#17a2b8',
          ticketNumber: booking.ticketNumber,
          ticketOpenedOn: booking.ticketOpenedOn
            ? moment(booking.ticketOpenedOn).format('YYYY-MM-DD HH:mm:ss')
            : null,  // caso venha undefined ou null
          description: booking.description,
          is_deleted: booking.is_deleted,
          status: booking.status,
          type: 'booking',
        }));
  
        setMechanicBookings(formattedBookings);
      } catch (error) {
        console.error('Erro ao buscar agendamentos:', error);
      }
    };
  
    fetchEvents();
    fetchData();
  }, []);

  const handleOpenModal = () => {
    setEditMode(false);
    setAppointmentToEdit(null);
    setBookingToEdit(null);
  
    if (selectedFilter === 'showMechanicWork') {
      setBookingMechanicModalIsOpen(true);
    } else {
      setModalIsOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setNewEvent({ title: '', description: '', date: '' });
  };

  const handleSaveBookingMechanic = async (bookingData) => {
    try {
      const token = sessionStorage.getItem('token');
  
      // Valida se os campos obrigatórios de data e horário estão preenchidos
      if (!bookingData.booking_date) {
        toast.error(t('Booking date is required.'));
        return;
      }
      if (!bookingData.bookingTime) {
        toast.error(t('Booking time is required.'));
        return;
      }

      if (!bookingData.issue_description) {
        toast.error(t('Booking description is required.'));
        return;
      }

      // Usa moment-timezone para converter para o fuso 'Europe/Rome'
      const booking_date = moment.tz(bookingData.booking_date, 'Europe/Rome').format('YYYY-MM-DD');
      const start_time = moment.tz(bookingData.bookingTime, 'HH:mm:ss', 'Europe/Rome').format('HH:mm:ss');
  
      // Para calcular o end_time, combine a data e o horário
      const bookingDateObj = new Date(bookingData.booking_date);
      const [hours, minutes, seconds] = bookingData.bookingTime.split(':').map(Number);
      const combinedDateTime = new Date(bookingDateObj);
      combinedDateTime.setHours(hours, minutes, seconds, 0);
  
      // Valida a duração (em minutos)
      const minutesToAdd = Number(bookingData.duration);
      if (isNaN(minutesToAdd) || minutesToAdd <= 0) {
        toast.error(t('Invalid duration.'));
        return;
      }
  
      const endObj = new Date(combinedDateTime.getTime() + minutesToAdd * 60000);
      const end_time = moment(endObj).format('HH:mm:ss');
  
      // Se estiver associando um ticket existente, usa o ID; caso contrário, null
      const service_log_id = bookingData.ticketOption === 'existing' ? bookingData.ticketId : null;
  
      // Formata os additionalDays, se houver
      const formattedAdditionalDays = bookingData.additionalDays?.map(day => {
        // Para cada dia adicional, formata a data e mantém o start_time informado
        const dayDateObj = new Date(day.date);
        const day_date = day.date ? dayDateObj.toISOString().split('T')[0] : null;
        const day_start_time = day.start_time || '08:00:00';
        const dayMinutesToAdd = Number(day.duration) || 0;
        const [dHours, dMinutes, dSeconds] = day_start_time.split(':').map(Number);
        const combinedDayTime = new Date(dayDateObj);
        combinedDayTime.setHours(dHours, dMinutes, dSeconds, 0);
        const dayEndObj = new Date(combinedDayTime.getTime() + dayMinutesToAdd * 60000);
        const day_end_time = moment(dayEndObj).format('HH:mm:ss');
        return {
          ...day,
          date: day_date,
          start_time: day_start_time,
          end_time: day_end_time,
        };
      }) || [];
  
      // Monta o payload para enviar ao backend
      const payload = {
        ...bookingData,
        booking_date,       // data formatada (YYYY-MM-DD)
        start_time,         // horário de início (HH:mm:ss)
        end_time,           // horário de fim calculado
        service_log_id,     // ticket associado, se existir
        additionalDays: formattedAdditionalDays,
      };
  
      console.log('Payload para salvar booking:', payload);
  
      const response = await fetch(`${API_BASE_URL}/api/mechanics/${bookingData.mechanic_id}/book`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const newBooking = await response.json();
      
        // Atualiza o estado dos bookings
        setMechanicBookings((prevBookings) => [...prevBookings, newBooking]);
      
        // Cria o objeto do novo evento
        const newEvent = {
          id: `booking-${newBooking.id}`,
          title: newBooking.mechanic_name
            ? `${newBooking.mechanic_name} - ${newBooking.car_brand} ${newBooking.car_model}`
            : `${newBooking.car_brand} ${newBooking.car_model}`,
          start: `${newBooking.booking_date}T${newBooking.start_time}`,
          end: `${newBooking.booking_date}T${newBooking.end_time}`,
          color: newBooking.color || '#007bff',
          description: newBooking.issue_description || '',
          type: 'booking',
        };
      
        // Atualiza o estado dos eventos
        setEvents((prevEvents) => {
          const eventExists = prevEvents.find((e) => e.id === newEvent.id);
          if (eventExists) {
            return prevEvents.map((e) => (e.id === newEvent.id ? newEvent : e));
          } else {
            return [...prevEvents, newEvent];
          }
        });
      
        // Adiciona o evento diretamente ao calendário para atualização imediata
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.addEvent(newEvent);
        }
      
        toast.success(t('Mechanic booking created successfully.'));
        setBookingMechanicModalIsOpen(false);
        window.location.href = '/calendar?booking=mechanic';
      }
    } catch (error) {
      console.error('Error creating mechanic booking:', error);
      toast.error(t('Error creating mechanic booking.'));
    }
  };

  const handleUpdateBookingMechanic = async (bookingId, data) => {
    try {
      const token = sessionStorage.getItem('token');
  
      // Valida os campos obrigatórios de data, horário e duração
      if (!data.booking_date) {
        toast.error(t('Booking date is required.'));
        return;
      }
      if (!data.bookingTime) {
        toast.error(t('Booking time is required.'));
        return;
      }
      if (!data.duration) {
        toast.error(t('Duration is required.'));
        return;
      }
      const minutesToAdd = Number(data.duration);
      if (isNaN(minutesToAdd) || minutesToAdd <= 0) {
        toast.error(t('Invalid duration.'));
        return;
      }
  
      // Formata a data para "YYYY-MM-DD"
      const booking_date = moment(data.booking_date).format('YYYY-MM-DD');
      // O campo bookingTime já deve estar no formato "HH:mm:ss"
      const start_time = data.bookingTime;
  
      // Cria um objeto Date combinando a data e o horário para calcular o end_time
      const bookingDateObj = new Date(data.booking_date);
      const [hours, minutes, seconds] = data.bookingTime.split(':').map(Number);
      bookingDateObj.setHours(hours, minutes, seconds, 0);
      const endObj = new Date(bookingDateObj.getTime() + minutesToAdd * 60000);
      const end_time = moment(endObj).format('HH:mm:ss');
  
      // Monta o payload completo, usando os estados atuais (mesmo os não editáveis)
      // Para os campos que não foram alterados, os estados mantêm os valores originais
      const payload = {
        client_name: data.clientName,               // Valor atual do cliente
        client_phone_country: data.clientPhoneCountry,
        client_phone: data.clientPhone,
        car_brand: data.carBrand,
        car_model: data.carModel,
        car_year: data.carYear,
        car_plate: data.carPlate,
        car_mileage: data.carMileage,
        issue_description: data.description,
        booking_date,   // Atualizado com a nova data
        start_time,     // Atualizado com o novo horário
        end_time,       // Calculado com base na nova duração
        duration: data.duration,                     // Nova duração
        additionalDays: data.additionalDays,         // Se houver additionalDays
        service_log_id: bookingToEdit.service_log_id,  // Mantém o mesmo ticket associado
        mechanic_id: data.mechanic_id || bookingToEdit.mechanic_id,
      };
  
      console.log('Payload for update booking:', payload);
  
      const response = await fetch(`${API_BASE_URL}/api/mechanics/bookings/${bookingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const updatedBooking = await response.json();
        console.log('Booking updated:', updatedBooking);

        const updatedEvent = {
          id: `booking-${updatedBooking.id}`, // mesmo id usado anteriormente
          title: updatedBooking.mechanic_name
            ? `${updatedBooking.mechanic_name} - ${updatedBooking.car_brand} ${updatedBooking.car_model}`
            : `${updatedBooking.car_brand} ${updatedBooking.car_model}`,
          start: `${updatedBooking.booking_date}T${updatedBooking.start_time}`,
          end: `${updatedBooking.booking_date}T${updatedBooking.end_time}`,
          color: updatedBooking.color || '#007bff',
          description: updatedBooking.issue_description || '',
          type: 'booking',
        };
        
        // Atualiza o estado dos eventos
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === updatedEvent.id ? updatedEvent : event
          )
        );

        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.refetchEvents();
        }
        setBookingMechanicModalIsOpen(false);
        window.location.href = '/calendar?booking=mechanic';
        toast.success(t('Booking updated successfully.'));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || t('Error updating booking.'));
      }
    } catch (error) {
      console.error('Error updating booking:', error);
      toast.error(t('Error updating booking.'));
    }
  };

  const handleSaveAppointment = async (data) => {
    try {
      const token = sessionStorage.getItem('token');
  
      const dateObj = new Date(data.appointmentDate);
  
      const minutesToAdd = Number(data.duration) || 0;
      const endObj = new Date(dateObj.getTime() + minutesToAdd * 60 * 1000);
  
      const formatToMySQL = (dateVal) => {
        return moment(dateVal).format('YYYY-MM-DD HH:mm:ss');
      };
  
      const startTime = formatToMySQL(dateObj);
      const endTime = formatToMySQL(endObj);
  
      const payload = {
        client_name: data.clientName,
        client_phone_country: data.clientPhoneCountry,
        client_phone: data.clientPhone,
        car_brand: data.carBrand,
        car_model: data.carModel,
        car_year: data.carYear,
        car_plate: data.carPlate,
        car_mileage: data.carMileage,
        description: data.description,
        short_description: data.short_description,
        start_time: startTime,
        end_time: endTime,
        color: '#007bff'
      };
  
      // 5) Fazer o POST
      const response = await fetch(`${API_BASE_URL}/api/appointments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const newAppointment = await response.json();
  
        const formattedAppointment = {
          id: newAppointment.id,
          title: newAppointment.client_name,
          start: moment(newAppointment.start_time, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          end: moment(newAppointment.end_time, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          color: newAppointment.color || '#007bff',
          description: newAppointment.description || '',
          short_description: newAppointment.short_description || '',
          phone: newAppointment.client_phone,
          car_brand: newAppointment.car_brand,
          car_model: newAppointment.car_model,
          car_plate: newAppointment.car_plate,
          car_year: newAppointment.car_year,
          car_mileage: newAppointment.car_mileage,
          type: 'appointment',
        };
        setAppointments((prev) => {
          const updated = [...prev, formattedAppointment];
          return updated;
        });
        toast.success(t('Appointment created successfully.'));
        setModalIsOpen(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || t('Error creating appointment.'));
      }
    } catch (error) {
      console.error('Error creating appointment:', error);
      toast.error(t('Error creating appointment.'));
    }
  };

  const handleUpdateAppointment = async (appointmentId, data) => {
    try {
      const token = sessionStorage.getItem('token');
  
      // Valida se a data do agendamento foi definida e é válida
      if (!data.appointmentDate) {
        toast.error(t('Appointment date is required.'));
        return;
      }
      const dateObj = new Date(data.appointmentDate);
      if (isNaN(dateObj.getTime())) {
        toast.error(t('Invalid appointment date.'));
        return;
      }
  
      // Valida a duração (deve ser um número maior que zero)
      const minutesToAdd = Number(data.duration);
      if (isNaN(minutesToAdd) || minutesToAdd <= 0) {
        toast.error(t('Invalid duration.'));
        return;
      }
  
      // Calcula o horário final com base na duração
      const endObj = new Date(dateObj.getTime() + minutesToAdd * 60000);
  
      // Função para formatar datas no padrão MySQL
      const formatToMySQL = (dateVal) => moment(dateVal).format('YYYY-MM-DD HH:mm:ss');
  
      const startTime = formatToMySQL(dateObj);
      const endTime = formatToMySQL(endObj);
  
      // Monta o payload com os dados do agendamento
      const payload = {
        client_name: data.clientName,
        client_phone_country: data.clientPhoneCountry,
        client_phone: data.clientPhone,
        car_brand: data.carBrand,
        car_model: data.carModel,
        car_year: data.carYear,
        car_plate: data.carPlate,
        car_mileage: data.carMileage,
        description: data.description,
        short_description: data.short_description,
        start_time: startTime,
        end_time: endTime,
        color: '#007bff'
      };
  
      console.log('Enviando atualização para API:', payload);
  
      const response = await fetch(`${API_BASE_URL}/api/appointments/${appointmentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const updatedAppointment = await response.json();
        console.log('Resposta da atualização:', updatedAppointment);
  
        // Formata os dados retornados para o formato esperado pelo FullCalendar
        const formattedUpdated = {
          id: updatedAppointment.id,
          title: updatedAppointment.name, // Alterado de client_name para name
          start: moment(updatedAppointment.start_time).toISOString(),
          end: moment(updatedAppointment.end_time).toISOString(),
          color: updatedAppointment.color || '#007bff',
          description: updatedAppointment.description || '',
          short_description: updatedAppointment.short_description || '',
          phone_country: updatedAppointment.phone_country,
          phone: updatedAppointment.phone,
          car_brand: updatedAppointment.car_brand,
          car_model: updatedAppointment.car_model,
          car_plate: updatedAppointment.car_plate,
          car_year: updatedAppointment.car_year,
          car_mileage: updatedAppointment.car_mileage,
          type: 'appointment',
        };
  
        // Atualiza o estado de appointments e events mesclando os dados novos
        setAppointments((prev) =>
          prev.map((appt) => (appt.id === appointmentId ? formattedUpdated : appt))
        );
        setEvents((prevEvents) =>
          prevEvents.map((event) => (event.id === appointmentId ? formattedUpdated : event))
        );
  
        // Se necessário, refetch dos eventos (opcional, pois os estados já foram atualizados)
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.refetchEvents();
        }
  
        toast.success(t('Appointment updated successfully.'));
        setModalIsOpen(false);
      } else {
        const errorData = await response.json();
        console.error('Erro na atualização:', errorData);
        toast.error(errorData.message || t('Error updating appointment.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar appointment:', error);
      toast.error(t('Error updating appointment.'));
    }
  };

  const handleEventClick = (info) => {
    console.log("Evento Clicado:", info.event.id);
  
    // Encontra o evento clicado, tratando o caso em que o id pode vir com prefixo "booking-"
    const clickedEvent = events.find((e) =>
      String(e.id) === String(info.event.id) ||
      String(e.id) === String(info.event.id).replace(/^booking-/, '')
    );
  
    if (!clickedEvent) {
      console.error('Evento não encontrado:', info.event.id);
      toast.error(t('Event not found.'));
      return;
    }
  
    // Define o tipo do evento (em minúsculas) com fallback para 'appointment'
    const eventType = clickedEvent.type?.toLowerCase() || 'appointment';
    console.log("Tipo de evento detectado:", eventType);
  
    // Cria o objeto base do infobox, garantindo que a propriedade 'name' seja definida para todos os tipos
    let infoboxData = {
      id: clickedEvent.id,
      type: eventType,
      description: clickedEvent.description || 'N/A',
      name: '', // Será definida abaixo conforme o tipo do evento
    };
  
    if (eventType === 'due_date') {
      // Para due_date, definimos um nome padrão que exibe o cliente
      infoboxData = {
        ...infoboxData,
        name: `${t('Deliverie for')} ${clickedEvent.client_name || '-'}`,
        client_name: clickedEvent.client_name || '-',
        client_phone: clickedEvent.client_phone || '-',
        car_brand: clickedEvent.car_brand || '',
        car_model: clickedEvent.car_model || '',
        due_date: clickedEvent.due_date ? moment(clickedEvent.due_date).format('DD-MM-YYYY HH:mm:ss') : '-',
        ticketNumber: clickedEvent.ticketNumber || '-',
      };
    } else if (eventType === 'booking') {
      // Para booking, usamos o nome do mecânico como título
      infoboxData = {
        ...infoboxData,
        name: clickedEvent.mechanic_name || '-',
        mechanic_name: clickedEvent.mechanic_name || '-',
        car_brand: clickedEvent.car_brand || '',
        car_model: clickedEvent.car_model || '',
        car_plate: clickedEvent.car_plate || '',
        car_year: clickedEvent.car_year || '',
        car_mileage: clickedEvent.car_mileage || '',
        ticketNumber: clickedEvent.ticketNumber || '-',
        ticketOpenedOn: clickedEvent.ticketOpenedOn || '-',
        is_deleted: clickedEvent.is_deleted || '-',
        status: clickedEvent.status || 6,
      };
    } else {
      // Caso padrão para appointments
      const countryOption = countryOptions.find(
        (option) =>
          option.value.toLowerCase() === (clickedEvent.phone_country || '').toLowerCase()
      );
      const dialCode = countryOption ? countryOption.dialCode : clickedEvent.phone_country || '';
      infoboxData = {
        ...infoboxData,
        name: clickedEvent.title || '-',
        phone_country: dialCode, // Use o dial code em vez da sigla
        phone: clickedEvent.phone || '-',
        car_brand: clickedEvent.car_brand || '',
        car_model: clickedEvent.car_model || '',
        car_plate: clickedEvent.car_plate || '',
        car_year: clickedEvent.car_year || '',
        car_mileage: clickedEvent.car_mileage || '',
        short_description: clickedEvent.short_description || '',
        start: clickedEvent.start ? moment(clickedEvent.start).format('YYYY-MM-DD HH:mm') : '-',
        end: clickedEvent.end ? moment(clickedEvent.end).format('YYYY-MM-DD HH:mm') : '-',
        ticket_active: clickedEvent.ticket_active,
      };
    }
    
    setInfoBox(infoboxData);
  
    // Define dimensões do popup com base no tipo do evento
    let popupWidth, popupHeight;
    if (eventType === 'booking' || eventType === 'due_date') {
      popupWidth = 400; // Maior largura para popups com mais informações
      popupHeight = 300;
    } else {
      popupWidth = 280; // Dimensões padrão para appointments
      popupHeight = 200;
    }
  
    // Calcula a posição do popup com base nas coordenadas do clique
    const { clientX: xClick, clientY: yClick } = info.jsEvent;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
  
    let adjustedLeft = xClick + 10;
    let adjustedTop = yClick + 10;
  
    // Ajusta a posição horizontal se o popup ultrapassar a largura da tela
    if (adjustedLeft + popupWidth > screenWidth) {
      adjustedLeft = xClick - popupWidth - 10;
    }
    // Ajusta a posição vertical se o popup ultrapassar a altura da tela
    if (adjustedTop + popupHeight > screenHeight) {
      adjustedTop = yClick - popupHeight - 10;
    }
  
    console.log(`Popup ajustado para posição: top=${adjustedTop}, left=${adjustedLeft}`);
  
    setPopupPosition({
      top: adjustedTop,
      left: adjustedLeft,
    });
  
    // Exibe o popup
    setIsPopupVisible(true);
  };

  const handleActivateTicket = async (ticketId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/activate/${ticketId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ is_deleted: '0' }),
      });
      if (response.ok) {
        toast.success(t('Ticket activated successfully.'));
        setInfoBox((prev) => ({ ...prev, is_deleted: '0' }));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || t('Error activating ticket.'));
      }
    } catch (error) {
      console.error('Error activating ticket:', error);
      toast.error(t('Error activating ticket.'));
    }
  };

  const handleDeleteBooking = async (bookingId) => {
    try {
      // Se o bookingId vier com prefixo, remova-o:
      const numericBookingId = bookingId.replace(/booking-/g, '');
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/mechanics/bookings/${numericBookingId}/delete`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao deletar booking.');
      }
      toast.success(t('Booking deleted successfully.'));
  
      // Atualize o estado para remover o booking deletado
      setMechanicBookings((prevBookings) =>
        prevBookings.filter((booking) => booking.id !== bookingId)
      );
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== bookingId)
      );
  
      // Se você tiver uma referência para o calendário, remova o evento da instância
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        const eventToRemove = calendarApi.getEventById(bookingId);
        if (eventToRemove) {
          eventToRemove.remove();
        }
      }
      setIsPopupVisible(false);
    } catch (error) {
      console.error('Error deleting booking:', error);
      toast.error(error.message || t('Error deleting booking.'));
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/appointments`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar agendamentos.');
      }

      const data = await response.json();

      // Formatar os dados no formato esperado pelo FullCalendar
      const formattedAppointments = data.map((event) => {
        let color = event.color || '#007bff'; // cor padrão azul
      
        // Verifica se o start_time está antes da data atual (comparando apenas a data)
        if (moment(event.start_time).isBefore(moment(), 'day')) {
          // Se o ticket não estiver ativo, muda para vermelho; se estiver ativo, cinza claro.
          color = event.ticket_active === '1' ? '#c3c3c3' : '#ff0000';
        }
      
        return {
          id: event.id,
          title: event.name,
          start: moment.tz(event.start_time, 'Europe/Rome').format(),
          end: moment.tz(event.end_time, 'Europe/Rome').format(),
          color,
          description: event.description || '',
          short_description: event.short_description || '',
          phone: event.phone,
          phone_country: event.phone_country,
          car_brand: event.car_brand,
          car_model: event.car_model,
          car_plate: event.car_plate,
          car_year: event.car_year,
          car_mileage: event.car_mileage,
          ticket_active: event.ticket_active,
        };
      });

      setAppointments(formattedAppointments); // Atualiza o estado
    } catch (error) {
      console.error('Erro ao buscar agendamentos:', error);
    }
  };

  const fetchDueDates = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/service_logs/due_dates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(t('Failed to fetch due dates.'));
      }

      const data = await response.json();

      // Formate os dados para o FullCalendar
      const formattedDueDates = data.map((log) => ({
        id: log.id, // ID único
        title: `${t('Deliverie for')} ${log.client_name}`,
        start: moment(log.due_date).format(),
        end: moment(log.due_date).add(1, 'hour').format(),
        color: '#ff5722',
        description: log.description || '',
        client_name: log.client_name,
        client_phone: log.client_phone,
        due_date: log.due_date,
        car_brand: log.car_brand,
        car_model: log.car_model,
        ticketNumber: log.id,
        type: 'due_date',
      }));

      setDueDateEvents(formattedDueDates);
    } catch (error) {
      console.error('Error fetching due dates:', error);
      toast.error(error.message || t('Error fetching due dates.'));
    }
  };

  useEffect(() => {
    if (dueDateEvents) {
      fetchDueDates();
    } else {
      setDueDateEvents([]);
    }
  }, [dueDateEvents]);

  const fetchBookingById = async (bookingId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/mechanics/bookings/${bookingId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar os detalhes do booking.');
      }
      const bookingData = await response.json();
      console.log('Booking completo:', bookingData);
      return bookingData;
    } catch (error) {
      console.error('Erro no fetchBookingById:', error);
      toast.error(error.message);
      return null;
    }
  };

  useEffect(() => {
    let filtered = [];
    if (selectedFilter === 'showMechanicWork') {
      // Se nenhum mecânico estiver selecionado, mostra todos os bookings dos mecânicos
      filtered = selectedMechanic 
        ? mechanicBookings.filter((booking) => booking.mechanic_id === selectedMechanic.id)
        : mechanicBookings;
    } else if (selectedFilter === 'showAppointments') {
      filtered = appointments;
    } else if (selectedFilter === 'showDueDates') {
      filtered = dueDateEvents;
    }
    setEvents(filtered);
  }, [selectedFilter, selectedMechanic, mechanicBookings, appointments, dueDateEvents]);

  // Chama o fetchAppointments ao carregar o componente
  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('calendar')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('calendar')}
                </li>
              </ol>
            </nav>
          </div>
          {["admin", "receptionist"].includes(role) && selectedFilter !== 'showDueDates' && (
            <button className="btn btn-primary ms-auto" onClick={handleOpenModal}>
              {selectedFilter === 'showMechanicWork' ? t('Booking Mechanic') : t('newSchedule')}
            </button>
          )}
        </div>
        {["admin", "receptionist"].includes(role) && (
        <>
          <div className="filters d-flex align-items-center mb-3">
            <div className="form-check me-3">
              <input
                type="radio"
                className="form-check-input"
                id="showAppointments"
                name="calendarFilter"
                value="showAppointments"
                checked={selectedFilter === 'showAppointments'}
                onChange={(e) => setSelectedFilter(e.target.value)}
              />
              <label className="form-check-label" htmlFor="showAppointments">
                {t('Show Appointments')}
              </label>
            </div>
          
            <div className="form-check me-3">
              <input
                type="radio"
                className="form-check-input"
                id="showMechanicWork"
                name="calendarFilter"
                value="showMechanicWork"
                checked={selectedFilter === 'showMechanicWork'}
                onChange={(e) => setSelectedFilter(e.target.value)}
              />
              <label className="form-check-label" htmlFor="showMechanicWork">
                {t('Show Mechanic Work')}
              </label>
            </div>
          
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="showDueDates"
                name="calendarFilter"
                value="showDueDates"
                checked={selectedFilter === 'showDueDates'}
                onChange={(e) => setSelectedFilter(e.target.value)}
              />
              <label className="form-check-label" htmlFor="showDueDates">
                {t('Show Due Dates')}
              </label>
            </div>
          </div>

          {selectedFilter === 'showMechanicWork' && (
            <div className="mechanic-buttons">
              <button
                className={`mechanic-button ${selectedMechanic === null ? 'active' : ''}`}
                onClick={() => setSelectedMechanic(null)}
              >
                {t('All Mechanics')}
              </button>
              {mechanics.map((mechanic) => (
                <button
                  key={mechanic.id}
                  className={`mechanic-button ${selectedMechanic && selectedMechanic.id === mechanic.id ? 'active' : ''}`}
                  onClick={() => handleMechanicClick(mechanic)}
                >
                  {mechanic.name}
                </button>
              ))}
            </div>
          )}
        </>
      )}
       <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
          initialView="dayGridWeek"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridWeek,dayGridMonth,listWeek',
          }}
          locale={languageOptions[locale]}
          nowIndicator
          selectable
          eventOverlap={false}
          dayMaxEvents={true}
          expandRows={true}
          height="auto"
          contentHeight="auto"
          displayEventTime={false}
          displayEventEnd={false}
          events={events}
          navLinks
          editable={false}
          eventStartEditable={false}
          eventDurationEditable={false}
          hiddenDays={[0]}
          
          eventClick={handleEventClick}

          eventContent={(arg) => {
            return (
              <div style={{
                padding: '10px',
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#fff',
                backgroundColor: arg.event.backgroundColor || '#007bff',
                borderLeft: '6px solid #FFCC00',
                borderRadius: '8px',
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
              }}>
          
                {/* Horário do evento destacado */}
                <div style={{ 
                  fontSize: '16px', 
                  fontWeight: 'bold', 
                  color: '#FFCC00',
                  textAlign: 'left',
                  marginBottom: '5px',
                }}>
                  {moment(arg.event.start).format('HH:mm')} - {moment(arg.event.end).format('HH:mm')}
                </div>
          
                {/* Nome do cliente e telefone */}
                <div style={{ 
                  fontSize: '14px', 
                  fontWeight: 'bold', 
                  display: 'flex',
                  alignItems: 'left',
                }}>
                  <span style={{ marginLeft: '5px' }}>{arg.event.title}</span>
                </div>
          
                {/* Informações do veículo */}
                {arg.event.extendedProps.car_model && (
                  <div style={{ 
                    fontSize: '13px', 
                    fontWeight: '500', 
                    display: 'flex',
                    alignItems: 'left',
                    marginTop: '4px',
                  }}>
                    <span style={{ marginLeft: '5px' }}>
                      {arg.event.extendedProps.car_brand} {arg.event.extendedProps.car_model} 
                      {arg.event.extendedProps.car_plate ? ` (${arg.event.extendedProps.car_plate})` : ''}
                    </span>
                  </div>
                )}
          
                {/* Descrição curta do serviço */}
                {arg.event.extendedProps.short_description && (
                  <div style={{ 
                    fontSize: '12px', 
                    fontStyle: 'italic', 
                    color: '#f1f1f1',
                    marginTop: '4px',
                  }}>
                    {arg.event.extendedProps.short_description}
                  </div>
                )}

                {arg.event.extendedProps.type === 'booking' && (
                  <div>
                    <div className={`ticket-status ${mapStatus(arg.event.extendedProps.status).className}`} style={{ fontSize: '0.8rem' }}>
                      {mapStatus(arg.event.extendedProps.status).translation}
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        />
        
        {isPopupVisible && infoBox && (
          <div 
            ref={infoBoxRef}
            className="info-box"
            style={{ 
              position: 'absolute', 
              top: popupPosition.top, 
              left: popupPosition.left
             }}
          >
            <button className="close-btn" onClick={() => setIsPopupVisible(false)}>
              &times;
            </button>
            <h4>
              {infoBox.type === 'booking'
                ? t('Booking Details')
                : infoBox.type === 'due_date'
                  ? t('Due Date Details')
                  : t('Appointment Details')}
            </h4>

            {/* Informações específicas para bookings */}
            {infoBox.type === 'booking' && (
              <>
                <p><strong>{t('Mechanic Name')}:</strong> {infoBox.mechanic_name}</p>
                <p><strong>{t('Car')}:</strong> {infoBox.car_brand} {infoBox.car_model}</p>
                <p><strong>{t('Car Plate')}:</strong> {infoBox.car_plate}</p>
                <p><strong>{t('Ticket Number')}:</strong> {infoBox.ticketNumber}</p>
                <p><strong>{t('Ticket Opened On')}:</strong> {infoBox.ticketOpenedOn}</p>
                <div className="btn-container">
                  {infoBox.is_deleted === '1' || infoBox.is_deleted === 1 ? (
                    <button
                      className="btn btn-warning"
                      onClick={() => handleActivateTicket(infoBox.ticketNumber)}
                    >
                      {t('Activate')}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => window.location.href = `/tickets/${infoBox.ticketNumber}`}
                    >
                      {t('Open Ticket')}
                    </button>
                  )}
                  {/* Botão para editar o booking */}
                  <button
                    className="btn btn-info"
                    onClick={async () => {
                      setEditMode(true);
                      const numericBookingId = infoBox.id.replace(/booking-/g, '');
                      const fetchedBooking = await fetchBookingById(numericBookingId);
                      if (fetchedBooking) {
                        setBookingToEdit({
                          id: fetchedBooking.bookingId || fetchedBooking.id,
                          clientName: fetchedBooking.clientName,
                          clientPhoneCountry: fetchedBooking.clientPhoneCountry,
                          clientPhone: fetchedBooking.clientPhone,
                          carBrand: fetchedBooking.carBrand,
                          carModel: fetchedBooking.carModel,
                          carYear: fetchedBooking.carYear,
                          carPlate: fetchedBooking.carPlate,
                          carMileage: fetchedBooking.carMileage,
                          issue_description: fetchedBooking.issue_description,
                          booking_date: fetchedBooking.booking_date,
                          start_time: fetchedBooking.start_time,
                          duration: fetchedBooking.estimated_time,
                          ticket_id: fetchedBooking.ticket_id,
                          bookingMechanicId: fetchedBooking.bookingMechanicId,
                          mechanicName: fetchedBooking.mechanicName,
                          service_log_id: fetchedBooking.service_log_id,
                          additionalDays: fetchedBooking.additionalDays,
                        });
                        setBookingMechanicModalIsOpen(true);
                        setIsPopupVisible(false);
                      }
                    }}
                  >
                    {t('Edit')}
                  </button>
                  {/* Novo botão para deletar o booking */}
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteBooking(infoBox.id)}
                  >
                    {t('Delete')}
                  </button>
                </div>
              </>
            )}

            {/* Informações específicas para due_dates */}
            {infoBox.type === 'due_date' && (
              <>
                <p><strong>{t('Client Name')}:</strong> {infoBox.client_name}</p>
                <p><strong>{t('Client Phone')}:</strong> {infoBox.client_phone}</p>
                <p><strong>{t('Car')}:</strong> {infoBox.car_brand} {infoBox.car_model}</p>
                <p><strong>{t('Delivery Time')}:</strong> {infoBox.due_date}</p>
                <p><strong>{t('Ticket Number')}:</strong> {infoBox.ticketNumber}</p>
                <div className="btn-container">
                  <button
                    className="btn btn-primary"
                    onClick={() => window.location.href = `/tickets/${infoBox.ticketNumber}`}
                  >
                    {t('Open Ticket')}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => setIsPopupVisible(false)}
                  >
                    {t('Close')}
                  </button>
                </div>
              </>
            )}

            {/* Informações específicas para appointments */}
            {infoBox.type === 'appointment' && (
              <>
                <p><strong>{t('Phone')}:</strong> {infoBox.phone_country}{infoBox.phone}</p>
                {infoBox.description && (
                  <p>
                    <strong>{t('Description')}:</strong>
                    <br />
                    {infoBox.description.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                )}
                <div className="btn-container">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setEditMode(true);
                      setAppointmentToEdit({
                        id: infoBox.id,
                        clientName: infoBox.name,
                        clientPhoneCountry: infoBox.phone_country,
                        clientPhone: infoBox.phone,
                        car_brand: infoBox.car_brand,
                        car_model: infoBox.car_model,
                        car_year: infoBox.car_year,
                        car_plate: infoBox.car_plate,
                        car_mileage: infoBox.car_mileage,
                        description: infoBox.description,
                        short_description: infoBox.short_description,
                        start_time: infoBox.start,
                        end_time: infoBox.end,
                        ticket_active: infoBox.ticket_active,
                      });
                      setModalIsOpen(true);
                      setIsPopupVisible(false);
                    }}
                  >
                    {t('Edit')}
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteAppointment(infoBox.id)}
                  >
                    {t('Delete')}
                  </button>
                  <button onClick={handleWhatsAppReminder} className="btn btn-info">
                    <FaPaperPlane style={{ color: '#fff' }}/>
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {/* Modal para criar/editar agendamento */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel={editMode ? "Edit Appointment Modal" : "Create Appointment Modal"}
          className="custom-modal"
          overlayClassName="custom-modal-overlay"
        >
          <CalendarModalContent
            onClose={handleCloseModal}
            onSave={handleSaveAppointment}
            onUpdate={handleUpdateAppointment}
            editMode={editMode}
            appointmentToEdit={appointmentToEdit}
          />
        </Modal>

        <Modal
          isOpen={bookingMechanicModalIsOpen}
          onRequestClose={() => setBookingMechanicModalIsOpen(false)}
          contentLabel="Booking Mechanic Modal"
          className="custom-modal"
          overlayClassName="custom-modal-overlay"
        >
          <BookingMechanicModalContent
            onClose={handleCloseModal}
            selectedMechanic={selectedMechanic}
            onSaveBooking={handleSaveBookingMechanic}
            onUpdateBooking={handleUpdateBookingMechanic}
            editMode={editMode}
            bookingToEdit={bookingToEdit}
            ticketOption={ticketOption}
            selectedTicket={selectedTicket}
            setTicketOption={setTicketOption}
            setSelectedTicket={setSelectedTicket}
          />
        </Modal>

      </div>
      <ToastContainer />
    </div>
  );
}

export default withAuth(Calendar, ['admin', 'mechanic', 'receptionist']);