import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Timer.css';
import { useTranslation } from 'react-i18next';

function Timer({ totalTime, isRunning, startTime }) {
  const { t } = useTranslation();
  const [displayTime, setDisplayTime] = useState(totalTime || 0);

  useEffect(() => {
    let interval = null;

    if (isRunning && startTime) {
      interval = setInterval(() => {
        const now = new Date();
        const start = new Date(startTime);
        const elapsed = Math.floor((now - start) / 1000);
        setDisplayTime(totalTime + elapsed);
      }, 1000);
    } else {
      setDisplayTime(totalTime || 0);
    }

    return () => clearInterval(interval);
  }, [isRunning, startTime, totalTime]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="timer-container">
      <h5>{t('Total Time')}</h5>
      <div className="timer-display">{formatTime(displayTime)}</div>
    </div>
  );
}

Timer.propTypes = {
  totalTime: PropTypes.number.isRequired,
  isRunning: PropTypes.bool.isRequired,
  startTime: PropTypes.string,
  state: PropTypes.oneOf(['idle', 'running', 'paused']).isRequired,
  onStart: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onResume: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
};

export default Timer;