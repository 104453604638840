import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profile.css';
import flag_it from '../assets/flags/1x1/it.svg';
import flag_br from '../assets/flags/1x1/br.svg';
import flag_en from '../assets/flags/1x1/gb.svg';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import API_BASE_URL from '../config';

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    preferred_language: '',
    password: '',
  });

  const languageOptions = [
    { value: 'en', label: 'English', icon: flag_en },
    { value: 'pt', label: 'Português', icon: flag_br },
    { value: 'it', label: 'Italiano', icon: flag_it },
  ];

  const { t, i18n } = useTranslation();

  // Fetch user data from the backend
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = sessionStorage.getItem('token'); // Use the token for authentication
        const response = await axios.get(`${API_BASE_URL}/api/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Update state with user data
        const userData = response.data;
        setProfileData({
          name: userData.name,
          email: userData.email,
          preferred_language: userData.preferred_language,
          password: '',
        });
      } catch (error) {
        console.error('Erro ao buscar dados do perfil:', error);
      }
    };

    fetchProfileData();
  }, []);

  // Handle changes in form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Atualizar idioma no banco de dados e no sistema
  const handleLanguageChange = async (lang) => {
    try {
      // Atualiza o idioma no frontend imediatamente
      i18n.changeLanguage(lang);
  
      // Atualiza o idioma no estado para refletir a mudança visual
      setProfileData((prevData) => ({
        ...prevData,
        preferred_language: lang,
      }));
  
      // Atualiza o idioma no backend
      const token = sessionStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/profile`,
        { preferred_language: lang },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error('Erro ao alterar o idioma:', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = sessionStorage.getItem('token');
      const updatedData = {};

      // Only send the fields that were modified
      if (profileData.password) updatedData.password = profileData.password;
      if (profileData.preferred_language) updatedData.preferred_language = profileData.preferred_language;

      await axios.put(`${API_BASE_URL}/api/profile`, updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProfileData((prevData) => ({ ...prevData, password: '' })); // Clear password field after saving
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('profile')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('settings')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <form className="row g-3 needs-validation" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">{t('name')}:</label>
                    <input
                      id="name"
                      disabled
                      className="form-control"
                      type="text"
                      name="name"
                      value={profileData.name}
                      onChange={handleInputChange}
                      placeholder={t('enter_name')}
                    />
                    <br />
                    <label htmlFor="email">{t('email')}:</label>
                    <input
                      id="email"
                      disabled
                      className="form-control"
                      type="email"
                      name="email"
                      value={profileData.email}
                      onChange={handleInputChange}
                      placeholder={t('enter_email')}
                    />
                    <br />
                    <label htmlFor="preferred_language">{t('language')}:</label>
                    <div className="custom-select">
                      {languageOptions.map((option) => (
                        <div
                          key={option.value}
                          className={`custom-option ${profileData.preferred_language === option.value ? 'selected' : ''}`}
                          onClick={() => handleLanguageChange(option.value)}
                        >
                          <img src={option.icon} alt={option.label} className="language-icon" />
                          {option.label}
                        </div>
                      ))}
                    </div>
                    <br />
                    <label htmlFor="password">{t('new_password')}:</label>
                    <input
                      id="password"
                      className="form-control"
                      type="password"
                      name="password"
                      value={profileData.password}
                      onChange={handleInputChange}
                      placeholder={t('enter_new_password')}
                    />
                   <br />
                    <button className="btn btn-primary" type="submit">
                      {t('save_changes')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Profile, ['admin', 'mechanic', 'receptionist']);