
const initialCarBrands = [
    {
        value: 'Toyota',
        label: 'Toyota',
        models: ['Corolla', 'Camry', 'RAV4', 'Highlander', 'Prius', 'Tacoma', 'Yaris', 'Avalon', '4Runner', 'Sequoia', 'Tundra', 'Sienna', 'Land Cruiser', 'C-HR', 'Supra', 'Venza', 'Matrix', 'FJ Cruiser', 'Hilux', 'Fortuner', 'Innova', 'Etios'],
    },
    {
        value: 'Ford',
        label: 'Ford',
        models: ['Fiesta', 'Focus', 'Mustang', 'Explorer', 'Ranger', 'Edge', 'Escape', 'Expedition', 'Bronco', 'Bronco Sport', 'F-150', 'F-250', 'F-350', 'Transit', 'Transit Connect', 'EcoSport', 'Taurus', 'Fusion', 'Maverick', 'Galaxy', 'Mondeo', 'C-Max', 'S-Max', 'Kuga', 'Puma', 'Flex', 'Thunderbird', 'Cortina', 'Falcon', 'Courier', 'Probe', 'Ka', 'Everest', 'Territory'],
    },
    {
        value: 'Chevrolet',
        label: 'Chevrolet',
        models: ['Cruze', 'Malibu', 'Silverado', 'Tahoe', 'Equinox', 'Camaro', 'Impala', 'Trailblazer', 'Suburban', 'Traverse', 'Colorado', 'Blazer', 'Corvette', 'Spark', 'Bolt EV', 'Sonic', 'Avalanche', 'Captiva', 'Orlando', 'HHR', 'Monte Carlo', 'Uplander', 'Astro', 'LUV', 'Cobalt', 'Venture', 'Express'],
    },
    {
        value: 'Honda',
        label: 'Honda',
        models: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Fit', 'HR-V', 'Odyssey', 'Passport', 'Ridgeline', 'Insight', 'Element', 'Prelude', 'S2000', 'Clarity', 'Del Sol', 'CR-Z', 'Brio', 'Amaze', 'City', 'Jazz', 'Shuttle', 'Stream', 'Freed', 'Legend', 'Elysion', 'Acty', 'Beat'],
    },
    {
        value: 'Nissan',
        label: 'Nissan',
        models: ['Altima', 'Sentra', 'Rogue', 'Murano', 'Frontier', 'Leaf', 
                'Maxima', 'Pathfinder', 'Armada', 'Versa', 'Titan', 'Kicks', 
                'GT-R', 'Juke', 'Qashqai', '370Z', 'Navara', 'X-Trail', 
                'Patrol', 'Terrano', 'Magnite', 'Sunny', 'Bluebird', 
                'Stagea', 'Cube', 'March', 'Note', 'Micra'],
    },
    {
        value: 'Volkswagen',
        label: 'Volkswagen',
        models: ['Golf', 'Passat', 'Jetta', 'Tiguan', 'Atlas', 
                'Beetle', 'Polo', 'Arteon', 'Touareg', 'ID.4', 
                'ID.3', 'ID.5', 'ID.Buzz', 'Taos', 'Amarok', 
                'Caddy', 'California', 'Transporter', 'Multivan', 
                'Caravelle', 'Up!', 'Scirocco', 'Corrado', 'Fox', 
                'Sharan', 'Touran', 'Eos', 'Routan', 'CrossFox', 
                'Saveiro', 'Voyage', 'Gol', 'Virtus', 'T-Cross', 
                'Nivus', 'Teramont'],
    },
    {
        value: 'BMW',
        label: 'BMW',
        models: ['1 Series', '2 Series', '3 Series', '4 Series', '5 Series', '6 Series', '7 Series', 
                '8 Series', 'X1', 'X2', 'X3', 'X4', 'X5', 'X6', 'X7', 'Z3', 'Z4', 'i3', 'i4', 
                'i7', 'i8', 'iX', 'iX3', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M8', 'X5 M', 
                'X6 M', 'X3 M', 'X4 M', 'Z8', 'ActiveHybrid 3', 'ActiveHybrid 5', 'ActiveHybrid 7', 
                '2 Series Active Tourer', '2 Series Gran Tourer', 'Gran Coupe', 'Gran Turismo'],
    },
    {
        value: 'Mercedes-Benz',
        label: 'Mercedes-Benz',
        models: ['C-Class', 'E-Class', 'GLC', 'GLE', 'A-Class', 'S-Class', 'GLA', 'GLS', 
                'CLS', 'AMG GT', 'G-Class', 'SL', 'SLK', 'CLA', 'B-Class', 'V-Class', 
                'Sprinter', 'Maybach S-Class', 'EQC', 'EQS', 'EQA', 'EQB', 'EQV'],
    },
    {
        value: 'Audi',
        label: 'Audi',
        models: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 
                'Q2', 'Q3', 'Q4', 'Q5', 'Q7', 'Q8', 
                'TT', 'TTS', 'TT RS', 
                'R8', 'RS3', 'RS4', 'RS5', 'RS6', 'RS7', 
                'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 
                'e-tron', 'e-tron GT', 'Q4 e-tron', 'Q8 e-tron', 
                'Allroad', 'Cabriolet', 'Coupe', 'Sportback', 'Avant'],
    },
    {
        value: 'Hyundai',
        label: 'Hyundai',
        models: ['Elantra', 'Sonata', 'Santa Fe', 'Tucson', 'Kona', 
                'Palisade', 'Veloster', 'Ioniq', 'Genesis', 
                'Accent', 'Creta', 'Venue', 'Verna', 'Getz', 
                'Atos', 'Terracan', 'Starex', 'Galloper', 'Matrix', 
                'Azera', 'Entourage', 'Grandeur', 'Equus', 'Trajet', 
                'Scoupe', 'H1', 'H100', 'Nexo'],
    },
    {
        value: 'Kia',
        label: 'Kia',
        models: ['Rio', 'Sportage', 'Sorento', 'Optima', 'Stinger', 
                'Seltos', 'Carnival', 'Soul', 'Telluride', 'Forte', 
                'Picanto', 'Ceed', 'Stonic', 'Niro', 'Carens', 
                'EV6', 'K900', 'Mohave', 'Rondo', 'Cadenza', 
                'Borrego', 'Sedona', 'Cerato', 'ProCeed', 'K5', 
                'K8', 'K3', 'XCeed', 'Spectra', 'Shuma'],
    },
    {
        value: 'Fiat',
        label: 'Fiat',
        models: ['500', 'Panda', 'Tipo', 'Punto', 'Doblo', 'Uno', 
                'Palio', 'Siena', 'Linea', 'Bravo', 'Brava', 
                'Marea', 'Strada', 'Toro', 'Fiorino', 'Ducato', 
                'Freemont', 'Multipla', 'Stilo', 'Idea', 'Albea', 
                'Tempra', 'Argenta', 'Croma', 'Ritmo', 'Regata', 
                'Sedici', 'Qubo', 'Ulysse', 'Seicento', 'Cinquecento', 
                'X1/9', '124 Spider', '850', '600', '1100', 
                'Campagnola', 'Barchetta', 'Topolino'],
    },
    {
        value: 'Peugeot',
        label: 'Peugeot',
        models: ['208', '308', '3008', '5008', '2008', '206', 
                '207', '405', '406', '407', '508', '607', 
                '806', '807', '1007', '3008 Hybrid', '4008', 
                'Partner', 'Boxer', 'Expert', 'Traveller', 'Rifter'],
    },
    {
        value: 'Renault',
        label: 'Renault',
        models: ['Clio', 'Megane', 'Captur', 'Kadjar', 'Talisman', 'Scenic', 
                'Kangoo', 'Trafic', 'Master', 'Zoe', 'Twizy', 'Fluence', 
                'Espace', 'Laguna', 'Modus', 'Latitude', 'Koleos', 
                'Arkana', 'Duster', 'Sandero', 'Logan', 'Symbol', 
                'Wind', 'Vel Satis', 'Avantime', 'Alaskan', '4', 
                '5', '9', '11', '21', '25', 'Fuego', 'Safrane', 'Express'],
    },
    {
        value: 'Opel',
        label: 'Opel',
        models: ["Astra", "Corsa", "Insignia", "Mokka", "Crossland", "Grandland", 
                "Zafira", "Vivaro", "Combo", "Movano", "Adam", "Karl", 
                "Meriva", "Antara", "Vectra", "Signum", "Omega", "Tigra", 
                "Agila", "Frontera", "Ampera", "Monza", "Kadett", "Ascona", 
                "Calibra", "Rekord", "Senator", "Diplomat", "GT", "Manta"],
    },
    {
        value: 'Skoda',
        label: 'Skoda',
        models: ["Fabia", "Octavia", "Superb", "Karoq", "Kodiaq", "Scala", 
                "Kamiq", "Enyaq iV", "Rapid", "Citigo", "Roomster", 
                "Yeti", "Felicia", "Favorit", "Forman", "120", "130", 
                "110", "100", "Citigo-e iV"],
    },
    {
        value: 'Mazda',
        label: 'Mazda',
        models: ["Mazda2", "Mazda3", "Mazda6", "CX-3", "CX-30", "CX-5", "CX-50",
                "CX-60", "CX-90", "MX-5 Miata", "MX-30", "RX-7", "RX-8", 
                "BT-50", "Mazda5", "Tribute", "B-Series", "Premacy", "Protege", 
                "626", "323", "Demio", "Familia", "Millenia", "MPV", "Cosmo"],
    },
    {
        value: 'Jaguar',
        label: 'Jaguar',
        models: ["E-PACE", "F-PACE", "I-PACE", "F-TYPE", "XE", "XF", "XJ", 
                "XK", "XKR", "XK8", "XJ6", "XJ8", "S-Type", "X-Type", 
                "XJ220", "XJR", "XJS", "MK2", "F-PACE SVR", "F-TYPE R"],
    },
    {
        value: 'Land Rover',
        label: 'Land Rover',
        models: ["Defender", "Discovery", "Discovery Sport", "Range Rover", "Range Rover Sport",
                "Range Rover Evoque", "Range Rover Velar", "Freelander", "LR2", "LR3", "LR4"],
    },
    {
        value: 'Porsche',
        label: 'Porsche',
        models: ["911", "Cayenne", "Macan", "Panamera", "Taycan", "718 Boxster", 
                "718 Cayman", "Boxster", "Cayman", "Carrera", "Turbo", 
                "GT3", "GT4", "Targa", "Spyder", "959", "924", 
                "928", "944", "968", "356", "Carrera GT"],
    },
    {
        value: 'Jeep',
        label: 'Jeep',
        models: ["Wrangler", "Grand Cherokee", "Cherokee", "Compass", "Renegade", 
                "Gladiator", "Patriot", "Commander", "Liberty", "Wagoneer", 
                "Grand Wagoneer", "CJ", "Scrambler", "J-Series", "Comanche", 
                "Trailhawk", "Trackhawk", "Overland", "Rubicon", "Laredo", 
                "Sahara", "Willys", "Freedom", "Mojave"],
    },
    {
        value: 'Alfa Romeo',
        label: 'Alfa Romeo',
        models: ["Giulia", "Stelvio", "Tonale", "Giulietta", "4C", "8C", "147", "156", 
                "159", "166", "Spider", "Brera", "GTV", "MiTo", "Alfa 33", "Alfa 75", 
                "Alfa 90", "Alfetta", "SZ", "RZ", "Montreal", "Alfa 145", "Alfa 146", 
                "Alfa 164", "Alfa 6", "Alfasud", "Alfasud Sprint", "GT", "Junior", 
                "Giulia Sprint", "Duetto", "Quadrifoglio"],
    },
    {
        value: 'Seat',
        label: 'Seat',
        models: ["Ibiza", "Leon", "Arona", "Ateca", "Tarraco", "Mii", 
                "Toledo", "Alhambra", "Cordoba", "Exeo", "Altea", 
                "Inca", "Marbella", "Ronda", "Malaga", "Fura", 
                "Panda", "600", "133"],
    },
    {
        value: 'Suzuki',
        label: 'Suzuki',
        models: ["Alto", "Swift", "Baleno", "Celerio", "Ignis", "Wagon R", "Ertiga", 
                "XL6", "Vitara", "Jimny", "S-Cross", "Kizashi", "Grand Vitara", 
                "SX4", "Dzire", "Splash", "Ritz", "Esteem", "Aerio", "Samurai", 
                "Sidekick", "X-90", "Equator", "Cappuccino", "Carry"],
    },
    {
        value: 'Volvo',
        label: 'Volvo',
        models: ["XC40", "XC60", "XC90", "S60", "S90", "V60", "V90", "C40 Recharge", 
                "V60 Cross Country", "V90 Cross Country", "XC40 Recharge", 
                "C30", "C70", "V40", "V50", "V70", "S40", "S80", "740", "760", 
                "780", "850", "940", "960", "Amazon", "PV444", "PV544", "P1800"],
    },
    {
        value: 'Lexus',
        label: 'Lexus',
        models: ["ES", "GS", "IS", "LS", "NX", "RX", "GX", "LX", 
                "UX", "RC", "LC", "CT", "HS", "RX L", "RXh", 
                "NXh", "LC Convertible", "IS F", "LFA", "SC"],
    },
    {
        value: 'Citroen',
        label: 'Citroen',
        models: ["C1", "C2", "C3", "C3 Aircross", "C4", "C4 Cactus", "C4 Picasso", "C5", 
                "C5 Aircross", "C5 X", "C6", "C8", "Berlingo", "Jumpy", "Spacetourer", 
                "Dispatch", "Relay", "DS3", "DS4", "DS5", "Saxo", "Xantia", "XM", "ZX", 
                "AX", "BX", "CX", "Evasion", "Dyane", "Mehari", "Ami", "Traction Avant", 
                "2CV", "Visa", "C15", "HY", "GS", "GSA", "C-Crosser", "C-Zero", "Metropolis", 
                "Pluriel", "Nemo", "C-Elysee"],
    },
    {
        value: 'Mitsubishi',
        label: 'Mitsubishi',
        models: ["Lancer", "Outlander", "Pajero", "Eclipse Cross", "ASX", "Mirage", "Triton", 
                "Montero", "Delica", "Galant", "Eclipse", "RVR", "i-MiEV", "Challenger", 
                "Endeavor", "Sigma", "FTO", "Starion", "GTO", "3000GT", "Shogun", 
                "Diamante", "Space Star", "Colt", "Carisma", "Raider", "Minicab", 
                "Canter", "Express", "Attrage", "Xforce"],
    },
    {
        value: 'Mini',
        label: 'Mini',
        models: ["Cooper", "Cooper S", "Cooper SE", "Cooper SD", "John Cooper Works", 
                "Countryman", "Countryman Plug-In Hybrid", "Clubman", "Paceman", 
                "Convertible", "Roadster", "Coupe", "Hatchback", "Cabrio", 
                "One", "One D", "One First", "Electric", "Mini E"],
    },
    {
        value: 'Smart',
        label: 'Smart',
        models: ["Fortwo", "Fortwo Cabrio", "Forfour", "Crossblade", "Roadster", 
                "Roadster Coupe", "EQ Fortwo", "EQ Forfour", "Smart #1"],
    },
    {
        value: 'Chrysler',
        label: 'Chrysler',
        models: ["300", "300C", "Pacifica", "Pacifica Hybrid", "Voyager", "Aspen", "PT Cruiser", 
                "Sebring", "Crossfire", "Town & Country", "200", "Concorde", "LHS", "LeBaron", 
                "New Yorker", "Imperial", "Fifth Avenue", "Cordoba", "Valiant", "Saratoga", 
                "Windsor", "Newport", "Laser"],
    },
    {
        value: 'Dodge',
        label: 'Dodge',
        models: ["Charger", "Challenger", "Durango", "Journey", "Grand Caravan", "Nitro", 
                "Caliber", "Avenger", "Viper", "Magnum", "Dakota", "Ram 1500", "Ram 2500", 
                "Ram 3500", "Stealth", "Intrepid", "Stratus", "Neon", "Spirit", 
                "Daytona", "Lancer", "Omni", "Dart", "Polara", "Coronet", "Monaco", 
                "Aspen", "Diplomat", "Caravan", "B-Series", "A100", "Custom 880", 
                "Power Wagon", "Raider", "Shadow", "Colt", "Raider", "Aries", "Mirada", 
                "St. Regis", "Aspen SE", "Demon", "Super Bee"],
    },
    {
        value: 'Iveco',
        label: 'Iveco',
        models: ["Daily", "Eurocargo", "Stralis", "Trakker", "S-Way", "PowerStar", 
                "EuroTrakker", "Massif", "Magirus", "TurboDaily", "Zeta", "LMV", 
                "EuroTech", "EuroStar", "Cursor", "EuroClass", "TurboStar", "TurboTech", 
                "Vertis", "Tector", "Citelis", "Urbanway", "Crealis", "Crossway", 
                "Evadys", "Eurobus", "Turboliner", "Irisbus", "Bus", "Camper"],
    },
    {
        value: 'Lancia',
        label: 'Lancia',
        models: ["Ypsilon", "Delta", "Fulvia", "Stratos", "Thema", "Kappa", "Beta", "Montecarlo", 
                "Gamma", "Dedra", "Lybra", "Thesis", "Prisma", "Flavia", "Aurelia", "Appia", 
                "Ardea", "Augusta", "Dilambda", "Astura", "Aprilia", "Phedra", "Zeta", "Voyager", 
                "Trevi", "Scorpion"],
    },
    {
        value: 'Dacia',
        label: 'Dacia',
        models: ["Logan", "Sandero", "Duster", "Spring", "Lodgy", "Dokker", "Solenza", 
                "1300", "1100", "Nova", "Pick-Up", "1304", "Double Cab", "Denem", 
                "SuperNova", "Stepway", "Bigster", "Manifesto"],
    },
    {
        value: 'dR',
        label: 'dR',
        models: ["DR1", "DR2", "DR3", "DR3 EV", "DR4", "DR5", "DR5.0", "DR6", 
                "DR6.0", "DR7", "DR F35", "DR EVO", "DR Zero"],
    },
    {
        value: 'Lamborghini',
        label: 'Lamborghini',
        models: ["Aventador", "Huracán", "Urus", "Gallardo", "Murciélago", 
                "Diablo", "Countach", "Sian", "Reventón", "Veneno", 
                "Centenario", "Miura", "Espada", "Jarama", "Islero", 
                "LM002", "Estoque", "350 GT", "400 GT", "Silhouette", 
                "Jalpa", "Bravo", "Marzal", "Egoista", "Asterion", 
                "Terzo Millennio"],
    },
    {
        value: 'Ferrari',
        label: 'Ferrari',
        models: ["488 GTB", "488 Spider", "812 Superfast", "812 GTS", 
                "California", "California T", "Portofino", "Portofino M", 
                "Roma", "SF90 Stradale", "SF90 Spider", "F8 Tributo", 
                "F8 Spider", "296 GTB", "296 GTS", "LaFerrari", 
                "LaFerrari Aperta", "F12berlinetta", "F12tdf", "GTC4Lusso", 
                "GTC4Lusso T", "Enzo", "Dino 246", "250 GTO", 
                "458 Italia", "458 Spider", "458 Speciale", "599 GTB Fiorano", 
                "599 GTO", "612 Scaglietti", "F50", "F40", "Testarossa", 
                "Mondial", "308", "328", "348", "355", "360 Modena", 
                "360 Spider", "430 Scuderia", "Challenge Stradale", "275 GTB", 
                "512 TR", "512 BB", "Superamerica", "FXX", "SP1 Monza", 
                "SP2 Monza", "296 GTB", "Purosangue"],
    },
    {
        value: 'Subaro',
        label: 'Subaro',
        models: ["Impreza", "Legacy", "Outback", "Forester", 
                "Crosstrek", "Ascent", "WRX", "WRX STI", 
                "BRZ", "XV", "Levorg", "Baja", 
                "Tribeca", "SVX", "Justy", "Alcyone", 
                "Rex", "Sambar", "Trezia", "Stella", 
                "Lucra", "Dias Wagon", "Exiga", "Gloria"],
    },
    {
        value: "Abarth",
        label: "Abarth",
        models: ["500","595","695","124 Spider","Grande Punto",
                "Punto Evo","Ritmo Abarth","131 Rally","1000 TC",
                "OT 1300","OT 1600","Simca 1300","Simca 2000","SE 010",
                "SE 025","SE 030","SE 037","SP 1000","SP 2000"]
    },
    {
        value: "SsangYong",
        label: "SsangYong",
        models: ["Tivoli", "Tivoli XLV", "Korando", "Rexton", 
                  "Musso", "Musso Grand", "Torres", "XLV", 
                  "Actyon", "Actyon Sports", "Kyron", "Rodius", 
                  "Chairman", "Chairman W", "Korando Family", 
                  "Istana", "Korando Turismo"]
    },
    {
        value: 'AIWAYS',
        label: 'AIWAYS',
        models: ['U5', 'U6', 'U7']
    },
    {
        value: 'ALPINA',
        label: 'ALPINA',
        models: ['B3', 'B4', 'B5', 'B6', 'B7']
    },
    {
        value: 'ALPINE',
        label: 'ALPINE',
        models: ['A110']
    },
    {
        value: 'AUTOBIANCHI',
        label: 'AUTOBIANCHI',
        models: ['A112']
    },
    {
        value: 'Bentley',
        label: 'Bentley',
        models: ['Continental GT', 'Bentayga', 'Flying Spur', 'Mulsanne']
    },
    {
        value: 'Genesis',
        label: 'Genesis',
        models: ['G70', 'G80', 'G90', 'GV80']
    },
    {
        value: 'Cupra',
        label: 'Cupra',
        models: ['Ateca', 'Formentor', 'Leon', 'El-Born']
    },
    {
        value: 'Buick',
        label: 'Buick',
        models: ['Enclave', 'Encore', 'Regal', 'LaCrosse', 'Cascada']
    },
    {
        value: 'Haval',
        label: 'Haval',
        models: ['H2', 'H6', 'H9']
    },
    {
        value: "Eunos",
        label: "Eunos",
        models: ["Eunos 100", "Eunos 300", "Eunos 500"]
    },
    {
        value: "Daewoo",
        label: "Daewoo",
        models: ["Lanos", "Nubira", "Matiz", "Leganza", "Espero"]
    },
    {
        value: "Lifan",
        label: "Lifan",
        models: ["520", "620", "X60", "X80", "Solano", "Foison"]
    },
    {
        value: "Byd",
        label: "Byd",
        models: ["F3", "F0", "Qin", "Tang", "Song"]
    },
    {
        value: "Tesla",
        label: "Tesla",
        models: ["Model S", "Model 3", "Model X", "Model Y", "Cybertruck", "Roadster"]
    },
    {
        value: "Cadillac",
        label: "Cadillac",
        models: ["Escalade", "CTS", "ATS", "XT5", "CT6", "XT4", "XT6"]
    },
    {
        value: 'Lincoln',
        label: 'Lincoln',
        models: ['Navigator', 'Aviator', 'MKZ', 'MKC', 'Continental']
    },
    {
        value: 'Mg',
        label: 'Mg',
        models: ['MG3', 'MG ZS', 'MG HS', 'MG5', 'MG ZS EV']
    },
    {
        value: 'Ora',
        label: 'Ora',
        models: ['Good Cat', 'R1', 'Black Cat']
    },
    {
        value: 'Proton',
        label: 'Proton',
        models: ['Saga', 'Persona', 'Iriz', 'X70', 'Exora']
    },
    {
        value: 'Ram',
        label: 'Ram',
        models: ['Ram 1500', 'Ram 2500', 'Ram 3500', 'Ram ProMaster']
    },
    {
        value: 'Polestar',
        label: 'Polestar',
        models: ['Polestar 1', 'Polestar 2', 'Polestar 3']
    },
    {
        value: 'Maxus/LDV',
        label: 'Maxus/LDV',
        models: ['V80', 'T90', 'D90']
    },
    {
        value: 'Jac',
        label: 'Jac',
        models: ['J5', 'J7', 'S3', 'S7']
    },
    {
        value: 'Piaggio',
        label: 'Piaggio',
        models: ['Ape', 'Liberty', 'MP3', 'Medley']
    },
    {
        value: 'Maserati',
        label: 'Maserati',
        models: ['Ghibli', 'Quattroporte', 'Levante', 'GranTurismo']
    },
    {
        value: 'Saab',
        label: 'Saab',
        models: ['900', '9-3', '9-5', '9-7X']
    },
    {
        value: 'Seres',
        label: 'Seres',
        models: ['Seres 3', 'Seres 5', 'Seres SF5']
    },
    {
        value: 'ZAZ',
        label: 'ZAZ',
        models: ['ZAZ-1102', 'ZAZ-1103', 'ZAZ-968']
    },
    {
        value: 'Xpeng',
        label: 'Xpeng',
        models: ['P7', 'G3', 'P5']
    },
    {
        value: 'Triumph',
        label: 'Triumph',
        models: ['TR6', 'Spitfire', 'Stag', 'Vitesse']
    },
    {
        value: 'TVR',
        label: 'TVR',
        models: ['Tuscan', 'Sagaris', 'Griffith', 'Cerbera']
    },
    {
        value: 'Vauxhall',
        label: 'Vauxhall',
        models: ['Corsa', 'Astra', 'Insignia', 'Mokka', 'Zafira']
    },
    {
        value: 'Rover',
        label: 'Rover',
        models: ['200', '400', '600', 'Metro', '75']
    },
    {
        value: 'MAN',
        label: 'MAN',
        models: ['TGX', 'TGS', 'TGE']
    },
    {
        value: 'Link&Co',
        label: 'Link&Co',
        models: ['01', '02', '03']
    },
    {
        value: 'Lada',
        label: 'Lada',
        models: ['Niva', 'Granta', 'Vesta', 'Priora']
    },
    {
        value: 'Perodua',
        label: 'Perodua',
        models: ['Myvi', 'Bezza', 'Alza', 'Axia', 'Ativa']
    },
    {
        value: 'Infiniti',
        label: 'Infiniti',
        models: ['Q50', 'Q60', 'Q70', 'QX50', 'QX60', 'QX80']
    },
    {
        value: 'Great Wall',
        label: 'Great Wall',
        models: ['Wingle', 'Steed', 'Poer', 'Pegasus']
    }
  ];
  export default initialCarBrands;