import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import Select from 'react-select';
import './Budget.css';
import CreatableSelect from 'react-select/creatable';
import ServiceAutosuggest from './ServiceAutosuggest';
import initialCarBrands from '../components/CarBrands';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEyeSlash, faEye, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import predefinedServices from '../components/Services';

function Budget() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [budgetNumber, setBudgetNumber] = useState(null);
  const [existingClients, setExistingClients] = useState([]);
  const [clientCars, setClientCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [isTagliandoChecked, setIsTagliandoChecked] = useState(false);
  const [isDistribuzioneChecked, setIsDistribuzioneChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleDescription = (index) => {
    setExpandedRows((prev) => {
      if (prev.includes(index)) {
        // já está aberto, então fecha
        return prev.filter((idx) => idx !== index);
      } else {
        // estava fechado, então abre
        return [...prev, index];
      }
    });
    setBudgetItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index
          ? { ...item, showDescription: !item.showDescription }
          : item
      )
    );
  };

  const addTagliandoItems = () => {
    const tagliandoItems = [
      { service: 'Olio', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Filtro Olio', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Filtro Aria', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Filtro Abitacolo', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Filtro Carburante', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Smaltimento rifiuti e materiale di consumo', quantity: 1, costPrice: 4.89, profitMargin: 0, iva: 22, finalPrice: 4.89 },
      { service: 'Manodopera', quantity: 1.5, costPrice: 35, profitMargin: 0, iva: 22, finalPrice: 35 },
    ];
  
    setBudgetItems((prev) => [...tagliandoItems]);
  };

  const addDistribuzioneItems = () => {
    const distribuzioneItems = [
      { service: 'Kit Distribuzione', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Pompa Acqua', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Filtro Olio', quantity: 1, costPrice: 0, profitMargin: 0, iva: 22, finalPrice: 0 },
      { service: 'Smaltimento rifiuti e materiale di consumo', quantity: 1, costPrice: 4.89, profitMargin: 0, iva: 22, finalPrice: 4.89 },
      { service: 'Manodopera', quantity: 1.5, costPrice: 35, profitMargin: 0, iva: 22, finalPrice: 35 },
    ];
  
    setBudgetItems((prev) => [...distribuzioneItems]);
  };

  const countryOptions = [
    // Europa
    { value: 'it', label: '🇮🇹 Italia (+39)', dialCode: '+39' },
    { value: 'es', label: '🇪🇸 Spagna (+34)', dialCode: '+34' },
    { value: 'gb', label: '🇬🇧 Regno Unito (+44)', dialCode: '+44' },
    { value: 'fr', label: '🇫🇷 Francia (+33)', dialCode: '+33' },
    { value: 'ie', label: '🇮🇪 Irlanda (+353)', dialCode: '+353' },
    { value: 'pt', label: '🇵🇹 Portogallo (+351)', dialCode: '+351' },
    { value: 'nl', label: '🇳🇱 Paesi Bassi (+31)', dialCode: '+31' },
    { value: 'be', label: '🇧🇪 Belgio (+32)', dialCode: '+32' },
    { value: 'de', label: '🇩🇪 Germania (+49)', dialCode: '+49' },
    { value: 'ch', label: '🇨🇭 Svizzera (+41)', dialCode: '+41' },
    { value: 'se', label: '🇸🇪 Svezia (+46)', dialCode: '+46' },
    { value: 'no', label: '🇳🇴 Norvegia (+47)', dialCode: '+47' },
    { value: 'dk', label: '🇩🇰 Danimarca (+45)', dialCode: '+45' },
    { value: 'pl', label: '🇵🇱 Polonia (+48)', dialCode: '+48' },
    { value: 'at', label: '🇦🇹 Austria (+43)', dialCode: '+43' },
    { value: 'gr', label: '🇬🇷 Grecia (+30)', dialCode: '+30' },
    { value: 'fi', label: '🇫🇮 Finlandia (+358)', dialCode: '+358' },
    { value: 'cz', label: '🇨🇿 Repubblica Ceca (+420)', dialCode: '+420' },
    { value: 'hu', label: '🇭🇺 Ungheria (+36)', dialCode: '+36' },
    { value: 'ro', label: '🇷🇴 Romania (+40)', dialCode: '+40' },
    { value: 'bg', label: '🇧🇬 Bulgaria (+359)', dialCode: '+359' },
    { value: 'sk', label: '🇸🇰 Slovacchia (+421)', dialCode: '+421' },
    
    // África
    { value: 'za', label: '🇿🇦 Sud Africa (+27)', dialCode: '+27' },
    { value: 'eg', label: '🇪🇬 Egitto (+20)', dialCode: '+20' },
    { value: 'ng', label: '🇳🇬 Nigeria (+234)', dialCode: '+234' },
    { value: 'ke', label: '🇰🇪 Kenya (+254)', dialCode: '+254' },
    { value: 'ma', label: '🇲🇦 Marocco (+212)', dialCode: '+212' },
    { value: 'dz', label: '🇩🇿 Algeria (+213)', dialCode: '+213' },
    { value: 'tn', label: '🇹🇳 Tunisia (+216)', dialCode: '+216' },
    { value: 'gh', label: '🇬🇭 Ghana (+233)', dialCode: '+233' },
    { value: 'et', label: '🇪🇹 Etiopia (+251)', dialCode: '+251' },
    { value: 'ug', label: '🇺🇬 Uganda (+256)', dialCode: '+256' },
    { value: 'ci', label: '🇨🇮 Costa d\'Avorio (+225)', dialCode: '+225' },
    { value: 'sn', label: '🇸🇳 Senegal (+221)', dialCode: '+221' },
    { value: 'tz', label: '🇹🇿 Tanzania (+255)', dialCode: '+255' },
    { value: 'cm', label: '🇨🇲 Camerun (+237)', dialCode: '+237' },
    { value: 'zw', label: '🇿🇼 Zimbabwe (+263)', dialCode: '+263' },
    { value: 'ly', label: '🇱🇾 Libia (+218)', dialCode: '+218' },
    { value: 'ml', label: '🇲🇱 Mali (+223)', dialCode: '+223' },
    { value: 'ao', label: '🇦🇴 Angola (+244)', dialCode: '+244' },
    
    // América do Norte
    { value: 'ca', label: '🇨🇦 Canada (+1)', dialCode: '+1' },
    { value: 'mx', label: '🇲🇽 Messico (+52)', dialCode: '+52' },
    { value: 'us', label: '🇺🇸 Stati Uniti (+1)', dialCode: '+1' },
    { value: 'cu', label: '🇨🇺 Cuba (+53)', dialCode: '+53' },
    { value: 'do', label: '🇩🇴 Repubblica Dominicana (+1 809/829/849)', dialCode: '+1' },
    { value: 'jm', label: '🇯🇲 Giamaica (+1 876)', dialCode: '+1' },
    { value: 'bs', label: '🇧🇸 Bahamas (+1 242)', dialCode: '+1' },
    { value: 'tt', label: '🇹🇹 Trinidad e Tobago (+1 868)', dialCode: '+1' },
    { value: 'bb', label: '🇧🇧 Barbados (+1 246)', dialCode: '+1' },
    { value: 'lc', label: '🇱🇨 Santa Lucia (+1 758)', dialCode: '+1' },
    { value: 'gd', label: '🇬🇩 Grenada (+1 473)', dialCode: '+1' },
    { value: 'vc', label: '🇻🇨 Saint Vincent e Grenadine (+1 784)', dialCode: '+1' },
    { value: 'ag', label: '🇦🇬 Antigua e Barbuda (+1 268)', dialCode: '+1' },
    { value: 'dm', label: '🇩🇲 Dominica (+1 767)', dialCode: '+1' },
    { value: 'kn', label: '🇰🇳 Saint Kitts e Nevis (+1 869)', dialCode: '+1' },
    { value: 'bm', label: '🇧🇲 Bermuda (+1 441)', dialCode: '+1' },

    // America do Sul
    { value: 'br', label: '🇧🇷 Brasil (+55)', dialCode: '+55' },
    
  ];

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [carBrands, setCarBrands] = useState(initialCarBrands);
  const [carModels, setCarModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  const [budgetItems, setBudgetItems] = useState([
    {
      service: '',
      quantity: 1,
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: 0,
      description: '',
    },
  ]);

  const fetchNextBudgetId = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/next-budget-id`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar o próximo número do orçamento');
      }
      const data = await response.json();
      setBudgetNumber(data.nextId || 1);
    } catch (error) {
      console.error('Erro ao buscar o próximo número do orçamento:', error);
      setBudgetNumber(1); // Valor padrão em caso de erro
    }
  };

  useEffect(() => {
    fetchNextBudgetId();
  }, []);

  function reorderItems(items) {
    // Fazemos uma cópia para não mutar diretamente a referência
    const arr = [...items];
  
    // Localizar os índices de cada serviço se existirem
    let manodoperaIndex = arr.findIndex(
      (i) => i.service === 'Manodopera'
    );
    let smaltimentoIndex = arr.findIndex(
      (i) => i.service === 'Smaltimento rifiuti e materiale di consumo'
    );
  
    // Se ambos existirem:
    if (manodoperaIndex !== -1 && smaltimentoIndex !== -1) {
      // Remove primeiro o item Manodopera
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      // Se manodoperaIndex < smaltimentoIndex, ao remover Manodopera, 
      // o índice do Smaltimento pode mudar
      if (smaltimentoIndex > manodoperaIndex) {
        smaltimentoIndex--;
      }
      // Remove Smaltimento
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
  
      // Agora adiciona novamente Smaltimento e Manodopera no final do array,
      // garantindo que Smaltimento fique penúltimo e Manodopera seja o último
      arr.push(smaltimentoItem);
      arr.push(manodoperaItem);
  
      return arr;
    }
  
    // Se existir só Manodopera (sem Smaltimento)
    if (manodoperaIndex !== -1) {
      const [manodoperaItem] = arr.splice(manodoperaIndex, 1);
      arr.push(manodoperaItem);
    }
  
    // Se existir só Smaltimento (sem Manodopera)
    if (smaltimentoIndex !== -1) {
      const [smaltimentoItem] = arr.splice(smaltimentoIndex, 1);
      arr.push(smaltimentoItem);
    }
  
    return arr;
  }

  const handleAddItem = () => {
    setBudgetItems((prev) => {
      const newItems = [
        ...prev,
        {
          service: '',
          quantity: 1,
          costPrice: 0,
          profitMargin: 0,
          iva: 22,
          finalPrice: 0,
        },
      ];
      return reorderItems(newItems);
    });
  };

  const handleRemoveItem = (index) => {
    setBudgetItems((prev) => {
      const updated = prev.filter((_, i) => i !== index);
      return reorderItems(updated);
    });
  };

  const handleItemChange = (index, field, value) => {
    setBudgetItems((prev) => {
      const updatedItems = [...prev];
      const item = { ...updatedItems[index] };
  
      if (field === 'service' && predefinedServices.find((s) => s.value === value)) {
        const predefined = predefinedServices.find((s) => s.value === value);
        item.service = predefined.label;
        item.costPrice = predefined.costPrice;
        item.profitMargin = predefined.profitMargin;
        item.finalPrice = calculateFinalPrice(
          predefined.costPrice,
          predefined.profitMargin,
          item.quantity
        );
      } else {
        item[field] = value;
        if (
          field === 'costPrice' ||
          field === 'profitMargin' ||
          field === 'iva' ||
          field === 'quantity'
        ) {
          item.finalPrice = calculateFinalPrice(
            item.costPrice || 0,
            item.profitMargin || 0,
            item.quantity || 1
          );
        }
      }
  
      item.finalPrice = parseFloat(item.finalPrice) || 0; // Garante que seja numérico
      updatedItems[index] = item;
      return reorderItems(updatedItems);
    });
  };

  const calculateFinalPrice = (costPrice, profitMargin, quantity) => {
    // Converte para float e garante que valores inválidos não causem erro
    costPrice = parseFloat(String(costPrice).replace(',', '.')) || 0;
    profitMargin = parseFloat(String(profitMargin).replace(',', '.')) || 0;
    quantity = parseFloat(String(quantity).replace(',', '.')) || 1;

    // Cálculo do preço final
    const profit = costPrice * (profitMargin / 100);
    const finalPrice = (costPrice + profit) * quantity;

    // Retorna o valor arredondado para duas casas decimais
    return finalPrice.toFixed(2);
  };

  const success_noti = (message) => {
    toast.success(message || t('Customer and car successfully registered.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };
  
  const error_noti = (message) => {
    toast.error(message || t('An error occurred while registering.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  useEffect(() => {
    if (selectedCar) {
      // Se um veículo foi selecionado do banco de dados
      const selectedCarDetails = clientCars.find((car) => car.id === selectedCar);
  
      if (selectedCarDetails) {
        const brand = carBrands.find((b) => b.value === selectedCarDetails.car_brand);
        if (brand) {
          setCarModels(
            brand.models.map((model) => ({ value: model, label: model }))
          );
        }
        // Define o modelo selecionado automaticamente
        setSelectedModel({
          value: selectedCarDetails.car_model,
          label: selectedCarDetails.car_model,
        });
      }
    } else if (selectedBrand) {
      // Caso contrário, atualiza os modelos para a marca selecionada
      const brand = carBrands.find((b) => b.value === selectedBrand.value);
      setCarModels(
        brand ? brand.models.map((model) => ({ value: model, label: model })) : []
      );
      setSelectedModel(null); 
    } else {
      setCarModels([]);
      setSelectedModel(null);
    }
  }, [selectedBrand, selectedCar, clientCars, carBrands]);

  const handleBrandChange = (newValue) => {
    setSelectedBrand(newValue);
    setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
  
    if (newValue && !carBrands.some((brand) => brand.value === newValue.value)) {
      setCarBrands([...carBrands, { value: newValue.value, label: newValue.label, models: [] }]);
    }
  };
  
  const handleModelChange = (newValue) => {
    setSelectedModel(newValue);
    setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
  
    if (newValue && selectedBrand) {
      const updatedBrands = carBrands.map((brand) => {
        if (brand.value === selectedBrand.value) {
          return {
            ...brand,
            models: brand.models.includes(newValue.value)
              ? brand.models
              : [...brand.models, newValue.value],
          };
        }
        return brand;
      });
      setCarBrands(updatedBrands);
    }
  };

  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhoneCountry: '+39',
    clientPhone: '',
    carBrand: '',
    carModel: '',
    carYear: '',
    carPlate: '',
    carMileage: '',
    carProblem: '',
  });

  const steps = [
    { id: 1, title: t('Personal Information'), number: '1', description: t('Client details') },
    { id: 2, title: t('Car Details'), number: '2', description: t('Car information') },
    { id: 3, title: t('budget'), number: '3', description: t('Add products or services') },
    { id: 4, title: t('Summary'), number: '4', description: t('Review and submit') },
  ];

  const fetchClientDetails = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/clients/details/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar detalhes do cliente.');
      }

      const clientDetails = await response.json();

      setFormData({
        clientName: clientDetails.name || '',
        clientEmail: clientDetails.email || '',
        clientPhoneCountry: clientDetails.phone_country || '',
        clientPhone: clientDetails.phone || '',
        carBrand: '',
        carModel: '',
        carYear: '',
        carPlate: '',
        carMileage: '',
        carProblem: ''
      });
    } catch (err) {
      console.error('Erro:', err);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setExistingClients(data);
      } catch (err) {
        console.error('Erro ao buscar clientes existentes:', err);
      }
    };

    fetchClients();
  }, []);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
        console.log('Iniciando o processo de envio do orçamento...');
        const token = sessionStorage.getItem('token');
        if (!token) {
            console.error('Erro: Usuário não está logado.');
            error_noti('User is not logged');
            return;
        }

        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const operatorId = decodedToken.userId;

        const payload = {
            clientName: formData.clientName,
            clientPhoneCountry: formData.clientPhoneCountry,
            clientPhone: formData.clientPhone,
            carBrand: formData.carBrand,
            carModel: formData.carModel,
            carYear: parseInt(formData.carYear, 10),
            carPlate: formData.carPlate,
            carMileage: formData.carMileage,
            operatorId: operatorId,
            budgetItems: budgetItems.map((item) => ({
                service: item.service,
                description: item.description || null,
                quantity: parseFloat(item.quantity),
                costPrice: parseFloat(item.costPrice),
                profitMargin: parseFloat(item.profitMargin),
                iva: parseFloat(item.iva),
                finalPrice: parseFloat(item.finalPrice),
            })),
            totals: {
                totalExclIva: parseFloat(totals.totalExclIva),
                totalIva: parseFloat(totals.totalIva),
                totalInclIva: parseFloat(totals.totalInclIva),
            },
        };

        console.log('Payload preparado:', JSON.stringify(payload, null, 2));

        const response = await fetch(`${API_BASE_URL}/api/budgets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            navigate('/budget/list');
            success_noti(t('Budget created successfully!'));
        } else {
            const errorData = await response.json();
            console.error('Erro ao salvar orçamento. Detalhes:', errorData);
            error_noti(errorData.error || t('Error to creat budget!'));
        }
    } catch (error) {
        console.error('Erro ao conectar ao servidor:', error);
        error_noti(t('Error to conect to server.'));
    }
};
  
  const handleDescriptionChange = (index, value) => {
    setBudgetItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, description: value } : item
      )
    );
  };

  const fetchClientCars = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/clients/${clientId}/cars`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao buscar carros do cliente.');
      }
  
      const cars = await response.json();
      setClientCars(cars);
    } catch (err) {
      console.error('Erro ao buscar carros do cliente:', err);
      setClientCars([]);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ 
      ...formData, 
      clientPhoneCountry: selectedOption ? selectedOption.dialCode : '' 
    });
  };

  const getSelectedCountry = () => {
    const selected = countryOptions.find(option => option.dialCode === formData.clientPhoneCountry);
    return selected || countryOptions.find(option => option.value === 'it');
  };

  const calculateTotals = () => {
    const totalExclIva = budgetItems.reduce((sum, item) => {
      const finalPrice = parseFloat(item.finalPrice) || 0;
      return sum + finalPrice;
    }, 0);
  
    const totalIva = totalExclIva * 0.22;
    const totalInclIva = totalExclIva + totalIva;
  
    return {
      totalExclIva: totalExclIva.toFixed(2),
      totalIva: totalIva.toFixed(2),
      totalInclIva: totalInclIva.toFixed(2),
    };
  };
  
  const totals = calculateTotals();

  const handleNumberInputChange = (index, field, value) => {
    if (value === undefined) return;
  
    // Converte o valor para string (caso não seja)
    let newValue = String(value).replace(/,/g, '.');
  
    // Remove todos os caracteres que não sejam dígitos ou pontos
    newValue = newValue.replace(/[^0-9.]/g, '');
  
    // Garante que haja no máximo um ponto decimal
    const parts = newValue.split('.');
    if (parts.length > 2) {
      newValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Limita a dois decimais, se houver parte decimal
    if (parts[1]?.length > 2) {
      newValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
  
    // Se o campo for 'quantity', 'costPrice' ou 'profitMargin':
    // - Se o usuário digitar "0.50", o zero permanece.
    // - Se o usuário digitar "01" (ou "012", etc.) remove o zero inicial.
    if (['quantity', 'costPrice', 'profitMargin'].includes(field)) {
      if (newValue.length > 1 && newValue.startsWith('0') && newValue[1] !== '.') {
        newValue = newValue.substring(1);
      }
    }
  
    // Se o valor resultante for uma string vazia, define como "0"
    if (newValue === '') {
      newValue = '0';
    }
  
    // Atualiza o estado com o valor formatado
    handleItemChange(index, field, newValue);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h4 className="form-section-title">{t('Client details')}</h4>
            <div className="col-12 mb-3">
              <label htmlFor="clientSelection" className="form-label">{t('select_client')}</label>
              {existingClients.length > 0 ? (
                <Select
                  options={[
                    { value: 'new', label: t('new_client') },
                    ...existingClients.map((client) => ({
                      value: client.id,
                      label: `${client.name} - ${client.phone_country} ${client.phone}`,
                    })),
                  ]}
                  value={selectedClient ? { value: selectedClient, label: `${formData.clientName} - ${formData.clientPhoneCountry} ${formData.clientPhone}` } : null}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedClient(null);
                      setFormData({
                        clientName: '',
                        clientEmail: '',
                        clientPhoneCountry: '+39',
                        clientPhone: '',
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                        carMileage: '',
                        carProblem: '',
                      });
                      setClientCars([]);
                    } else {
                      setSelectedClient(selectedOption.value);
                      fetchClientDetails(selectedOption.value);
                      fetchClientCars(selectedOption.value);
                    }
                  }}
                  placeholder={t('select_or_search_client')}
                  isSearchable
                />
              ) : (
                <p className="text-muted">{t('no_clients_found')}</p>
              )}
            </div>
            <div className="row g-4">
            <div className="col-md-6">
              <label htmlFor="clientName" className="form-label">{t('name')}</label>
              <input
                type="text"
                className="form-control"
                id="clientName"
                name="clientName"
                placeholder={t('Enter full name')}
                value={formData.clientName}
                onChange={handleChange}
                required
                disabled={!!selectedClient}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="clientPhone" className="form-label">{t('phone')}</label>
              <div className="d-flex">
                <Select
                  options={countryOptions}
                  value={getSelectedCountry()}
                  onChange={handleSelectChange}
                  name="phone_country"
                  className="country-select"
                  classNamePrefix="select"
                  isSearchable
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '150px',
                      marginRight: '10px',
                    }),
                    option: (base, state) => ({
                      ...base,
                      display: 'flex',
                      alignItems: 'center',
                    }),
                  }}
                />
                  <input
                    type="text"
                    className="form-control"
                    id="clientPhone"
                    name="clientPhone"
                    placeholder={t('Enter phone number')}
                    value={formData.clientPhone}
                    onChange={(e) => {
                      // Remove caracteres não numéricos
                      const numericValue = e.target.value.replace(/[^0-9]/g, '');
                      setFormData({ ...formData, clientPhone: numericValue });

                      // Verifica se o número já existe na lista de clientes
                      const existingClient = existingClients.find(
                        (client) => client.phone === numericValue && numericValue !== ''
                      );

                      if (existingClient) {
                        toast.warn(t('This phone number already exists. Please select the client.'));
                      }
                    }}
                    onKeyDown={(e) => {
                      // Impede a digitação de espaços e caracteres não numéricos
                      if (e.key === ' ' || isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault();
                      }
                    }}
                    required
                    disabled={!!selectedClient} // Desabilita se um cliente for selecionado
                  />
                </div>
            </div>
            </div>
          </div>
        );
        case 2:
          return (
            <div>
              <h4 className="form-section-title">{t('Car Details')}</h4>

              {/* Seleção de veículos */}
              <div className="col-12 mb-3">
                <label htmlFor="carSelection" className="form-label">{t('Select a Car')}</label>
                <Select
                  id="carSelection"
                  options={[
                    { value: 'new', label: t('New Vehicle') },
                    ...clientCars.map((car) => ({
                      value: car.id,
                      label: `${car.car_brand} ${car.car_model} (${car.car_year}) - ${car.car_plate} (${car.last_mileage})`,
                    })),
                  ]}
                  value={
                    selectedCar
                      ? clientCars.find((car) => car.id === selectedCar) 
                        ? { value: selectedCar, label: `${clientCars.find((car) => car.id === selectedCar).car_brand} ${clientCars.find((car) => car.id === selectedCar).car_model} (${clientCars.find((car) => car.id === selectedCar).car_year}) - ${clientCars.find((car) => car.id === selectedCar).car_plate} - ${clientCars.find((car) => car.id === selectedCar).last_mileage}` }
                        : { value: 'new', label: t('New Vehicle') }
                      : { value: 'new', label: t('New Vehicle') }
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedCar(null); // Limpa a seleção de veículo
                      setFormData({
                        ...formData,
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                        carMileage: ''
                      });
                    } else {
                      setSelectedCar(selectedOption.value); // Define o veículo selecionado
                      const selectedCarDetails = clientCars.find((car) => car.id === selectedOption.value);
                      if (selectedCarDetails) {
                        setFormData({
                          ...formData,
                          carBrand: selectedCarDetails.car_brand,
                          carModel: selectedCarDetails.car_model,
                          carYear: selectedCarDetails.car_year,
                          carPlate: selectedCarDetails.car_plate,
                          carMileage: selectedCarDetails.last_mileage
                        });
                      }
                    }
                  }}
                  placeholder={t('Select or register a vehicle')}
                  isSearchable
                />
              </div>

              {/* Formulário para cadastrar/editar veículo */}
              <div className="row g-4">
                <div className="col-md-6">
                  <label className="form-label">{t('Brand')}</label>
                  <CreatableSelect
                    options={carBrands}
                    value={
                      formData.carBrand
                        ? carBrands.find((brand) => brand.value === formData.carBrand) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleBrandChange(newValue);
                      setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car brand')}
                    isClearable
                    isDisabled={selectedCar}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Model')}</label>
                  <CreatableSelect
                    options={carModels}
                    value={
                      formData.carModel
                        ? carModels.find((model) => model.value === formData.carModel) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleModelChange(newValue);
                      setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car model')}
                    isClearable
                    isDisabled={selectedCar}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Year')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carYear"
                    value={formData.carYear}
                    onChange={handleChange}
                    placeholder={t('Enter car year')}
                    required
                    disabled={selectedCar} 
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Plate')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="carPlate"
                    value={formData.carPlate}
                    onChange={handleChange}
                    placeholder={t('Enter car plate')}
                    required
                    disabled={selectedCar}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Mileage')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="carMileage"
                    value={formData.carMileage}
                    onChange={handleChange}
                    placeholder={t('Enter car mileage')}
                  />
                </div>
              </div>
            </div>
          );
      case 3:
        return (
            <div>
            <h4 className="form-section-title">{t('Create Budget')}</h4>
            
            {/* Checkbox para Tagliando */}
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="budgetType"
                  value="tagliando"
                  checked={selectedOption === 'tagliando'}
                  onChange={() => {
                    setSelectedOption('tagliando');
                    addTagliandoItems();
                    setIsDistribuzioneChecked(false);
                  }}
                />
                {t('Tagliando')}
              </label>

              <label>
                <input
                  type="radio"
                  name="budgetType"
                  value="distribuzione"
                  checked={selectedOption === 'distribuzione'}
                  onChange={() => {
                    setSelectedOption('distribuzione');
                    addDistribuzioneItems();
                    setIsTagliandoChecked(false);
                  }}
                />
                {t('Distribuzione')}
              </label>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('Service')}</th>
                  <th>{t('Quantity')}</th>
                  <th>{t('Cost Price')}</th>
                  <th>{t('Profit Margin')} (%)</th>
                  <th>{t('Final Price')}</th>
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {budgetItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{index + 1}</td>

                      {/* Service */}
                      <td>
                        <ServiceAutosuggest
                          className="form-control"
                          value={item.service}
                          onChange={(newValue) => handleItemChange(index, 'service', newValue)}
                        />
                      </td>

                      {/* Quantity */}
                      <td>
                          <input
                              type="text"
                              className="form-control"
                              value={item.quantity}
                              onChange={(e) => handleNumberInputChange(index, 'quantity', e.target.value)}
                          />
                      </td>

                      {/* Cost Price */}
                      <td>
                          <input
                              type="text"
                              className="form-control"
                              value={item.costPrice}
                              onChange={(e) => handleNumberInputChange(index, 'costPrice', e.target.value)}
                          />
                      </td>

                      {/* Profit Margin */}
                      <td>
                          <input
                              type="text"
                              className="form-control"
                              value={item.profitMargin}
                              onChange={(e) => handleNumberInputChange(index, 'profitMargin', e.target.value)}
                          />
                      </td>

                      {/* Final Price */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.finalPrice}
                          readOnly
                        />
                      </td>

                      <td>
                        {/* Botões lado a lado */}
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger me-2"
                            onClick={() => handleRemoveItem(index)}
                            disabled={budgetItems.length === 1}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-primary space"
                            onClick={() => toggleDescription(index)}
                          >
                            {item.showDescription ? (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            ) : (
                              <FontAwesomeIcon icon={faEye} />
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>

                    {/* Linha extra para o campo description, se expandido */}
                    {expandedRows.includes(index) && (
                      <tr>
                        <td colSpan={6}>
                          <textarea
                            className="form-control mt-2"
                            placeholder={t('Add detailed description')}
                            value={item.description || ''}
                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                            rows="3"
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={handleAddItem}
            >
                <FontAwesomeIcon icon={faPlusSquare} /> {t('Add')}
            </button>

            {/* Totais */}
            <div className="totals-summary mt-4 p-3 bg-light rounded">
                <h5>{t('Summary')}</h5>
                <p>
                <strong>{t('Total excl. IVA')}:</strong> €{totals.totalExclIva}
                </p>
                <p>
                <strong>{t('IVA 22%')}:</strong> €{totals.totalIva}
                </p>
                <p>
                <strong>{t('Total incl. IVA')}:</strong> €{totals.totalInclIva}
                </p>
            </div>
            </div>
            );
            case 4:
                return (
                  <div className="review-budget">
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="p-3 border rounded">
                          <h5>{t('Client Details')}</h5>
                          <p>
                            <strong>{t('Name')}:</strong> {formData.clientName || '-'}
                          </p>
                          <p>
                            <strong>{t('phone')}:</strong> {formData.clientPhoneCountry} {formData.clientPhone || '-'}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="p-3 border rounded">
                          <h5>{t('Car Details')}</h5>
                          <p>
                            <strong>{t('Brand')}:</strong> {formData.carBrand || '-'}
                          </p>
                          <p>
                            <strong>{t('Model')}:</strong> {formData.carModel || '-'}
                          </p>
                          <p>
                            <strong>{t('Year')}:</strong> {formData.carYear || '-'}
                          </p>
                          <p>
                            <strong>{t('Plate')}:</strong> {formData.carPlate || '-'}
                          </p>
                          <p>
                            <strong>{t('Mileage')}:</strong> {formData.carMileage || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
              
                    {/* Tabela de Itens do Orçamento */}
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t('Product/Service')}</th>
                          <th>{t('Quantity')}</th>
                          <th>{t('Cost Price (excl. IVA)')}</th>
                          <th>{t('Final Price')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {budgetItems.map((item, index) => {
                          // Calcula o cost price (excl. IVA) => custo + margem, sem IVA:
                          const costPricePerItem =
                            parseFloat(item.costPrice || 0) *
                            (1 + (parseFloat(item.profitMargin || 0) / 100));

                          return (
                            <React.Fragment key={index}>
                              {/* Linha principal */}
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.service || '-'}</td>
                                <td>{item.quantity || '-'}</td>
                                <td>€{costPricePerItem.toFixed(2)}</td>
                                <td>€ {Number(item.finalPrice || 0).toFixed(2)}</td>
                              </tr>

                              {/* Se houver descrição, mostra em nova linha, ocupando todas as colunas */}
                              {item.description && (
                                <tr>
                                  <td colSpan={5}>
                                    {item.description
                                      .split('\n')
                                      .map((line, i) => (
                                        <div key={i}>- {line}</div>
                                      ))}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
              
                    {/* Summary alinhado à direita */}
                    <div className="d-flex justify-content-end mt-12">
                      <div className="p-3 border rounded" style={{ minWidth: '300px' }}>
                        <h4 className="form-section-title">{t('Summary')}</h4>
                        <p>
                          <strong>{t('Total excl. IVA')}:</strong> €{totals.totalExclIva}
                        </p>
                        <p>
                          <strong>{t('IVA 22%')}:</strong> €{totals.totalIva}
                        </p>
                        <p>
                          <strong>{t('Total incl. IVA')}:</strong> €{totals.totalInclIva}
                        </p>
                      </div>
                    </div>
                  </div>
                );
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('budget')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('newBudget')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* Progress Bar */}
            <div className="progress-bar-container mb-4">
              {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                  <div
                    className={`progress-step ${currentStep === step.id ? 'active' : ''} ${
                      currentStep > step.id ? 'completed' : ''
                    }`}
                    onClick={() => handleStepClick(step.id)}
                  >
                    <div className="step-icon">{step.number}</div>
                    <div className="step-title">{step.title}</div>
                    <div className="step-description">{step.description}</div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`bs-stepper-line ${
                        currentStep > step.id ? 'completed' : ''
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <form>
              {renderStep()}
              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  disabled={currentStep === 1}
                >
                  {t('Previous')}
                </button>
                {currentStep === 4 ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {t('Submit')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setCurrentStep((prev) => prev + 1)}
                  >
                    {t('Next')}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Budget;