import React, { useEffect } from 'react';
import MetisMenu from 'metismenu';
import '../assets/plugins/metismenu/css/metisMenu.min.css';
import { NavLink, useLocation } from 'react-router-dom';
import '../assets/css/sidebar.css';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import API_BASE_URL from '../config';

function Sidebar({ isVisible, toggleSidebar }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [reportFilter, setReportFilter] = React.useState('all');
  const [selectedMonth, setSelectedMonth] = React.useState('');
  const [selectedYear, setSelectedYear] = React.useState('');
  const [availableYears, setAvailableYears] = React.useState([]);
  const [availableMonths, setAvailableMonths] = React.useState([]);

  // Obtém a role do usuário da sessão
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    new MetisMenu("#menu");
  }, []);

  useEffect(() => {
    const fetchAvailableDates = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/tickets/available-dates`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const data = await response.json();
        setAvailableYears(data.years || []);
        setAvailableMonths(data.months || []);
      } catch (error) {
        console.error('Erro ao buscar anos e meses disponíveis:', error);
      }
    };
    fetchAvailableDates();
  }, []);

  const handleReport = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/detailed-report?filter=${reportFilter}&month=${selectedMonth}&year=${selectedYear}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (!response.ok) throw new Error(t('Error fetching report.'));
      const reportData = await response.json();
  
      const generateTable = (data, title) => {
        const rows = Object.entries(data).map(([period, tickets]) => {
          const ticketRows = tickets.map(ticket => `
            <tr>
              <td>${ticket.ticket_id}</td>
              <td>${ticket.client_name}</td>
              <td>${ticket.car_brand} ${ticket.car_model}</td>
              <td>${ticket.created_at}</td>
              <td>${ticket.is_warranty ? '✔️' : '—'}</td>
            </tr>
          `).join('');
  
          return `
            <h3>${title}: ${period}</h3>
            <table>
              <thead>
                <tr>
                  <th>${t('Ticket Number')}</th>
                  <th>${t('Client Name')}</th>
                  <th>${t('Car')}</th>
                  <th>${t('Date')}</th>
                  <th>${t('Warranty')}</th>
                </tr>
              </thead>
              <tbody>
                ${ticketRows}
              </tbody>
            </table>
          `;
        }).join('');
  
        return rows || `<p>${t('No data found.')}</p>`;
      };
  
      let reportHtml = `
        <html>
          <head>
            <title>${t('Detailed Tickets Report')}</title>
            <style>
              body { font-family: Arial; margin: 20px; }
              h1, h3 { color: #007bff; }
              table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
              th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }
              th { background: #f4f4f4; }
              .filter-section { margin-bottom: 20px; }
              .filter-section label { margin-right: 0.5rem; }
              .filter-section select, .filter-section input { margin-right: 1rem; padding: 0.25rem; }
              .filter-section button { padding: 0.5rem 1rem; }
            </style>
            <script>
              function applyFilter() {
                window.opener.document.querySelector('[onclick*="handleReport"]').click();
              }
              window.onload = function() {
                window.print();
              };
            </script>
          </head>
          <body>
            <h1>${t('Detailed Tickets Report')}</h1>
      <div class="filter-section">
              <label>${t('Report Filter')}:</label>
              <select id="reportType" onchange="applyFilter()">
                <option value="all" ${reportFilter === 'all' ? 'selected' : ''}>${t('Show All')}</option>
                <option value="monthly" ${reportFilter === 'monthly' ? 'selected' : ''}>${t('Monthly')}</option>
                <option value="annual" ${reportFilter === 'annual' ? 'selected' : ''}>${t('Annual')}</option>
              </select>
            ${
                reportFilter === 'monthly'
                  ? `<label>${t('Select Month')}:</label>
                      <select id="monthSelect">
                        ${availableMonths.map(month => `<option value="${month}" ${month === selectedMonth ? 'selected' : ''}>${month}</option>`).join('')}
                      </select>`
                  : reportFilter === 'annual'
                  ? `<label>${t('Select Year')}:</label>
                      <select id="yearSelect">
                        ${availableYears.map(year => `<option value="${year}" ${year === selectedYear ? 'selected' : ''}>${year}</option>`).join('')}
                      </select>`
                  : ''
              }
              <button onclick="applyFilter()">${t('Apply Filters')}</button>
            </div>
      `;
      
      if (reportFilter === 'monthly') {
        reportHtml += generateTable(reportData.monthly, t('Month'));
      } else if (reportFilter === 'annual') {
        reportHtml += generateTable(reportData.annual, t('Year'));
      } else {
        reportHtml += `
          <h2>${t('Monthly Report')}</h2>
          ${generateTable(reportData.monthly, t('Month'))}
          <h2>${t('Annual Report')}</h2>
          ${generateTable(reportData.annual, t('Year'))}
        `;
      }
      
      reportHtml += `
          </body>
        </html>
      `;
  
      const reportWindow = window.open('', '_blank');
      reportWindow.document.open();
      reportWindow.document.write(reportHtml);
      reportWindow.document.close();
    } catch (error) {
      console.error(error);
      alert(t('Error generating report.'));
    }
  };

  // Verifica permissões com base no role
  const hasPermission = (allowedRoles) => allowedRoles.includes(role);

  // Função para verificar se a rota atual corresponde ao item do menu
  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <aside className={`sidebar ${isVisible ? "open" : "closed"}`}>
      <div className="sidebar-content">
        <nav className="sidebar-nav">
          <ul className="metismenu" id="menu">
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/dashboard") ? "mm-active" : ""}>
                <NavLink to="/dashboard" className="sidebar-link">
                  <i className="bx bx-home-alt"></i>
                  Dashboard
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "receptionist"]) && (
              <li className={isActive("/customer-care") ? "mm-active" : ""}>
                <NavLink to="/customer-care" className="sidebar-link">
                  <i className="bx bx-id-card"></i>
                  {t('customerCare')}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "receptionist"]) && (
              <li className={isActive("/budget") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-money"></i> {/* Ícone para Budget */}
                  {t('budget')}
                </a>
                <ul>
                  <li><NavLink to="/budget/new">{t('newBudget')}</NavLink></li>
                  <li><NavLink to="/budget/list">{t('listBudgets')}</NavLink></li>
                  <li><NavLink to="/budget/work">{t('acceptedWork')}</NavLink></li>
                </ul>
              </li>
            )}
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/calendar") ? "mm-active" : ""}>
                <NavLink to="/calendar" className="sidebar-link">
                  <i className="bx bx-calendar"></i>
                  {t('calendar')}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "receptionist"]) && (
              <li className={isActive("/Client") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-group"></i>
                  {t('clients')}
                </a>
                <ul>
                  <li><NavLink to="/ClientRegister">{t('newClient')}</NavLink></li>
                  <li><NavLink to="/ClientList">{t('listClient')}</NavLink></li>
                </ul>
              </li>
            )}
            {hasPermission(["admin", "receptionist"]) && (
              <li className={isActive("/Auto") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-car"></i>
                  {t('vehicles')}
                </a>
                <ul>
                  <li><NavLink to="/AutoRegister">{t('newVehicle')}</NavLink></li>
                  <li><NavLink to="/AutoList">{t('listVehicles')}</NavLink></li>
                </ul>
              </li>
            )}
            {hasPermission(["admin", "receptionist", "mechanic"]) && (
              <li className={isActive("/warehouse") ? "mm-active" : ""}>
                <a href="/warehouse" className="sidebar-link">
                  <i className="bx bx-box"></i>
                  {t('warehouse')}
                </a>
              </li>
            )}
            {role === "admin" && (
              <li className={isActive("/profile") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-user"></i>
                  {t('profile')}
                </a>
                <ul>
                  <li><NavLink to="/profile">{t('UserSettings')}</NavLink></li>
                  <li><NavLink to="/userRegister">{t('newUser')}</NavLink></li>
                  <li><NavLink to="/userList">{t('listUsers')}</NavLink></li>
                </ul>
              </li>
            )}
            {role !== "admin" && hasPermission(["mechanic", "receptionist"]) && (
              <li className={isActive("/profile") ? "mm-active" : ""}>
                <NavLink to="/profile" className="sidebar-link">
                  <i className="bx bx-user"></i>
                  {t('profile')}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin"]) && (
              <li className={isActive("/settings") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-cog"></i>
                  {t('settings')}
                </a>
                <ul>
                  <li><NavLink to="/authorizedlocation">{t('Authorized Location')}</NavLink></li>
                </ul>
              </li>
            )}
            {(role === 'admin' || role === 'receptionist') ? (
              <li className={isActive('/tickets') ? 'mm-active' : ''}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-message-detail"></i>
                  {t('tickets')}
                </a>
                <ul>
                  <li>
                    <NavLink to="/tickets">{t('tickets')}</NavLink>
                  </li>
                  <li>
                    <a href="#" onClick={handleReport}>{t('Tickets Report')}</a>
                  </li>
                </ul>
              </li>
            ) : (
              hasPermission(['admin', 'mechanic', 'receptionist']) && (
                <li className={isActive('/tickets') ? 'mm-active' : ''}>
                  <NavLink to="/tickets" className="sidebar-link">
                    <i className="bx bx-message-detail"></i>
                    {t('tickets')}
                  </NavLink>
                </li>
              )
            )}
            {role === "admin" && (
              <li className={isActive("/work") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-briefcase"></i>
                  {t('work')}
                </a>
                <ul>
                  <li><NavLink to="/work">{t('check')}</NavLink></li>
                  <li><NavLink to="/workers">{t('workers')}</NavLink></li>
                </ul>
              </li>
            )}
            {role !== "admin" && hasPermission(["mechanic", "receptionist"]) && (
              <li className={isActive("/work") ? "mm-active" : ""}>
                <NavLink to="/work" className="sidebar-link">
                  <i className="bx bx-briefcase"></i>
                  {t('check')}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/database") ? "mm-active" : ""}>
                <a href="/database" className="sidebar-link">
                  <i className="bx bx-data"></i>
                  {t('database')}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default withAuth(Sidebar, ['admin', 'mechanic', 'receptionist']);