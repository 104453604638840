import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProductSearchBar = ({ onSearch }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value); // Notifica o componente pai
  };

  return (
    <div className="product-search-bar">
      <input
        type="text"
        placeholder={t('Search products...')}
        value={query}
        onChange={handleChange}
      />
    </div>
  );
};

export default ProductSearchBar;