import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import './Dashboard.css';
import moment from 'moment-timezone';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [priorityTickets, setPriorityTickets] = useState([]);

  // Controla se o modal está aberto
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Guarda o ID do ticket em que o usuário clicou para marcar como entregue
  const [ticketIdToDeliver, setTicketIdToDeliver] = useState(null);

  const openConfirm = () => setConfirmOpen(true);
  const closeConfirm = () => setConfirmOpen(false);

  const mapStatus = (status) => {
    switch (status) {
      case 1:
        return { translation: t('Not Assigned'), className: 'not-assigned' };
      case 2:
        return { translation: t('Pending'), className: 'pending' };
      case 3:
        return { translation: t('Working'), className: 'working' };
      case 4:
        return { translation: t('Finished'), className: 'finished' };
      case 6:
        return { translation: t('reservation'), className: 'reservation' };
      default:
        return { translation: t('Unknown'), className: 'unknown' };
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });
        if (response.data) {
          setUserName(response.data.name);
          setUserRole(response.data.role);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        navigate('/login');
      }
    };

    const fetchPriorityTickets = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/api/tickets/priorities`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPriorityTickets(response.data);
      } catch (error) {
        console.error('Erro ao buscar tickets com prioridade:', error);
      }
    };

    fetchUserData();
    fetchPriorityTickets();
  }, [navigate]);


  const markAsDelivered = async (ticketId) => {
    try {
      const token = sessionStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/api/tickets/${ticketId}/mark-delivered`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Ticket marked as delivered.');
      setPriorityTickets((prev) => prev.filter((ticket) => ticket.id !== ticketId));
    } catch (error) {
      console.error('Erro ao marcar ticket como entregue:', error);
      toast.error('Error marking ticket as delivered.');
    }
    setConfirmOpen(false);
  };

  const filteredPriorityTickets = useMemo(() => {
    if (!searchTerm) return priorityTickets;
  
    const lowercasedTerm = searchTerm.toLowerCase();
  
    return priorityTickets.filter((ticket) => {
      const { id, client_name, client_phone, car_brand, car_model } = ticket;
      return (
        id.toString().includes(lowercasedTerm) ||
        (client_name && client_name.toLowerCase().includes(lowercasedTerm)) ||
        (client_phone && client_phone.toLowerCase().includes(lowercasedTerm)) ||
        (car_brand && car_brand.toLowerCase().includes(lowercasedTerm)) ||
        (car_model && car_model.toLowerCase().includes(lowercasedTerm))
      );
    });
  }, [searchTerm, priorityTickets]);

  const renderPriorityTicketsCard = () => {
    if (!filteredPriorityTickets || filteredPriorityTickets.length === 0) return null;
  
    return (
      <div className="col-12 mt-4">
        <div className="card radius-10">
          <div className="card-body">
            <h6 className="mb-3">{t('Priority Tickets')}</h6>
            <div className="row">
              {filteredPriorityTickets.map((ticket) => {
                let dueDateFormatted = t('No due date');
                if (ticket.due_date) {
                  dueDateFormatted = moment(ticket.due_date)
                    .tz('Europe/Rome')
                    .format('DD-MM-YYYY HH:mm:ss');
                }

                let createdAt = null;

                if(ticket.created_at){
                  createdAt = moment(ticket.created_at)
                    .tz('Europe/Rome')
                    .format('DD-MM-YYYY');
                }
  
                const { translation: statusText, className: statusClassName } = mapStatus(ticket.status);
  
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5" key={ticket.id}>
                    <div className={`p-2 ticket-card ${ticket.color}`}>
                      {/* ID do ticket */}
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <span><strong>#{ticket.id}</strong></span>
                        <span>{createdAt}</span>
                      </div>
  
                      {/* Cliente */}
                      <p className="mb-1">
                        <strong>{t('Client')}:</strong> {ticket.client_name}
                      </p>
  
                      {/* Carro */}
                      <p className="mb-1">
                        <strong>{t('Car')}:</strong> {ticket.car_brand} {ticket.car_model}
                      </p>
  
                     {/* Status */}
                      <div className="mb-1">
                        <strong>{t('Status')}:</strong>{' '}
                        <p className={`ticket-status ${statusClassName}`} style={{ display: 'inline-block', margin: 0 }}>
                          {statusText}
                        </p>
                      </div>
  
                      {/* Data de Entrega Formatada */}
                      <p className="mb-1">
                        <strong>{t('Due date')}:</strong><br/> {dueDateFormatted}
                      </p>
  
                      {/* Botão para abrir o ticket */}
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() => navigate(`/tickets/${ticket.id}`)}
                      >
                        {t('Open')}
                      </button>
  
                      {/* Condicional para exibir o botão apenas para certos papéis */}
                      {(userRole === 'admin' || userRole === 'receptionist') && (
                        <>
                          <button
                            className="btn btn-sm btn-light mt-2"
                            onClick={() => {
                              setTicketIdToDeliver(ticket.id);
                              openConfirm();
                            }}
                          >
                            {t('Delivered')}
                          </button>
  
                          <Modal
                            isOpen={confirmOpen && ticketIdToDeliver === ticket.id}
                            onRequestClose={closeConfirm}
                            contentLabel={t('Confirm the Delivery')}
                            className="confirm-modal"
                            overlayClassName="confirm-overlay"
                          >
                            <h2>{t('Confirm the Delivery')}</h2>
                            <p>{t('Are you delivering to the client?')}</p>
  
                            <button className="btn btn-secondary" onClick={closeConfirm}>
                              {t('Cancel')}
                            </button>
  
                            <button
                              className="btn btn-primary space"
                              onClick={() => markAsDelivered(ticketIdToDeliver)}
                            >
                              {t('Confirm')}
                            </button>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('dashboard')}</div>
        </div>

        {/* Barra de Pesquisa para Tickets com Prioridade */}
        <div className="row mb-4">
          <div className="col-12">
            <input
              type="text"
              className="form-control"
              placeholder={t('Search Tickets by Name or Phone, car brand, car model, car plate')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Card dos Tickets com Prioridade */}
        <div className="row">
          {renderPriorityTicketsCard()}
        </div>
      </div>
    </div>
  );
}

export default withAuth(Dashboard);