import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/images/big_logo.png';
import { FaBan, FaPaperPlane, FaTrash, FaCheck, FaClock } from 'react-icons/fa';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ServiceAutosuggest from './ServiceAutosuggest';
import API_BASE_URL from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEyeSlash, faEye, faLink, faUnlink, faPlusSquare, faEdit, faSave, faPrint } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './EditBudget.css';

// Configure o Modal
Modal.setAppElement('#root');

function EditBudget() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showAssociateModal, setShowAssociateModal] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBanModal, setShowBanModal] = useState(false);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);

  const [isEditingMileage, setIsEditingMileage] = useState(false);
  const [editedMileage, setEditedMileage] = useState('');

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const openRemoveModal = () => setIsRemoveModalOpen(true);
  const closeRemoveModal = () => setIsRemoveModalOpen(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);
  
  const [expandedRows, setExpandedRows] = useState([]);

  const handleDeleteReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/delete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as DELETE!'));
        setShowDeleteModal(false);
        setSelectedBudgetId(null);
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleWaitingReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/waiting`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as WAITING!'));
        setShowWaitingModal(false);
        setSelectedBudgetId(null);
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleAcceptReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/accept`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as ACCEPT!'));
        setShowAcceptModal(false);
        setSelectedBudgetId(null);
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleBanReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/ban`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as REJECT!'));
        setShowBanModal(false);
        setSelectedBudgetId(null);
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const handleConfirmReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/ready`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as READY!'));
        setShowConfirmModal(false);
        setSelectedBudgetId(null);
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };

  const toggleDescription = (rowIndex) => {
    setExpandedRows((prev) => {
      // se estiver aberto, fecha
      if (prev.includes(rowIndex)) {
        return prev.filter((idx) => idx !== rowIndex);
      } else {
        // se estava fechado, abre
        return [...prev, rowIndex];
      }
    });
  };

  const [totals, setTotals] = useState({
    totalExclIva: '0.00',
    totalIva: '0.00',
    totalInclIva: '0.00',
  });

  const [budgetItems, setBudgetItems] = useState([
    {
      service: '',
      quantity: 1,
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: 0,
    },
  ]);

  const predefinedServices = [
    {
      value: 'Manodopera',
      label: 'Manodopera',
      costPrice: 35,
      profitMargin: 0,
      iva: 22,
      finalPrice: 35,
    },
    {
      value: 'Smaltimento rifiuti e materiale di consumo',
      label: 'Smaltimento rifiuti e materiale di consumo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Olio 10W40',
      label: 'Olio 10W40',
      costPrice: 10,
      profitMargin: 0,
      iva: 22,
      finalPrice: 10,
    },
    {
      value: 'Olio 5W30',
      label: 'Olio 5W30',
      costPrice: 13,
      profitMargin: 0,
      iva: 22,
      finalPrice: 13,
    },
    {
      value: 'Olio 5W40',
      label: 'Olio 5W40',
      costPrice: 12,
      profitMargin: 0,
      iva: 22,
      finalPrice: 12,
    },
    {
      value: 'Olio 0W30',
      label: 'Olio 0W30',
      costPrice: 16.50,
      profitMargin: 0,
      iva: 22,
      finalPrice: 16.50,
    },
    {
      value: 'Olio 0W20',
      label: 'Olio 0W20',
      costPrice: 19,
      profitMargin: 0,
      iva: 22,
      finalPrice: 19,
    },
    {
      value: 'Filtro Olio',
      label: 'Filtro Olio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Aria',
      label: 'Filtro Aria',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Abitacolo',
      label: 'Filtro Abitacolo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Carburante',
      label: 'Filtro Carburante',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'KIT Distribuzione',
      label: 'KIT Distribuzione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pompa Acqua',
      label: 'Pompa Acqua',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
  ];

  const [budgetData, setBudgetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const fetchBudget = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/budgets/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (response.data && response.data.items) {
        setBudgetData({ ...response.data, items: response.data.items });
  
        if (response.data.files && Array.isArray(response.data.files)) {
          const files = response.data.files
            .filter(file => file.file_url)
            .map(file => ({
              id: file.id,
              file_url: file.file_url,
              file_type: file.file_type,
            }));
          setUploadedFiles(files);
        } else {
          setUploadedFiles([]);
        }
      } else {
        setBudgetData(response.data);
        setUploadedFiles([]);
      }
  
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar orçamento:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudget();
  }, []);

  useEffect(() => {
    if (budgetData && budgetData.items) {
      const newTotals = calculateTotals(budgetData.items);
      setTotals(newTotals);
    }
  }, [budgetData]);

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error(t('Por favor, selecione um arquivo para fazer upload.'));
      return;
    }
  
    setUploading(true);
  
    const formData = new FormData();
    formData.append('file', selectedFile);
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(`${API_BASE_URL}/api/budgets/${id}/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
      const { fileUrl, fileId, fileType } = response.data;
  
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        { id: fileId, file_url: fileUrl, file_type: fileType || 'other' },
      ]);
  
      toast.success(t('Arquivo enviado com sucesso!'));
      setSelectedFile(null);
    } catch (error) {
      console.error('Erro ao fazer upload do arquivo:', error);
      toast.error(t('Erro ao fazer upload do arquivo.'));
    } finally {
      setUploading(false);
    }
  };

  // Function to open the Delete Confirmation Modal
  const openDeleteModal = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
    setConfirmationText('');
    setIsDeleteEnabled(false);
  };

  // Function to close the Delete Confirmation Modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
    setConfirmationText('');
    setIsDeleteEnabled(false);
  };

  // Handle confirmation text input
  const handleDeleteConfirmation = (e) => {
    const value = e.target.value;
    setConfirmationText(value);
    if (value === 'elimina definitivamente') {
      setIsDeleteEnabled(true);
    } else {
      setIsDeleteEnabled(false);
    }
  };

  // Function to handle file deletion
  const handleDeleteFile = async () => {
    if (!fileToDelete) return;
  
    try {
      const token = sessionStorage.getItem('token');
      await axios.delete(`${API_BASE_URL}/api/budgets/${id}/files/${fileToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== fileToDelete.id));
      toast.success('File deleted successfully.');
      closeDeleteModal();
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error('Error deleting file.');
      closeDeleteModal();
    }
  };

  const calculateTotals = (items) => {
    let totalExclIva = 0;
    items.forEach((item) => {
      // finalPrice é o valor total do item, sem IVA adicional pois você já incluiu profitMargin
      const fPrice = parseFloat(item.finalPrice || 0);
      totalExclIva += fPrice;
    });
    // Vamos assumir IVA = 22% fixo, ou use item.iva se for por item
    const totalIva = totalExclIva * 0.22;
    const totalInclIva = totalExclIva + totalIva;

    return {
      totalExclIva: totalExclIva.toFixed(2),
      totalIva: totalIva.toFixed(2),
      totalInclIva: totalInclIva.toFixed(2),
    };
  };
    
  const calculateFinalPrice = (costPrice, profitMargin, quantity) => {
    const cp = parseFloat(costPrice) || 0;
    const pm = parseFloat(profitMargin) || 0;
    const qty = parseFloat(quantity) || 1;

    // Ex.: finalPrice = (cp + (cp * pm/100)) * qty
    const profit = cp * (pm / 100);
    const finalPrice = (cp + profit) * qty;
    return finalPrice.toFixed(2);
  };

  const getAvailableServices = () => {
    // Serviços que não podem ser duplicados
    const restrictedServices = ['Manodopera', 'Smaltimento rifiuti e materiale di consumo'];
  
    // Verifica quais serviços restritos já estão no orçamento
    const existingRestrictedServices = budgetData.items
      .filter(item => restrictedServices.includes(item.service))
      .map(item => item.service);
  
    // Filtra os serviços disponíveis removendo os que já existem
    const availableServices = predefinedServices.filter(
      service => !existingRestrictedServices.includes(service.label)
    );
  
    return availableServices;
  };

  const handleItemChange = (index, field, value) => {
    if (value === undefined) return;

    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;

      const updatedItems = [...prevBudget.items];
      const currentItem = { ...updatedItems[index] };

      if (['quantity', 'costPrice', 'profitMargin'].includes(field)) {
        // Se o valor estiver vazio, define como '0'
        if (value === '') {
          currentItem[field] = '0';
        } else {
          currentItem[field] = value;
        }

        currentItem.finalPrice = calculateFinalPrice(
          field === 'costPrice' ? (value === '' ? '0' : value) : currentItem.costPrice,
          field === 'profitMargin' ? (value === '' ? '0' : value) : currentItem.profitMargin,
          field === 'quantity' ? (value === '' ? '0' : value) : currentItem.quantity
        );
      } else if (field === 'service') {
        const predefined = predefinedServices.find((s) => s.value === value);
        if (predefined) {
          currentItem.service = predefined.label;
          currentItem.costPrice = predefined.costPrice ?? '0';
          currentItem.profitMargin = predefined.profitMargin ?? '0';
          currentItem.finalPrice = calculateFinalPrice(
            currentItem.costPrice,
            currentItem.profitMargin,
            currentItem.quantity
          );
        } else {
          currentItem.service = value;
        }
      } else {
        currentItem[field] = value;
      }

      updatedItems[index] = currentItem;
      return { ...prevBudget, items: updatedItems };
    });
  };

  const handleRemoveItem = (index) => {
    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;
      const updatedItems = [...prevBudget.items];
      updatedItems.splice(index, 1);
      return { ...prevBudget, items: updatedItems };
    });
  };

  // Função para reordenar os itens antes de salvar
  const reorderItems = (items) => {
    // Clonar itens para evitar mutações diretas
    const itemList = [...items];
  
    // Filtrar os itens específicos e removê-los do array principal
    const smaltimentoItem = itemList.find(item => item.service === 'Smaltimento rifiuti e materiale di consumo');
    const manodoperaItem = itemList.find(item => item.service === 'Manodopera');
  
    // Excluir os itens específicos do array principal
    const filteredItems = itemList.filter(
      item => item.service !== 'Smaltimento rifiuti e materiale di consumo' && item.service !== 'Manodopera'
    );
  
    // Adicionar os itens de volta nas posições corretas
    if (smaltimentoItem) filteredItems.push(smaltimentoItem); // Penúltimo
    if (manodoperaItem) filteredItems.push(manodoperaItem); // Último
  
    // Atribuir o campo 'order' com base na nova posição
    const orderedItems = filteredItems.map((item, index) => ({
      ...item,
      order: index + 1, // Iniciando do 1
    }));
  
    return orderedItems;
  };

  const handleAddItem = () => {
    const newItem = {
      service: '',
      quantity: 1,
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: 0,
      description: '',
      showDescription: false,
    };
  
    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;
  
      // Adicionar o item e reordenar
      const updatedItems = [...prevBudget.items, newItem];
      const reorderedItems = reorderItems(updatedItems);
  
      return { ...prevBudget, items: reorderedItems };
    });
  };

  // Função para formatar a entrada numérica
  const handleNumberInputChange = (index, field, value) => {
    if (value === undefined) return;
  
    // Converte o valor para string (caso não seja)
    let newValue = String(value).replace(/,/g, '.');
  
    // Remove todos os caracteres que não sejam dígitos ou pontos
    newValue = newValue.replace(/[^0-9.]/g, '');
  
    // Garante que haja no máximo um ponto decimal
    const parts = newValue.split('.');
    if (parts.length > 2) {
      newValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Limita a dois decimais, se houver parte decimal
    if (parts[1]?.length > 2) {
      newValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
  
    // Se o campo for 'quantity', 'costPrice' ou 'profitMargin':
    // - Se o usuário digitar "0.50", o zero permanece.
    // - Se o usuário digitar "01" (ou "012", etc.) remove o zero inicial.
    if (['quantity', 'costPrice', 'profitMargin'].includes(field)) {
      if (newValue.length > 1 && newValue.startsWith('0') && newValue[1] !== '.') {
        newValue = newValue.substring(1);
      }
    }
  
    // Se o valor resultante for uma string vazia, define como "0"
    if (newValue === '') {
      newValue = '0';
    }
  
    // Atualiza o estado com o valor formatado
    handleItemChange(index, field, newValue);
  };

  const formatValue = (value) => {
    const num = parseFloat(value);
    return num === 0 ? "0" : value.toString();
  };

  const handleSave = async () => {
    if (!budgetData) return;
    setSaving(true);
  
    // Garantir a ordem correta dos itens e atribuir o campo 'order'
    const reorderedItems = reorderItems(budgetData.items);
  
    // Log para verificar a ordem dos itens
    console.log('Itens reordenados antes de salvar:', reorderedItems);
  
    const updatedBudgetData = { ...budgetData, items: reorderedItems };
  
    try {
      const token = sessionStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/api/budgets/${id}`, updatedBudgetData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSaving(false);
      navigate('/budget/list');
    } catch (error) {
      console.error('Erro ao salvar orçamento:', error);
      setSaving(false);
      alert('Erro ao salvar orçamento.');
    }
  };

  const handleDescriptionChange = (index, value) => {
    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;
      const updatedItems = [...prevBudget.items];
      updatedItems[index] = {
        ...updatedItems[index],
        description: value,
      };
      return { ...prevBudget, items: updatedItems };
    });
  };

  if (loading) {
    return <p>{t('Loading...')}</p>;
  }

  if (!budgetData) {
    return <p>{t('Budget not found')}</p>;
  }

  const fetchTickets = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/tickets/by-car/${budgetData.car_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTickets(response.data);
      console.log('Tickets obtidos:', response.data);
    } catch (error) {
      console.error('Erro ao buscar tickets:', error);
      toast.error(t('Erro ao buscar tickets.'));
    }
  };

  const handleAssociateTicket = () => {
    setShowAssociateModal(true);
    fetchTickets();
  };

  const handleCloseAssociateModal = () => {
    setShowAssociateModal(false);
    setSelectedTicketId(null);
  };

  const associateTicketToBudget = async () => {
    if (!selectedTicketId) {
      toast.error(t('Please select a ticket to associate.'));
      return;
    }
    
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${budgetData.budget_id}/associate-ticket`,
        { ticketId: selectedTicketId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      toast.success(t('Ticket associado com sucesso.'));
      setBudgetData((prevData) => ({
        ...prevData,
        ticket_id: selectedTicketId,
        associatedTicket: response.data.associatedTicket, // Se a API retornar detalhes do ticket
      }));
      handleCloseAssociateModal();
    } catch (error) {
      console.error('Erro ao associar ticket:', error);
      toast.error(t('Erro ao associar ticket.'));
    }
  };

  // Função para confirmar e remover a associação:
  const confirmRemoveAssociation = async () => {
    try {
      const token = sessionStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/budgets/${budgetData.budget_id}/remove-ticket`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(t('Ticket association removed successfully.'));
      // Atualiza o estado local removendo a associação
      setBudgetData((prevData) => ({
        ...prevData,
        ticket_id: null,
        associatedTicket: null,
      }));
    } catch (error) {
      console.error('Erro ao remover associação do ticket:', error);
      toast.error(t('Error removing ticket association.'));
    } finally {
      closeRemoveModal();
    }
  };

  const handleRemoveAssociation = async () => {
    if (!window.confirm(t('Are you sure you want to remove the ticket association?'))) {
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${budgetData.budget_id}/remove-ticket`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      toast.success(t('Ticket association removed successfully.'));
      
      // Atualiza o estado local para refletir a mudança
      setBudgetData((prevData) => ({
        ...prevData,
        ticket_id: null,
        associatedTicket: null, // Se houver esse campo
      }));
    } catch (error) {
      console.error('Erro ao remover associação do ticket:', error);
      toast.error(t('Error removing ticket association.'));
    }
  };

  const handleSaveMileage = async () => {
    if (!editedMileage || isNaN(editedMileage)) {
      toast.error(t('Please enter a valid mileage.'));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${budgetData.budget_id}/update-mileage`,
        { carMileage: editedMileage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Mileage updated successfully.'));
        setBudgetData((prevData) => ({ ...prevData, car_mileage: editedMileage }));
        setIsEditingMileage(false);
      } else {
        toast.error(t('Error updating mileage.'));
      }
    } catch (error) {
      console.error('Error updating mileage:', error);
      toast.error(t('Error updating mileage.'));
    }
  };

  const generatePDF = async (budgetData) => {
    try {
      const doc = new jsPDF('p', 'mm', 'a4');
      const currentDate = new Date().toLocaleDateString();
      let operatorName = 'N/A';

      // 1) Buscar os detalhes do orçamento no backend
      const token = sessionStorage.getItem('token');

      // 2) Buscar dados do operador
      const responseOperator = await fetch(`${API_BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (responseOperator.ok) {
        const userData = await responseOperator.json();
        operatorName = userData.name || 'N/A';
      }

      // =========== Montagem do PDF =========== //

      // 3. Adicionar logo da empresa
      doc.addImage(logo, 'PNG', 10, 15, 50, 30);

      // 4. Adicionar nome da empresa
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.text('3B AUTO DI ELIDIR BUSNELLO E C. SAS', 65, 20);

      // 5. Adicionar informações da empresa
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      const companyInfo = [
        'via Sandro Pertini, 20',
        '46045, MARMIROLO (MN)',
        'P. Iva/ Cod. Fiscale: 02709760207',
        'SDI: WTYVJK9',
        'Tel: 389 026 2392',
        'Email: 3BAuto3B@gmail.com',
      ];
      let companyY = 25;
      companyInfo.forEach((line) => {
        doc.text(65, companyY, line);
        companyY += 5;
      });

      // 7. Dados cliente e veículo
      const headerY = 70;

      // 7.1. Título da seção
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Dati Cliente e Veicolo', 12, headerY + 6);

      // 7.2. Dados do Cliente - Coluna Esquerda
      doc.setFont('helvetica', 'normal');
      doc.text(`Nome Cliente: ${budgetData.client_name || '-'}`, 12, headerY + 12);
      doc.text(`Telefono Cliente: ${budgetData.client_phone_country}${budgetData.client_phone || '-'}`, 12, headerY + 18);

      // 7.3. Dados do Operador e Data - Coluna Direita
      doc.text(`Operatore: ${operatorName}`, 140, headerY + 12);
      doc.text(`Data: ${currentDate}`, 140, headerY + 18);

      // 7.4. Dados do Veículo - Coluna Esquerda
      doc.text(
        `Veicolo: ${budgetData.car_brand || '-'} ${budgetData.car_model || '-'}`,
        12,
        headerY + 24
      );

      // 7.5. Dados do Veículo - Coluna Direita
      doc.text(`Targa: ${budgetData.car_plate || '-'}`, 140, headerY + 24);

      // 7.6. **Nova Posição Y para "Chilometraggio"**
      // Ajustamos o Y para evitar sobreposição com "Targa"
      doc.text(`Chilometraggio: ${budgetData.car_mileage || '-'}`, 140, headerY + 30);

      // 8. Linha de separação
      doc.setDrawColor(0, 0, 0); // Define a cor da linha (preto)
      doc.line(10, headerY + 35, 200, headerY + 35);

      // 9. Tabela de itens (usando autoTable)
      const tableStartY = headerY + 40;
      const tableData = budgetData.items.flatMap((item, index) => {
        // Cálculo corrigido do preço por unidade
        const unitPrice = item.costPrice
          ? parseFloat(item.costPrice) + parseFloat(item.costPrice) * (parseFloat(item.profitMargin) / 100)
          : 0;

        const mainRow = [
          index + 1,
          item.service || '-',
          parseFloat(item.quantity || '-').toFixed(2),
          `€${unitPrice.toFixed(2)}`, // Preço por unidade com margem de lucro
          `€${parseFloat(item.finalPrice || 0).toFixed(2)}`,
        ];

        // Se tiver descrição, gera linha extra
        const descriptionRow = item.description
          ? [{ colSpan: 5, content: `- ${item.description.replace(/\n/g, '\n- ')}` }]
          : null;

        return descriptionRow ? [mainRow, descriptionRow] : [mainRow];
      });

      doc.autoTable({
        startY: tableStartY,
        head: [
          [
            '#',
            `${t('Service or Product')}`,
            `${t('Quantity')}`,
            `${t('Unit Price excl. IVA')}`,
            `${t('Final Price')}`,
          ],
        ],
        body: tableData,
        styles: { font: 'helvetica', fontSize: 10 },
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        margin: { left: 10, right: 10 },
      });

      // 10. Totais
      const calculateTotals = (budgetItems) => {
        if (!Array.isArray(budgetItems) || budgetItems.length === 0) {
          return { totalExclIva: '0.00', totalIva: '0.00', totalInclIva: '0.00' };
        }
        const totalExclIva = budgetItems.reduce((sum, item) => {
          const finalPrice = parseFloat(item?.finalPrice) || 0;
          return sum + finalPrice;
        }, 0);
        const totalIva = totalExclIva * 0.22;
        const totalInclIva = totalExclIva + totalIva;
        return {
          totalExclIva: totalExclIva.toFixed(2),
          totalIva: totalIva.toFixed(2),
          totalInclIva: totalInclIva.toFixed(2),
        };
      };

      const summaryStartY = doc.previousAutoTable.finalY + 10;
      const totals = calculateTotals(budgetData.items);

      doc.setFont('helvetica', 'bold');
      doc.text('Riepilogo', 12, summaryStartY);
      doc.setFont('helvetica', 'normal');
      doc.text(`${t('Total excl. IVA')}: €${totals.totalExclIva}`, 12, summaryStartY + 6);
      doc.text(`IVA 22%: €${totals.totalIva}`, 12, summaryStartY + 12);
      doc.text(`${t('Total incl. IVA')}: €${totals.totalInclIva}`, 12, summaryStartY + 18);

      // 11. Rodapé
      const footerY = 280;
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(10);
      doc.text(
        'Il presente preventivo non costituisce documento valido ai fini fiscali. ' +
          'RingraziandoVi per l\'attenzione, rimaniamo in attesa di un gentile riscontro.',
        10,
        footerY,
        { maxWidth: 190 }
      );
      doc.text('Firma per l\'accettazione: ____________________', 10, footerY + 10);
  
      // Gera o blob URL do PDF
      const pdfBlobUrl = doc.output('bloburl');
  
      // Abre o PDF em uma nova aba para impressão ou salvamento
      window.open(pdfBlobUrl, '_blank');
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex align-items-center mb-3">
      <button
          className="btn btn-secondary"
          onClick={() => {
              if (budgetData && budgetData.status === 4) {
                  navigate('/budget/work');
              } else {
                  navigate('/budget/list');
              }
          }}
      >
          {t('Back to List')}
      </button>

        <h1 className="flex-grow-1 text-center">
            {t('Edit Budget')} #{budgetData?.budget_id}
        </h1>

        <button className="btn btn-primary" onClick={() => generatePDF(budgetData)}>
          <FontAwesomeIcon icon={faPrint} className="me-2" />
          {t('Print Budget')}
        </button>
      </div>

    <div className="row">
        {/* Condicional para exibir os botões de ações se o orçamento não estiver no status 4 */}
        {budgetData.status !== 4 && (
          <div className="action-buttons mt-3">
            <button
              className="btn btn-sm btn-success me-2"
              onClick={() => {
                setSelectedBudgetId(budgetData.budget_id);
                setShowConfirmModal(true);
              }}
            >
              <FaCheck />
            </button>
            <button
              className="btn btn-sm btn-danger me-2"
              onClick={() => {
                setSelectedBudgetId(budgetData.budget_id);
                setShowBanModal(true);
              }}
            >
              <FaBan />
            </button>
            <button
              className="btn btn-sm btn-info me-2"
              onClick={() => {
                setSelectedBudgetId(budgetData.budget_id);
                setShowAcceptModal(true);
              }}
            >
              <FaPaperPlane />
            </button>
            <button
              className="btn btn-sm btn-warning me-2"
              onClick={() => {
                setSelectedBudgetId(budgetData.budget_id);
                setShowWaitingModal(true);
              }}
            >
              <FaClock />
            </button>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                setSelectedBudgetId(budgetData.budget_id);
                setShowDeleteModal(true);
              }}
            >
              <FaTrash />
            </button>
          </div>
        )}
        <div className="col-md-6">
          <h5>{t('Client Details')}</h5>
          <p>
              <strong>{t('Name')}:</strong> {budgetData.client_name || '-'}
          </p>
          <p>
              <strong>{t('Phone')}:</strong>{budgetData.client_phone_country}{budgetData.client_phone || '-'}
          </p>
        </div>

        <div className="col-md-3">
            <h5>{t('Car Details')}</h5>
            <p>
                <strong>{t('Brand')}:</strong> {budgetData.car_brand || '-'}
            </p>
            <p>
                <strong>{t('Model')}:</strong> {budgetData.car_model || '-'}
            </p>
            {budgetData.ticket_id ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={openRemoveModal}
              >
                <FontAwesomeIcon icon={faUnlink} /> {t('Remove Ticket Association')}
              </button>
            ) : (
              <button 
                type="button"
                className="btn btn-primary"
                onClick={handleAssociateTicket}
              >
                <FontAwesomeIcon icon={faLink} /> {t('Associate Ticket')}
              </button>
            )}
        </div>
        <div className="col-md-3">
            <br/><br/>
            <p>
                <strong>{t('Year')}:</strong> {budgetData.car_year || '-'}
            </p>
            <p>
                <strong>{t('Plate')}:</strong> {budgetData.car_plate || '-'}
            </p>
            <p>
              <strong>{t('Mileage')}:</strong> 
              {isEditingMileage ? (
                <>
                  <input 
                    type="text" 
                    value={editedMileage} 
                    onChange={(e) => setEditedMileage(e.target.value.replace(/\D/g, ''))} 
                    className="form-control"
                    style={{ width: '100px', display: 'inline-block', marginLeft: '5px' }}
                  />
                  <button 
                    onClick={handleSaveMileage} 
                    className="btn btn-sm btn-success ms-2"
                    style={{ padding: '2px 5px', fontSize: '12px' }}
                  >
                    <FontAwesomeIcon icon={faSave}/>
                  </button>
                </>
              ) : (
                <>
                  {budgetData.car_mileage || '-'}
                  <button 
                    onClick={() => {
                      setEditedMileage(budgetData.car_mileage || '');
                      setIsEditingMileage(true);
                    }} 
                    className="btn btn-sm btn-outline-secondary ms-2"
                    style={{ padding: '2px 5px', fontSize: '12px' }}
                  >
                    <FontAwesomeIcon icon={faEdit}/>
                  </button>
                </>
              )}
            </p>
        </div>

        {/* Seção de Upload de Arquivos */}
        <div className="mt-4">
          <h3>{t('Upload Files')}</h3>
          <div className="d-flex align-items-center mb-3">
            <input
              type="file"
              accept="*"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              className="form-control me-2"
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleFileUpload}
              disabled={uploading || !selectedFile}
            >
              {uploading ? t('Sending...') : t('Send')}
            </button>
          </div>
          
          {/* Exibir os arquivos enviados */}
          {uploadedFiles.length > 0 && (
            <div>
              <h5>{t('Sended Files')}</h5>
              <ul>
                {uploadedFiles.map((file) => (
                  <li key={file.id} className="d-flex align-items-center">
                    <a href={file.file_url} target="_blank" rel="noopener noreferrer" className="me-3">
                      {file.file_url}
                    </a>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => openDeleteModal(file)}
                      title="Delete file"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
    </div>

    <div className="d-flex justify-content-end mt-4">
        <div className="p-3 border rounded" style={{ width: '100%' }}>
          <h4 className="mb-3">{t('Summary')}</h4>
          <p>
            <strong>{t('Total excl. IVA')}:</strong> €{totals.totalExclIva}
          </p>
          <p>
            <strong>IVA 22%:</strong> €{totals.totalIva}
          </p>
          <p>
            <strong>{t('Total incl. IVA')}:</strong> €{totals.totalInclIva}
          </p>
        </div>
      </div>

      {/* Tabela de itens para editar */}
      <h3 className="mt-4 mb-2">{t('budget_items')}</h3>

      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>{t('Service')}</th>
            <th>{t('Quantity')}</th>
            <th>{t('Cost Price')}</th>
            <th>{t('Profit Margin')} (%)</th>
            <th>{t('Final Price')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {budgetData.items.map((item, index) => (
            <React.Fragment key={index}>
                <tr key={index}>
                <td>{index + 1}</td>

                {/* Service */}
                <td>
                  <ServiceAutosuggest
                    className="form-control"
                    value={item.service}
                    onChange={(newValue) => handleItemChange(index, 'service', newValue)}
                  />
                </td>

                {/* Quantity */}
                <td>
                    <input
                        type="text"
                        className="form-control"
                        value={formatValue(item.quantity)}
                        onChange={(e) => handleNumberInputChange(index, 'quantity', e.target.value)}
                    />
                </td>

                {/* Cost Price */}
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={formatValue(item.costPrice)}
                    onChange={(e) => handleNumberInputChange(index, 'costPrice', e.target.value)}
                  />
                </td>

                {/* Profit Margin */}
                <td>
                    <input
                        type="text"
                        className="form-control"
                        value={formatValue(item.profitMargin)}
                        onChange={(e) => handleNumberInputChange(index, 'profitMargin', e.target.value)}
                    />
                </td>

                {/* Final Price */}
                <td>
                    <input
                    type="text"
                    className="form-control"
                    value={item.finalPrice}
                    readOnly
                    />
                </td>

                <td>
                    {/* Contêiner flex para deixar os botões lado a lado */}
                    <div className="d-flex align-items-center">
                        <button
                        className="btn btn-sm btn-danger me-2"
                        onClick={() => handleRemoveItem(index)}
                        >
                        <FontAwesomeIcon icon={faTrash} />
                        </button>

                        <button
                        type="button"
                        className="btn btn-outline-primary space"
                        onClick={() => toggleDescription(index)}
                        >
                        {item.showDescription ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                            <FontAwesomeIcon icon={faEye} />
                        )}
                        </button>
                    </div>
                    </td>
                </tr>
                {expandedRows.includes(index) && (
                    <tr>
                    <td colSpan={6}>
                    <div>
                        <textarea
                            className="form-control mt-2"
                            placeholder={t('Add detailed description')}
                            value={item.description || ''}
                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                            rows="3"
                        />
                    </div>
                    </td>
                    </tr>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Linha de botões (Add à esquerda, Save à direita) */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddItem}
        >
          <FontAwesomeIcon icon={faPlusSquare} /> {t('Add')}
        </button>

        <button
          className="btn btn-success"
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? t('Saving...') : t('Save')}
        </button>
      </div>
      
      {/* Modal para associar tickets */}
      <Modal
        isOpen={showAssociateModal}
        onRequestClose={handleCloseAssociateModal}
        contentLabel="Associate Ticket Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{t('Associate Ticket')}</h2>
        <button onClick={handleCloseAssociateModal} className="close-button">
          &times;
        </button>
        <div>
          {tickets.length > 0 ? (
            <ul>
              {tickets.map((ticket) => (
                <li key={ticket.id}>
                  <input
                    type="radio"
                    name="selectedTicket"
                    value={ticket.id}
                    checked={selectedTicketId === ticket.id}
                    onChange={() => setSelectedTicketId(ticket.id)}
                  />
                  {` Ticket #${ticket.id} - ${ticket.client_name}`}
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('No tickets available to associate.')}</p>
          )}
          <button onClick={associateTicketToBudget} className="btn btn-success">
            {t('Associate')}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <h4 className="modal-title">{t('Confirm Deletion')}</h4>
          <button className="close-button" onClick={closeDeleteModal} title="Close">&times;</button>
        </div>
        <div className="modal-body">
          <p>{t('Are you sure you want to permanently delete this file? This action cannot be undone.')}</p>
          <p>{t('Please type')} <strong>'elimina definitivamente'</strong> {t('to confirm.')}</p>
          <input
            type="text"
            value={confirmationText}
            onChange={handleDeleteConfirmation}
            placeholder="Type 'elimina definitivamente'"
            className="form-control"
          />
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-secondary me-2"
            onClick={closeDeleteModal}
          >
            {t('Cancel')}
          </button>
          <button
            className="btn btn-danger"
            onClick={handleDeleteFile}
            disabled={!isDeleteEnabled}
          >
            {t('Delete')}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isRemoveModalOpen}
        onRequestClose={closeRemoveModal}
        contentLabel="Confirm Ticket Association Removal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div>
          <h2>{t('Confirm Removal')}</h2>
          <p>{t('Are you sure you want to remove the ticket association?')}</p>
          <div className="modal-buttons">
            <button className="btn btn-secondary" onClick={closeRemoveModal}>
              {t('Cancel')}
            </button>
            <button className="btn btn-danger space" onClick={confirmRemoveAssociation}>
              {t('Confirm')}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showConfirmModal}
        onRequestClose={() => setShowConfirmModal(false)}
        contentLabel={t('Are you sure you want to mark this budget as READY?')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            inset: 'unset',
            padding: '20px',
            borderRadius: '8px',
            width: 'auto',
            height: 'auto',
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'auto',
          },
        }}
      >
        <h2>{t('Change Budget Status')}</h2>
        <p>{t('Are you sure you want to mark this budget as READY?')}</p>

        <div className="d-flex justify-content-end gap-2 mt-3">
          <button
            className="btn btn-secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            {t('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleConfirmReady}
          >
            {t('Confirm')}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showBanModal}
        onRequestClose={() => setShowBanModal(false)}
        contentLabel={t('Are you sure you want to mark this budget as REJECTED?')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            inset: 'unset',
            padding: '20px',
            borderRadius: '8px',
            width: 'auto',
            height: 'auto',
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'auto',
          },
        }}
      >
        <h2>{t('Change Budget Status')}</h2>
        <p>{t('Are you sure you want to mark this budget as REJECTED?')}</p>

        <div className="d-flex justify-content-end gap-2 mt-3">
          <button
            className="btn btn-secondary"
            onClick={() => setShowBanModal(false)}
          >
            {t('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleBanReady}
          >
            {t('Confirm')}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showAcceptModal}
        onRequestClose={() => setShowAcceptModal(false)}
        contentLabel={t('Are you sure you want to mark this budget as ACCEPT?')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            inset: 'unset',
            padding: '20px',
            borderRadius: '8px',
            width: 'auto',
            height: 'auto',
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'auto',
          },
        }}
      >
        <h2>{t('Change Budget Status')}</h2>
        <p>{t('Are you sure you want to mark this budget as ACCEPT?')}</p>

        <div className="d-flex justify-content-end gap-2 mt-3">
          <button
            className="btn btn-secondary"
            onClick={() => setShowAcceptModal(false)}
          >
            {t('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleAcceptReady}
          >
            {t('Confirm')}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showWaitingModal}
        onRequestClose={() => setShowWaitingModal(false)}
        contentLabel={t('Are you sure you want to mark this budget as WAITING?')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            inset: 'unset',
            padding: '20px',
            borderRadius: '8px',
            width: 'auto',
            height: 'auto',
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'auto',
          },
        }}
      >
        <h2>{t('Change Budget Status')}</h2>
        <p>{t('Are you sure you want to mark this budget as WAITING?')}</p>

        <div className="d-flex justify-content-end gap-2 mt-3">
          <button
            className="btn btn-secondary"
            onClick={() => setShowWaitingModal(false)}
          >
            {t('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleWaitingReady}
          >
            {t('Confirm')}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        contentLabel={t('Are you sure you want to delete this budget?')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            inset: 'unset',
            padding: '20px',
            borderRadius: '8px',
            width: 'auto',
            height: 'auto',
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'auto',
          },
        }}
      >
        <h2>{t('Change Budget Status')}</h2>
        <p>{t('Are you sure you want to delete this budget?')}</p>

        <div className="d-flex justify-content-end gap-2 mt-3">
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            {t('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleDeleteReady}
          >
            {t('Confirm')}
          </button>
        </div>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default EditBudget;