import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ServiceAutosuggest from '../pages/ServiceAutosuggest';
import logo from '../assets/images/big_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusSquare, faWindowMinimize, faEyeSlash, faEye, faPrint } from '@fortawesome/free-solid-svg-icons';
import API_BASE_URL from '../config';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Draggable from 'react-draggable';
import './BudgetPanel.css';
import predefinedServices from '../components/Services';

function BudgetPanel({ ticketId, userRole, onClose }) {
  const [budgetData, setBudgetData] = useState(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleDescription = (rowIndex) => {
    setExpandedRows((prev) => {
      // se estiver aberto, fecha
      if (prev.includes(rowIndex)) {
        return prev.filter((idx) => idx !== rowIndex);
      } else {
        // se estava fechado, abre
        return [...prev, rowIndex];
      }
    });
  };

  const [totals, setTotals] = useState({
    totalExclIva: '0.00',
    totalIva: '0.00',
    totalInclIva: '0.00',
  });

  const [budgetItems, setBudgetItems] = useState([
    {
      service: '',
      quantity: 1,
      costPrice: 0,
      profitMargin: 0,
      iva: 22,
      finalPrice: 0,
    },
  ]);

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Carrega os itens do back
  const fetchBudget = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/tickets/${ticketId}/budget`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    
      // Se a resposta for um array vazio, significa que não há orçamento
      if (Array.isArray(response.data) && response.data.length === 0) {
        setBudgetData(null);
      } else {
        // Caso contrário, mapeie os itens para converter snake_case para camelCase
        const mappedItems = (response.data.items || []).map((item) => ({
          ...item,
          costPrice: item.cost_price,
          profitMargin: item.profit_margin,
          finalPrice: item.final_price,
        }));
        setBudgetData({
          ...response.data,
          items: mappedItems,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching budget:", error);
      setLoading(false);
      toast.error(t('Error fetching budget.'));
    }
  };

  useEffect(() => {
    if (ticketId) {
      fetchBudget();
    }
  }, [ticketId]);

  const reorderItems = (items) => {
    // Clonar itens para evitar mutações diretas
    const itemList = [...items];
  
    // Filtrar os itens específicos e removê-los do array principal
    const smaltimentoItem = itemList.find(item => item.service === 'Smaltimento rifiuti e materiale di consumo');
    const manodoperaItem = itemList.find(item => item.service === 'Manodopera');
  
    // Excluir os itens específicos do array principal
    const filteredItems = itemList.filter(
      item => item.service !== 'Smaltimento rifiuti e materiale di consumo' && item.service !== 'Manodopera'
    );
  
    // Adicionar os itens de volta nas posições corretas
    if (smaltimentoItem) filteredItems.push(smaltimentoItem); // Penúltimo
    if (manodoperaItem) filteredItems.push(manodoperaItem); // Último
  
    // Atribuir o campo 'order' com base na nova posição
    const orderedItems = filteredItems.map((item, index) => ({
      ...item,
      order: index + 1, // Iniciando do 1
    }));
  
    return orderedItems;
  };

  const handleItemChange = (index, field, value) => {
    if (value === undefined) return;

    setBudgetData((prevBudget) => {
      if (!prevBudget) return prevBudget;

      const updatedItems = [...prevBudget.items];
      const currentItem = { ...updatedItems[index] };

      if (['quantity', 'costPrice', 'profitMargin'].includes(field)) {
        // Se o valor estiver vazio, define como '0'
        if (value === '') {
          currentItem[field] = '0';
        } else {
          currentItem[field] = value;
        }

        currentItem.finalPrice = calculateFinalPrice(
          field === 'costPrice' ? (value === '' ? '0' : value) : currentItem.costPrice,
          field === 'profitMargin' ? (value === '' ? '0' : value) : currentItem.profitMargin,
          field === 'quantity' ? (value === '' ? '0' : value) : currentItem.quantity
        );
      } else if (field === 'service') {
        const predefined = predefinedServices.find((s) => s.value === value);
        if (predefined) {
          currentItem.service = predefined.label;
          currentItem.costPrice = predefined.costPrice ?? '0';
          currentItem.profitMargin = predefined.profitMargin ?? '0';
          currentItem.finalPrice = calculateFinalPrice(
            currentItem.costPrice,
            currentItem.profitMargin,
            currentItem.quantity
          );
        } else {
          currentItem.service = value;
        }
      } else {
        currentItem[field] = value;
      }

      updatedItems[index] = currentItem;
      return { ...prevBudget, items: updatedItems };
    });
  };

  const handleRemoveItem = (index) => {
    setBudgetData((prev) => {
      if (!prev) return prev;
      const updatedItems = [...prev.items];
      updatedItems.splice(index, 1);
  
      return {
        ...prev,
        items: reorderItems(updatedItems),
      };
    });
  };

  const handleAddItem = () => {
    setBudgetData((prev) => {
      if (!prev) return prev;
      const newItem = {
        service: '',
        quantity: 1,
        costPrice: 0,
        profitMargin: 0,
        iva: 22,
        finalPrice: 0,
        description: ''
      };
  
      const updatedItems = [...prev.items, newItem];
      return {
        ...prev,
        items: reorderItems(updatedItems),
      };
    });
  };

  const generatePDF = async (budgetData) => {
    try {
      const doc = new jsPDF('p', 'mm', 'a4');
      const currentDate = new Date().toLocaleDateString();
      let operatorName = 'N/A';

      // 1) Buscar os detalhes do orçamento no backend
      const token = sessionStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/budgets/${budgetData.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      let budget;
      if (Array.isArray(response.data) && response.data.length > 0) {
        budget = response.data[0];
      } else {
        budget = response.data;
      }
      
      // Faça o mapeamento dos itens se necessário
      const mappedItems = (budget.items || []).map((item) => ({
        ...item,
        costPrice: item.cost_price,
        profitMargin: item.profit_margin,
        finalPrice: item.final_price,
      }));
      budget.items = mappedItems;

      // 2) Buscar dados do operador
      const responseOperator = await fetch(`${API_BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (responseOperator.ok) {
        const userData = await responseOperator.json();
        operatorName = userData.name || 'N/A';
      }

      // =========== Montagem do PDF =========== //

      // 3. Adicionar logo da empresa
      doc.addImage(logo, 'PNG', 10, 15, 50, 30);

      // 4. Adicionar nome da empresa
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.text('3B AUTO DI ELIDIR BUSNELLO E C. SAS', 65, 20);

      // 5. Adicionar informações da empresa
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      const companyInfo = [
        'via Sandro Pertini, 20',
        '46045, MARMIROLO (MN)',
        'P. Iva/ Cod. Fiscale: 02709760207',
        'SDI: WTYVJK9',
        'Tel: 389 026 2392',
        'Email: 3BAuto3B@gmail.com',
      ];
      let companyY = 25;
      companyInfo.forEach((line) => {
        doc.text(65, companyY, line);
        companyY += 5;
      });

      // 7. Dados cliente e veículo
      const headerY = 70;

      // 7.1. Título da seção
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Dati Cliente e Veicolo', 12, headerY + 6);

      // 7.2. Dados do Cliente - Coluna Esquerda
      doc.setFont('helvetica', 'normal');
      doc.text(`Nome Cliente: ${budget.client_name || '-'}`, 12, headerY + 12);
      doc.text(`Telefono Cliente: ${budget.client_phone_country}${budget.client_phone || '-'}`, 12, headerY + 18);

      // 7.3. Dados do Operador e Data - Coluna Direita
      doc.text(`Operatore: ${operatorName}`, 140, headerY + 12);
      doc.text(`Data: ${currentDate}`, 140, headerY + 18);

      // 7.4. Dados do Veículo - Coluna Esquerda
      doc.text(
        `Veicolo: ${budget.car_brand || '-'} ${budget.car_model || '-'}`,
        12,
        headerY + 24
      );

      // 7.5. Dados do Veículo - Coluna Direita
      doc.text(`Targa: ${budget.car_plate || '-'}`, 140, headerY + 24);

      // 7.6. **Nova Posição Y para "Chilometraggio"**
      // Ajustamos o Y para evitar sobreposição com "Targa"
      doc.text(`Chilometraggio: ${budget.car_mileage || '-'}`, 140, headerY + 30);

      // 8. Linha de separação
      doc.setDrawColor(0, 0, 0); // Define a cor da linha (preto)
      doc.line(10, headerY + 35, 200, headerY + 35);

      // 9. Tabela de itens (usando autoTable)
      const tableStartY = headerY + 40;
      const tableData = budgetData.items.flatMap((item, index) => {
        // Cálculo corrigido do preço por unidade
        const unitPrice = item.costPrice
          ? parseFloat(item.costPrice) + parseFloat(item.costPrice) * (parseFloat(item.profitMargin) / 100)
          : 0;

        const mainRow = [
          index + 1,
          item.service || '-',
          parseFloat(item.quantity || '-').toFixed(2),
          `€${unitPrice.toFixed(2)}`, // Preço por unidade com margem de lucro
          `€${parseFloat(item.finalPrice || 0).toFixed(2)}`,
        ];

        // Se tiver descrição, gera linha extra
        const descriptionRow = item.description
          ? [{ colSpan: 5, content: `- ${item.description.replace(/\n/g, '\n- ')}` }]
          : null;

        return descriptionRow ? [mainRow, descriptionRow] : [mainRow];
      });

      doc.autoTable({
        startY: tableStartY,
        head: [
          [
            '#',
            `${t('Service or Product')}`,
            `${t('Quantity')}`,
            `${t('Unit Price excl. IVA')}`,
            `${t('Final Price')}`,
          ],
        ],
        body: tableData,
        styles: { font: 'helvetica', fontSize: 10 },
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        margin: { left: 10, right: 10 },
      });

      // 10. Totais
      const calculateTotals = (budgetItems) => {
        if (!Array.isArray(budgetItems) || budgetItems.length === 0) {
          return { totalExclIva: '0.00', totalIva: '0.00', totalInclIva: '0.00' };
        }
        const totalExclIva = budgetItems.reduce((sum, item) => {
          const finalPrice = parseFloat(item?.finalPrice) || 0;
          return sum + finalPrice;
        }, 0);
        const totalIva = totalExclIva * 0.22;
        const totalInclIva = totalExclIva + totalIva;
        return {
          totalExclIva: totalExclIva.toFixed(2),
          totalIva: totalIva.toFixed(2),
          totalInclIva: totalInclIva.toFixed(2),
        };
      };

      const summaryStartY = doc.previousAutoTable.finalY + 10;
      const totals = calculateTotals(budgetData.items);

      doc.setFont('helvetica', 'bold');
      doc.text('Riepilogo', 12, summaryStartY);
      doc.setFont('helvetica', 'normal');
      doc.text(`${t('Total excl. IVA')}: €${totals.totalExclIva}`, 12, summaryStartY + 6);
      doc.text(`IVA 22%: €${totals.totalIva}`, 12, summaryStartY + 12);
      doc.text(`${t('Total incl. IVA')}: €${totals.totalInclIva}`, 12, summaryStartY + 18);

      // 11. Rodapé
      const footerY = 280;
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(10);
      doc.text(
        'Il presente preventivo non costituisce documento valido ai fini fiscali. ' +
          'RingraziandoVi per l\'attenzione, rimaniamo in attesa di un gentile riscontro.',
        10,
        footerY,
        { maxWidth: 190 }
      );
      doc.text('Firma per l\'accettazione: ____________________', 10, footerY + 10);
  
      // Gera o blob URL do PDF
      const pdfBlobUrl = doc.output('bloburl');
  
      // Abre o PDF em uma nova aba para impressão ou salvamento
      window.open(pdfBlobUrl, '_blank');
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  const handleDescriptionChange = (index, value) => {
    setBudgetData((prev) => {
      if (!prev) return prev;
      const updatedItems = [...prev.items];
      updatedItems[index] = {
        ...updatedItems[index],
        description: value,
      };
      return { 
        ...prev, 
        items: updatedItems 
      };
    });
  };

  useEffect(() => {
    if (budgetData && budgetData.items) {
      const newTotals = calculateTotals(budgetData.items);
      setTotals(newTotals);
    }
  }, [budgetData]);

  const calculateTotals = (items) => {
    let totalExclIva = 0;
    items.forEach((item) => {
      const fPrice = parseFloat(item.finalPrice || 0);
      totalExclIva += fPrice;
    });
    // Vamos assumir IVA = 22% fixo, ou use item.iva se for por item
    const totalIva = totalExclIva * 0.22;
    const totalInclIva = totalExclIva + totalIva;

    return {
      totalExclIva: totalExclIva.toFixed(2),
      totalIva: totalIva.toFixed(2),
      totalInclIva: totalInclIva.toFixed(2),
    };
  };
    
  const calculateFinalPrice = (costPrice, profitMargin, quantity) => {
    const cp = parseFloat(costPrice) || 0;
    const pm = parseFloat(profitMargin) || 0;
    const qty = parseFloat(quantity) || 1;

    // Ex.: finalPrice = (cp + (cp * pm/100)) * qty
    const profit = cp * (pm / 100);
    const finalPrice = (cp + profit) * qty;
    return finalPrice.toFixed(2);
  };

  const handleNumberInputChange = (index, field, value) => {
    if (value === undefined) return;
  
    // Converte o valor para string (caso não seja)
    let newValue = String(value).replace(/,/g, '.');
  
    // Remove todos os caracteres que não sejam dígitos ou pontos
    newValue = newValue.replace(/[^0-9.]/g, '');
  
    // Garante que haja no máximo um ponto decimal
    const parts = newValue.split('.');
    if (parts.length > 2) {
      newValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Limita a dois decimais, se houver parte decimal
    if (parts[1]?.length > 2) {
      newValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
  
    // Se o campo for 'quantity', 'costPrice' ou 'profitMargin':
    // - Se o usuário digitar "0.50", o zero permanece.
    // - Se o usuário digitar "01" (ou "012", etc.) remove o zero inicial.
    if (['quantity', 'costPrice', 'profitMargin'].includes(field)) {
      if (newValue.length > 1 && newValue.startsWith('0') && newValue[1] !== '.') {
        newValue = newValue.substring(1);
      }
    }
  
    // Se o valor resultante for uma string vazia, define como "0"
    if (newValue === '') {
      newValue = '0';
    }
  
    // Atualiza o estado com o valor formatado
    handleItemChange(index, field, newValue);
  };

  const formatValue = (value) => {
    const num = parseFloat(value);
    return num === 0 ? "0" : value.toString();
  };

  const isMobile = window.innerWidth < 768;

  // Salva no backend
  const handleSave = async () => {
    if (!budgetData || !budgetData.id) {
      toast.error(t('Budget data is not loaded.'));
      return;
    }
    
    setSaving(true);
    const reorderedItems = reorderItems(budgetData.items);
    const updatedBudgetData = { ...budgetData, items: reorderedItems };
  
    try {
      const token = sessionStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/api/budgets/${budgetData.id}`, updatedBudgetData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSaving(false);
      toast.success(t('Budget saved successfully.'));
      if (userRole === 'mechanic') {
        onClose();
      }
    } catch (error) {
      console.error('Erro ao salvar orçamento:', error);
      setSaving(false);
      toast.error(t('Error saving budget.'));
    }
  };

  if (loading) {
    return <p>{t('Loading...')}</p>;
  }
  if (!budgetData) {
    return <p>{t('Budget not found')}</p>;
  }

  const renderBudgetContent = () => (
    <table className="table budget-table">
        <thead>
          <tr>
            <th>{t('Product/Service')}</th>
            <th>{t('Quantity')}</th>
            {(userRole === 'admin' || userRole === 'receptionist') && (
              <>
                <th>{t('Unit Price excl. IVA')}</th>
                <th>{t('Profit Margin %')}</th>
                <th>{t('Final Price')}</th>
              </>
            )}
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
        {budgetData?.items?.map((item, index) => (
            <React.Fragment key={index}>
                <tr key={index}>
                {/* Service */}
                <td>
                  <ServiceAutosuggest
                    value={item.service}
                    onChange={(newValue) => handleItemChange(index, 'service', newValue)}
                    suggestionsPosition={isMobile ? 'top' : 'bottom'} // passa a prop para ServiceAutosuggest
                  />
                </td>

                {/* Quantity */}
                <td>
                    <input
                        type="text"
                        className="form-control"
                        value={formatValue(item.quantity)}
                        onChange={(e) => handleNumberInputChange(index, 'quantity', e.target.value)}
                    />
                </td>
                {(userRole === 'admin' || userRole === 'receptionist') && (
                    <>
                    {/* Cost Price */}
                    <td>
                        <input
                            type="text"
                            className="form-control"
                            value={formatValue(item.costPrice)}
                            onChange={(e) => handleNumberInputChange(index, 'costPrice', e.target.value)}
                        />
                    </td>

                    {/* Profit Margin */}
                    <td>
                        <input
                            type="text"
                            className="form-control"
                            value={formatValue(item.profitMargin)}
                            onChange={(e) => handleNumberInputChange(index, 'profitMargin', e.target.value)}
                        />
                    </td>

                    {/* Final Price */}
                    <td>
                        <input
                        type="text"
                        className="form-control"
                        value={item.finalPrice}
                        readOnly
                        />
                    </td>
                    </>
                )}
                <td className="d-flex">
                    <button className="btn btn-danger" onClick={() => handleRemoveItem(index)}>
                    <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button
                    type="button"
                    className="btn btn-outline-primary space"
                    onClick={() => toggleDescription(index)}
                    >
                    {item.showDescription ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                        <FontAwesomeIcon icon={faEye} />
                    )}
                    </button>
                </td>
                </tr>
                {expandedRows.includes(index) && (
                    <tr>
                    <td colSpan={6}>
                    <div>
                        <textarea
                          className="form-control mt-2"
                          placeholder={t('Add detailed description')}
                          value={item.description || ''}
                          onChange={(e) => handleDescriptionChange(index, e.target.value)}
                          rows="3"
                        />
                    </div>
                    </td>
                    </tr>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
  );

  return (
    isMobile ? (
      <div className="budget-panel fullscreen">
        <div className="budget-panel-header mobile-header">
          <button className="back-button" onClick={onClose}>← {t('Back')}</button>
          <h3>{t('Budget of ticket')} #{ticketId}</h3>
        </div>
        <div className="budget-panel-content">
          {renderBudgetContent()}
          <div className="budget-bottom-section">
            <div className="budget-actions-left">
              <button className="btn btn-secondary" onClick={handleAddItem}>
                <FontAwesomeIcon icon={faPlusSquare} /> {t('Add')}
              </button>
              <button className="btn btn-primary space" onClick={handleSave}>
                {t('Save')}
              </button>
              <button className="btn btn-light space" onClick={onClose}>
                {t('Cancel')}
              </button>
              {(userRole === 'admin' || userRole === 'receptionist') && (
              <button className="btn btn-primary" onClick={() => generatePDF(budgetData)}>
                <FontAwesomeIcon icon={faPrint} className="me-2" />
                {t('Print Budget')}
              </button>
              )}
            </div>
            {(userRole === 'admin' || userRole === 'receptionist') && (
            <div className="budget-summary-right">
              <div className="budget-summary-card">
                <div className="budget-summary-row">
                  <span className="label">{t('Total excl. IVA')}:</span>
                  <span className="value">€{totals.totalExclIva}</span>
                </div>
                <div className="budget-summary-row">
                  <span className="label">IVA 22%:</span>
                  <span className="value">€{totals.totalIva}</span>
                </div>
                <div className="budget-summary-row">
                  <span className="label">{t('Total incl. IVA')}:</span>
                  <span className="value">€{totals.totalInclIva}</span>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <Draggable handle=".budget-panel-header">
        <div className={`budget-panel ${isMinimized ? 'minimized' : ''}`}>
          <div className="budget-panel-header">
            <h3>{t('Budget of ticket')} #{ticketId}</h3>
            <div className="budget-panel-controls">
              <button onClick={handleToggleMinimize} title={isMinimized ? t('Maximize') : t('Minimize')}>
                {isMinimized ? '▢' : <FontAwesomeIcon icon={faWindowMinimize} />}
              </button>
              <button onClick={onClose} title={t('Close')}>×</button>
            </div>
          </div>
          {!isMinimized && (
            <div className="budget-panel-content">
              {renderBudgetContent()}
              {/* Seção de Resumo do Orçamento */}
              <div className="budget-bottom-section">
                <div className="budget-actions-left">
                  <button className="btn btn-secondary" onClick={handleAddItem}>
                    <FontAwesomeIcon icon={faPlusSquare} /> {t('Add')}
                  </button>
                  <button className="btn btn-primary space" onClick={handleSave}>
                    {t('Save')}
                  </button>
                  <button className="btn btn-light space" onClick={onClose}>
                    {t('Cancel')}
                  </button>
                  {(userRole === 'admin' || userRole === 'receptionist') && (
                  <button className="btn btn-primary" onClick={() => generatePDF(budgetData)}>
                    <FontAwesomeIcon icon={faPrint} className="me-2" />
                    {t('Print Budget')}
                  </button>
                  )}
                </div>
                {(userRole === 'admin' || userRole === 'receptionist') && (
                <div className="budget-summary-right">
                  <div className="budget-summary-card">
                    <div className="budget-summary-row">
                      <span className="label">{t('Total excl. IVA')}:</span>
                      <span className="value">€{totals.totalExclIva}</span>
                    </div>
                    <div className="budget-summary-row">
                      <span className="label">IVA 22%:</span>
                      <span className="value">€{totals.totalIva}</span>
                    </div>
                    <div className="budget-summary-row">
                      <span className="label">{t('Total incl. IVA')}:</span>
                      <span className="value">€{totals.totalInclIva}</span>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Draggable>
    )
  );
}

export default BudgetPanel;