import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './ServiceAutosuggest.css';
import predefinedServices from '../components/Services';

// 1) Seus dados de serviços (por exemplo):


  function renderSuggestion(suggestion) {
    return <div>{suggestion.label}</div>;
  }
  
  function getSuggestionValue(suggestion) {
    return suggestion.label;
  }
  
  export default function ServiceAutosuggest({ value, onChange, suggestionsPosition = 'bottom' }) {
    const [suggestions, setSuggestions] = useState([]);
  
    const onSuggestionsFetchRequested = ({ value }) => {
      const inputValue = value.trim().toLowerCase();
      if (!inputValue) {
        setSuggestions([]);
        return;
      }
      const filtered = predefinedServices.filter((svc) =>
        svc.label.toLowerCase().includes(inputValue)
      );
      setSuggestions(filtered);
    };
  
    const onSuggestionsClearRequested = () => {
      setSuggestions([]);
    };
  
    const inputProps = {
      placeholder: 'Digita o seleziona un servizio',
      value: value,
      onChange: (e, { newValue }) => onChange(newValue),
      className: 'form-control',
    };
  
    // Função customizada para renderizar o container de sugestões
    const renderSuggestionsContainer = ({ containerProps, children }) => {
      const customStyle =
        suggestionsPosition === 'bottom'
          ? { ...containerProps.style, bottom: '100%', top: 'auto' }
          : { ...containerProps.style, top: '100%', bottom: 'auto' };
    
      return (
        <div {...containerProps} style={customStyle}>
          {children}
        </div>
      );
    };
  
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
      />
    );
  }