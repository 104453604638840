import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import initialCarBrands from '../components/CarBrands';
import API_BASE_URL from '../config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import 'react-datepicker/dist/react-datepicker.css';
import './CalendarModalContent.css';
import countryOptions from '../components/CountryOptions';

registerLocale('it', it);

function CalendarModalContent({ onClose, onSave, onUpdate, editMode, appointmentToEdit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Estados para seleção de cliente
  const [useExistingClient, setUseExistingClient] = useState(false);
  const [existingClients, setExistingClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientName, setClientName] = useState('');
  const [clientPhoneCountry, setClientPhoneCountry] = useState('+39');
  const [clientPhone, setClientPhone] = useState('');

  // Estados para seleção de veículo
  const [useExistingVehicle, setUseExistingVehicle] = useState(false);
  const [existingVehicles, setExistingVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [carBrand, setCarBrand] = useState('');
  const [carModel, setCarModel] = useState('');
  const [carYear, setCarYear] = useState('');
  const [carPlate, setCarPlate] = useState('');
  const [carMileage, setCarMileage] = useState('');

  // Estados para descrição, data e duração
  const [description, setDescription] = useState('');
  const [shortDescription, setshortDescription] = useState('');
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [duration, setDuration] = useState('');

  // useEffect para preencher os dados do evento ao editar
  useEffect(() => {
  if (editMode && appointmentToEdit) {
    setClientName(appointmentToEdit.clientName || '');
    setClientPhoneCountry(appointmentToEdit.clientPhoneCountry || '+39');
    setClientPhone(appointmentToEdit.clientPhone || '');

    // Verifica se o telefone do cliente existe e se há um cliente correspondente em existingClients
    if (appointmentToEdit.clientPhone) {
      const clientFound = existingClients.find(
        (client) => client.phone === appointmentToEdit.clientPhone
      );
      if (clientFound) {
        setUseExistingClient(true);
        setSelectedClient(clientFound);
      } else {
        setUseExistingClient(false);
      }
    } else {
      setUseExistingClient(false);
    }
    
    // Carrega os dados do veículo e demais campos normalmente
    setSelectedVehicle({
      value: appointmentToEdit.car_id || '',
      label: appointmentToEdit.car_brand
        ? `${appointmentToEdit.car_brand} ${appointmentToEdit.car_model} (${appointmentToEdit.car_plate})`
        : '',
      car_brand: appointmentToEdit.car_brand || '',
      car_model: appointmentToEdit.car_model || '',
      car_year: appointmentToEdit.car_year || '',
      car_plate: appointmentToEdit.car_plate || '',
      car_mileage: appointmentToEdit.car_mileage || '',
    });
    setCarBrand(appointmentToEdit.car_brand || '');
    setCarModel(appointmentToEdit.car_model || '');
    setCarYear(appointmentToEdit.car_year || '');
    setCarPlate(appointmentToEdit.car_plate || '');
    setCarMileage(appointmentToEdit.car_mileage || '');
  
    setDescription(appointmentToEdit.description || '');
    setshortDescription(appointmentToEdit.short_description || '');
    setAppointmentDate(appointmentToEdit.start_time ? new Date(appointmentToEdit.start_time) : null);
    setDuration(calculateDuration(appointmentToEdit.start_time, appointmentToEdit.end_time));
  } else {
    resetForm();
  }
}, [editMode, appointmentToEdit, existingClients]);
  
  const resetForm = () => {
    setClientName('');
    setClientPhoneCountry('+39');
    setClientPhone('');
    setUseExistingClient(false);
    setSelectedClient(null);
    setUseExistingVehicle(false);
    setSelectedVehicle(null);
    setCarBrand('');
    setCarModel('');
    setCarYear('');
    setCarPlate('');
    setCarMileage('');
    setDescription('');
    setshortDescription('');
    setAppointmentDate(null);
    setDuration('');
  };

  // Função para calcular a duração em minutos
  const calculateDuration = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const diffInMs = endTime - startTime;
    const diffInMinutes = Math.round(diffInMs / 60000);
    return diffInMinutes.toString();
  };

  const areVehicleFieldsValid = () => {
    return (
      carBrand && carBrand.trim() !== '' &&
      carModel && carModel.trim() !== '' &&
      carYear && carYear.toString().trim() !== '' &&
      carPlate && carPlate.trim() !== '' &&
      carMileage && carMileage.toString().trim() !== '' &&
      // Se estivermos editando um agendamento e o ticket já estiver ativo, a validação falha
      (!appointmentToEdit || appointmentToEdit.ticket_active !== '1')
    );
  };

const onCreateTicket = async (ticketData) => {
  try {
    const token = sessionStorage.getItem('token');
    // Inclui fromCalendar: true e appointmentId (caso exista)
    const payload = { 
      ...ticketData, 
      fromCalendar: true, 
      appointmentId: appointmentToEdit ? appointmentToEdit.id : null 
    };
    const response = await fetch(`${API_BASE_URL}/api/customer-care`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    
    if (response.ok) {
      const data = await response.json();
      const { nextTicketNumber } = data;
      toast.success(t('Ticket created successfully.'));
      
      navigate(`/tickets/${nextTicketNumber}`);
    } else {
      const errorData = await response.json();
      toast.error(errorData.message || t('Error creating ticket.'));
    }
  } catch (error) {
    console.error('Error creating ticket:', error);
    toast.error(t('Error creating ticket.'));
  }
};
  

  // Fetch Existing Clients (sempre busca os dados, independente do checkbox)
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(t('Failed to fetch clients.'));
        }
        const data = await response.json();
        const clientOptions = data.map((client) => ({
          value: client.id,
          label: client.name,
          phone_country: client.phone_country,
          phone: client.phone,
        }));
        setExistingClients(clientOptions);
      } catch (error) {
        console.error('Error fetching clients:', error);
        toast.error(t('Error fetching clients.'));
      }
    };
    fetchClients();
  }, [t]);

  // Fetch Existing Vehicles based on Selected Client
  useEffect(() => {
    if (useExistingVehicle && selectedClient) {
      const fetchVehicles = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${API_BASE_URL}/api/clients/${selectedClient.value}/cars`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error(t('Failed to fetch vehicles.'));
          }
          const data = await response.json();
          const vehicleOptions = data.map((vehicle) => ({
            value: vehicle.id,
            label: `${vehicle.car_brand} ${vehicle.car_model} (${vehicle.car_plate})`,
            car_brand: vehicle.car_brand,
            car_model: vehicle.car_model,
            car_year: vehicle.car_year,
            car_plate: vehicle.car_plate,
            car_mileage: vehicle.last_mileage,
          }));
          setExistingVehicles(vehicleOptions);
        } catch (error) {
          console.error('Error fetching vehicles:', error);
          toast.error(t('Error fetching vehicles.'));
        }
      };
      fetchVehicles();
    }
  }, [useExistingVehicle, selectedClient, t]);

  // Handle Client Selection
  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    if (selectedOption) {
      setClientName(selectedOption.label); // Nome do cliente
  
      // Procura a opção correspondente na countryOptions
      const countryOption = countryOptions.find(
        (option) =>
          option.value.toLowerCase() === (selectedOption.phone_country || '').toLowerCase()
      );
      // Armazena o dial code, se encontrado; caso contrário, usa o padrão "+39"
      setClientPhoneCountry(countryOption ? countryOption.dialCode : '+39');
  
      setClientPhone(selectedOption.phone || '');
    } else {
      setClientName('');
      setClientPhoneCountry('+39');
      setClientPhone('');
    }
  };

  // Handle Vehicle Selection
  const handleVehicleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    setCarBrand(selectedOption.car_brand);
    setCarModel(selectedOption.car_model);
    setCarYear(selectedOption.car_year);
    setCarPlate(selectedOption.car_plate);
    setCarMileage(selectedOption.car_mileage);
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação
    if (useExistingClient) {
      if (!selectedClient) {
        toast.error(t('Please select an existing client or enter new details.'));
        return;
      }
    } else {
      if (!clientName.trim()) {
        toast.error(t('Please enter the client name.'));
        return;
      }
    }

    if (!appointmentDate || !duration) {
      toast.error(t('Please select the appointment date and duration.'));
      return;
    }

    // Preparar os dados para salvar
    const data = {
        clientId: useExistingClient ? selectedClient.value : null,
        clientName: useExistingClient ? selectedClient.label : clientName,
        clientPhoneCountry: useExistingClient ? selectedClient.phone_country : clientPhoneCountry,
        clientPhone: useExistingClient ? selectedClient.phone : clientPhone,
        vehicleId: useExistingVehicle ? selectedVehicle.value : null,
        carBrand: useExistingVehicle ? selectedVehicle.car_brand : carBrand,
        carModel: useExistingVehicle ? selectedVehicle.car_model : carModel,
        carYear: useExistingVehicle ? selectedVehicle.car_year : carYear,
        carPlate: useExistingVehicle ? selectedVehicle.car_plate : carPlate,
        carMileage: useExistingVehicle ? selectedVehicle.car_mileage : carMileage,
        description,
        short_description: shortDescription,
        appointmentDate: appointmentDate.toISOString(),
        duration,
    };

    console.log('Dados do formulário antes de enviar:', data);

    // Passar os dados para o componente pai
    if (editMode) {
      onUpdate(appointmentToEdit.id, data);
    } else {
      onSave(data);
    }
  };

  // Estilos personalizados para o react-select
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#007bff' : '#cccccc',
      boxShadow: state.isFocused ? '0 0 5px rgba(0, 123, 255, 0.5)' : 'none',
      '&:hover': {
        borderColor: '#007bff',
      },
      borderRadius: '6px',
      padding: '2px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '6px',
      marginTop: '2px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#e9f5ff' : '#ffffff',
      color: '#333333',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: '#d0eaff',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#888888',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333333',
    }),
  };

  return (
    <div className="calendar-modal-content">
      <div className="modal-header">
        <h2>{editMode ? t('Edit Appointment') : t('New Appointment')}</h2>
        <button onClick={onClose} className="close-button">&times;</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={useExistingClient}
              onChange={(e) => {
                setUseExistingClient(e.target.checked);
                if (!e.target.checked) {
                  // Resetar estados relacionados ao cliente existente
                  setSelectedClient(null);
                  setExistingVehicles([]);
                  setUseExistingVehicle(false);
                }
              }}
            />
            {t('Use Existing Client')}
          </label>
        </div>

        {/* Seção de Nome e Telefone lado a lado */}
        <div className="form-row">
          {/* Nome */}
          <div className="form-group">
            <label htmlFor="name">{t('Client Name')}</label>
            {useExistingClient ? (
              <Select
                styles={customSelectStyles}
                options={existingClients}
                value={selectedClient}
                onChange={handleClientChange}
                placeholder={t('select_client')}
              />
            ) : (
              <input
                type="text"
                id="name"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder={t('Enter client name')}
                required={!useExistingClient}
              />
            )}
          </div>
          {/* Telefone */}
          <div className="form-group">
            <label htmlFor="phone">{t('Client Phone')}</label>
            {useExistingClient ? (
                selectedClient ? (
                <div className="phone-display">
                    <span>{selectedClient.phone_country}</span> <span>{selectedClient.phone}</span>
                </div>
                ) : (
                <div className="phone-display">
                    {t('please_select_client_phone')}
                </div>
                )
            ) : (
                <div className="phone-input">
                <Select
                    styles={customSelectStyles}
                    options={countryOptions}
                    value={countryOptions.find(option => option.dialCode === clientPhoneCountry)}
                    onChange={(selectedOption) => setClientPhoneCountry(selectedOption.value)}
                    placeholder={t('Country Code')}
                    className="country-code-select"
                    isDisabled={useExistingClient}
                />
                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    value={clientPhone}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, '');
                      setClientPhone(numericValue);

                      // Procura no array existingClients um cliente cujo telefone seja igual ao valor digitado
                      const existingClient = existingClients.find(
                        (client) => client.phone === numericValue && numericValue !== ''
                      );

                      if (existingClient) {
                        // Preenche automaticamente os dados do cliente
                        setSelectedClient(existingClient);
                        setClientName(existingClient.label); // ou existingClient.name
                        setUseExistingClient(true);
                        toast.info(t('Client already exists. Data auto-filled.'));
                      }
                    }}
                    onKeyDown={(e) => {
                      // Impede a digitação de espaços ou caracteres não numéricos
                      if (e.key === ' ' || (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Delete')) {
                        e.preventDefault();
                      }
                    }}
                    placeholder={t('Enter client phone')}
                    required={!useExistingClient}
                />
                </div>
            )}
          </div>
        </div>

        {/* Checkbox para usar veículo existente (aparece apenas se Use Existing Client está selecionado) */}
        {useExistingClient && (
          <div className="form-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={useExistingVehicle}
                onChange={(e) => {
                  setUseExistingVehicle(e.target.checked);
                  if (!e.target.checked) {
                    setSelectedVehicle(null);
                    // Opcional: resetar campos do veículo manual
                    setCarBrand('');
                    setCarModel('');
                    setCarYear('');
                    setCarPlate('');
                    setCarMileage('');
                  }
                }}
              />
              {t('Use Existing Vehicle')}
            </label>
          </div>
        )}

        {/* Seção de Veículo */}
        <div className="form-row">
          {/* Marca do Carro */}
          <div className="form-group">
            <label htmlFor="car-brand">{t('Car Brand')}</label>
            {useExistingVehicle ? (
              <Select
                styles={customSelectStyles}
                options={existingVehicles}
                value={selectedVehicle}
                onChange={handleVehicleChange}
                placeholder={t('Select a Car')}
              />
            ) : (
              <CreatableSelect
                styles={customSelectStyles}
                options={initialCarBrands}
                value={initialCarBrands.find(brand => brand.value === carBrand) || null}
                onChange={(selectedOption) => setCarBrand(selectedOption ? selectedOption.value : '')}
                placeholder={t('Select or enter a car brand')}
                isClearable
              />
            )}
          </div>
          {/* Modelo do Carro */}
          <div className="form-group">
            <label htmlFor="car-model">{t('Car Model')}</label>
            {useExistingVehicle ? (
              <input
                type="text"
                id="car-model"
                value={useExistingVehicle ? (selectedVehicle ? selectedVehicle.label.split(' ')[1] : '') : carModel}
                disabled
              />
            ) : (
              <CreatableSelect
                styles={customSelectStyles}
                options={
                  carBrand
                    ? initialCarBrands.find((brand) => brand.value === carBrand)?.models.map((model) => ({
                        value: model,
                        label: model,
                      })) || []
                    : []
                }
                value={carModel ? { value: carModel, label: carModel } : null}
                onChange={(selectedOption) => setCarModel(selectedOption ? selectedOption.value : '')}
                placeholder={t('Select or enter a car model')}
                isClearable
                isDisabled={!carBrand}
              />
            )}
          </div>
        </div>

        <div className="form-row">
          {/* Ano do Carro */}
          <div className="form-group">
            <label htmlFor="car-year">{t('Car Year')}</label>
            {useExistingVehicle ? (
              <input
                type="text"
                id="car-year"
                value={useExistingVehicle ? (selectedVehicle ? carYear : '') : carYear}
                onChange={(e) => setCarYear(e.target.value)}
                disabled
              />
            ) : (
              <input
                type="text"
                id="car-year"
                value={carYear}
                onChange={(e) => setCarYear(e.target.value)}
                placeholder={t('Enter car year')}
                min="1900"
                max={new Date().getFullYear() + 1}
              />
            )}
          </div>

          {/* Placa do Carro */}
          <div className="form-group">
            <label htmlFor="car-plate">{t('Car Plate')}</label>
            {useExistingVehicle ? (
              <input
                type="text"
                id="car-plate"
                value={useExistingVehicle ? (selectedVehicle ? carPlate : '') : carPlate}
                onChange={(e) => setCarPlate(e.target.value)}
                placeholder={t('Enter car plate')}
                disabled={useExistingVehicle}
              />
            ) : (
              <input
                type="text"
                id="car-plate"
                value={carPlate}
                onChange={(e) => setCarPlate(e.target.value)}
                placeholder={t('Enter car plate')}
              />
            )}
          </div>
          {/* Kilometragem do Carro */}
          <div className="form-group">
            <label htmlFor="car-mileage">{t('Car Mileage')}</label>
            {useExistingVehicle ? (
              <input
                type="text"
                id="car-mileage"
                value={useExistingVehicle ? (selectedVehicle ? carMileage : '') : carMileage}
                onChange={(e) => setCarMileage(e.target.value)}
              />
            ) : (
              <input
                type="text"
                id="car-mileage"
                value={carMileage}
                onChange={(e) => setCarMileage(e.target.value)}
                placeholder={t('Enter car mileage')}
                min="0"
              />
            )}
          </div>
        </div>

        {/* Descrição Curta */}
        <div className="form-group">
          <label htmlFor="short-description">{t('Short Description')}</label>
          <input
            type="text"
            id="short-description"
            value={shortDescription}
            onChange={(e) => setshortDescription(e.target.value)}
            placeholder={t('Enter a short description')}
          />
        </div>
        <br/>

        {/* Descrição */}
        <div className="form-group">
          <label htmlFor="description">{t('Description')}</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('Add detailed description')}
            rows="5"
            required
          ></textarea>
        </div>

        <br/>

        {/* Data e Duração */}
        <div className="form-row">
          {/* Data do Agendamento */}
          <div className="form-group">
            <label htmlFor="appointment-date">{t('Appointment Date')}</label>
            <DatePicker
              selected={appointmentDate}
              onChange={(date) => setAppointmentDate(date)}
              showTimeSelect
              dateFormat="Pp"
              placeholderText={t('Select date and time')}
              className="custom-datepicker"
              id="appointment-date"
              autoComplete="off"
              required
              locale="it"
            />
          </div>
          {/* Duração do Agendamento */}
          <div className="form-group">
            <label htmlFor="duration">{t('Duration (minutes)')}</label>
            <select
              id="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            >
              <option value="">{t('Select duration')}</option>
              <option value="30">{t('30 minutes')}</option>
              <option value="60">{t('1 hour')}</option>
              <option value="90">{t('1.5 hours')}</option>
              <option value="120">{t('2 hours')}</option>
              <option value="150">{t('2.5 hours')}</option>
              <option value="180">{t('3 hours')}</option>
              <option value="210">{t('3.5 hours')}</option>
              <option value="240">{t('4 hours')}</option>
              <option value="270">{t('4.5 hours')}</option>
              <option value="300">{t('5 hours')}</option>
              <option value="330">{t('5.5 hours')}</option>
              <option value="360">{t('6 hours')}</option>
              <option value="390">{t('6.5 hours')}</option>
              <option value="420">{t('7 hours')}</option>
              <option value="450">{t('7.5 hours')}</option>
              <option value="480">{t('8 hours')}</option>
            </select>
          </div>
        </div>

        {/* Botões de Ação */}
        <button
          type="button"
          className="import-to-booking-button"
          onClick={() => {
            onClose(); // Fecha o modal atual
            
            const formData = {
              clientName,
              clientPhoneCountry,
              clientPhone,
              carBrand,
              carModel,
              carYear,
              carPlate,
              carMileage,
              issue_description: description,
              appointmentDate,
              duration
            };

            // Salva os dados no sessionStorage
            sessionStorage.setItem('bookingFormData', JSON.stringify(formData));

            // Redireciona para o calendário com filtro para abrir modal de mecânico
            window.location.href = '/calendar?booking=mechanic';
          }}
        >
          {t('Import to Mechanic Booking')}
        </button>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary">
            {editMode ? t('Update') : t('Save')}
          </button>
          {areVehicleFieldsValid() && (
            <button
              type="button"
              className='btn btn-success'
              onClick={() => onCreateTicket({
                clientName,
                clientPhoneCountry,
                clientPhone,
                carBrand,
                carModel,
                carYear,
                carPlate,
                carMileage,
                carProblem: description,
              })}
            >
              {t('Create Ticket')}
            </button>
          )}
          <button type="button" onClick={onClose} className="btn btn-secondary">
            {t('Cancel')}
          </button>
        </div>
      </form>
    </div>
  );
}

// Definição de PropTypes para verificação de tipos
CalendarModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CalendarModalContent;