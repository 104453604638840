// BookingMechanicModalContent.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import initialCarBrands from '../components/CarBrands';
import { FaPlus, FaTrash } from 'react-icons/fa';
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import API_BASE_URL from '../config';
import PropTypes from 'prop-types';
import './BookingMechanicModalContent.css';
import moment from 'moment';

Modal.setAppElement('#root');
registerLocale('it', it);

function BookingMechanicModalContent({ onClose, selectedMechanic, onSaveBooking, onUpdateBooking, editMode, bookingToEdit, ticketOption, selectedTicket, setTicketOption, setSelectedTicket }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Se o modal recebeu um mecânico selecionado, use-o; caso contrário, permita seleção.
  const [mechanicSelection, setMechanicSelection] = useState(selectedMechanic);
  const [mechanicsList, setMechanicsList] = useState([]);
  const effectiveMechanic = mechanicSelection || selectedMechanic;

  useEffect(() => {
    if (selectedMechanic) {
      setMechanicSelection({
        value: selectedMechanic.id,
        label: selectedMechanic.name,
      });
    }
  }, [selectedMechanic]);

  // Se não houver mecânico selecionado, busque a lista de mecânicos
  useEffect(() => {
    if (!mechanicSelection) {
      const fetchMechanics = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${API_BASE_URL}/api/mechanics`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.ok) {
            const data = await response.json();
            // Mapeia os dados para { value, label }
            const options = data.map((mech) => ({
              value: mech.id,
              label: mech.name,
            }));
            setMechanicsList(options);
          }
        } catch (error) {
          console.error('Erro ao buscar mecânicos:', error);
        }
      };
  
      fetchMechanics();
    }
  }, [mechanicSelection, effectiveMechanic]);

  useEffect(() => {
    const storedData = sessionStorage.getItem('bookingFormData');
    if (storedData) {
      try {
        const formData = JSON.parse(storedData);
        setClientName(formData.clientName || '');
        setClientPhoneCountry(formData.clientPhoneCountry || '+39');
        setClientPhone(formData.clientPhone || '');
        setCarBrand(formData.carBrand || '');
        setCarModel(formData.carModel || '');
        setCarYear(formData.carYear || '');
        setCarPlate(formData.carPlate || '');
        setCarMileage(formData.carMileage || '');
        setDescription(formData.issue_description || '');
        setBookingDate(formData.appointmentDate ? new Date(formData.appointmentDate) : null);
        setDuration(formData.duration || '');
        sessionStorage.removeItem('bookingFormData');
      } catch (error) {
        console.error('Erro ao importar dados do agendamento:', error);
      }
    }
  }, []);

  // Estados para informações do cliente
  const [useExistingClient, setUseExistingClient] = useState(false);
  const [existingClients, setExistingClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientName, setClientName] = useState('');
  const [clientPhoneCountry, setClientPhoneCountry] = useState('+39');
  const [clientPhone, setClientPhone] = useState('');

  // Estados para informações do veículo
  const [useExistingVehicle, setUseExistingVehicle] = useState(false);
  const [existingVehicles, setExistingVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [carBrand, setCarBrand] = useState('');
  const [carModel, setCarModel] = useState('');
  const [carYear, setCarYear] = useState('');
  const [carPlate, setCarPlate] = useState('');
  const [carMileage, setCarMileage] = useState('');

  // Estados para detalhes do booking
  const [bookingDate, setBookingDate] = useState(null);
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [bookingTime, setBookingTime] = useState('');

  // Estado para a opção de ticket
  // 'new' para criar novo ticket e 'existing' para associar a um ticket já existente.
  //const [ticketOption, setTicketOption] = useState('new');

  // Estados para tickets existentes (quando o usuário optar por associar a um ticket existente)
  const [existingTickets, setExistingTickets] = useState([]);
  //const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    if (ticketOption === 'existing' && selectedTicket && selectedTicket.value) {
      handleTicketSelect(selectedTicket);
    }
  }, [ticketOption, selectedTicket]);
  
  const [ticketListModalIsOpen, setTicketListModalIsOpen] = useState(false);

  // At the top of your component (inside the function component):
    const [additionalDays, setAdditionalDays] = useState([]);

    // Adds a new additional day (with empty values)
    // Ao adicionar um additional day, incluir start_time no objeto:
    const addAdditionalDay = () => {
      setAdditionalDays([...additionalDays, { date: null, duration: '', start_time: '' }]);
    };

    // Handler para atualizar o start_time do additional day:
    const handleAdditionalDayStartTimeChange = (index, time) => {
      const updatedDays = additionalDays.map((day, i) =>
        i === index ? { ...day, start_time: time } : day
      );
      setAdditionalDays(updatedDays);
    };

    // Removes an additional day by index
    const removeAdditionalDay = (index) => {
    setAdditionalDays(additionalDays.filter((_, i) => i !== index));
    };

    // Handles change for the date of an additional day
    const handleAdditionalDayDateChange = (index, date) => {
    const updatedDays = additionalDays.map((day, i) =>
        i === index ? { ...day, date } : day
    );
    setAdditionalDays(updatedDays);
    };

    // Handles change for the duration of an additional day
    const handleAdditionalDayDurationChange = (index, duration) => {
    const updatedDays = additionalDays.map((day, i) =>
        i === index ? { ...day, duration } : day
    );
    setAdditionalDays(updatedDays);
    };

  const countryOptions = [
    // Europa
    { value: 'it', label: '🇮🇹 Italia (+39)', dialCode: '+39' },
    { value: 'es', label: '🇪🇸 Spagna (+34)', dialCode: '+34' },
    { value: 'gb', label: '🇬🇧 Regno Unito (+44)', dialCode: '+44' },
    { value: 'fr', label: '🇫🇷 Francia (+33)', dialCode: '+33' },
    { value: 'ie', label: '🇮🇪 Irlanda (+353)', dialCode: '+353' },
    { value: 'pt', label: '🇵🇹 Portogallo (+351)', dialCode: '+351' },
    { value: 'nl', label: '🇳🇱 Paesi Bassi (+31)', dialCode: '+31' },
    { value: 'be', label: '🇧🇪 Belgio (+32)', dialCode: '+32' },
    { value: 'de', label: '🇩🇪 Germania (+49)', dialCode: '+49' },
    { value: 'ch', label: '🇨🇭 Svizzera (+41)', dialCode: '+41' },
    { value: 'se', label: '🇸🇪 Svezia (+46)', dialCode: '+46' },
    { value: 'no', label: '🇳🇴 Norvegia (+47)', dialCode: '+47' },
    { value: 'dk', label: '🇩🇰 Danimarca (+45)', dialCode: '+45' },
    { value: 'pl', label: '🇵🇱 Polonia (+48)', dialCode: '+48' },
    { value: 'at', label: '🇦🇹 Austria (+43)', dialCode: '+43' },
    { value: 'gr', label: '🇬🇷 Grecia (+30)', dialCode: '+30' },
    { value: 'fi', label: '🇫🇮 Finlandia (+358)', dialCode: '+358' },
    { value: 'cz', label: '🇨🇿 Repubblica Ceca (+420)', dialCode: '+420' },
    { value: 'hu', label: '🇭🇺 Ungheria (+36)', dialCode: '+36' },
    { value: 'ro', label: '🇷🇴 Romania (+40)', dialCode: '+40' },
    { value: 'bg', label: '🇧🇬 Bulgaria (+359)', dialCode: '+359' },
    { value: 'sk', label: '🇸🇰 Slovacchia (+421)', dialCode: '+421' },
    
    // África
    { value: 'za', label: '🇿🇦 Sud Africa (+27)', dialCode: '+27' },
    { value: 'eg', label: '🇪🇬 Egitto (+20)', dialCode: '+20' },
    { value: 'ng', label: '🇳🇬 Nigeria (+234)', dialCode: '+234' },
    { value: 'ke', label: '🇰🇪 Kenya (+254)', dialCode: '+254' },
    { value: 'ma', label: '🇲🇦 Marocco (+212)', dialCode: '+212' },
    { value: 'dz', label: '🇩🇿 Algeria (+213)', dialCode: '+213' },
    { value: 'tn', label: '🇹🇳 Tunisia (+216)', dialCode: '+216' },
    { value: 'gh', label: '🇬🇭 Ghana (+233)', dialCode: '+233' },
    { value: 'et', label: '🇪🇹 Etiopia (+251)', dialCode: '+251' },
    { value: 'ug', label: '🇺🇬 Uganda (+256)', dialCode: '+256' },
    { value: 'ci', label: '🇨🇮 Costa d\'Avorio (+225)', dialCode: '+225' },
    { value: 'sn', label: '🇸🇳 Senegal (+221)', dialCode: '+221' },
    { value: 'tz', label: '🇹🇿 Tanzania (+255)', dialCode: '+255' },
    { value: 'cm', label: '🇨🇲 Camerun (+237)', dialCode: '+237' },
    { value: 'zw', label: '🇿🇼 Zimbabwe (+263)', dialCode: '+263' },
    { value: 'ly', label: '🇱🇾 Libia (+218)', dialCode: '+218' },
    { value: 'ml', label: '🇲🇱 Mali (+223)', dialCode: '+223' },
    { value: 'ao', label: '🇦🇴 Angola (+244)', dialCode: '+244' },
    
    // América do Norte
    { value: 'ca', label: '🇨🇦 Canada (+1)', dialCode: '+1' },
    { value: 'mx', label: '🇲🇽 Messico (+52)', dialCode: '+52' },
    { value: 'us', label: '🇺🇸 Stati Uniti (+1)', dialCode: '+1' },
    { value: 'cu', label: '🇨🇺 Cuba (+53)', dialCode: '+53' },
    { value: 'do', label: '🇩🇴 Repubblica Dominicana (+1 809/829/849)', dialCode: '+1' },
    { value: 'jm', label: '🇯🇲 Giamaica (+1 876)', dialCode: '+1' },
    { value: 'bs', label: '🇧🇸 Bahamas (+1 242)', dialCode: '+1' },
    { value: 'tt', label: '🇹🇹 Trinidad e Tobago (+1 868)', dialCode: '+1' },
    { value: 'bb', label: '🇧🇧 Barbados (+1 246)', dialCode: '+1' },
    { value: 'lc', label: '🇱🇨 Santa Lucia (+1 758)', dialCode: '+1' },
    { value: 'gd', label: '🇬🇩 Grenada (+1 473)', dialCode: '+1' },
    { value: 'vc', label: '🇻🇨 Saint Vincent e Grenadine (+1 784)', dialCode: '+1' },
    { value: 'ag', label: '🇦🇬 Antigua e Barbuda (+1 268)', dialCode: '+1' },
    { value: 'dm', label: '🇩🇲 Dominica (+1 767)', dialCode: '+1' },
    { value: 'kn', label: '🇰🇳 Saint Kitts e Nevis (+1 869)', dialCode: '+1' },
    { value: 'bm', label: '🇧🇲 Bermuda (+1 441)', dialCode: '+1' },

    // America do Sul
    { value: 'br', label: '🇧🇷 Brasil (+55)', dialCode: '+55' },
    
  ];

  // Carrega os tickets existentes quando o usuário escolhe a opção "existing"
  useEffect(() => {
    if (ticketOption === 'existing') {
      fetchAvailableTickets();
    }
  }, [ticketOption]);

  useEffect(() => {
    if (mechanicSelection) {
      // Limpa os tickets anteriores e busca os novos
      setExistingTickets([]);
      fetchAvailableTickets();
    } else {
      setExistingTickets([]);
    }
  }, [mechanicSelection]);

  const fetchAvailableTickets = async () => {
    if (!effectiveMechanic) {
      toast.error(t('Selecione um mecânico primeiro.'));
      return;
    }
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        `${API_BASE_URL}/api/tickets/available?mechanicId=${effectiveMechanic.value}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) {
        throw new Error(t('Erro ao buscar tickets disponíveis.'));
      }
      const data = await response.json();
      const ticketOptions = data.map(ticket => ({
        value: ticket.id,
        label: `Ticket #${ticket.id}`,
        clientName: ticket.client_name,
        carBrand: ticket.car_brand,
        carModel: ticket.car_model,
      }));
      setExistingTickets(ticketOptions);
    } catch (error) {
      console.error('Erro ao buscar tickets disponíveis:', error);
      toast.error(error.message);
    }
  };

  // Quando o ticket for selecionado (no handler de seleção)
  const handleTicketSelect = async (ticket) => {
    console.log('Ticket selecionado:', ticket);
    
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${ticket.value}/details`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      if (!response.ok) {
        throw new Error('Erro ao buscar detalhes do ticket.');
      }
      
      const ticketDetails = await response.json();
      console.log('Detalhes do ticket:', ticketDetails);
      
      // Atualiza os estados do formulário com os dados do ticket
      setClientName(ticketDetails.clientName || '');
      setClientPhone(ticketDetails.clientPhone || '');
      setClientPhoneCountry(ticketDetails.clientPhoneCountry || '+39');
      setCarBrand(ticketDetails.carBrand || '');
      setCarModel(ticketDetails.carModel || '');
      setCarYear(ticketDetails.carYear || '');
      setCarPlate(ticketDetails.carPlate || '');
      setCarMileage(ticketDetails.carMileage || '');
      setDescription(ticketDetails.issue_description || '');
      
    } catch (error) {
      console.error('Erro ao buscar detalhes do ticket:', error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    // Se estiver em modo de edição, não executa a busca
    if (editMode) return;
  
    if (selectedTicket) {
      console.log('Ticket selecionado:', selectedTicket);
      // Atualiza os campos automaticamente com base nos dados do ticket
      setClientName(selectedTicket.clientName || '');
      setClientPhone(selectedTicket.clientPhone || '');
      setCarBrand(selectedTicket.carBrand || '');
      setCarModel(selectedTicket.carModel || '');
      setCarYear(selectedTicket.carYear || '');
      setCarPlate(selectedTicket.carPlate || '');
      setCarMileage(selectedTicket.carMileage || '');
      setDescription(selectedTicket.issue_description || '');
    }
  }, [selectedTicket, editMode]);

  useEffect(() => {
    if (editMode && bookingToEdit) {
      setClientName(bookingToEdit.clientName || '');
      setClientPhoneCountry(bookingToEdit.clientPhoneCountry || '+39');
      setClientPhone(bookingToEdit.clientPhone || '');
      setCarBrand(bookingToEdit.carBrand || '');
      setCarModel(bookingToEdit.carModel || '');
      setCarYear(bookingToEdit.carYear || '');
      setCarPlate(bookingToEdit.carPlate || '');
      setCarMileage(bookingToEdit.carMileage || '');
      setDescription(bookingToEdit.issue_description || '');
  
      // Preenche a data
      if (bookingToEdit.booking_date) {
        setBookingDate(new Date(bookingToEdit.booking_date));
      } else {
        setBookingDate(null);
      }
  
      // Adicione o set para a hora
      if (bookingToEdit.start_time) {
        setBookingTime(bookingToEdit.start_time);
      } else {
        setBookingTime('');
      }
      
      setDuration(bookingToEdit.duration || bookingToEdit.estimated_time || '');
  
      if (bookingToEdit.service_log_id) {
        setTicketOption('existing');
        setSelectedTicket({
          value: bookingToEdit.service_log_id,
          label: `Ticket #${bookingToEdit.service_log_id}`,
        });
      } else {
        setTicketOption('new');
        setSelectedTicket(null);
      }
      
      // Seleção automática do mecânico:
      if (bookingToEdit.bookingMechanicId && bookingToEdit.mechanicName) {
        setMechanicSelection({
          value: bookingToEdit.bookingMechanicId,
          label: bookingToEdit.mechanicName,
        });
      } else {
        setMechanicSelection(null);
      }
    }
  }, [editMode, bookingToEdit]);

  // Handle Client Selection
  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    if (selectedOption) {
      setClientName(selectedOption.label); // Nome do cliente
      setClientPhoneCountry(selectedOption.phone_country || '+39'); // Código do país
      setClientPhone(selectedOption.phone || ''); // Número de telefone
    } else {
      setClientName('');
      setClientPhoneCountry('+39');
      setClientPhone('');
    }
  };

  // Handle Vehicle Selection
  const handleVehicleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    setCarBrand(selectedOption.car_brand);
    setCarModel(selectedOption.car_model);
    setCarYear(selectedOption.car_year);
    setCarPlate(selectedOption.car_plate);
    setCarMileage(selectedOption.car_mileage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!mechanicSelection || !mechanicSelection.value) {
      toast.error(t('Select a mechanic.'));
      return;
    }

    // Monta o objeto que será enviado
    const bookingData = {
      clientName,
      clientPhoneCountry,
      clientPhone,
      carBrand,
      carModel,
      carYear,
      carPlate,
      carMileage,
      issue_description: description,
      booking_date: bookingDate ? bookingDate.toISOString() : null,
      bookingTime, // campo separado para horário
      duration,
      additionalDays,
      ticketOption,
      ticketId: selectedTicket ? selectedTicket.value : null,
      mechanic_id: mechanicSelection?.value || selectedMechanic?.value || null,
    };
  
    console.log('Booking Data object:', bookingData);
    
    if (!mechanicSelection && !selectedMechanic) {
      toast.error(t('Select a mechanic.'));
      return;
    }

    if (editMode) {
      onUpdateBooking(bookingToEdit.id, bookingData);
    } else {
      onSaveBooking(bookingData);
    }
    onClose();
  };

  // Estilos personalizados para o react-select
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#007bff' : '#cccccc',
      boxShadow: state.isFocused ? '0 0 5px rgba(0, 123, 255, 0.5)' : 'none',
      '&:hover': {
        borderColor: '#007bff',
      },
      borderRadius: '6px',
      padding: '2px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '6px',
      marginTop: '2px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#e9f5ff' : '#ffffff',
      color: '#333333',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: '#d0eaff',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#888888',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333333',
    }),
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/clients`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error('Falha ao buscar clientes.');
        }
        const data = await response.json();
        const clientOptions = data.map((client) => ({
          value: client.id,
          label: client.name,
          phone_country: client.phone_country,
          phone: client.phone,
        }));
        setExistingClients(clientOptions);
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
        toast.error('Erro ao buscar clientes.');
      }
    };
    fetchClients();
  }, []);

  // Fetch Existing Vehicles based on Selected Client
  useEffect(() => {
    if (useExistingVehicle && selectedClient) {
      const fetchVehicles = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${API_BASE_URL}/api/clients/${selectedClient.value}/cars`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error(t('Failed to fetch vehicles.'));
          }
          const data = await response.json();
          const vehicleOptions = data.map((vehicle) => ({
            value: vehicle.id,
            label: `${vehicle.car_brand} ${vehicle.car_model} (${vehicle.car_plate})`,
            car_brand: vehicle.car_brand,
            car_model: vehicle.car_model,
            car_year: vehicle.car_year,
            car_plate: vehicle.car_plate,
            car_mileage: vehicle.last_mileage,
          }));
          setExistingVehicles(vehicleOptions);
        } catch (error) {
          console.error('Error fetching vehicles:', error);
          toast.error(t('Error fetching vehicles.'));
        }
      };
      fetchVehicles();
    }
  }, [useExistingVehicle, selectedClient, t]);

  const handleAdditionalDayTimeChange = (index, time) => {
    const formattedTime = moment(time).format('HH:mm:ss');
    const updatedDays = additionalDays.map((day, i) =>
      i === index ? { ...day, start_time: formattedTime } : day
    );
    setAdditionalDays(updatedDays);
  };

  return (
    <div className="booking-modal-content">
      <div className="modal-header">
        <h2>{editMode ? t('Edit Mechanic Booking') : t('New Mechanic Booking')}</h2>
        <button onClick={onClose} className="close-button">&times;</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group mechanic-selection">
        {mechanicSelection ? (
          <div>
            <p>{t('Selected Mechanic:')} <strong>{mechanicSelection.label || mechanicSelection.name}</strong></p>
            <button className='btn' type="button" onClick={() => setMechanicSelection(null)}>
              {t('Change Mechanic')}
            </button>
          </div>
        ) : (
          <div>
            <p>{t('Select a Mechanic')}</p>
            <Select
              options={mechanicsList}
              onChange={(option) => setMechanicSelection(option)}
              placeholder={t('Select Mechanic')}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              }}
            />
          </div>
        )}
        </div>

        <div className="form-group ticket-option">
          <label>{t('Ticket Options')}</label>
          {ticketOption === 'existing' && selectedTicket ? (
            <div className="ticket-associated">
              <span>
                {t('Ticket')} #{selectedTicket.value}
              </span>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  // Remove a associação, permitindo nova associação
                  setTicketOption('new');
                  setSelectedTicket(null);
                }}
              >
                {t('Remove association')}
              </button>
            </div>
          ) : (
            <div className="btn-group">
              <button 
              type="button" 
              className={`btn btn-secondary ${ticketOption === 'new' ? 'active' : ''}`}
              onClick={() => {
                  setTicketOption('new');
                  // Se desejar, você pode limpar o ticket selecionado
                  setSelectedTicket(null);
              }}
              >
              {t('Create New Ticket')}
              </button>
              <button 
              type="button" 
              className={`btn btn-secondary ${ticketOption === 'existing' ? 'active' : ''}`}
              onClick={() => {
                  setTicketOption('existing');
                  setTicketListModalIsOpen(true);
              }}
              >
              {t('Associate Existing Ticket')}
              </button>
            </div>
          )}
        </div>
        <br/>

        <div className="form-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={useExistingClient}
              onChange={(e) => {
                setUseExistingClient(e.target.checked);
                if (!e.target.checked) {
                  // Resetar estados relacionados ao cliente existente
                  setSelectedClient(null);
                  setExistingVehicles([]);
                  setUseExistingVehicle(false);
                }
              }}
            />
            {t('Use Existing Client')}
          </label>
        </div>
        
        {/* Seção de Nome e Telefone lado a lado */}
        <div className="form-row">
          {/* Nome */}
          <div className="form-group">
            <label htmlFor="name">{t('Client Name')}</label>
            {useExistingClient ? (
              <Select
                styles={customSelectStyles}
                options={existingClients}
                value={selectedClient}
                onChange={handleClientChange}
                placeholder={t('select_client')}
              />
            ) : (
              <input
                type="text"
                id="name"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder={t('Enter client name')}
                required={!useExistingClient}
              />
            )}
          </div>
          {/* Telefone */}
          <div className="form-group">
            <label htmlFor="phone">{t('Client Phone')}</label>
            {useExistingClient ? (
                selectedClient ? (
                <div className="phone-display">
                    <span>{selectedClient.phone_country}</span> <span>{selectedClient.phone}</span>
                </div>
                ) : (
                <div className="phone-display">
                    {t('please_select_client_phone')}
                </div>
                )
            ) : (
                <div className="phone-input">
                <Select
                    styles={customSelectStyles}
                    options={countryOptions}
                    value={countryOptions.find(option => option.dialCode === clientPhoneCountry)}
                    onChange={(selectedOption) => setClientPhoneCountry(selectedOption.value)}
                    placeholder={t('Country Code')}
                    className="country-code-select"
                    isDisabled={useExistingClient}
                />
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  value={clientPhone}
                  onChange={(e) => {
                    // Remove caracteres não numéricos
                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                    setClientPhone(numericValue);

                    // Verifica se o número já existe na lista de clientes
                    const existingClient = existingClients.find(
                      (client) => client.phone === numericValue && numericValue !== ''
                    );

                    if (existingClient) {
                      toast.warn(t('This phone number already exists. Please select the client.'));
                    }
                  }}
                  onKeyDown={(e) => {
                    // Impede a digitação de espaços e caracteres não numéricos
                    if (e.key === ' ' || isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Delete') {
                      e.preventDefault();
                    }
                  }}
                  placeholder={t('Enter client phone')}
                  required={!useExistingClient}
                />
                </div>
            )}
          </div>
        </div>

        {/* Checkbox para usar veículo existente (aparece apenas se Use Existing Client está selecionado) */}
        {useExistingClient && (
          <div className="form-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={useExistingVehicle}
                onChange={(e) => {
                  setUseExistingVehicle(e.target.checked);
                  if (!e.target.checked) {
                    setSelectedVehicle(null);
                    // Opcional: resetar campos do veículo manual
                    setCarBrand('');
                    setCarModel('');
                    setCarYear('');
                    setCarPlate('');
                    setCarMileage('');
                  }
                }}
              />
              {t('Use Existing Vehicle')}
            </label>
          </div>
        )}

        {/* Seção de Veículo */}
        <div className="form-row">
          {/* Marca do Carro */}
          <div className="form-group">
            <label htmlFor="car-brand">{t('Car Brand')}</label>
            {useExistingVehicle ? (
              <Select
                styles={customSelectStyles}
                options={existingVehicles}
                value={selectedVehicle}
                onChange={handleVehicleChange}
                placeholder={t('Select a Car')}
              />
            ) : (
              <CreatableSelect
                styles={customSelectStyles}
                options={initialCarBrands}
                value={initialCarBrands.find(brand => brand.value === carBrand) || null}
                onChange={(selectedOption) => setCarBrand(selectedOption ? selectedOption.value : '')}
                placeholder={t('Select or enter a car brand')}
                isClearable
              />
            )}
          </div>
          {/* Modelo do Carro */}
          <div className="form-group">
            <label htmlFor="car-model">{t('Car Model')}</label>
            {useExistingVehicle ? (
              <input
                type="text"
                id="car-model"
                value={useExistingVehicle ? (selectedVehicle ? selectedVehicle.label.split(' ')[1] : '') : carModel}
                disabled
              />
            ) : (
              <CreatableSelect
                styles={customSelectStyles}
                options={
                  carBrand
                    ? initialCarBrands.find((brand) => brand.value === carBrand)?.models.map((model) => ({
                        value: model,
                        label: model,
                      })) || []
                    : []
                }
                value={carModel ? { value: carModel, label: carModel } : null}
                onChange={(selectedOption) => setCarModel(selectedOption ? selectedOption.value : '')}
                placeholder={t('Select or enter a car model')}
                isClearable
                isDisabled={!carBrand}
              />
            )}
          </div>
        </div>

        <div className="form-row">
          {/* Input para Car Year */}
        <div className="form-group">
          <label htmlFor="car-year">{t('Car Year')}</label>
          {useExistingVehicle && selectedVehicle ? (
            // Se estiver usando veículo existente e houver um veículo selecionado, exibe o valor preenchido e desabilitado
            <input
              type="text"
              id="car-year"
              value={selectedVehicle.car_year || carYear}
              disabled
              className="form-control"
            />
          ) : (
            // Caso contrário, permite digitar o ano do carro
            <input
              type="text"
              id="car-year"
              value={carYear}
              onChange={(e) => setCarYear(e.target.value)}
              placeholder={t('Enter car year')}
              required
              className="form-control"
            />
          )}
        </div>

        {/* Input para Car Plate */}
        <div className="form-group">
          <label htmlFor="car-plate">{t('Car Plate')}</label>
          {useExistingVehicle && selectedVehicle ? (
            <input
              type="text"
              id="car-plate"
              value={selectedVehicle.car_plate || carPlate}
              disabled
              className="form-control"
            />
          ) : (
            <input
              type="text"
              id="car-plate"
              value={carPlate}
              onChange={(e) => setCarPlate(e.target.value)}
              placeholder={t('Enter car plate')}
              required
              className="form-control"
            />
          )}
        </div>
        </div>

        {/* Existing booking date and duration fields */}
        <div className="form-row">
          <div className="form-row date-time-container">
            <div className="form-group">
              <label htmlFor="bookingDate">{t('Booking Date')}</label>
              <DatePicker
                selected={bookingDate ? moment(bookingDate).toDate() : null}
                onChange={(date) => setBookingDate(date)}
                dateFormat="dd-MM-YYYY"
                placeholderText={t('Select date')}
                className="custom-datepicker"
                id="bookingDate"
                autoComplete="off"
                locale="it"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="bookingTime">{t('Booking Time')}</label>
              <DatePicker
                selected={bookingTime ? new Date(`1970-01-01T${bookingTime}`) : null}
                onChange={(time) => {
                  const formattedTime = moment(time).format('HH:mm:ss');
                  setBookingTime(formattedTime);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption={t('Time')}
                dateFormat="HH:mm:ss"
                timeFormat="HH:mm:ss"
                placeholderText={t('Select time')}
                className="custom-datepicker time-picker"
                id="bookingTime"
                required
              />
            </div>
          </div>
          <div className="form-group">
              <label htmlFor="duration">{t('Duration (minutes)')}</label>
              <select
              id="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
              >
                  <option value="">{t('Select duration')}</option>
                  <option value="30">{t('30 minutes')}</option>
                  <option value="60">{t('1 hour')}</option>
                  <option value="90">{t('1.5 hours')}</option>
                  <option value="120">{t('2 hours')}</option>
                  <option value="150">{t('2.5 hours')}</option>
                  <option value="180">{t('3 hours')}</option>
                  <option value="210">{t('3.5 hours')}</option>
                  <option value="240">{t('4 hours')}</option>
                  <option value="270">{t('4.5 hours')}</option>
                  <option value="300">{t('5 hours')}</option>
                  <option value="330">{t('5.5 hours')}</option>
                  <option value="360">{t('6 hours')}</option>
                  <option value="390">{t('6.5 hours')}</option>
                  <option value="420">{t('7 hours')}</option>
                  <option value="450">{t('7.5 hours')}</option>
                  <option value="480">{t('8 hours')}</option>
              </select>
          </div>
        </div>

        {!editMode && (
          <>
            {/* Additional Days Section */}
            <div className="form-row">
              <button type="button" onClick={addAdditionalDay} className="btn btn-secondary">
                <FaPlus /> {t('Add Day')}
              </button>
              <p>{t('Additional Days (optional)')}</p>
            </div>
            <div className="form-group">
              {additionalDays.map((day, index) => (
                <div key={index} className="additional-day">
                  <div className="form-row date-time-container">
                    {/* Campo para a data */}
                    <div className="form-group">
                      <label htmlFor={`additionalBookingDate-${index}`}>
                        {t('Booking Date for Day')} {index + 2}
                      </label>
                      <DatePicker
                        selected={day.date}
                        onChange={(date) => handleAdditionalDayDateChange(index, date)}
                        dateFormat="yyyy-MM-dd"
                        placeholderText={t('Select date')}
                        className="custom-datepicker date-picker"
                        id={`additionalBookingDate-${index}`}
                        autoComplete="off"
                        required
                        locale="it"
                      />
                    </div>
                    {/* Campo para o horário */}
                    <div className="form-group">
                      <label htmlFor={`additionalBookingTime-${index}`}>
                        {t('Booking Time for Day')} {index + 2}
                      </label>
                      <DatePicker
                        selected={day.start_time ? new Date(`1970-01-01T${day.start_time}Z`) : null}
                        onChange={(time) => handleAdditionalDayTimeChange(index, time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption={t('Time')}
                        dateFormat="HH:mm:ss"
                        placeholderText={t('Select time')}
                        className="custom-datepicker time-picker"
                        id={`additionalBookingTime-${index}`}
                        required
                        locale="it"
                      />
                    </div>
                    {/* Campo para a duração */}
                    <div className="form-group">
                      <label htmlFor={`additionalDuration-${index}`}>
                        {t('Duration (minutes) for Day')} {index + 2}
                      </label>
                      <select
                        id={`additionalDuration-${index}`}
                        value={day.duration}
                        onChange={(e) => handleAdditionalDayDurationChange(index, e.target.value)}
                        required
                      >
                        <option value="">{t('Select duration')}</option>
                        <option value="30">{t('30 minutes')}</option>
                        <option value="60">{t('1 hour')}</option>
                        <option value="90">{t('1.5 hours')}</option>
                        <option value="120">{t('2 hours')}</option>
                        <option value="150">{t('2.5 hours')}</option>
                        <option value="180">{t('3 hours')}</option>
                        <option value="210">{t('3.5 hours')}</option>
                        <option value="240">{t('4 hours')}</option>
                        <option value="270">{t('4.5 hours')}</option>
                        <option value="300">{t('5 hours')}</option>
                        <option value="330">{t('5.5 hours')}</option>
                        <option value="360">{t('6 hours')}</option>
                        <option value="390">{t('6.5 hours')}</option>
                        <option value="420">{t('7 hours')}</option>
                        <option value="450">{t('7.5 hours')}</option>
                        <option value="480">{t('8 hours')}</option>
                      </select>
                    </div>
                    <button
                      type="button"
                      onClick={() => removeAdditionalDay(index)}
                      className="btn btn-danger"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="description">{t('Description')}</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('Enter booking description')}
            rows="4"
          ></textarea>
        </div>
        <br/>

        <div className="form-group">
          <button type="submit" className="btn btn-success">
            {editMode ? t('Update Booking') : t('Save Booking')}
          </button>
        </div>
      </form>

      <Modal
        isOpen={ticketListModalIsOpen}
        onRequestClose={() => setTicketListModalIsOpen(false)}
        contentLabel="Select Ticket Modal"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          overlay: {
            zIndex: 9999, // Valor maior que o do outro modal
          },
        }}
      >
        <div className="ticket-modal-header">
          <h3>{t('Select a Ticket')}</h3>
          <button onClick={() => setTicketListModalIsOpen(false)} className="close-button">&times;</button>
        </div>
        <div className="ticket-modal-body">
          {existingTickets && existingTickets.length > 0 ? (
            <ul className="ticket-list">
              {existingTickets.map(ticket => (
                <li key={ticket.value} className="ticket-item">
                  <button 
                    type="button" 
                    className="btn btn-outline-primary btn-block"
                    onClick={() => {
                      setSelectedTicket(ticket);
                      handleTicketSelect(ticket);
                      setTicketListModalIsOpen(false);
                    }}
                  >
                    {ticket.label}
                    {/* Se desejar, adicione detalhes extras abaixo, por exemplo: */}
                    <div className="ticket-detail">
                      <span>{ticket.clientName}</span>
                      <span>{ticket.carBrand} {ticket.carModel}</span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('No tickets available')}</p>
          )}
        </div>
        <div className="ticket-modal-footer">
          <button className="btn btn-secondary" onClick={() => setTicketListModalIsOpen(false)}>
            {t('Close')}
          </button>
        </div>
      </Modal>
    </div>
  );
}

BookingMechanicModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSaveBooking: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  onUpdateBooking: PropTypes.func,
  bookingToEdit: PropTypes.object,
  mechanicSelection: PropTypes.object,
  ticketOption: PropTypes.object,
  selectedTicket: PropTypes.object,
};

BookingMechanicModalContent.defaultProps = {
  editMode: false,
  bookingToEdit: null,
};

export default BookingMechanicModalContent;