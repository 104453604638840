import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './Pagination.css'; // Assegure-se de que este caminho está correto

function Pagination({ currentPage, totalPages, onPageChange, maxPageButtons = 5 }) {
  const { t } = useTranslation();

  const getPageNumbers = () => {
    const pages = [];

    if (totalPages <= maxPageButtons) {
      // Se o número total de páginas for menor ou igual ao máximo de botões, mostre todas
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Caso contrário, determine quais páginas mostrar
      const half = Math.floor(maxPageButtons / 2);
      let start = currentPage - half;
      let end = currentPage + half;

      if (maxPageButtons % 2 === 0) {
        end -= 1;
      }

      if (start <= 1) {
        start = 1;
        end = maxPageButtons;
      }

      if (end >= totalPages) {
        end = totalPages;
        start = totalPages - maxPageButtons + 1;
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      // Adicionar elipses se necessário
      if (start > 2) {
        pages.unshift('...');
        pages.unshift(1);
      } else if (start === 2) {
        pages.unshift(1);
      }

      if (end < totalPages - 1) {
        pages.push('...');
        pages.push(totalPages);
      } else if (end === totalPages - 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="pagination d-flex justify-content-between align-items-center mt-3">
      {/* Botão de Página Anterior */}
      <button
        className="btn btn-sm btn-primary"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        {t('previous')}
      </button>

      {/* Botões das Páginas */}
      <div>
        {pageNumbers.map((number, index) => (
          number === '...' ? (
            <span key={`ellipsis-${index}`} className="mx-1">...</span>
          ) : (
            <button
              key={number}
              className={`btn btn-sm pagination-btn ${currentPage === number ? 'active' : ''}`}
              onClick={() => onPageChange(number)}
            >
              {number}
            </button>
          )
        ))}
      </div>

      {/* Botão de Próxima Página */}
      <button
        className="btn btn-sm btn-primary"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        {t('next')}
      </button>
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired, // Página atual
  totalPages: PropTypes.number.isRequired,  // Número total de páginas
  onPageChange: PropTypes.func.isRequired,  // Função para alterar a página
  maxPageButtons: PropTypes.number,         // Máximo de botões de página a exibir
};

export default Pagination;