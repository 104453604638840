import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './CarDetails.css';

function CarDetails({ car, onBack }) {
  const { t } = useTranslation();
  const [client, setClient] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchDetails = async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('Token ausente');
      }

      // 1. Obter informações do cliente
      const clientResponse = await axios.get(`${API_BASE_URL}/api/clients/${car.client_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClient(clientResponse.data);

      // 2. Obter lista de tickets do carro
      const ticketsResponse = await axios.get(`${API_BASE_URL}/api/cars/${car.id}/tickets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTickets(ticketsResponse.data);

      setLoading(false);
    } catch (err) {
      console.error('Erro ao buscar detalhes do carro:', err);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [car]);

  useEffect(() => {
    console.log('Dados do carro recebidos no CarDetails.js:', car);
  }, [car]);

  if (loading) {
    return <div className="car-details"><p>{t('loading')}...</p></div>;
  }

  if (error) {
    return <div className="car-details"><p>{t('errorLoadingDetails')}</p></div>;
  }

  // Mapeamento dos status
  const mapStatus = (statusLabels) => {
    switch (statusLabels) {
      case 1:
        return { translation: t("Not Assigned"), className: "not-assigned" };
      case 2:
        return { translation: t("Pending"), className: "pending" };
      case 3:
        return { translation: t("Working"), className: "working" };
      case 4:
        return { translation: t("Finished"), className: "finished" };
      default:
        return { translation: t("Unknown"), className: "unknown" };
    }
  };

  return (
    <div className="car-details">
      <button className="back-button" onClick={onBack}>
        <FaArrowLeft /> {t('back')}
      </button>

      <h2>{t('carDetails')}</h2>

      <section className="client-info">
        <h3>{t('clientInfo')}</h3>
        <p><strong>{t('name')}:</strong> {client.name}</p>
        <p><strong>{t('phone')}:</strong> {client.phone}</p>
        {/* Adicione outras informações do cliente conforme necessário */}
      </section>

      <section className="vehicle-info">
        <h3>{t('vehicleInfo')}</h3>
        <p><strong>{t('Brand')}:</strong> {car.car_brand}</p>
        <p><strong>{t('Model')}:</strong> {car.car_model}</p>
        <p><strong>{t('Year')}:</strong> {car.car_year}</p>
        <p><strong>{t('Plate')}:</strong> {car.car_plate}</p>
        <p><strong>{t('Mileage')}:</strong> {car.last_mileage} km</p>
      </section>

      <section className="tickets-info">
        <h3>{t('tickets')}</h3>
        {tickets.length > 0 ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{t('ticketNumber')}</th>
                <th>{t('issueDescription')}</th>
                <th>{t('status')}</th>
                <th>{t('createdAt')}</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.id}</td>
                  <td>{ticket.issue_description}</td>
                  <td>
                    <div className={`mb-0 ticket-status ${mapStatus(ticket.status).className}`}>
                      <i className="bx bxs-circle me-1"></i>
                      {mapStatus(ticket.status).translation}
                    </div>
                  </td>
                  <td>{new Date(ticket.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t('noTicketsFound')}</p>
        )}
      </section>
    </div>
  );
}

export default CarDetails;