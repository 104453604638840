// src/pages/Database.js
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import API_BASE_URL from '../config';
import initialCarBrands from '../components/CarBrands';
import './Database.css';

const Database = () => {
  const { t } = useTranslation();

  // Estados para a seção de Pesquisa de Veículos
  const [searchSelectedBrand, setSearchSelectedBrand] = useState(null);
  const [searchCarModels, setSearchCarModels] = useState([]);
  const [searchSelectedModel, setSearchSelectedModel] = useState(null);
  const [ano, setAno] = useState('');
  const [combustivel, setCombustivel] = useState('');
  const [transmissao, setTransmissao] = useState('');
  const [cavalos, setCavalos] = useState('');
  const [cilindradas, setCilindradas] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  // Estado para exibir detalhes do problema selecionado (modo full page)
  const [selectedProblem, setSelectedProblem] = useState(null);

  // Handlers para a pesquisa (marca e modelo)
  const handleSearchBrandChange = (newValue) => {
    setSearchSelectedBrand(newValue);
    const brandValue = newValue ? newValue.value : '';
    const brand = initialCarBrands.find(b => b.value === brandValue);
    if (brand && brand.models) {
      const modelsOptions = brand.models.map(model => ({ value: model, label: model }));
      setSearchCarModels(modelsOptions);
    } else {
      setSearchCarModels([]);
    }
    setSearchSelectedModel(null);
  };

  const handleSearchModelChange = (newValue) => {
    setSearchSelectedModel(newValue);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();
    if (searchSelectedBrand && searchSelectedBrand.value) {
      queryParams.append('brand', searchSelectedBrand.value);
    }
    if (searchSelectedModel && searchSelectedModel.value) {
      queryParams.append('model', searchSelectedModel.value);
    }
    if (ano) queryParams.append('year', ano);
    if (combustivel) queryParams.append('fuel', combustivel);
    if (transmissao) queryParams.append('transmission', transmissao);
    if (cavalos) queryParams.append('horsepower', cavalos);
    if (cilindradas) queryParams.append('displacement', cilindradas);

    fetch(`${API_BASE_URL}/api/db_vehicle_problems?${queryParams.toString()}`)
      .then(response => response.json())
      .then(data => {
        setSearchResults(data);
        setHasSearched(true);
        toast.success(t('Search completed successfully.'));
      })
      .catch(error => {
        console.error(error);
        toast.error(t('Error fetching search results.'));
      });
  };

  // Estados para a seção de Cadastro de Problema (com dados do veículo)
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [carModels, setCarModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [carYear, setCarYear] = useState('');
  const [carTransmission, setCarTransmission] = useState('');
  const [carHorsepower, setCarHorsepower] = useState('');
  const [carDisplacement, setCarDisplacement] = useState('');

  const [tipoProblema, setTipoProblema] = useState('');
  const [origem, setOrigem] = useState('');
  const [descricao, setDescricao] = useState('');
  const [midias, setMidias] = useState(null);

  const handleBrandChange = (newValue) => {
    setSelectedBrand(newValue);
    const brandValue = newValue ? newValue.value : '';
    if (brandValue) {
      const brand = initialCarBrands.find(b => b.value === brandValue);
      if (brand && brand.models) {
        const modelsOptions = brand.models.map(model => ({ value: model, label: model }));
        setCarModels(modelsOptions);
      } else {
        setCarModels([]);
      }
    } else {
      setCarModels([]);
    }
    setSelectedModel(null);
  };

  const handleModelChange = (newValue) => {
    setSelectedModel(newValue);
  };

  const handleMediaChange = (e) => {
    setMidias(e.target.files);
  };

  const handleProblemSubmit = (e) => {
    e.preventDefault();

    if (!selectedBrand || !selectedModel || !carYear || !carTransmission || !carHorsepower || !carDisplacement) {
      toast.error(t('Please fill in all vehicle details.'));
      return;
    }
    if (!tipoProblema || !origem || !descricao) {
      toast.error(t('Please fill in all problem details.'));
      return;
    }

    const payload = {
      brand: selectedBrand.value,
      model: selectedModel.value,
      year: carYear,
      transmission: carTransmission,
      horsepower: carHorsepower,
      displacement: carDisplacement,
      problem_type: tipoProblema,
      problem_origin: origem,
      problem_description: descricao,
      problem_media: midias ? Array.from(midias) : null
    };

    fetch(`${API_BASE_URL}/api/db_vehicle_problems`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error registering problem.');
        }
        return response.json();
      })
      .then(data => {
        toast.success(t('Problem registered successfully!'));
        // Limpa todos os campos após o cadastro
        setSelectedBrand(null);
        setCarModels([]);
        setSelectedModel(null);
        setCarYear('');
        setCarTransmission('');
        setCarHorsepower('');
        setCarDisplacement('');
        setTipoProblema('');
        setOrigem('');
        setDescricao('');
        setMidias(null);
      })
      .catch(error => {
        console.error(error);
        toast.error(t('Error registering problem.'));
      });
  };

  // Se um problema estiver selecionado, exibe a visualização full page dos detalhes
  if (selectedProblem) {
    return (
      <div className="container mt-4">
        <h1 className="mb-4">{t('Problem Details')}</h1>
        <div className="card detail-card">
          <div className="card-body">
            <p><strong>{t('Brand')}: </strong>{selectedProblem.brand}</p>
            <p><strong>{t('Model')}: </strong>{selectedProblem.model}</p>
            <p><strong>{t('Year')}: </strong>{selectedProblem.year}</p>
            <p><strong>{t('Transmission')}: </strong>{selectedProblem.transmission}</p>
            <p><strong>{t('Horsepower')}: </strong>{selectedProblem.horsepower}</p>
            <p><strong>{t('Displacement')}: </strong>{selectedProblem.displacement}</p>
            <p><strong>{t('Problem Type')}: </strong>{selectedProblem.problem_type}</p>
            <p><strong>{t('Origin')}: </strong>{selectedProblem.problem_origin}</p>
            <p><strong>{t('Problem Description')}: </strong>{selectedProblem.problem_description}</p>
            <p><strong>{t('Created At')}: </strong>{new Date(selectedProblem.created_at).toLocaleString()}</p>
            <button className="btn btn-secondary" onClick={() => setSelectedProblem(null)}>{t('Back')}</button>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }

  // Visualização padrão (pesquisa, resultados e cadastro)
  return (
    <div className="container mt-4">
      <h1 className="mb-4">{t('Vehicle and Issues Database')}</h1>

      {/* Seção de Pesquisa de Veículos */}
      <div className="card mb-4">
        <div className="card-header">
          <h5>{t('Search Vehicles')}</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSearchSubmit}>
            <div className="row g-3">
              <div className="col-md-4">
                <label className="form-label">{t('Brand')}</label>
                <CreatableSelect
                  options={initialCarBrands}
                  value={searchSelectedBrand}
                  onChange={handleSearchBrandChange}
                  placeholder={t('Select or enter a car brand')}
                  isClearable
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">{t('Model')}</label>
                <CreatableSelect
                  options={searchCarModels}
                  value={searchSelectedModel}
                  onChange={handleSearchModelChange}
                  placeholder={t('Select or enter a car model')}
                  isClearable
                  isDisabled={!searchSelectedBrand}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="ano" className="form-label">{t('Year')}</label>
                <input
                  type="number"
                  className="form-control"
                  id="ano"
                  value={ano}
                  onChange={(e) => setAno(e.target.value)}
                  placeholder={t('e.g., 2020')}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="combustivel" className="form-label">{t('Fuel')}</label>
                <select
                  className="form-select"
                  id="combustivel"
                  value={combustivel}
                  onChange={(e) => setCombustivel(e.target.value)}
                >
                  <option value="">{t('Select')}</option>
                  <option value="gasolina">{t('Gasoline')}</option>
                  <option value="alcool">{t('Alcohol')}</option>
                  <option value="diesel">{t('Diesel')}</option>
                  <option value="flex">{t('Flex')}</option>
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="transmissao" className="form-label">{t('Transmission')}</label>
                <select
                  className="form-select"
                  id="transmissao"
                  value={transmissao}
                  onChange={(e) => setTransmissao(e.target.value)}
                >
                  <option value="">{t('Select')}</option>
                  <option value="manual">{t('Manual')}</option>
                  <option value="automatico">{t('Automatic')}</option>
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="cavalos" className="form-label">{t('Horsepower')}</label>
                <input
                  type="number"
                  className="form-control"
                  id="cavalos"
                  value={cavalos}
                  onChange={(e) => setCavalos(e.target.value)}
                  placeholder={t('e.g., 150')}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="cilindradas" className="form-label">{t('Displacement')}</label>
                <input
                  type="number"
                  className="form-control"
                  id="cilindradas"
                  value={cilindradas}
                  onChange={(e) => setCilindradas(e.target.value)}
                  placeholder={t('e.g., 2000')}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary mt-3">{t('Search')}</button>
          </form>
        </div>
      </div>

      {/* Exibição dos resultados da busca */}
      {hasSearched && searchResults.length === 0 && (
        <div className="card mt-4">
          <div className="card-header">
            <h5>{t('Search Results')}</h5>
          </div>
          <div className="card-body">
            <p>{t('No results found')}</p>
          </div>
        </div>
      )}
      {searchResults.length > 0 && (
        <div className="card mt-4">
          <div className="card-header">
            <h5>{t('Search Results')}</h5>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>{t('Brand')}</th>
                  <th>{t('Model')}</th>
                  <th>{t('Year')}</th>
                  <th>{t('Transmission')}</th>
                  <th>{t('Horsepower')}</th>
                  <th>{t('Displacement')}</th>
                  <th>{t('Problem Type')}</th>
                  <th>{t('Origin')}</th>
                  <th>{t('Problem Description')}</th>
                  <th>{t('Created At')}</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => setSelectedProblem(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>{item.brand}</td>
                    <td>{item.model}</td>
                    <td>{item.year}</td>
                    <td>{item.transmission}</td>
                    <td>{item.horsepower}</td>
                    <td>{item.displacement}</td>
                    <td>{item.problem_type}</td>
                    <td>{item.problem_origin}</td>
                    <td>{item.problem_description}</td>
                    <td>{new Date(item.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Seção de Cadastro de Problema */}
      <div className="card mb-4">
        <div className="card-header">
          <h5>{t('Register Problem')}</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleProblemSubmit} encType="multipart/form-data">
            <h6>{t('Vehicle Details')}</h6>
            <div className="row g-3 mb-3">
              <div className="col-md-6">
                <label className="form-label">{t('Brand')}</label>
                <CreatableSelect
                  options={initialCarBrands}
                  value={selectedBrand}
                  onChange={handleBrandChange}
                  placeholder={t('Select or enter a car brand')}
                  isClearable
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">{t('Model')}</label>
                <CreatableSelect
                  options={carModels}
                  value={selectedModel}
                  onChange={handleModelChange}
                  placeholder={t('Select or enter a car model')}
                  isClearable
                  isDisabled={!selectedBrand}
                />
              </div>
            </div>
            <div className="row g-3 mb-3">
              <div className="col-md-4">
                <label className="form-label">{t('Year')}</label>
                <input
                  type="number"
                  className="form-control"
                  value={carYear}
                  onChange={(e) => setCarYear(e.target.value)}
                  placeholder={t('e.g., 2020')}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">{t('Transmission')}</label>
                <select
                  className="form-select"
                  value={carTransmission}
                  onChange={(e) => setCarTransmission(e.target.value)}
                >
                  <option value="">{t('Select')}</option>
                  <option value="manual">{t('Manual')}</option>
                  <option value="automatico">{t('Automatic')}</option>
                </select>
              </div>
              <div className="col-md-4">
                <label className="form-label">{t('Horsepower')}</label>
                <input
                  type="number"
                  className="form-control"
                  value={carHorsepower}
                  onChange={(e) => setCarHorsepower(e.target.value)}
                  placeholder={t('e.g., 150')}
                />
              </div>
            </div>
            <div className="row g-3 mb-3">
              <div className="col-md-4">
                <label className="form-label">{t('Displacement')}</label>
                <input
                  type="number"
                  className="form-control"
                  value={carDisplacement}
                  onChange={(e) => setCarDisplacement(e.target.value)}
                  placeholder={t('e.g., 2000')}
                />
              </div>
            </div>
            <h6>{t('Problem Details')}</h6>
            <div className="mb-3">
              <label className="form-label">{t('Problem Type')}</label>
              <select
                className="form-select"
                value={tipoProblema}
                onChange={(e) => setTipoProblema(e.target.value)}
              >
                <option value="">{t('Select')}</option>
                <option value="facil">{t('Easy')}</option>
                <option value="medio">{t('Medium')}</option>
                <option value="dificil">{t('Difficult')}</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">{t('Origin')}</label>
              <select
                className="form-select"
                value={origem}
                onChange={(e) => setOrigem(e.target.value)}
              >
                <option value="">{t('Select')}</option>
                <option value="mecanica">{t('Mechanical')}</option>
                <option value="eletrica">{t('Electrical')}</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">{t('Problem Description')}</label>
              <textarea
                className="form-control"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                rows="3"
                placeholder={t('Describe the problem and solution in detail')}
              ></textarea>
            </div>
            <div className="mb-3">
              <label className="form-label">{t('Media (Photos/Videos)')}</label>
              <input
                className="form-control"
                type="file"
                onChange={handleMediaChange}
                multiple
              />
            </div>
            <button type="submit" className="btn btn-success">{t('Register Problem')}</button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Database;