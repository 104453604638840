import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaEye } from 'react-icons/fa';
import withPermission from '../hoc/withPermission';
import './AutoList.css';
import API_BASE_URL from '../config';
import Pagination from '../components/Pagination';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import CarDetails from './CarDetails';
import initialCarBrands from '../components/CarBrands';
import CreatableSelect from 'react-select/creatable';

Modal.setAppElement('#root');

function AutoList({ isVisible }) {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [carToEdit, setCarToEdit] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);
  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [carBrands, setCarBrands] = useState(initialCarBrands);
  const [carModels, setCarModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  useEffect(() => {
    if (carToEdit && carToEdit.car_brand) {
      const brandFound = carBrands.find((b) => b.value === carToEdit.car_brand);
      if (brandFound) {
        setSelectedBrand(brandFound);
        setCarModels(brandFound.models.map((model) => ({ value: model, label: model })));
      }
    }
    if (carToEdit && carToEdit.car_model) {
      const modelFound = carModels.find((m) => m.value === carToEdit.car_model);
      if (modelFound) {
        setSelectedModel(modelFound);
      }
    }
  }, [carToEdit, carBrands, carModels]);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token ausente. Redirecionando...');
          return;
        }
        const response = await axios.get(`${API_BASE_URL}/api/cars`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCars(response.data);
        setFilteredCars(response.data);
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
        toast.error(t('errorFetchingCars'), { position: 'bottom-right' });
      }
    };

    fetchCars();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filtered = cars.filter((car) =>
        Object.values(car).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredCars(filtered);
    } else {
      setFilteredCars(cars);
    }
  }, [searchTerm, cars]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const sortedCars = [...filteredCars].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastCar = currentPage * resultsPerPage;
  const indexOfFirstCar = indexOfLastCar - resultsPerPage;
  const currentCars = sortedCars.slice(indexOfFirstCar, indexOfLastCar);

  const totalPages = Math.ceil(filteredCars.length / resultsPerPage);

  const renderSortIcons = (field) => (
    <span className="sort-icons">
      <span
        className={`arrow ${sortField === field && sortOrder === 'asc' ? 'active' : ''}`}
      >
        ↑
      </span>
      <span
        className={`arrow ${sortField === field && sortOrder === 'desc' ? 'active' : ''}`}
      >
        ↓
      </span>
    </span>
  );

  // Função para abrir a visualização detalhada
  const openCarDetails = (car) => {
    setSelectedCar(car);
  };

  // Função para fechar a visualização detalhada
  const closeCarDetails = () => {
    setSelectedCar(null);
  };

  // Função para abrir a modal de edição
  const openEditModal = (car) => {
    setCarToEdit({
      ...car,
      mileage: car.last_mileage || '',
    });
    setIsEditModalOpen(true);
  };

  // Função para fechar a modal de edição
  const closeEditModal = () => {
    setCarToEdit(null);
    setIsEditModalOpen(false);
  };
  
  // Quando clica no botão "Delete" na modal principal, abrimos o modal de confirmação
  const handleDeleteClick = () => {
    setDeleteConfirmationText('');
    setShowDeleteModal(true);
  };

  // Fecha o modal de confirmação sem deletar
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteConfirmationText('');
  };

  // Confirma o delete (se typed "elimina definitivamente") e faz a requisição
  const handleConfirmDelete = async () => {
    if (deleteConfirmationText.trim() !== 'elimina definitivamente') {
      // Se não for exatamente igual, não faz nada ou mostra um toast de erro
      toast.error(t('deleteConfirmationMismatch'));
      return;
    }

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        toast.error(t('errorNoToken'), { position: 'bottom-right' });
        return;
      }

      // Fazendo DELETE => na verdade, atualizamos "active=0"
      await axios.put(
        `${API_BASE_URL}/api/cars/${carToEdit.id}/deactivate`,
        { active: '0' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Atualizamos a lista de carros
      setCars((prevCars) =>
        prevCars.filter((car) => car.id !== carToEdit.id)
      );
      setFilteredCars((prevCars) =>
        prevCars.filter((car) => car.id !== carToEdit.id)
      );

      toast.success(t('carDeletedSuccessfully'), { position: 'bottom-right' });

      // Fecha os modais
      setShowDeleteModal(false);
      setIsEditModalOpen(false);
      setDeleteConfirmationText('');
    } catch (error) {
      console.error('Erro ao deletar veículo:', error);
      toast.error(t('errorDeletingCar'), { position: 'bottom-right' });
    }
  };

  // Função para lidar com a submissão do formulário de edição
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        toast.error('Token ausente!');
        return;
      }
  
      // 1) Atualiza a placa do carro
      const updatedCar = {
        car_plate: carToEdit.car_plate,
        car_brand: carToEdit.car_brand,
        car_model: carToEdit.car_model,
        car_year: carToEdit.car_year,
      };
  
      const updateCarResponse = await axios.put(
        `${API_BASE_URL}/api/cars/${carToEdit.id}`,
        updatedCar,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // 2) Insere nova quilometragem
      if (carToEdit.mileage && carToEdit.mileage !== '') {
        await axios.post(
          `${API_BASE_URL}/api/car-logs`,
          { car_id: carToEdit.id, mileage: carToEdit.mileage },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      // Atualiza a lista de carros para refletir a atualização em tempo real
      const updatedCarData = updateCarResponse.data;
      setCars((prevCars) =>
        prevCars.map((car) =>
          car.id === updatedCarData.id ? { ...car, ...updatedCarData } : car
        )
      );

      toast.success(t('Veichle updated successfully!'));
      closeEditModal();
    } catch (error) {
      console.error('Erro ao atualizar veículo:', error);
      toast.error(t('Error to update veichle.'));
    }
  };

  // Função para lidar com as mudanças nos campos do formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarToEdit((prevCar) => ({
      ...prevCar,
      [name]: value,
    }));
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo semi-transparente
      zIndex: 2000, // Deve ser maior que o z-index de outros elementos
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      maxHeight: '90vh',
      overflowY: 'auto',
      zIndex: 3000, // Maior que o overlay
    },
  };

  if (selectedCar) {
    return (
      <CarDetails car={selectedCar} onBack={closeCarDetails} />
    );
  }

  return (
    <div className="page-wrapper">
      <ToastContainer />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('vehicles')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('listVehicles')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              type="text"
              className="form-control me-3"
              placeholder={t('searchVehicles')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div class="ms-auto me-3">
              <div class="btn-group">
                <select
                  className="form-select w-auto ms-3"
                  value={resultsPerPage}
                  onChange={(e) => setResultsPerPage(Number(e.target.value))}
                >
                  <option value={10}>10 {t('results')}</option>
                  <option value={20}>20 {t('results')}</option>
                  <option value={30}>30 {t('results')}</option>
                </select>
              </div>
            </div>
            <span className="me-3">
              {t('showingCars')} {currentCars.length} {t('of')} {filteredCars.length} {t('cars')}
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped custom-table">
              <thead className="table-light">
                <tr>
                  {['owner_name', 'owner_phone', 'car_brand', 'car_model', 'car_plate'].map((field) => (
                    <th
                      key={field}
                      onClick={() => handleSort(field)}
                      className="sortable"
                    >
                      {t(field)}
                      {renderSortIcons(field)}
                    </th>
                  ))}
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentCars.length > 0 ? (
                  currentCars.map((car) => (
                    <tr key={car.id}>
                      <td>{car.owner_name ?? 'N/A'}</td>
                      <td>{car.owner_phone ?? 'N/A'}</td>
                      <td>{car.car_brand ?? 'N/A'}</td>
                      <td>{car.car_model ?? 'N/A'}</td>
                      <td>{car.car_plate ?? 'N/A'}</td>
                      <td>
                      <button
                          className="btn btn-sm btn-primary me-2"
                          title={t('view')}
                          onClick={() => openCarDetails(car)}
                        >
                          <FaEye />
                        </button>
                        <button
                          className="btn btn-sm btn-warning me-2"
                          title={t('edit')}
                          onClick={() => openEditModal(car)}
                        >
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      {t('no_vehicles_found')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Componente de Paginação */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            maxPageButtons={5} // Ajuste conforme o layout
          />
        </div>
      </div>

      {/* Modal de Edição */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel={t('editVehicle')}
        style={customStyles}
      >
        <h4>{t('editVehicle')}</h4>
        <form onSubmit={handleEditSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="owner_name">{t('owner_name')}</label>
            <input
              type="text"
              id="owner_name"
              name="owner_name"
              className="form-control"
              value={carToEdit?.owner_name || ''}
              onChange={handleInputChange}
              disabled
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="owner_phone">{t('owner_phone')}</label>
            <input
              type="text"
              id="owner_phone"
              name="owner_phone"
              className="form-control"
              value={carToEdit?.owner_phone || ''}
              onChange={handleInputChange}
              disabled
            />
          </div>
          {/* Campo de Marca */}
          <div className="form-group mb-3">
            <label htmlFor="car_brand">{t('car_brand')}</label>
            <CreatableSelect
              options={carBrands}
              value={selectedBrand}
              onChange={(newValue) => {
                setSelectedBrand(newValue);
                setCarToEdit((prev) => ({
                  ...prev,
                  car_brand: newValue ? newValue.value : '',
                  // Mantém os demais dados já presentes e apenas reseta o modelo
                  car_model: '',
                }));
                if (newValue) {
                  setCarModels(newValue.models.map((model) => ({ value: model, label: model })));
                } else {
                  setCarModels([]);
                }
                setSelectedModel(null);
              }}
              placeholder={t('Select or enter a car brand')}
              isClearable
            />
          </div>

          {/* Campo de Modelo */}
          <div className="form-group mb-3">
            <label htmlFor="car_model">{t('car_model')}</label>
            <CreatableSelect
              options={carModels}
              value={selectedModel}
              onChange={(newValue) => {
                setSelectedModel(newValue);
                setCarToEdit({ ...carToEdit, car_model: newValue ? newValue.value : '' });
              }}
              placeholder={t('Select or enter a car model')}
              isClearable
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="car_year">{t('Year')}</label>
            <input
              type="number"
              id="car_year"
              name="car_year"
              className="form-control"
              value={carToEdit?.car_year || ''}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="car_plate">{t('car_plate')}</label>
            <input
              type="text"
              id="car_plate"
              name="car_plate"
              className="form-control"
              value={carToEdit?.car_plate || ''}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="mileage">{t('mileage')}</label>
            <input
              type="number"
              id="mileage"
              name="mileage"
              className="form-control"
              value={carToEdit?.mileage || ''}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <button type="button" className="btn btn-danger" onClick={handleDeleteClick}>
              {t('Delete')}
            </button>

            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={closeEditModal}
                >
                  {t('cancel')}
                </button>
                <button type="submit" className="btn btn-primary">
                  {t('save')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        onRequestClose={handleCancelDelete}
        contentLabel={t('confirmDelete')}
        style={customStyles}
      >
        <h5>{t('confirmDeleteTitle')}</h5>
        <p>{t('instructions')}</p>
        <input
          type="text"
          className="form-control mb-3"
          value={deleteConfirmationText}
          onChange={(e) => setDeleteConfirmationText(e.target.value)}
          placeholder={t('Type: elimina definitivamente')}
        />

        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary me-2"
            onClick={handleCancelDelete}
          >
            {t('cancel')}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            disabled={deleteConfirmationText.trim() !== 'elimina definitivamente'}
            onClick={handleConfirmDelete}
          >
            {t('confirm')}
          </button>
        </div>
      </Modal>

    </div>
  );
}

export default withPermission(AutoList, ['admin', 'receptionist']);