import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FaEdit, FaToggleOn, FaToggleOff, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './userList.css';
import API_BASE_URL from '../config';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function UserList() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userToToggle, setUserToToggle] = useState(null);

  const openEditModal = (user) => {
    setSelectedUser(user);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setSelectedUser(null);
  };

  const saveChanges = async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token ausente. Redirecionando...');
        return;
      }
      
      // Cria o payload incluindo a cor se o usuário for mechanic
      const payload = {
        name: selectedUser.name,
        email: selectedUser.email,
        role: selectedUser.role,
        isActive: selectedUser.isActive,
        ...(selectedUser.role === 'mechanic' && { color: selectedUser.color }),
      };
  
      const response = await axios.put(
        `${API_BASE_URL}/api/users/${selectedUser.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        const updatedUsers = users.map((user) =>
          user.id === selectedUser.id ? { ...user, ...selectedUser } : user
        );
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
  
        closeEditModal();
  
        toast.success(t('Dados do usuário atualizados com sucesso.'), {
          position: 'bottom-right',
        });
      } else {
        console.error('Erro ao atualizar usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
      toast.error(t('Erro ao atualizar os dados do usuário.'), {
        position: 'bottom-right',
      });
    }
  };

  // Função para alternar o status ativo/inativo do usuário
  const toggleUserStatus = async (userId, currentStatus) => {
    try {
      const token = sessionStorage.getItem('token');
      const newStatus = currentStatus === 'yes' ? 'no' : 'yes';
  
      await axios.put(
        `${API_BASE_URL}/api/users/${userId}/toggle-active`,
        { isActive: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (newStatus === 'no') {
        // Remove o usuário da lista se for desativado
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        setFilteredUsers((prevFilteredUsers) =>
          prevFilteredUsers.filter((user) => user.id !== userId)
        );
        toast.success(t('User successfully deactivated.'));
      } else {
        // Atualiza o status para 'yes' se for ativado
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, isActive: newStatus } : user
          )
        );
        setFilteredUsers((prevFilteredUsers) =>
          prevFilteredUsers.map((user) =>
            user.id === userId ? { ...user, isActive: newStatus } : user
          )
        );
        toast.success(t('User successfully activated.'));
      }
    } catch (error) {
      console.error('Erro ao alternar status do usuário:', error);
      toast.error(t('Error toggling user status.'), {
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = sessionStorage.getItem('token');
  
        // Obter o ID do usuário logado
        const userResponse = await axios.get(`${API_BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const loggedUserId = userResponse.data.id; // ID do usuário logado
  
        // Buscar a lista de usuários
        const response = await axios.get(`${API_BASE_URL}/api/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        // Filtrar para remover o usuário logado e apenas usuários ativos
        const filteredUsers = response.data.filter(
          (user) => user.id !== loggedUserId && user.isActive === 'yes'
        );
  
        setUsers(filteredUsers);
        setFilteredUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        setLoading(false);
      }
    };
  
    fetchUsers();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filtered = users.filter((user) =>
        Object.values(user).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [searchTerm, users]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastUser = currentPage * resultsPerPage;
  const indexOfFirstUser = indexOfLastUser - resultsPerPage;
  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / resultsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const roleOptions = [
    { value: 'admin', label: t('Admin') },
    { value: 'mechanic', label: t('Mechanic') },
    { value: 'receptionist', label: t('Receptionist') },
  ];

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('profile')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('userList')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control me-3"
                placeholder={t('Search users')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select
                className="form-select w-auto"
                value={resultsPerPage}
                onChange={(e) => setResultsPerPage(Number(e.target.value))}
              >
                <option value={10}>10 {t('results')}</option>
                <option value={20}>20 {t('results')}</option>
                <option value={30}>30 {t('results')}</option>
              </select>
            </div>
            <span>
              {t('Showing')} {currentUsers.length} {t('of')} {filteredUsers.length} {t('users')}
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped custom-table">
              <thead className="table-light">
                <tr>
                  {['id', t('name'), t('email'), t('role'), t('actions')].map((field) => (
                    <th
                      key={field}
                      onClick={() => handleSort(field)}
                      className="sortable"
                    >
                      {field.toLowerCase()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentUsers.length > 0 ? (
                  currentUsers.map((user) => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>
                      <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setUserToToggle(user);
                            setIsConfirmModalOpen(true);
                          }}
                          title={t('deactivate')}
                        >
                          <FaTrash />
                        </button>
                        <button
                          className="btn btn-sm btn-warning space me-2"
                          title={t('edit')}
                          onClick={() => openEditModal(user)}
                        >
                          <FaEdit />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      {t('No users found')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === 1}
              onClick={prevPage}
            >
              {t('Previous')}
            </button>
            <div>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  className={`btn btn-sm pagination-btn ${currentPage === page ? 'active' : ''}`}
                  onClick={() => paginate(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <button
              className="btn btn-sm btn-primary"
              disabled={currentPage === totalPages}
              onClick={nextPage}
            >
              {t('Next')}
            </button>
          </div>
        </div>

        {isConfirmModalOpen && userToToggle && (
          <Modal
            isOpen={isConfirmModalOpen}
            onRequestClose={() => {
              setIsConfirmModalOpen(false);
              setUserToToggle(null);
            }}
            contentLabel={t('Confirm Deletion')}
            className="custom-modal"
            overlayClassName="custom-overlay"
          >
            <h2>{t('Confirm Deletion')}</h2>
            <p>{t('Are you sure to delete this account?')}</p>
            <button
              className="btn btn-danger me-2"
              onClick={() => {
                toggleUserStatus(userToToggle.id, userToToggle.isActive);
                setIsConfirmModalOpen(false);
                setUserToToggle(null);
              }}
            >
              {t('Confirm')}
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setIsConfirmModalOpen(false);
                setUserToToggle(null);
              }}
            >
              {t('Cancel')}
            </button>
          </Modal>
        )}

        {editModalOpen && selectedUser && (
          <Modal
            isOpen={editModalOpen}
            onRequestClose={closeEditModal}
            contentLabel={t('editUserDetails')}
            className="custom-modal"
            overlayClassName="custom-overlay"
          >
            <h4>{t('editUserDetails')}</h4>
            <form>
              <div className="mb-3">
                <label className="form-label">{t('name')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={selectedUser.name || ''}
                  onChange={(e) =>
                    setSelectedUser((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('email')}</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={selectedUser.email || ''}
                  onChange={(e) =>
                    setSelectedUser((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('Role')}</label>
                <select
                  className="form-select"
                  name="role"
                  value={selectedUser.role || ''}
                  onChange={(e) =>
                    setSelectedUser((prev) => ({
                      ...prev,
                      role: e.target.value,
                    }))
                  }
                >
                  <option value="" disabled>
                    {t('Select role')}
                  </option>
                  {roleOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              {selectedUser.role === 'mechanic' && (
                <div className="mb-3">
                  <label className="form-label">{t('Mechanic Color')}</label>
                  <input
                    type="color"
                    className="form-control"
                    name="color"
                    value={selectedUser.color || '#000000'}
                    onChange={(e) =>
                      setSelectedUser((prev) => ({
                        ...prev,
                        color: e.target.value,
                      }))
                    }
                  />
                </div>
              )}
              <br />
              <button
                type="button"
                className="btn btn-success"
                onClick={saveChanges}
              >
                {t('saveChanges')}
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={closeEditModal}
              >
                {t('cancel')}
              </button>
            </form>
          </Modal>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default UserList;