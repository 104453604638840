// Warehouse.js
import React, { useState, useEffect, useRef } from 'react';
import Barcode from 'react-barcode';
import Modal from 'react-modal';
import { useReactToPrint } from 'react-to-print';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import API_BASE_URL from '../config';
import 'react-toastify/dist/ReactToastify.css';
import './Warehouse.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faEdit, faPrint, faTrash, faClock } from '@fortawesome/free-solid-svg-icons';
import ProductSearchBar from '../components/ProductSearchBar';
import moment from 'moment';

// Configuração do Modal
Modal.setAppElement('#root');

/* 
  Componente que renderiza efetivamente o barcode 
  (usado dentro do PrintArea quando product não é null).
*/
const BarcodePrintComponent = ({ product, t }) => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
        {product.name}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Barcode
          value={product.barcode}
          format="CODE128"
          width={2}
          height={50}
          displayValue={true}  // Já exibe o código abaixo do barcode
        />
      </div>
    </div>
  );
};

/* 
  PrintArea: sempre renderizado no DOM, 
  mas posicionado fora da tela via CSS .print-area 
*/
const PrintArea = React.forwardRef(({ product, t }, ref) => {
  return (
    <div ref={ref} className="print-area">
      {product ? (
        <BarcodePrintComponent product={product} t={t} />
      ) : (
        <div />
      )}
    </div>
  );
});

function Warehouse() {
  const { t } = useTranslation();

  // Estados já existentes
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isSubcategoryModalOpen, setIsSubcategoryModalOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newSubcategoryName, setNewSubcategoryName] = useState('');
  const [newProduct, setNewProduct] = useState({
    name: '',
    cost_price: '',
    sale_price: '',
    stock_quantity: '',
  });
  const [productToEdit, setProductToEdit] = useState(null);
  const [deleteType, setDeleteType] = useState(''); 
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmationInput, setDeleteConfirmationInput] = useState('');
  const [selectedProductForPrint, setSelectedProductForPrint] = useState(null);
  const printRef = useRef();
  

  // *** NOVOS ESTADOS PARA LEITOR DE BARCODE E HISTÓRICO ***
  const [barcodeInput, setBarcodeInput] = useState('');
  const debounceTimer = useRef(null);
  // Modal para exibir a busca do histórico
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  // O date picker para o histórico usará um objeto Date
  const [historyDate, setHistoryDate] = useState(null);
  const [usageHistory, setUsageHistory] = useState([]);

  // Buscas iniciais
  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
    fetchProducts();
  }, []);

  const fetchCategories = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/categories`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Error fetching categories.');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const fetchSubcategories = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/subcategories`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Error fetching subcategories.');
      const data = await response.json();
      setSubcategories(data);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const fetchProducts = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/products`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Error fetching products.');
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  // Atualize a função para salvar histórico:
  const saveCartHistory = async (product, quantity, actionType) => {
    try {
      const token = sessionStorage.getItem('token');
      const payload = {
        product_id: product.id,
        quantity_used: quantity,
        action_type: actionType, // 'add' ou 'remove'
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss')  // Formato compatível com MySQL
      };
      await fetch(`${API_BASE_URL}/api/cart-history`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload)
      });
    } catch (error) {
      console.error('Error saving cart history:', error);
    }
  };

  // *** FUNÇÕES PARA CRIAÇÃO/EDIÇÃO/DELEÇÃO (já existentes) ***
  const handleCreateCategory = async (e) => {
    e.preventDefault();
    if (!newCategoryName.trim()) {
      toast.error(t('Please enter a category name'));
      return;
    }
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newCategoryName }),
      });
      if (response.ok) {
        toast.success(t('Category created successfully'));
        setNewCategoryName('');
        setIsCategoryModalOpen(false);
        fetchCategories();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error creating category');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleCreateSubcategory = async (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      toast.error(t('Please select a category first'));
      return;
    }
    if (!newSubcategoryName.trim()) {
      toast.error(t('Please enter a subcategory name'));
      return;
    }
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/subcategories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ category_id: selectedCategory.id, name: newSubcategoryName }),
      });
      if (response.ok) {
        toast.success(t('Subcategory created successfully'));
        setNewSubcategoryName('');
        setIsSubcategoryModalOpen(false);
        fetchSubcategories();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error creating subcategory');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const generateBarcode = () => {
    return Math.floor(10000000 + Math.random() * 90000000).toString(); // Generates 8-digit numeric barcode
  };

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    if (!newProduct.name.trim() || !newProduct.cost_price || !newProduct.sale_price || !newProduct.stock_quantity) {
      toast.error(t('Please fill all product fields'));
      return;
    }

    let categoryId = null;
    let subcategoryId = null;
    if (selectedSubcategory) {
      categoryId = selectedCategory.id;
      subcategoryId = selectedSubcategory.id;
    } else if (selectedCategory) {
      categoryId = selectedCategory.id;
    } else {
      toast.error(t('Please select a category or subcategory'));
      return;
    }

    const barcode = generateBarcode();

    try {
      const token = sessionStorage.getItem('token');
      const payload = {
        category_id: categoryId,
        subcategory_id: subcategoryId,
        name: newProduct.name,
        cost_price: newProduct.cost_price,
        sale_price: newProduct.sale_price,
        stock_quantity: newProduct.stock_quantity,
        barcode,
      };
      const response = await fetch(`${API_BASE_URL}/api/products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const product = await response.json();
        toast.success(t('Product added successfully'));
        setNewProduct({ name: '', cost_price: '', sale_price: '', stock_quantity: '' });
        setIsProductModalOpen(false);
        fetchProducts();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error adding product');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleEditProduct = async (e) => {
    e.preventDefault();
    if (!productToEdit.name.trim() || !productToEdit.cost_price || !productToEdit.sale_price || !productToEdit.stock_quantity) {
      toast.error(t('Please fill all product fields'));
      return;
    }
    try {
      const token = sessionStorage.getItem('token');
      const payload = {
        name: productToEdit.name,
        cost_price: productToEdit.cost_price,
        sale_price: productToEdit.sale_price,
        stock_quantity: productToEdit.stock_quantity,
      };
      const response = await fetch(`${API_BASE_URL}/api/products/${productToEdit.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const updatedProduct = await response.json();
        toast.success(t('Product updated successfully'));
        setProducts(products.map(prod => prod.id === updatedProduct.id ? updatedProduct : prod));
        setIsEditProductModalOpen(false);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error updating product');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleUseProduct = async (product) => {
    const quantityStr = window.prompt(t('Enter quantity to use:'));
    const quantityToUse = parseInt(quantityStr, 10);
    if (!quantityToUse || quantityToUse <= 0) {
      toast.error(t('Invalid quantity.'));
      return;
    }
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/products/${product.id}/stock`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ quantityToAdd: -quantityToUse }),
      });
      if (response.ok) {
        setProducts(products.map(prod =>
          prod.id === product.id ? { ...prod, stock_quantity: prod.stock_quantity - quantityToUse } : prod
        ));
        setCart(prevCart => {
          const existingItem = prevCart.find(item => item.product.id === product.id);
          if (existingItem) {
            return prevCart.map(item =>
              item.product.id === product.id ? { ...item, quantity: item.quantity + quantityToUse } : item
            );
          } else {
            return [...prevCart, { product, quantity: quantityToUse }];
          }
        });
        toast.success(t('Product added to cart successfully.'));
        setIsCartModalOpen(true);
        // Registra o uso do produto no histórico (você precisará criar a tabela no BD)
        recordCartUsage(product, quantityToUse);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error using product');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const recordCartUsage = async (product, quantity) => {
    try {
      const token = sessionStorage.getItem('token');
      const payload = {
        product_id: product.id,
        quantity_used: quantity,
        timestamp: new Date().toISOString()
      };
      await fetch(`${API_BASE_URL}/api/usage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload)
      });
    } catch (error) {
      console.error('Error recording usage:', error);
    }
  };

  const handleRemoveFromCart = async (item) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/products/${item.product.id}/stock`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ quantityToAdd: item.quantity }),
      });
      if (response.ok) {
        setProducts(products.map(prod =>
          prod.id === item.product.id ? { ...prod, stock_quantity: prod.stock_quantity + item.quantity } : prod
        ));
        setCart(prevCart => prevCart.filter(cartItem => cartItem.product.id !== item.product.id));
        toast.success(t('Product removed from cart and stock updated.'));
        // Salva o histórico com a ação 'remove'
        saveCartHistory(item.product, item.quantity, 'remove');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error removing product from cart');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  // Atualize a função para buscar histórico:
  const fetchUsageHistory = async (dateStr) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/cart-history?date=${dateStr}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Error fetching usage history.');
      const data = await response.json();
      console.log('History data received:', data);
      setUsageHistory(data);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };


  const handleHistorySearch = () => {
    if (!historyDate) {
      toast.error('Please select a date.');
      return;
    }
    const dateStr = moment(historyDate).format('YYYY-MM-DD');
    console.log('Searching history for date:', dateStr);
    fetchUsageHistory(dateStr);
  };

  // Alterna a view de History (fechar o modal de history)
  const closeHistoryModal = () => {
    setIsHistoryModalOpen(false);
    setUsageHistory([]);
    setHistoryDate(null);
  };

  // *** RENDERIZAÇÃO DOS ELEMENTOS ***

  const renderCategories = () => {
    return (
      <div className="category-grid">
        {categories.map((cat) => (
          <div
            key={cat.id}
            className="category-card"
            onClick={() => {
              setSelectedCategory(cat);
              setSelectedSubcategory(null);
            }}
          >
            <h3>{cat.name}</h3>
            <button className="delete-btn" onClick={(e) => { e.stopPropagation(); openDeleteModal('category', cat); }}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderSubcategories = () => {
    const filteredSubcats = subcategories.filter((sub) => sub.category_id === selectedCategory?.id);
    return (
      <div className="subcategory-grid">
        {filteredSubcats.map((sub) => (
          <div
            key={sub.id}
            className="subcategory-card"
            onClick={() => setSelectedSubcategory(sub)}
          >
            <h4>{sub.name}</h4>
            <button className="delete-btn" onClick={(e) => { e.stopPropagation(); openDeleteModal('subcategory', sub); }}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}
      </div>
    );
  };

  // Incluí a busca por nome ou barcode (modificação solicitada)
  const renderProducts = () => {
    let filteredProducts = products.filter((prod) => {
      const query = searchQuery.toLowerCase();
      return (
        prod.name.toLowerCase().includes(query) ||
        (prod.barcode && prod.barcode.toLowerCase().includes(query))
      );
    });
    
    if (selectedSubcategory) {
      filteredProducts = filteredProducts.filter((prod) => prod.subcategory_id === selectedSubcategory.id);
    } else if (selectedCategory) {
      filteredProducts = filteredProducts.filter((prod) => prod.category_id === selectedCategory.id);
    }
    
    return (
      <div className="product-list-grid">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card">
            <h3>{product.name}</h3>
            <p><strong>{t('Cost Price')}:</strong> € {product.cost_price}</p>
            <p><strong>{t('Sale Price')}:</strong> € {product.sale_price}</p>
            <p><strong>{t('Stock')}:</strong> {product.stock_quantity}</p>
            <div className="barcode">
              <Barcode
                value={product.barcode || ''}
                format="CODE128"
                width={2}
                height={50}
                displayValue={true}
              />
            </div>
            <div className="product-buttons">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  setProductToEdit(product);
                  setIsEditProductModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} /> {t('Edit Product')}
              </button>
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={() => handleUseProduct(product)}
              >
                {t('Use Product')}
              </button>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => handlePrintBarcode(product)}
              >
                <FontAwesomeIcon icon={faPrint} /> {t('Print Barcode')}
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => openDeleteModal('product', product)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCartSummary = () => {
    return (
      <div className="cart-summary">
        <h3>{t('Cart Summary')}</h3>
        {cart.length === 0 ? (
          <p className="empty-cart">{t('Cart is empty.')}</p>
        ) : (
          <ul>
            {cart.map((item, index) => (
              <li key={index} style={{ marginBottom: '10px' }}>
                {item.product.name} – {t('Quantity')}: {item.quantity}
                <button className="btn btn-sm btn-danger" style={{ marginLeft: '10px' }} onClick={() => handleRemoveFromCart(item)}>
                  {t('Remove')}
                </button>
              </li>
            ))}
          </ul>
        )}
        {/* Botão History sempre visível */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <button className="btn btn-outline-info" onClick={() => {
            // Chama a rota do backend para buscar o histórico, se necessário
            setIsHistoryModalOpen(true);
          }}>
            <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px' }} />
            {t('History')}
          </button>
        </div>
      </div>
    );
  };

  // Trata a entrada do código de barras; se tiver 6 ou mais dígitos, busca o produto
  const handleBarcodeInputChange = (e) => {
    const code = e.target.value;
    setBarcodeInput(code);
  
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
  
    debounceTimer.current = setTimeout(() => {
      if (code.length >= 6) {
        const foundProduct = products.find(prod => prod.barcode === code);
        if (foundProduct) {
          handleBarcodeUseProduct(foundProduct);
        } else {
          toast.error(t('Product not found with scanned barcode.'));
        }
        setBarcodeInput('');
      }
    }, 400); // tempo em ms, ajuste conforme necessário
  };

  // Usa o produto escaneado: reduz o estoque em 1, adiciona ao carrinho e registra o uso
  const handleBarcodeUseProduct = async (product) => {
    const quantityToUse = 1;
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/products/${product.id}/stock`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ quantityToAdd: -quantityToUse }),
      });
      if (response.ok) {
        setProducts(products.map(prod =>
          prod.id === product.id
            ? { ...prod, stock_quantity: prod.stock_quantity - quantityToUse }
            : prod
        ));
        setCart(prevCart => {
          const existingItem = prevCart.find(item => item.product.id === product.id);
          if (existingItem) {
            return prevCart.map(item =>
              item.product.id === product.id
                ? { ...item, quantity: item.quantity + quantityToUse }
                : item
            );
          } else {
            return [...prevCart, { product, quantity: quantityToUse }];
          }
        });
        toast.success(t('Product added to cart successfully.'));
        setIsCartModalOpen(true);
        // Salva o histórico com a ação 'add'
        saveCartHistory(product, quantityToUse, 'add');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error using product');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleBackToCategories = () => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  };

  const openDeleteModal = (type, item) => {
    setDeleteType(type);
    setItemToDelete(item);
    setDeleteConfirmationInput('');
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmationInput !== 'elimina definitivamente') {
      toast.error(t('Please type "elimina definitivamente" to confirm deletion.'));
      return;
    }
    const token = sessionStorage.getItem('token');
    let url = '';
    if (deleteType === 'category') {
      url = `${API_BASE_URL}/api/categories/${itemToDelete.id}`;
    } else if (deleteType === 'subcategory') {
      url = `${API_BASE_URL}/api/subcategories/${itemToDelete.id}`;
    } else if (deleteType === 'product') {
      url = `${API_BASE_URL}/api/products/${itemToDelete.id}`;
    }
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error deleting item');
      }
      toast.success(t('Item deleted successfully.'));
      setIsDeleteModalOpen(false);
      if (deleteType === 'category') {
        fetchCategories();
      } else if (deleteType === 'subcategory') {
        fetchSubcategories();
      } else if (deleteType === 'product') {
        fetchProducts();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  // Dentro do seu componente Warehouse, adicione o hook:
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  
  const handlePrintBarcode = (product) => {
    setSelectedProductForPrint(product);
    // Aguarda o estado atualizar e o PrintArea ser renderizado
    setTimeout(() => {
      if (printRef.current) {
        const content = printRef.current.innerHTML;
        const printWindow = window.open('', '_blank', 'width=800,height=600');
        printWindow.document.open();
        printWindow.document.write(`
          <html>
            <head>
              <title>Imprimir Barcode</title>
              <style>
                body, html {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .print-area {
                  transform-origin: center;
                  /* Garante que o conteúdo fique centralizado */
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
              </style>
            </head>
            <body onload="window.print(); window.close();">
              <div class="print-area">
                ${content}
              </div>
            </body>
          </html>
        `);
        printWindow.document.close();
      }
    }, 300);
  };

  // Dentro do modal de History, após obter "usageHistory"
  // Agrupa os registros por product_id e soma a quantidade utilizada
  const groupedHistory = usageHistory.reduce((acc, record) => {
    if (!acc[record.product_id]) {
      acc[record.product_id] = { product_id: record.product_id, total_used: 0 };
    }
    acc[record.product_id].total_used += Number(record.quantity_used);
    return acc;
  }, {});

  const historySummary = Object.values(groupedHistory).map(item => {
    // Procura o produto na lista para obter nome e barcode
    const product = products.find(p => p.id === item.product_id);
    return {
      ...item,
      product_name: product ? product.name : 'N/A',
      barcode: product ? product.barcode : 'N/A'
    };
  });

  return (
    <div className="warehouse-container">
      <h1>{t('Warehouse')}</h1>

      {/* Área para input do código de barras (apenas o input) */}
      <div className="barcode-history-container" style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder={t('Scan barcode')}
          value={barcodeInput}
          onChange={handleBarcodeInputChange}
          autoFocus
          style={{ padding: '5px', fontSize: '16px' }}
        />
      </div>

      {/* Barra de pesquisa */}
      <ProductSearchBar onSearch={(query) => setSearchQuery(query)} />

      {/* Botão para adicionar novo produto (se categoria ou subcategoria estiver selecionada) */}
      {(selectedCategory || selectedSubcategory) && (
        <div className="top-product-btn">
          <button className="btn btn-outline-primary" onClick={() => setIsProductModalOpen(true)}>
            {t('Add New Product')}
          </button>
        </div>
      )}

      {/* Botão flutuante do carrinho */}
      <button className="floating-cart-button" onClick={() => setIsCartModalOpen(true)}>
        <FontAwesomeIcon icon={faShoppingCart} />
      </button>

      {/* Exibição de categorias, subcategorias e produtos */}
      {!selectedCategory ? (
        <>
          <h2>{t('Categories')}</h2>
          {renderCategories()}
          <button className="btn btn-outline-primary" onClick={() => setIsCategoryModalOpen(true)}>
            {t('Create New Category')}
          </button>
        </>
      ) : selectedCategory && !selectedSubcategory ? (
        <>
          <button className="btn btn-secondary" onClick={handleBackToCategories}>
            {t('Back to Categories')}
          </button>
          <h2>{t('Subcategories for')} {selectedCategory.name}</h2>
          {renderSubcategories()}
          <button className="btn btn-outline-primary" onClick={() => setIsSubcategoryModalOpen(true)}>
            {t('Create New Subcategory')}
          </button>
          <h2>{t('Products in')} {selectedCategory.name}</h2>
          {renderProducts()}
        </>
      ) : (
        <>
          <button className="btn btn-secondary" onClick={() => setSelectedSubcategory(null)}>
            {t('Back to Category')}
          </button>
          <h2>{t('Products in')} {selectedSubcategory.name}</h2>
          {renderProducts()}
        </>
      )}

      {/* Modal do carrinho */}
      <Modal
        isOpen={isCartModalOpen}
        onRequestClose={() => setIsCartModalOpen(false)}
        contentLabel="Cart Summary Modal"
        className="CartModal"
        overlayClassName="CartOverlay"
      >
        {renderCartSummary()}
        <div className="modal-buttons" style={{ marginTop: '20px' }}>
          <button className="btn btn-primary" onClick={() => setIsCartModalOpen(false)}>
            {t('Close')}
          </button>
        </div>
      </Modal>

      {/* Modal para History (busca com DatePicker) */}
      <Modal
        isOpen={isHistoryModalOpen}
        onRequestClose={closeHistoryModal}
        contentLabel="History Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{t('History')}</h2>
        <div style={{ marginBottom: '10px' }}>
          <DatePicker
            selected={historyDate}
            onChange={(date) => setHistoryDate(date)}
            dateFormat="dd-MM-YYYY"
            placeholderText={t('Select date')}
            className="form-control"
            autoComplete="off"
            locale="it"
          />
        </div>
        <div className="modal-buttons" style={{ marginBottom: '10px' }}>
          <button className="btn btn-primary" onClick={handleHistorySearch}>
            {t('Search')}
          </button>
          <button className="btn btn-secondary" onClick={closeHistoryModal}>
            {t('Close')}
          </button>
        </div>
        {usageHistory.length > 0 ? (
          <div className="history-results">
            <h4>{t('Results for')} {historyDate ? moment(historyDate).format('YYYY-MM-DD') : ''}:</h4>
            {historySummary.length > 0 ? (
              <ul className="history-list">
                {historySummary.map((item, index) => (
                  <li key={index} className="history-item">
                    <div className="history-row">
                      <span className="history-label">{t('Product ID')}:</span>
                      <span className="history-value">{item.product_id}</span>
                    </div>
                    <div className="history-row">
                      <span className="history-label">{t('Product Name')}:</span>
                      <span className="history-value">{item.product_name}</span>
                    </div>
                    <div className="history-row">
                      <span className="history-label">{t('Barcode')}:</span>
                      <span className="history-value">{item.barcode}</span>
                    </div>
                    <div className="history-row">
                      <span className="history-label">{t('Total Used')}:</span>
                      <span className="history-value">{item.total_used}</span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('No usage records found for this date.')}</p>
            )}
          </div>
        ) : (
          <p>{t('No usage records found for this date.')}</p>
        )}
      </Modal>

      {/* Modal para criar nova categoria */}
      <Modal
        isOpen={isCategoryModalOpen}
        onRequestClose={() => setIsCategoryModalOpen(false)}
        contentLabel="Create Category Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{t('Create New Category')}</h2>
        <form onSubmit={handleCreateCategory}>
          <div className="form-group vertical">
            <label htmlFor="newCategory">{t('Category Name')}</label>
            <input
              type="text"
              id="newCategory"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="modal-buttons">
            <button type="submit" className="btn btn-primary">
              {t('Save Category')}
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => setIsCategoryModalOpen(false)}>
              {t('Cancel')}
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal para criar nova subcategoria */}
      <Modal
        isOpen={isSubcategoryModalOpen}
        onRequestClose={() => setIsSubcategoryModalOpen(false)}
        contentLabel="Create Subcategory Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{t('Create New Subcategory')}</h2>
        <form onSubmit={handleCreateSubcategory}>
          <div className="form-group vertical">
            <label htmlFor="newSubcategory">{t('Subcategory Name')}</label>
            <input
              type="text"
              id="newSubcategory"
              value={newSubcategoryName}
              onChange={(e) => setNewSubcategoryName(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="modal-buttons">
            <button type="submit" className="btn btn-primary">
              {t('Save Subcategory')}
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => setIsSubcategoryModalOpen(false)}>
              {t('Cancel')}
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal para adicionar novo produto */}
      <Modal
        isOpen={isProductModalOpen}
        onRequestClose={() => setIsProductModalOpen(false)}
        contentLabel="Add Product Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        {/* No modal de cadastro de produto */}
        <div className="modal-header">
          {selectedCategory && (
            <p>
              {t('Selected Category')}: <strong>{selectedCategory.name}</strong>
              {selectedSubcategory && (
                <>
                  {' '} / <strong>{selectedSubcategory.name}</strong>
                </>
              )}
            </p>
          )}
        </div>
        <h2>{t('Add New Product')}</h2>
        <form onSubmit={handleCreateProduct} className="product-form vertical">
          <div className="form-group vertical">
            <label htmlFor="productName">{t('Product Name')}</label>
            <input
              type="text"
              id="productName"
              value={newProduct.name}
              onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
              required
              className="form-control"
            />
          </div>
          <div className="form-group vertical">
            <label htmlFor="costPrice">{t('Cost Price')}</label>
            <input
              type="number"
              step="0.01"
              id="costPrice"
              value={newProduct.cost_price}
              onChange={(e) => setNewProduct({ ...newProduct, cost_price: parseFloat(e.target.value) })}
              required
              className="form-control"
            />
          </div>
          <div className="form-group vertical">
            <label htmlFor="salePrice">{t('Sale Price')}</label>
            <input
              type="number"
              step="0.01"
              id="salePrice"
              value={newProduct.sale_price}
              onChange={(e) => setNewProduct({ ...newProduct, sale_price: parseFloat(e.target.value) })}
              required
              className="form-control"
            />
          </div>
          <div className="form-group vertical">
            <label htmlFor="stockQuantity">{t('Stock Quantity')}</label>
            <input
              type="number"
              id="stockQuantity"
              value={newProduct.stock_quantity}
              onChange={(e) => setNewProduct({ ...newProduct, stock_quantity: parseInt(e.target.value, 10) })}
              required
              className="form-control"
            />
          </div>
          <div className="modal-buttons">
            <button type="submit" className="btn btn-primary">
              {t('Add Product')}
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => setIsProductModalOpen(false)}>
              {t('Cancel')}
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal para editar produto */}
      <Modal
        isOpen={isEditProductModalOpen}
        onRequestClose={() => setIsEditProductModalOpen(false)}
        contentLabel="Edit Product Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        {productToEdit && (
          <>
            <h2>{t('Edit Product')}</h2>
            <form onSubmit={handleEditProduct} className="product-form vertical">
              <div className="form-group vertical">
                <label htmlFor="editProductName">{t('Product Name')}</label>
                <input
                  type="text"
                  id="editProductName"
                  value={productToEdit.name}
                  onChange={(e) => setProductToEdit({ ...productToEdit, name: e.target.value })}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group vertical">
                <label htmlFor="editCostPrice">{t('Cost Price')}</label>
                <input
                  type="number"
                  step="0.01"
                  id="editCostPrice"
                  value={productToEdit.cost_price}
                  onChange={(e) => setProductToEdit({ ...productToEdit, cost_price: parseFloat(e.target.value) })}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group vertical">
                <label htmlFor="editSalePrice">{t('Sale Price')}</label>
                <input
                  type="number"
                  step="0.01"
                  id="editSalePrice"
                  value={productToEdit.sale_price}
                  onChange={(e) => setProductToEdit({ ...productToEdit, sale_price: parseFloat(e.target.value) })}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group vertical">
                <label htmlFor="editStockQuantity">{t('Stock Quantity')}</label>
                <input
                  type="number"
                  id="editStockQuantity"
                  value={productToEdit.stock_quantity}
                  onChange={(e) => setProductToEdit({ ...productToEdit, stock_quantity: parseInt(e.target.value, 10) })}
                  required
                  className="form-control"
                />
              </div>
              <div className="modal-buttons">
                <button type="submit" className="btn btn-primary">
                  {t('Save Changes')}
                </button>
                <button type="button" className="btn btn-secondary" onClick={() => setIsEditProductModalOpen(false)}>
                  {t('Cancel')}
                </button>
              </div>
            </form>
          </>
        )}
      </Modal>

      {/* Modal de confirmação de deleção */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Delete Confirmation Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{t('Confirm Deletion')}</h2>
        <p>{t('Type "elimina definitivamente" to confirm deletion:')}</p>
        <input
          type="text"
          value={deleteConfirmationInput}
          onChange={(e) => setDeleteConfirmationInput(e.target.value)}
          className="form-control"
        />
        <div className="modal-buttons">
          <button className="btn btn-danger" onClick={handleConfirmDelete}>
            {t('Confirm Delete')}
          </button>
          <button className="btn btn-secondary" onClick={() => setIsDeleteModalOpen(false)}>
            {t('Cancel')}
          </button>
        </div>
      </Modal>

      {/* Conteúdo para impressão (oculto na tela) */}
      <div style={{ display: 'none' }}>
        <PrintArea ref={printRef} product={selectedProductForPrint} t={t} />
      </div>

      <ToastContainer />
    </div>
  );
}

export default Warehouse;