import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import ClientRegister from './pages/ClientRegister';
import ClientList from './pages/ClientList';
import ListAuto from './pages/AutoList';
import AutoRegister from './pages/AutoRegister';
import Terms from './pages/Terms';
import Budget from './pages/Budget';
import ListBudget from './pages/ListBudget';
import EditBudget from './pages/EditBudget';
import WorkBudget from './pages/WorkBudget';
import Profile from './pages/Profile';
import UserList from './pages/UserList';
import UserRegister from './pages/UserRegister';
import Work from './pages/Work';
import Workers from './pages/Workers';
import Warehouse from './pages/Warehouse';
import AuthorizedLocations from './pages/AuthorizedLocations';
import CustomerCare from './pages/CustomerCare';
import Calendar from './pages/Calendar';
import Tickets from './pages/Tickets';
import { useTranslation } from 'react-i18next'
import Database from './pages/Database';

function ProtectedRoute({ children, allowedRoles = [] }) {
  const token = sessionStorage.getItem('token'); // Token armazenado na sessão
  const role = sessionStorage.getItem('role'); // Papel do usuário armazenado

  if (!token) {
    console.warn('Usuário não está logado. Redirecionando para login.');
    return <Navigate to="/login" replace state={{ message: 'Por favor, faça login para acessar esta página.' }} />;
  }

  // Validação de papéis
  if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
    console.warn('Usuário não possui permissão para acessar esta página.');
    return <Navigate to="/unauthorized" replace state={{ message: 'Acesso negado a esta página.' }} />;
  }

  return children;
}

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Recupera o idioma preferido do sessionStorage e altera o idioma
    const preferredLanguage = sessionStorage.getItem('preferred_language');
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage); // Define o idioma ao carregar a aplicação
    }
  }, [i18n]); // Adiciona `i18n` como dependência
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const isLoggedIn = !!sessionStorage.getItem('token'); // Verifica se o usuário está logado

  return (
    <Router>
      <Routes>
        {/* Rota para Login */}
        <Route path="/login" element={<Login />} />

        {/* Rota protegida para Dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Recepcao de Cliente */}
        <Route
          path="/customer-care"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <CustomerCare />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Calendar */}
        <Route
          path="/calendar"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Calendar />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Tickets */}
        <Route
          path="/tickets/:id/"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Tickets />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/tickets"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Tickets />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Registro de Clientes */}
        <Route
          path="/ClientRegister"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <ClientRegister />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Lista de Carros */}
        <Route 
          path="/autoList" 
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <ListAuto />
              </Layout>
            </ProtectedRoute>
            } 
        />

        {/* Rota para Registrar um novo Veiculo */}
        <Route 
          path="/autoRegister" 
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <AutoRegister />
              </Layout>
            </ProtectedRoute>
            } 
        />

        {/* Rota para Lista de Clientes */}
        <Route
          path="/ClientList"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <ClientList />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Lista de Usuários */}
        <Route
          path="/userList"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <UserList />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Registrar Usuário */}
        <Route
          path="/userRegister"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <UserRegister />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Orçamentos */}
        <Route
          path="/budget/new"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Budget />
              </Layout>
            </ProtectedRoute>
          }
        />
        
        {/* Rota para Listagem dos Orçamentos */}
        <Route
          path="/budget/list"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <ListBudget />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-budget/:id"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <EditBudget />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Listagem dos Orçamentos Aceitos */}
        <Route
          path="/budget/work"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <WorkBudget />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para database */}
        <Route
          path="/database"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Database />
              </Layout>
            </ProtectedRoute>
          }
        />
        

        {/* Rota para Warehouse */}
        <Route
          path="/warehouse"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Warehouse />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Lista de Trabalhadores */}
        <Route
          path="/workers"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Workers />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Perfil */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Profile />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Work */}
        <Route
          path="/work"
          element={
            <ProtectedRoute>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Work />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Termos e Condições */}
        <Route path="/terms" element={<Terms />} />

        {/* Rota de Redirecionamento inicial */}
        <Route
          path="/"
          element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} />}
        />

        {/* Rota para Locais Autorizados */}
        <Route
          path="/authorizedlocation"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <AuthorizedLocations />
              </Layout>
            </ProtectedRoute>
          }
        />
        {/* Rota para páginas não encontradas */}
        <Route
          path="*"
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" state={{ message: 'Rota não encontrada, redirecionado para o login.' }} />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;