import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withPermission from '../hoc/withPermission';
import { FaEye, FaEdit, FaTicketAlt, FaTrash } from 'react-icons/fa';
import API_BASE_URL from '../config';
import './WorkBudget.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/images/big_logo.png';
import Pagination from '../components/Pagination';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function WorkBudget() {
  const { t } = useTranslation();
  const [budgets, setBudgets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [pdfUrl, setPdfUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
  const [budgetToCreateTicket, setBudgetToCreateTicket] = useState(null);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState(() => {
    return localStorage.getItem("listBudgetSearchTerm") || "";
  });

  const fetchBudgets = async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error(t('access_denied'));
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/budgets/working`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBudgets(response.data);
    } catch (error) {
      console.error('Erro ao buscar orçamentos:', error);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  const handleSort = (field) => {
    let actualField = field;
    
    // Mapeie os rótulos para os campos reais do objeto, se necessário
    switch (field) {
      case t('Budget ID'):
        actualField = 'budget_id';
        break;
      case t('Client'):
        actualField = 'client_name';
        break;
      case t('Car'):
        actualField = 'car_brand'; // Ou outro campo relevante
        break;
      case t('created_at'):
        actualField = 'created_at';
        break;
      case t('Status'):
        actualField = 'status';
        break;
      default:
        actualField = 'id';
    }
  
    const order = (sortField === actualField && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortField(actualField);
    setSortOrder(order);
  };

  // Função que gera o PDF com jsPDF e exibe na mesma página
  const generatePDF = async (budget) => {
    try {
      const doc = new jsPDF('p', 'mm', 'a4');
      const currentDate = new Date().toLocaleDateString();
      let operatorName = 'N/A';
      let budgetData = null;

      // 1) Buscar os detalhes do orçamento no backend
      const token = sessionStorage.getItem('token');
      const responseBudget = await fetch(`${API_BASE_URL}/api/budgets/${budget.budget_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!responseBudget.ok) {
        throw new Error('Erro ao buscar orçamento');
      }
      budgetData = await responseBudget.json();

      // 2) Buscar dados do operador
      const responseOperator = await fetch(`${API_BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (responseOperator.ok) {
        const userData = await responseOperator.json();
        operatorName = userData.name || 'N/A';
      }

      // =========== Montagem do PDF =========== //

      // 3. Adicionar logo da empresa
      doc.addImage(logo, 'PNG', 10, 15, 50, 30);

      // 4. Adicionar nome da empresa
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.text('3B AUTO DI ELIDIR BUSNELLO E C. SAS', 65, 20);

      // 5. Adicionar informações da empresa
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      const companyInfo = [
        'via Sandro Pertini, 20',
        '46045, MARMIROLO (MN)',
        'P. Iva/ Cod. Fiscale: 02709760207',
        'SDI: WTYVJK9',
        'Tel: 389 026 2392',
        'Email: 3BAuto3B@gmail.com',
      ];
      let companyY = 25;
      companyInfo.forEach((line) => {
        doc.text(65, companyY, line);
        companyY += 5;
      });

      // 7. Dados cliente e veículo
      const headerY = 70;

      // 7.1. Título da seção
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Dati Cliente e Veicolo', 12, headerY + 6);

      // 7.2. Dados do Cliente - Coluna Esquerda
      doc.setFont('helvetica', 'normal');
      doc.text(`Nome Cliente: ${budgetData.client_name || '-'}`, 12, headerY + 12);
      doc.text(`Telefono Cliente: ${budgetData.client_phone || '-'}`, 12, headerY + 18);

      // 7.3. Dados do Operador e Data - Coluna Direita
      doc.text(`Operatore: ${operatorName}`, 140, headerY + 12);
      doc.text(`Data: ${currentDate}`, 140, headerY + 18);

      // 7.4. Dados do Veículo - Coluna Esquerda
      doc.text(
        `Veicolo: ${budgetData.car_brand || '-'} ${budgetData.car_model || '-'}`,
        12,
        headerY + 24
      );

      // 7.5. Dados do Veículo - Coluna Direita
      doc.text(`Targa: ${budgetData.car_plate || '-'}`, 140, headerY + 24);

      // 7.6. **Nova Posição Y para "Chilometraggio"**
      // Ajustamos o Y para evitar sobreposição com "Targa"
      doc.text(`Chilometraggio: ${budgetData.car_mileage || '-'}`, 140, headerY + 30);

      // 8. Linha de separação
      doc.setDrawColor(0, 0, 0); // Define a cor da linha (preto)
      doc.line(10, headerY + 35, 200, headerY + 35);

      // 9. Tabela de itens (usando autoTable)
      const tableStartY = headerY + 40;
      const tableData = budgetData.items.flatMap((item, index) => {
        // Cálculo corrigido do preço por unidade
        const unitPrice = item.costPrice
          ? parseFloat(item.costPrice) + parseFloat(item.costPrice) * (parseFloat(item.profitMargin) / 100)
          : 0;

        const mainRow = [
          index + 1,
          item.service || '-',
          parseFloat(item.quantity || '-').toFixed(2),
          `€${unitPrice.toFixed(2)}`, // Preço por unidade com margem de lucro
          `€${parseFloat(item.finalPrice || 0).toFixed(2)}`,
        ];

        // Se tiver descrição, gera linha extra
        const descriptionRow = item.description
          ? [{ colSpan: 5, content: `- ${item.description.replace(/\n/g, '\n- ')}` }]
          : null;

        return descriptionRow ? [mainRow, descriptionRow] : [mainRow];
      });

      doc.autoTable({
        startY: tableStartY,
        head: [
          [
            '#',
            `${t('Service or Product')}`,
            `${t('Quantity')}`,
            `${t('Unit Price excl. IVA')}`,
            `${t('Final Price')}`,
          ],
        ],
        body: tableData,
        styles: { font: 'helvetica', fontSize: 10 },
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        margin: { left: 10, right: 10 },
      });

      // 10. Totais
      const calculateTotals = (budgetItems) => {
        if (!Array.isArray(budgetItems) || budgetItems.length === 0) {
          return { totalExclIva: '0.00', totalIva: '0.00', totalInclIva: '0.00' };
        }
        const totalExclIva = budgetItems.reduce((sum, item) => {
          const finalPrice = parseFloat(item?.finalPrice) || 0;
          return sum + finalPrice;
        }, 0);
        const totalIva = totalExclIva * 0.22;
        const totalInclIva = totalExclIva + totalIva;
        return {
          totalExclIva: totalExclIva.toFixed(2),
          totalIva: totalIva.toFixed(2),
          totalInclIva: totalInclIva.toFixed(2),
        };
      };

      const summaryStartY = doc.previousAutoTable.finalY + 10;
      const totals = calculateTotals(budgetData.items);

      doc.setFont('helvetica', 'bold');
      doc.text('Riepilogo', 12, summaryStartY);
      doc.setFont('helvetica', 'normal');
      doc.text(`${t('Total excl. IVA')}: €${totals.totalExclIva}`, 12, summaryStartY + 6);
      doc.text(`IVA 22%: €${totals.totalIva}`, 12, summaryStartY + 12);
      doc.text(`${t('Total incl. IVA')}: €${totals.totalInclIva}`, 12, summaryStartY + 18);

      // 11. Rodapé
      const footerY = 280;
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(10);
      doc.text(
        'Il presente preventivo non costituisce documento valido ai fini fiscali. ' +
          'RingraziandoVi per l\'attenzione, rimaniamo in attesa di un gentile riscontro.',
        10,
        footerY,
        { maxWidth: 190 }
      );
      doc.text('Firma per l\'accettazione: ____________________', 10, footerY + 10);

      // Converte para blobURL
      const pdfBlobUrl = doc.output('bloburl');
      setPdfUrl(pdfBlobUrl);
      setShowPdf(true);

    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
      toast.error(t('Error generating PDF'));
    }
  };

  const handleDeleteReady = async () => {
    if (!selectedBudgetId) return;
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/budgets/${selectedBudgetId}/delete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(t('Budget marked as DELETE!'));
        setShowDeleteModal(false);
        setSelectedBudgetId(null);
        fetchBudgets();
      } else {
        toast.error(t('Error updating budget status.'));
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error(t('Error updating budget status.'));
    }
  };
  
  // Função para lidar com a abertura do modal de criação de ticket
  const handleCreateTicket = (budget) => {
    setBudgetToCreateTicket(budget);
    setShowCreateTicketModal(true);
  };

  // Função para confirmar a criação do ticket
  const confirmCreateTicket = () => {
    if (budgetToCreateTicket) {
      createTicket(budgetToCreateTicket.budget_id);
      setShowCreateTicketModal(false);
      setBudgetToCreateTicket(null);
    }
  };

  // Função para cancelar a criação do ticket
  const cancelCreateTicket = () => {
    setShowCreateTicketModal(false);
    setBudgetToCreateTicket(null);
  };

  const createTicket = async (budgetId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(
        `${API_BASE_URL}/api/tickets/from-budget`,
        { budgetId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success(t('Ticket criado com sucesso a partir do orçamento.'));
        // Opcional: Atualizar a lista de orçamentos ou tickets
        fetchBudgets();
      } else {
        toast.error(t('Erro ao criar ticket.'));
      }
    } catch (error) {
      console.error('Erro ao criar ticket:', error);
      const errorMessage = error.response?.data?.error || t('Erro ao criar ticket.');
      toast.error(errorMessage);
    }
  };

  // Filtragem e Ordenação
  const filteredBudgets = budgets.filter((budget) => {
    const query = searchTerm.toLowerCase();
    return (
      budget.client_name.toLowerCase().includes(query) ||
      budget.budget_id.toString().includes(query) ||
      budget.status.toString().toLowerCase().includes(query) ||
      budget.car_brand.toLowerCase().includes(query) ||
      budget.car_model.toLowerCase().includes(query)
    );
  });

  const sortedBudgets = filteredBudgets.sort((a, b) => {
    let fieldA = a[sortField];
    let fieldB = b[sortField];

    // Se o campo for string, compare de forma case-insensitive
    if (typeof fieldA === 'string') fieldA = fieldA.toLowerCase();
    if (typeof fieldB === 'string') fieldB = fieldB.toLowerCase();

    if (fieldA < fieldB) return sortOrder === 'asc' ? -1 : 1;
    if (fieldA > fieldB) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  // Paginacao
  const totalBudgets = sortedBudgets.length;
  const totalPages = Math.ceil(totalBudgets / resultsPerPage);
  const indexOfLastBudget = currentPage * resultsPerPage;
  const indexOfFirstBudget = indexOfLastBudget - resultsPerPage;
  const currentBudgets = sortedBudgets.slice(indexOfFirstBudget, indexOfLastBudget);

  const renderSortIcons = (field) => (
    <span className="sort-icons">
      <span
        className={`arrow ${sortField === field && sortOrder === 'asc' ? 'active' : ''}`}
      >
        ↑
      </span>
      <span
        className={`arrow ${sortField === field && sortOrder === 'desc' ? 'active' : ''}`}
      >
        ↓
      </span>
    </span>
  );

  // Se "showPdf" for true, exibimos apenas o PDF e um botão para fechar
  if (showPdf && pdfUrl) {
    return (
      <div style={{ padding: '20px' }}>
        <button
          className="btn btn-secondary mb-3"
          onClick={() => {
            setShowPdf(false);
            setPdfUrl('');
          }}
        >
          {t('Close')}
        </button>

        <iframe
          src={pdfUrl}
          title="Budget PDF"
          width="100%"
          height="800px"
          frameBorder="0"
        />
      </div>
    );
  }

  return (
    <div className="page-wrapper">
      <ToastContainer />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('budget')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('listBudgets')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-4">
          <h1>{t('listBudgets')}</h1>

          {/* Barra de busca e configurações */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              type="text"
              className="form-control me-3"
              placeholder={t('searchBudgets')}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                localStorage.setItem("listBudgetSearchTerm", e.target.value);
                setCurrentPage(1);
              }}
            />
            <select
              className="form-select w-auto"
              value={resultsPerPage}
              onChange={(e) => setResultsPerPage(Number(e.target.value))}
            >
              <option value={10}>10 {t('results')}</option>
              <option value={20}>20 {t('results')}</option>
              <option value={30}>30 {t('results')}</option>
            </select>
          </div>

          {/* Tabela de orçamentos */}
          <table className="table table-bordered table-striped">
            <thead className="table-light">
                <tr>
                  {[t('Budget ID'), t('Client'), t('Car'), t('created_at')].map((field) => (
                    <th
                      key={field}
                      onClick={() => handleSort(field)}
                      className="sortable"
                    >
                      {t(field)}
                      {renderSortIcons(field)}
                    </th>
                  ))}
                  <th>{t('Ticket Associate')}</th>
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
            <tbody>
              {currentBudgets.length > 0 ? (
                currentBudgets.map((budget) => (
                  <tr key={budget.budget_id}>
                    <td>{budget.budget_id}</td>
                    <td>{budget.client_name}</td>
                    <td>{budget.car_brand} {budget.car_model}</td>
                    <td>{new Date(budget.created_at).toLocaleDateString()}</td>
                    <td>{budget.ticket_id}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary me-2"
                        onClick={() => generatePDF(budget)}
                      >
                        <FaEye />
                      </button>
                      <button
                        className="btn btn-sm btn-warning me-2"
                        onClick={() => navigate(`/edit-budget/${budget.budget_id}`)}
                      >
                        <FaEdit />
                      </button>
                      {!budget.ticket_id && (
                        <button
                          className="btn btn-sm btn-success me-2"
                          onClick={() => handleCreateTicket(budget)}
                        >
                          <FaTicketAlt />
                        </button>
                      )}
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          setSelectedBudgetId(budget.budget_id);
                          setShowDeleteModal(true);
                        }}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    {t('Nenhum orçamento encontrado.')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Componente de Paginação */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            maxPageButtons={5}
          />

          <Modal
            isOpen={showDeleteModal}
            onRequestClose={() => setShowDeleteModal(false)}
            contentLabel={t('Are you sure you want to delete this budget?')}
            style={{
              overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              content: {
                position: 'relative',
                inset: 'unset',
                padding: '20px',
                borderRadius: '8px',
                width: 'auto',
                height: 'auto',
                maxWidth: '90%',
                maxHeight: '90%',
                overflow: 'auto',
              },
            }}
          >
            <h2>{t('Change Budget Status')}</h2>
            <p>{t('Are you sure you want to delete this budget?')}</p>

            <div className="d-flex justify-content-end gap-2 mt-3">
              <button
                className="btn btn-secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                {t('Cancel')}
              </button>
              <button
                className="btn btn-primary"
                onClick={handleDeleteReady}
              >
                {t('Confirm')}
              </button>
            </div>
          </Modal>
          
          <Modal
            isOpen={showCreateTicketModal}
            onRequestClose={cancelCreateTicket}
            contentLabel={t('Confirm Ticket Creation')}
            style={{
              overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              content: {
                position: 'relative',
                inset: 'unset',
                padding: '20px',
                borderRadius: '8px',
                maxWidth: '90%',
                maxHeight: '90%',
                overflow: 'auto',
              },
            }}
          >
            <h2>{t('Confirm Ticket Creation')}</h2>
            <p>{t('Are you sure you want to create a ticket from this budget?')}</p>

            <div className="d-flex justify-content-end gap-2 mt-3">
              <button
                className="btn btn-secondary"
                onClick={cancelCreateTicket}
              >
                {t('Cancel')}
              </button>
              <button
                className="btn btn-primary"
                onClick={confirmCreateTicket}
              >
                {t('Confirm')}
              </button>
            </div>
          </Modal>

        </div>
      </div>
    </div>
  );
}

export default withPermission(WorkBudget, ['admin', 'receptionist']);