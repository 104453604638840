import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FaEye, FaEdit, FaPaperPlane, FaCheckCircle } from 'react-icons/fa';
import withPermission from '../hoc/withPermission';
import './ClientList.css';
import API_BASE_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '../components/Pagination';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import ClientDetails from './ClientDetails';

Modal.setAppElement('#root');

function ClientList({ isVisible }) {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const countryOptions = [
    // Europa
    { value: 'it', label: '🇮🇹 Italia (+39)', dialCode: '+39' },
    { value: 'es', label: '🇪🇸 Spagna (+34)', dialCode: '+34' },
    { value: 'gb', label: '🇬🇧 Regno Unito (+44)', dialCode: '+44' },
    { value: 'fr', label: '🇫🇷 Francia (+33)', dialCode: '+33' },
    { value: 'ie', label: '🇮🇪 Irlanda (+353)', dialCode: '+353' },
    { value: 'pt', label: '🇵🇹 Portogallo (+351)', dialCode: '+351' },
    { value: 'nl', label: '🇳🇱 Paesi Bassi (+31)', dialCode: '+31' },
    { value: 'be', label: '🇧🇪 Belgio (+32)', dialCode: '+32' },
    { value: 'de', label: '🇩🇪 Germania (+49)', dialCode: '+49' },
    { value: 'ch', label: '🇨🇭 Svizzera (+41)', dialCode: '+41' },
    { value: 'se', label: '🇸🇪 Svezia (+46)', dialCode: '+46' },
    { value: 'no', label: '🇳🇴 Norvegia (+47)', dialCode: '+47' },
    { value: 'dk', label: '🇩🇰 Danimarca (+45)', dialCode: '+45' },
    { value: 'pl', label: '🇵🇱 Polonia (+48)', dialCode: '+48' },
    { value: 'at', label: '🇦🇹 Austria (+43)', dialCode: '+43' },
    { value: 'gr', label: '🇬🇷 Grecia (+30)', dialCode: '+30' },
    { value: 'fi', label: '🇫🇮 Finlandia (+358)', dialCode: '+358' },
    { value: 'cz', label: '🇨🇿 Repubblica Ceca (+420)', dialCode: '+420' },
    { value: 'hu', label: '🇭🇺 Ungheria (+36)', dialCode: '+36' },
    { value: 'ro', label: '🇷🇴 Romania (+40)', dialCode: '+40' },
    { value: 'bg', label: '🇧🇬 Bulgaria (+359)', dialCode: '+359' },
    { value: 'sk', label: '🇸🇰 Slovacchia (+421)', dialCode: '+421' },
    
    // África
    { value: 'za', label: '🇿🇦 Sud Africa (+27)', dialCode: '+27' },
    { value: 'eg', label: '🇪🇬 Egitto (+20)', dialCode: '+20' },
    { value: 'ng', label: '🇳🇬 Nigeria (+234)', dialCode: '+234' },
    { value: 'ke', label: '🇰🇪 Kenya (+254)', dialCode: '+254' },
    { value: 'ma', label: '🇲🇦 Marocco (+212)', dialCode: '+212' },
    { value: 'dz', label: '🇩🇿 Algeria (+213)', dialCode: '+213' },
    { value: 'tn', label: '🇹🇳 Tunisia (+216)', dialCode: '+216' },
    { value: 'gh', label: '🇬🇭 Ghana (+233)', dialCode: '+233' },
    { value: 'et', label: '🇪🇹 Etiopia (+251)', dialCode: '+251' },
    { value: 'ug', label: '🇺🇬 Uganda (+256)', dialCode: '+256' },
    { value: 'ci', label: '🇨🇮 Costa d\'Avorio (+225)', dialCode: '+225' },
    { value: 'sn', label: '🇸🇳 Senegal (+221)', dialCode: '+221' },
    { value: 'tz', label: '🇹🇿 Tanzania (+255)', dialCode: '+255' },
    { value: 'cm', label: '🇨🇲 Camerun (+237)', dialCode: '+237' },
    { value: 'zw', label: '🇿🇼 Zimbabwe (+263)', dialCode: '+263' },
    { value: 'ly', label: '🇱🇾 Libia (+218)', dialCode: '+218' },
    { value: 'ml', label: '🇲🇱 Mali (+223)', dialCode: '+223' },
    { value: 'ao', label: '🇦🇴 Angola (+244)', dialCode: '+244' },
    
    // América do Norte
    { value: 'ca', label: '🇨🇦 Canada (+1)', dialCode: '+1' },
    { value: 'mx', label: '🇲🇽 Messico (+52)', dialCode: '+52' },
    { value: 'us', label: '🇺🇸 Stati Uniti (+1)', dialCode: '+1' },
    { value: 'cu', label: '🇨🇺 Cuba (+53)', dialCode: '+53' },
    { value: 'do', label: '🇩🇴 Repubblica Dominicana (+1 809/829/849)', dialCode: '+1' },
    { value: 'jm', label: '🇯🇲 Giamaica (+1 876)', dialCode: '+1' },
    { value: 'bs', label: '🇧🇸 Bahamas (+1 242)', dialCode: '+1' },
    { value: 'tt', label: '🇹🇹 Trinidad e Tobago (+1 868)', dialCode: '+1' },
    { value: 'bb', label: '🇧🇧 Barbados (+1 246)', dialCode: '+1' },
    { value: 'lc', label: '🇱🇨 Santa Lucia (+1 758)', dialCode: '+1' },
    { value: 'gd', label: '🇬🇩 Grenada (+1 473)', dialCode: '+1' },
    { value: 'vc', label: '🇻🇨 Saint Vincent e Grenadine (+1 784)', dialCode: '+1' },
    { value: 'ag', label: '🇦🇬 Antigua e Barbuda (+1 268)', dialCode: '+1' },
    { value: 'dm', label: '🇩🇲 Dominica (+1 767)', dialCode: '+1' },
    { value: 'kn', label: '🇰🇳 Saint Kitts e Nevis (+1 869)', dialCode: '+1' },
    { value: 'bm', label: '🇧🇲 Bermuda (+1 441)', dialCode: '+1' },

    // America do Sul
    { value: 'br', label: '🇧🇷 Brasil (+55)', dialCode: '+55' },
    
  ];

  // Função para abrir detalhes do cliente
  const openClientDetails = (client) => {
    setSelectedClient(client);
  };

  // Função para fechar detalhes do cliente
  const closeClientDetails = () => {
    setSelectedClient(null);
  };

  // Função para abrir o modal de edição
  const openEditModal = (client) => {
    setClientToEdit(client);
    setEditModalOpen(true);
  };

  // Função para fechar o modal de edição
  const closeEditModal = () => {
    setClientToEdit(null);
    setEditModalOpen(false);
  };

  const success_noti = (message) => {
    toast.success(message || t('termsSentSuccessfully'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  const error_noti = (message) => {
    toast.error(message || t('errorSendingTerms'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  const sendTerms = async (client) => {
    console.log('Iniciando o envio dos termos para o cliente:', client);
  
    try {
      const token = sessionStorage.getItem('token'); // Recupera o token JWT
      if (!token) {
        console.error('Token ausente. Redirecionando...');
        error_noti(t('unauthorizedAccess')); // Mensagem de erro amigável
        return;
      }
  
      const endpoint = `${API_BASE_URL}/api/clients/${client.id}/send-terms`;
  
      console.log('Enviando solicitação para o endpoint:', endpoint);
      console.log('Dados do cliente sendo enviados:', {
        contact_preference: client.contact_preference,
        email: client.email,
        phone: `${client.phone_country} ${client.phone}`,
      });
  
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          contact_preference: client.contact_preference,
          email: client.email,
          phone: `${client.phone_country} ${client.phone}`,
        }),
      });
  
      const responseData = await response.json();
      console.log('Resposta JSON da API:', responseData);
  
      if (response.ok) {
        success_noti(t('termsSentSuccessfully'));
  
        // Abrir o link do WhatsApp automaticamente
        if (client.contact_preference === 'whatsapp' && responseData.whatsappLink) {
          window.open(responseData.whatsappLink, '_blank'); // Abre em nova aba
        }
      } else {
        console.error('Erro na resposta da API:', response.status, response.statusText);
        error_noti(responseData.error || t('errorSendingTerms'));
      }
    } catch (error) {
      console.error('Erro ao enviar os termos:', error);
      error_noti(error.message || t('errorSendingTerms'));
    }
  };

  const handleAcceptTerms = async (clientId, currentTermsPolicy) => {
    if (!clientId) return; // Verifica se o ID do cliente foi fornecido
  
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token ausente. Redirecionando...');
        return;
      }
  
      const newTermsPolicy = currentTermsPolicy === 'yes' ? 'no' : 'yes';
  
      const response = await axios.post(
        `${API_BASE_URL}/api/clients/${clientId}/accept-terms`,
        { terms_policy: newTermsPolicy }, // Enviando terms_policy no corpo
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.status === 200) {
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.id === clientId ? { ...client, terms_policy: newTermsPolicy } : client
          )
        );
  
        setFilteredClients((prevFilteredClients) =>
          prevFilteredClients.map((client) =>
            client.id === clientId ? { ...client, terms_policy: newTermsPolicy } : client
          )
        );
  
        toast.success(t('termsAcceptedSuccessfully'), { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Erro ao aceitar termos:', error);
      toast.error(t('errorAcceptingTerms'), { position: 'bottom-right' });
    }
  };

  const handleFilter = (event) => {
    const type = event.target.value;
    setSelectedFilter(type);
  
    if (type === 'all') {
      setFilteredClients(clients);
    } else if (type === 'business') {
      const filtered = clients.filter(
        (client) => client.costumer_type === 'business_1' || client.costumer_type === 'business_2'
      );
      setFilteredClients(filtered);
    } else {
      const filtered = clients.filter((client) => client.costumer_type === type);
      setFilteredClients(filtered);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token ausente. Redirecionando...');
          return;
        }
        const response = await axios.get(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClients(response.data);
        setFilteredClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
  
    fetchClients();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filtered = clients.filter((client) => {
        const matchesSearch = Object.values(client).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
  
        if (selectedFilter === 'all') {
          return matchesSearch;
        } else if (selectedFilter === 'business') {
          return (
            matchesSearch &&
            (client.costumer_type === 'business_1' || client.costumer_type === 'business_2')
          );
        } else if (selectedFilter === 'private') {
          return matchesSearch && client.costumer_type === 'private';
        }
  
        return false;
      });
  
      setFilteredClients(filtered);
    } else {
      // Reaplica o filtro principal se o termo de busca for muito curto
      handleFilter({ target: { value: selectedFilter } });
    }
  }, [searchTerm, clients, selectedFilter]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setClientToEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveChanges = async () => {
    try {
      console.log('Iniciando a atualização do cliente.');
  
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token ausente. Redirecionando...');
        return;
      }
  
      console.log('Token encontrado. Prosseguindo com a requisição.');
  
      const response = await axios.put(
        `${API_BASE_URL}/api/clients/${clientToEdit.id}`,
        clientToEdit,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Resposta da API recebida:', response);
  
      if (response.status === 200) {
        console.log('Cliente atualizado com sucesso:', response.data);
  
        const updatedClients = clients.map((client) =>
          client.id === clientToEdit.id ? { ...client, ...response.data } : client
        );
  
        console.log('Lista de clientes após atualização:', updatedClients);
  
        // Atualiza a lista de clientes mesclando os dados atualizados
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.id === clientToEdit.id ? { ...client, ...response.data } : client
          )
        );

        // Se você também utiliza uma lista filtrada, atualize-a da mesma forma:
        setFilteredClients((prevClients) =>
          prevClients.map((client) =>
            client.id === clientToEdit.id ? { ...client, ...response.data } : client
          )
        );
  
        console.log('Estado atualizado com sucesso.');
        closeEditModal();
  
        toast.success('Cliente atualizado com sucesso.', { position: 'bottom-right' });
      } else {
        console.error('Erro na atualização do cliente:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
      toast.error('Erro ao atualizar cliente.', { position: 'bottom-right' });
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/clients/${clientId}/deactivate`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(t('clientDeletedSuccessfully'));
        // Atualiza a lista de clientes removendo o cliente desativado
        setClients((prevClients) => prevClients.filter((client) => client.id !== clientId));
        setDeleteModalOpen(false);
        closeEditModal();
      }
    } catch (error) {
      console.error('Erro ao deletar o cliente:', error);
      toast.error(t('errorDeletingClient'));
    }
  };

  const sortedClients = [...filteredClients].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastClient = currentPage * resultsPerPage;
  const indexOfFirstClient = indexOfLastClient - resultsPerPage;
  const currentClients = sortedClients.slice(indexOfFirstClient, indexOfLastClient);

  const totalPages = Math.ceil(filteredClients.length / resultsPerPage);
  
  const renderSortIcons = (field) => (
    <span className="sort-icons">
      <span
        className={`arrow ${sortField === field && sortOrder === 'asc' ? 'active' : ''}`}
      >
        ↑
      </span>
      <span
        className={`arrow ${sortField === field && sortOrder === 'desc' ? 'active' : ''}`}
      >
        ↓
      </span>
    </span>
  );

  if (selectedClient) {
    return (
      <ClientDetails clientId={selectedClient.id} onBack={() => setSelectedClient(null)} />
    );
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo semi-transparente
      zIndex: 2000, // Deve ser maior que o z-index de outros elementos
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      maxHeight: '90vh',
      overflowY: 'auto',
      zIndex: 3000, // Maior que o overlay
    },
  };

  const getSelectedCountry = () => {
    return countryOptions.find(option => option.dialCode === clientToEdit.phone_country) || countryOptions.find(option => option.value === 'it');
  };

  const handleSelectChange = (selectedOption) => {
    setClientToEdit((prev) => ({
      ...prev,
      phone_country: selectedOption ? selectedOption.dialCode : '',
    }));
  };

  return (
    <div className="page-wrapper">
      <ToastContainer />
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('clients')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('listClient')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
             <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control me-3"
                placeholder={t('searchClients')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div class="ms-auto me-3">
                <div class="btn-group">
                    <select className="form-select w-auto ms-3" onChange={handleFilter} value={selectedFilter}>
                        <option value="all">{t('allClients')}</option>
                        <option value="private">{t('privato')}</option>
                        <option value="business">{t('business')}</option>
                    </select>
                </div>
            </div>
            </div>
            <div class="ms-auto me-3">
                <div class="btn-group">
                    <select
                    className="form-select w-auto"
                    value={resultsPerPage}
                    onChange={(e) => setResultsPerPage(Number(e.target.value))}
                    >
                    <option value={10}>10 {t('results')}</option>
                    <option value={20}>20 {t('results')}</option>
                    <option value={30}>30 {t('results')}</option>
                    </select>
                </div>
            </div>
            <span class="me-3">
            {t('showingClients')} {currentClients.length} {t('of')} {filteredClients.length} {t('clients')}
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped custom-table">
                <thead className="table-light">
                    <tr>
                        {['costumer_type', 'name', 'phone'].map((field) => (
                        <th
                            key={field}
                            onClick={() => handleSort(field)}
                            className="sortable"
                        >
                            {t(field)}
                            {renderSortIcons(field)}
                        </th>
                        ))}
                        <th>{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                  {currentClients.length > 0 ? (
                    currentClients.map((client) => (
                        <tr key={client.id}>
                            <td>{t(client.costumer_type ?? 'N/A')}</td>
                            <td>{client.name ?? 'N/A'}</td>
                            <td>{client.phone_country}{client.phone ?? 'N/A'}</td>
                            <td>
                            <button
                              className="btn btn-sm btn-primary me-2"
                              title={t('view')}
                              onClick={() => openClientDetails(client)}
                            >
                              <FaEye />
                            </button>
                            <button
                              className="btn btn-sm btn-warning me-2"
                              title={t('edit')}
                              onClick={() => openEditModal(client)}
                            >
                              <FaEdit />
                            </button>
                            {client.terms_policy === 'no' && (
                              <>
                              <button
                                className="btn btn-sm btn-outline-danger me-2"
                                title={t('acceptTerms')}
                                onClick={() => handleAcceptTerms(client.id, client.terms_policy)}
                              >
                                <FaCheckCircle />
                              </button>
                              <button
                                className="btn btn-sm btn-primary"
                                title={t('sendTerms')}
                                onClick={() => sendTerms(client)}
                              >
                                <FaPaperPlane />
                              </button>
                              </>
                            )}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="11" className="text-center">
                            {t('no_clients_found')}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
          </div>
          
          {/* Componente de Paginação */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            maxPageButtons={5} // Ajuste conforme o layout
          />
        </div>
      </div>

      <Modal
        isOpen={deleteModalOpen}
        onRequestClose={() => setDeleteModalOpen(false)}
        contentLabel={t('confirmDeletion')}
        className="Modal"
        overlayClassName="Overlay"
        style={{
          overlay: {
            zIndex: 5000, // Valor maior que o do outro modal
          },
        }}
      >
        <div>
          <h4>{t('confirmDeletion')}</h4>
          <p>{t('Are you sure you want to delete this client?')}</p>
          <div className="modal-buttons">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setDeleteModalOpen(false)}
            >
              {t('cancel')}
            </button>
            <button
              type="button"
              className="btn btn-danger space"
              onClick={() => handleDeleteClient(clientToEdit.id)}
            >
              {t('confirm')}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={editModalOpen}
        onRequestClose={closeEditModal}
        contentLabel={t('editClientDetails')}
        className="Modal"
        style={customStyles}
        overlayClassName="Overlay"
      >
        {clientToEdit ? (
          <>
            <h4>{t('editClientDetails')}</h4>
            <form>
              {/* Tipo de Cliente */}
              <div className="mb-3">
                <label className="form-label">{t('costumerType')}</label>
                <select
                  className="form-select"
                  name="costumer_type"
                  value={clientToEdit.costumer_type || ''}
                  onChange={handleInputChange}
                >
                  <option value="private">{t('private')}</option>
                  <option value="business_1">{t('business1')}</option>
                  <option value="business_2">{t('business2')}</option>
                </select>
              </div>

              {/* Campos gerais */}
              <div className="mb-3">
                <label className="form-label">{t('name')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={clientToEdit.name || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('surname')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="surname"
                  value={clientToEdit.surname || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('email')}</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={clientToEdit.email || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('phone')}</label>
                <div className="d-flex">
                  <Select
                    options={countryOptions}
                    value={getSelectedCountry()}
                    onChange={handleSelectChange}
                    name="phone_country"
                    className="country-select"
                    classNamePrefix="select"
                    isSearchable
                    styles={{
                      control: (base) => ({
                        ...base,
                        minWidth: '150px',
                        marginRight: '10px',
                      }),
                      option: (base, state) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                      }),
                    }}
                  />
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder={t('Enter phone number')}
                    value={clientToEdit.phone}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Campos adicionais para empresas */}
              {['business_1', 'business_2'].includes(clientToEdit.costumer_type) && (
                <>
                  <div className="mb-3">
                    <label className="form-label">{t('pec')}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="pec"
                      value={clientToEdit.pec || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">{t('p_iva')}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="p_iva"
                      value={clientToEdit.p_iva || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">{t('cod_sdi')}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="cod_sdi"
                      value={clientToEdit.cod_sdi || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}

              {/* CF */}
              <div className="mb-3">
                <label className="form-label">{t('cf')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="cf"
                  value={clientToEdit.cf || ''}
                  onChange={handleInputChange}
                />
              </div>

              {/* Preferência de Contato */}
              <div className="mb-3">
                <div className="form-check">
                  <input
                    type="radio"
                    id="contactEmail"
                    name="contactPreference"
                    value="email"
                    checked={clientToEdit.contact_preference === 'email'}
                    onChange={(e) => setClientToEdit({ ...clientToEdit, contact_preference: e.target.value })}
                    className="form-check-input"
                  />
                  <label htmlFor="contactEmail" className="form-check-label">
                    {t('email')}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="contactWhatsapp"
                    name="contactPreference"
                    value="whatsapp"
                    checked={clientToEdit.contact_preference === 'whatsapp'}
                    onChange={(e) => setClientToEdit({ ...clientToEdit, contact_preference: e.target.value })}
                    className="form-check-input"
                  />
                  <label htmlFor="contactWhatsapp" className="form-check-label">
                    {t('whatsapp')}
                  </label>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-success"
                onClick={saveChanges}
              >
                {t('saveChanges')}
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={closeEditModal}
              >
                {t('cancel')}
              </button>
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={() => setDeleteModalOpen(true)}
              >
                {t('Delete')}
              </button>
            </form>
          </>
        ) : (
          <div>{t('loading')}...</div>
        )}
      </Modal>
    </div>
  );
}

export default withPermission(ClientList, ['admin', 'receptionist']);