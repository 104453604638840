const predefinedServices = [
    {
      value: 'Manodopera',
      label: 'Manodopera',
      costPrice: 35,
      profitMargin: 0,
      iva: 22,
      finalPrice: 35,
    },
    {
      value: 'Smaltimento rifiuti e materiale di consumo',
      label: 'Smaltimento rifiuti e materiale di consumo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Olio',
      label: 'Olio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Olio 10W40',
      label: 'Olio 10W40',
      costPrice: 10,
      profitMargin: 0,
      iva: 22,
      finalPrice: 10,
    },
    {
      value: 'Olio 5W30',
      label: 'Olio 5W30',
      costPrice: 13,
      profitMargin: 0,
      iva: 22,
      finalPrice: 13,
    },
    {
      value: 'Olio 5W40',
      label: 'Olio 5W40',
      costPrice: 12,
      profitMargin: 0,
      iva: 22,
      finalPrice: 12,
    },
    {
      value: 'Olio 0W30',
      label: 'Olio 0W30',
      costPrice: 16.50,
      profitMargin: 0,
      iva: 22,
      finalPrice: 16.50,
    },
    {
      value: 'Olio 0W20',
      label: 'Olio 0W20',
      costPrice: 19,
      profitMargin: 0,
      iva: 22,
      finalPrice: 19,
    },
    {
      value: 'Olio Idroguida',
      label: 'Olio Idroguida',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Olio',
      label: 'Filtro Olio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Aria',
      label: 'Filtro Aria',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Abitacolo',
      label: 'Filtro Abitacolo',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Benzina',
      label: 'Filtro Benzina',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Gasolio',
      label: 'Filtro Gasolio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Filtro Gas',
      label: 'Filtro Gas',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'KIT Distribuzione',
      label: 'KIT Distribuzione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pompa Acqua',
      label: 'Pompa Acqua',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Frizione',
      label: 'Frizione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pompa frizione',
      label: 'Pompa frizione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Bobina',
      label: 'Bobina',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Antigelo Rosso',
      label: 'Antigelo Rosso',
      costPrice: 6,
      profitMargin: 0,
      iva: 22,
      finalPrice: 6,
    },
    {
      value: 'Antigelo Blu',
      label: 'Antigelo Blu',
      costPrice: 6,
      profitMargin: 0,
      iva: 22,
      finalPrice: 6,
    },
    {
      value: 'Additivo Gasolio',
      label: 'Additivo Gasolio',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Guarnizione',
      label: 'Guarnizione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Guarnizione testata',
      label: 'Guarnizione testata',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Guarnizione iniettore',
      label: 'Guarnizione iniettore',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Iniettore',
      label: 'Iniettore',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Cinghia Servizi',
      label: 'Cinghia Servizi',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'KIT Ganasce',
      label: 'KIT Ganasce',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'KIT Freni',
      label: 'KIT Freni',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pastiglie Freni - Anteriori',
      label: 'Pastiglie Freni - Anteriori',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Pastiglie Freni - Posteriori',
      label: 'Pastiglie Freni - Posteriori',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Sospensione',
      label: 'Sospensione',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Bieleta',
      label: 'Bieleta',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Diagnosi Computer',
      label: 'Diagnosi Computer',
      costPrice: 25,
      profitMargin: 0,
      iva: 22,
      finalPrice: 25,
    },
    {
      value: 'Motorino avviamento',
      label: 'Motorino avviamento',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Azzeramento Parametri',
      label: 'Azzeramento Parametri',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Candela',
      label: 'Candela',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
    {
      value: 'Candele',
      label: 'Candele',
      costPrice: null,
      profitMargin: 0,
      iva: 22,
      finalPrice: null,
    },
  ];
  export default predefinedServices;