const countryOptions = [
    // Europa
    { value: 'it', label: '🇮🇹 Italia (+39)', dialCode: '+39' },
    { value: 'es', label: '🇪🇸 Spagna (+34)', dialCode: '+34' },
    { value: 'gb', label: '🇬🇧 Regno Unito (+44)', dialCode: '+44' },
    { value: 'fr', label: '🇫🇷 Francia (+33)', dialCode: '+33' },
    { value: 'ie', label: '🇮🇪 Irlanda (+353)', dialCode: '+353' },
    { value: 'pt', label: '🇵🇹 Portogallo (+351)', dialCode: '+351' },
    { value: 'nl', label: '🇳🇱 Paesi Bassi (+31)', dialCode: '+31' },
    { value: 'be', label: '🇧🇪 Belgio (+32)', dialCode: '+32' },
    { value: 'de', label: '🇩🇪 Germania (+49)', dialCode: '+49' },
    { value: 'ch', label: '🇨🇭 Svizzera (+41)', dialCode: '+41' },
    { value: 'se', label: '🇸🇪 Svezia (+46)', dialCode: '+46' },
    { value: 'no', label: '🇳🇴 Norvegia (+47)', dialCode: '+47' },
    { value: 'dk', label: '🇩🇰 Danimarca (+45)', dialCode: '+45' },
    { value: 'pl', label: '🇵🇱 Polonia (+48)', dialCode: '+48' },
    { value: 'at', label: '🇦🇹 Austria (+43)', dialCode: '+43' },
    { value: 'gr', label: '🇬🇷 Grecia (+30)', dialCode: '+30' },
    { value: 'fi', label: '🇫🇮 Finlandia (+358)', dialCode: '+358' },
    { value: 'cz', label: '🇨🇿 Repubblica Ceca (+420)', dialCode: '+420' },
    { value: 'hu', label: '🇭🇺 Ungheria (+36)', dialCode: '+36' },
    { value: 'ro', label: '🇷🇴 Romania (+40)', dialCode: '+40' },
    { value: 'bg', label: '🇧🇬 Bulgaria (+359)', dialCode: '+359' },
    { value: 'sk', label: '🇸🇰 Slovacchia (+421)', dialCode: '+421' },
    
    // África
    { value: 'za', label: '🇿🇦 Sud Africa (+27)', dialCode: '+27' },
    { value: 'eg', label: '🇪🇬 Egitto (+20)', dialCode: '+20' },
    { value: 'ng', label: '🇳🇬 Nigeria (+234)', dialCode: '+234' },
    { value: 'ke', label: '🇰🇪 Kenya (+254)', dialCode: '+254' },
    { value: 'ma', label: '🇲🇦 Marocco (+212)', dialCode: '+212' },
    { value: 'dz', label: '🇩🇿 Algeria (+213)', dialCode: '+213' },
    { value: 'tn', label: '🇹🇳 Tunisia (+216)', dialCode: '+216' },
    { value: 'gh', label: '🇬🇭 Ghana (+233)', dialCode: '+233' },
    { value: 'et', label: '🇪🇹 Etiopia (+251)', dialCode: '+251' },
    { value: 'ug', label: '🇺🇬 Uganda (+256)', dialCode: '+256' },
    { value: 'ci', label: '🇨🇮 Costa d\'Avorio (+225)', dialCode: '+225' },
    { value: 'sn', label: '🇸🇳 Senegal (+221)', dialCode: '+221' },
    { value: 'tz', label: '🇹🇿 Tanzania (+255)', dialCode: '+255' },
    { value: 'cm', label: '🇨🇲 Camerun (+237)', dialCode: '+237' },
    { value: 'zw', label: '🇿🇼 Zimbabwe (+263)', dialCode: '+263' },
    { value: 'ly', label: '🇱🇾 Libia (+218)', dialCode: '+218' },
    { value: 'ml', label: '🇲🇱 Mali (+223)', dialCode: '+223' },
    { value: 'ao', label: '🇦🇴 Angola (+244)', dialCode: '+244' },
    
    // América do Norte
    { value: 'ca', label: '🇨🇦 Canada (+1)', dialCode: '+1' },
    { value: 'mx', label: '🇲🇽 Messico (+52)', dialCode: '+52' },
    { value: 'us', label: '🇺🇸 Stati Uniti (+1)', dialCode: '+1' },
    { value: 'cu', label: '🇨🇺 Cuba (+53)', dialCode: '+53' },
    { value: 'do', label: '🇩🇴 Repubblica Dominicana (+1 809/829/849)', dialCode: '+1' },
    { value: 'jm', label: '🇯🇲 Giamaica (+1 876)', dialCode: '+1' },
    { value: 'bs', label: '🇧🇸 Bahamas (+1 242)', dialCode: '+1' },
    { value: 'tt', label: '🇹🇹 Trinidad e Tobago (+1 868)', dialCode: '+1' },
    { value: 'bb', label: '🇧🇧 Barbados (+1 246)', dialCode: '+1' },
    { value: 'lc', label: '🇱🇨 Santa Lucia (+1 758)', dialCode: '+1' },
    { value: 'gd', label: '🇬🇩 Grenada (+1 473)', dialCode: '+1' },
    { value: 'vc', label: '🇻🇨 Saint Vincent e Grenadine (+1 784)', dialCode: '+1' },
    { value: 'ag', label: '🇦🇬 Antigua e Barbuda (+1 268)', dialCode: '+1' },
    { value: 'dm', label: '🇩🇲 Dominica (+1 767)', dialCode: '+1' },
    { value: 'kn', label: '🇰🇳 Saint Kitts e Nevis (+1 869)', dialCode: '+1' },
    { value: 'bm', label: '🇧🇲 Bermuda (+1 441)', dialCode: '+1' },

    // America do Sul
    { value: 'br', label: '🇧🇷 Brasil (+55)', dialCode: '+55' },
    
  ];

  export default countryOptions;