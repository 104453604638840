import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './ClientDetails.css';

function ClientDetails({ clientId, onBack }) {
  const { t } = useTranslation();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchClientDetails = async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('Token ausente');
      }

      const response = await axios.get(`${API_BASE_URL}/api/clients/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setClient(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Erro ao buscar detalhes do cliente:', err);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  if (loading) {
    return <div className="client-details"><p>{t('loading')}...</p></div>;
  }

  if (error) {
    return <div className="client-details"><p>{t('errorLoadingDetails')}</p></div>;
  }

  return (
    <div className="client-details">
      <button className="back-button" onClick={onBack}>
        <FaArrowLeft /> {t('back')}
      </button>

      <h2>{t('clientDetails')}</h2>

      <section className="client-info">
        <h3>{t('clientInfo')}</h3>
        <p><strong>{t('costumerType')}:</strong> {t(client.costumer_type)}</p>
        <p><strong>{t('name')}:</strong> {client.name}</p>
        <p><strong>{t('surname')}:</strong> {client.surname}</p>
        <p><strong>{t('email')}:</strong> {client.email}</p>
        <p><strong>{t('phone')}:</strong> {client.phone}</p>
        {['business_1', 'business_2'].includes(client.costumer_type) && (
          <>
            <p><strong>{t('pec')}:</strong> {client.pec}</p>
            <p><strong>{t('p_iva')}:</strong> {client.p_iva}</p>
            <p><strong>{t('cod_sdi')}:</strong> {client.cod_sdi}</p>
          </>
        )}
        <p><strong>{t('cf')}:</strong> {client.cf}</p>
        <p><strong>{t('contactPreference')}:</strong> {t(client.contact_preference)}</p>
      </section>

      <section className="vehicles-info">
        <h3>{t('vehicles')}</h3>
        {client.cars.length > 0 ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{t('Brand')}</th>
                <th>{t('Model')}</th>
                <th>{t('Year')}</th>
                <th>{t('Plate')}</th>
                <th>{t('Mileage')} (km)</th>
              </tr>
            </thead>
            <tbody>
              {client.cars.map((car) => (
                <tr key={car.id}>
                  <td>{car.car_brand}</td>
                  <td>{car.car_model}</td>
                  <td>{car.car_year}</td>
                  <td>{car.car_plate}</td>
                  <td>{car.last_mileage || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t('noCarsFound')}</p>
        )}
      </section>
    </div>
  );
}

export default ClientDetails;