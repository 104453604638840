import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import API_BASE_URL from '../config';
import './AuthorizedLocations.css';

const AuthorizedLocations = () => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const [form, setForm] = useState({ id: '', name: '', latitude: '', longitude: '', radius: 1 });
  // If an authorized location already exists, editing is disabled until the user clicks "Edit"
  const [isEditing, setIsEditing] = useState(true);

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/authorized-locations`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching authorized locations:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/authorized-locations`,
        form,
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } }
      );
      toast.success(response.data.message);
      fetchLocations();
      setForm({ id: '', name: '', latitude: '', longitude: '', radius: 1 });
      // After saving/updating: if creating new, fields remain editable; if updating, disable editing until "Edit" is clicked again.
      setIsEditing(locations.length === 0);
    } catch (error) {
      console.error('Error saving authorized location:', error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      setForm(locations[0]);
      setIsEditing(false);
    } else {
      setForm({ id: '', name: '', latitude: '', longitude: '', radius: 1 });
      setIsEditing(true);
    }
  }, [locations]);

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setForm((prevForm) => ({
            ...prevForm,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
        (error) => {
          console.error("Error getting current location:", error);
          toast.error(t("Could not retrieve current location."));
        }
      );
    } else {
      toast.error(t("Geolocation is not supported by your browser."));
    }
  };

  return (
    <div className="authorized-locations">
      <h1>{locations.length > 0 ? t('Edit Authorized Location') : t('Create Authorized Location')}</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder={t("Name for this location")}
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          required
          disabled={!isEditing}
        />
        
        {/* Display the "Current Location" button only when editing */}
        {isEditing && (
          <button type="button" onClick={handleCurrentLocation}>
            {t('Current Location')}
          </button>
        )}
        
        <input
          type="number"
          placeholder={t("Latitude")}
          value={form.latitude}
          onChange={(e) => setForm({ ...form, latitude: e.target.value })}
          required
          disabled={!isEditing}
        />
        <input
          type="number"
          placeholder={t("Longitude")}
          value={form.longitude}
          onChange={(e) => setForm({ ...form, longitude: e.target.value })}
          required
          disabled={!isEditing}
        />
        <input
          type="number"
          placeholder={t("Radius (km)")}
          value={form.radius}
          onChange={(e) => setForm({ ...form, radius: e.target.value })}
          disabled={!isEditing}
        />

        {/* If an authorized location exists and not in editing mode, display the "Edit" button */}
        {locations.length > 0 && !isEditing ? (
          <button type="button" onClick={(e) => { e.preventDefault(); setIsEditing(true); }}>
            {t('Edit')}
          </button>
        ) : (
          <button type="submit">
        {locations.length > 0 ? t('Update') : t('Save')}
          </button>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default AuthorizedLocations;