import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import initialCarBrands from '../components/CarBrands';
import Select from 'react-select';
import './CustomerCare.css';
import { useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import 'jspdf-autotable';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CustomerCare() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [existingClients, setExistingClients] = useState([]);
  const [ticketNumber, setTicketNumber] = useState(null);
  const [clientCars, setClientCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);

  const countryOptions = [
    // Europa
    { value: 'it', label: '🇮🇹 Italia (+39)', dialCode: '+39' },
    { value: 'es', label: '🇪🇸 Spagna (+34)', dialCode: '+34' },
    { value: 'gb', label: '🇬🇧 Regno Unito (+44)', dialCode: '+44' },
    { value: 'fr', label: '🇫🇷 Francia (+33)', dialCode: '+33' },
    { value: 'ie', label: '🇮🇪 Irlanda (+353)', dialCode: '+353' },
    { value: 'pt', label: '🇵🇹 Portogallo (+351)', dialCode: '+351' },
    { value: 'nl', label: '🇳🇱 Paesi Bassi (+31)', dialCode: '+31' },
    { value: 'be', label: '🇧🇪 Belgio (+32)', dialCode: '+32' },
    { value: 'de', label: '🇩🇪 Germania (+49)', dialCode: '+49' },
    { value: 'ch', label: '🇨🇭 Svizzera (+41)', dialCode: '+41' },
    { value: 'se', label: '🇸🇪 Svezia (+46)', dialCode: '+46' },
    { value: 'no', label: '🇳🇴 Norvegia (+47)', dialCode: '+47' },
    { value: 'dk', label: '🇩🇰 Danimarca (+45)', dialCode: '+45' },
    { value: 'pl', label: '🇵🇱 Polonia (+48)', dialCode: '+48' },
    { value: 'at', label: '🇦🇹 Austria (+43)', dialCode: '+43' },
    { value: 'gr', label: '🇬🇷 Grecia (+30)', dialCode: '+30' },
    { value: 'fi', label: '🇫🇮 Finlandia (+358)', dialCode: '+358' },
    { value: 'cz', label: '🇨🇿 Repubblica Ceca (+420)', dialCode: '+420' },
    { value: 'hu', label: '🇭🇺 Ungheria (+36)', dialCode: '+36' },
    { value: 'ro', label: '🇷🇴 Romania (+40)', dialCode: '+40' },
    { value: 'bg', label: '🇧🇬 Bulgaria (+359)', dialCode: '+359' },
    { value: 'sk', label: '🇸🇰 Slovacchia (+421)', dialCode: '+421' },
    
    // África
    { value: 'za', label: '🇿🇦 Sud Africa (+27)', dialCode: '+27' },
    { value: 'eg', label: '🇪🇬 Egitto (+20)', dialCode: '+20' },
    { value: 'ng', label: '🇳🇬 Nigeria (+234)', dialCode: '+234' },
    { value: 'ke', label: '🇰🇪 Kenya (+254)', dialCode: '+254' },
    { value: 'ma', label: '🇲🇦 Marocco (+212)', dialCode: '+212' },
    { value: 'dz', label: '🇩🇿 Algeria (+213)', dialCode: '+213' },
    { value: 'tn', label: '🇹🇳 Tunisia (+216)', dialCode: '+216' },
    { value: 'gh', label: '🇬🇭 Ghana (+233)', dialCode: '+233' },
    { value: 'et', label: '🇪🇹 Etiopia (+251)', dialCode: '+251' },
    { value: 'ug', label: '🇺🇬 Uganda (+256)', dialCode: '+256' },
    { value: 'ci', label: '🇨🇮 Costa d\'Avorio (+225)', dialCode: '+225' },
    { value: 'sn', label: '🇸🇳 Senegal (+221)', dialCode: '+221' },
    { value: 'tz', label: '🇹🇿 Tanzania (+255)', dialCode: '+255' },
    { value: 'cm', label: '🇨🇲 Camerun (+237)', dialCode: '+237' },
    { value: 'zw', label: '🇿🇼 Zimbabwe (+263)', dialCode: '+263' },
    { value: 'ly', label: '🇱🇾 Libia (+218)', dialCode: '+218' },
    { value: 'ml', label: '🇲🇱 Mali (+223)', dialCode: '+223' },
    { value: 'ao', label: '🇦🇴 Angola (+244)', dialCode: '+244' },
    
    // América do Norte
    { value: 'ca', label: '🇨🇦 Canada (+1)', dialCode: '+1' },
    { value: 'mx', label: '🇲🇽 Messico (+52)', dialCode: '+52' },
    { value: 'us', label: '🇺🇸 Stati Uniti (+1)', dialCode: '+1' },
    { value: 'cu', label: '🇨🇺 Cuba (+53)', dialCode: '+53' },
    { value: 'do', label: '🇩🇴 Repubblica Dominicana (+1 809/829/849)', dialCode: '+1' },
    { value: 'jm', label: '🇯🇲 Giamaica (+1 876)', dialCode: '+1' },
    { value: 'bs', label: '🇧🇸 Bahamas (+1 242)', dialCode: '+1' },
    { value: 'tt', label: '🇹🇹 Trinidad e Tobago (+1 868)', dialCode: '+1' },
    { value: 'bb', label: '🇧🇧 Barbados (+1 246)', dialCode: '+1' },
    { value: 'lc', label: '🇱🇨 Santa Lucia (+1 758)', dialCode: '+1' },
    { value: 'gd', label: '🇬🇩 Grenada (+1 473)', dialCode: '+1' },
    { value: 'vc', label: '🇻🇨 Saint Vincent e Grenadine (+1 784)', dialCode: '+1' },
    { value: 'ag', label: '🇦🇬 Antigua e Barbuda (+1 268)', dialCode: '+1' },
    { value: 'dm', label: '🇩🇲 Dominica (+1 767)', dialCode: '+1' },
    { value: 'kn', label: '🇰🇳 Saint Kitts e Nevis (+1 869)', dialCode: '+1' },
    { value: 'bm', label: '🇧🇲 Bermuda (+1 441)', dialCode: '+1' },

    // America do Sul
    { value: 'br', label: '🇧🇷 Brasil (+55)', dialCode: '+55' },
    
  ];

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [carBrands, setCarBrands] = useState(initialCarBrands);
  const [carModels, setCarModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  const fetchNextTicketNumber = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/next-ticket-number`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao obter o próximo número do ticket.');
      }
  
      const data = await response.json();
      setTicketNumber(data.nextTicketNumber);
    } catch (err) {
      console.error('Erro ao buscar o próximo número do ticket:', err);
    }
  };

  useEffect(() => {
    fetchNextTicketNumber(); // Busca o próximo número do ticket ao carregar o componente
  }, []);

  const success_noti = (message) => {
    toast.success(message || t('Customer and car successfully registered.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };
  
  const error_noti = (message) => {
    toast.error(message || t('An error occurred while registering.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  // Atualizar modelos ao selecionar uma marca
  useEffect(() => {
    if (selectedCar) {
      // Se um veículo foi selecionado do banco de dados
      const selectedCarDetails = clientCars.find((car) => car.id === selectedCar);
  
      if (selectedCarDetails) {
        const brand = carBrands.find((b) => b.value === selectedCarDetails.car_brand);
        if (brand) {
          setCarModels(
            brand.models.map((model) => ({ value: model, label: model }))
          );
        }
        // Define o modelo selecionado automaticamente
        setSelectedModel({
          value: selectedCarDetails.car_model,
          label: selectedCarDetails.car_model,
        });
      }
    } else if (selectedBrand) {
      // Caso contrário, atualiza os modelos para a marca selecionada
      const brand = carBrands.find((b) => b.value === selectedBrand.value);
      setCarModels(
        brand ? brand.models.map((model) => ({ value: model, label: model })) : []
      );
      setSelectedModel(null); 
    } else {
      setCarModels([]);
      setSelectedModel(null);
    }
  }, [selectedBrand, selectedCar, clientCars, carBrands]);

  const handleBrandChange = (newValue) => {
    setSelectedBrand(newValue);
    setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
  
    if (newValue && !carBrands.some((brand) => brand.value === newValue.value)) {
      setCarBrands([...carBrands, { value: newValue.value, label: newValue.label, models: [] }]);
    }
  };
  
  const handleModelChange = (newValue) => {
    setSelectedModel(newValue);
    setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
  
    if (newValue && selectedBrand) {
      const updatedBrands = carBrands.map((brand) => {
        if (brand.value === selectedBrand.value) {
          return {
            ...brand,
            models: brand.models.includes(newValue.value)
              ? brand.models
              : [...brand.models, newValue.value],
          };
        }
        return brand;
      });
      setCarBrands(updatedBrands);
    }
  };

  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhoneCountry: '+39',
    clientPhone: '',
    carBrand: '',
    carModel: '',
    carYear: '',
    carPlate: '',
    carMileage: '',
    carProblem: '',
  });

  const steps = [
    { id: 1, title: t('Personal Information'), number: '1', description: t('Client details') },
    { id: 2, title: t('Car Details'), number: '2', description: t('Car information') },
    { id: 3, title: t('Problem Description'), number: '3', description: t('Describe the problem') },
    { id: 4, title: t('Summary'), number: '4', description: t('Review and submit') },
  ];

  const reportRef = useRef();

  const fetchClientDetails = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/clients/details/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar detalhes do cliente.');
      }

      const clientDetails = await response.json();

      setFormData({
        clientName: clientDetails.name || '',
        clientEmail: clientDetails.email || '',
        clientPhoneCountry: clientDetails.phone_country || '',
        clientPhone: clientDetails.phone || '',
        carBrand: '',
        carModel: '',
        carYear: '',
        carPlate: '',
        carMileage: '',
        carProblem: ''
      });
    } catch (err) {
      console.error('Erro:', err);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setExistingClients(data);
      } catch (err) {
        console.error('Erro ao buscar clientes existentes:', err);
      }
    };

    fetchClients();
  }, []);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    if (!formData.clientName || !formData.clientPhone || !formData.carBrand || !formData.carModel || !formData.carYear || !formData.carPlate || !formData.carMileage) {
      error_noti(t('All fields are required.'));
      return;
    }
  
    const payload = {
      clientName: formData.clientName,
      clientPhoneCountry: formData.clientPhoneCountry,
      clientPhone: formData.clientPhone,
      carBrand: selectedCar ? null : formData.carBrand,
      carModel: selectedCar ? null : formData.carModel,
      carYear: selectedCar ? null : formData.carYear,
      carPlate: formData.carPlate,
      carMileage: formData.carMileage,
      carProblem: formData.carProblem,
    };
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/customer-care`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        setTicketNumber(data.ticketNumber);
        success_noti();
        setTimeout(() => navigate(`/Tickets/${ticketNumber}/`), 2000);
      } else {
        const data = await response.json();
        error_noti(data.error || t('An error occurred while registering.'));
      }
    } catch (err) {
      error_noti(t('Server connection error.'));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ 
      ...formData, 
      clientPhoneCountry: selectedOption ? selectedOption.dialCode : '' 
    });
  };

  const getSelectedCountry = () => {
    const selected = countryOptions.find(option => option.dialCode === formData.clientPhoneCountry);
    return selected || countryOptions.find(option => option.value === 'it');
  };

  const fetchClientCars = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/clients/${clientId}/cars`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao buscar carros do cliente.');
      }
  
      const cars = await response.json();
      setClientCars(cars);
    } catch (err) {
      console.error('Erro ao buscar carros do cliente:', err);
      setClientCars([]); // Reseta a lista de carros se houver erro
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h4 className="form-section-title">{t('che_tipo_di_cliente_sei')}</h4>
            <div className="col-12 mb-3">
              <label htmlFor="clientSelection" className="form-label">{t('select_client')}</label>
              {existingClients.length > 0 ? (
                <Select
                  options={[
                    { value: 'new', label: t('new_client') },
                    ...existingClients.map((client) => ({
                      value: client.id,
                      label: `${client.name} - ${client.phone_country} ${client.phone}`,
                    })),
                  ]}
                  value={selectedClient ? { value: selectedClient, label: `${formData.clientName} - ${formData.clientPhoneCountry} ${formData.clientPhone}` } : null}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedClient(null);
                      setFormData({
                        clientName: '',
                        clientEmail: '',
                        clientPhoneCountry: '+39',
                        clientPhone: '',
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                        carMileage: '',
                        carProblem: '',
                      });
                      setClientCars([]);
                    } else {
                      setSelectedClient(selectedOption.value);
                      fetchClientDetails(selectedOption.value);
                      fetchClientCars(selectedOption.value);
                    }
                  }}
                  placeholder={t('select_or_search_client')}
                  isSearchable
                />
              ) : (
                <p className="text-muted">{t('no_clients_found')}</p> // Mensagem quando não há clientes
              )}
            </div>
            <div className="row g-4">
              <div className="col-md-6">
                <label htmlFor="clientName" className="form-label">{t('name')}</label>
                <input
                  type="text"
                  className="form-control"
                  id="clientName"
                  name="clientName"
                  placeholder={t('Enter full name')}
                  value={formData.clientName}
                  onChange={handleChange}
                  required
                  disabled={!!selectedClient} // Desabilita se um cliente for selecionado
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="clientPhone" className="form-label">{t('phone')}</label>
                <div className="d-flex">
                <Select
                  options={countryOptions}
                  value={getSelectedCountry()}
                  onChange={handleSelectChange}
                  name="phone_country"
                  className="country-select"
                  classNamePrefix="select"
                  isSearchable
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '150px',
                      marginRight: '10px',
                    }),
                    option: (base, state) => ({
                      ...base,
                      display: 'flex',
                      alignItems: 'center',
                    }),
                  }}
                />
                  <input
                    type="text"
                    className="form-control"
                    id="clientPhone"
                    name="clientPhone"
                    placeholder={t('Enter phone number')}
                    value={formData.clientPhone}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos
                      setFormData({ ...formData, clientPhone: numericValue });

                      // Verifica se o número já existe na lista de clientes
                      const existingClient = existingClients.find(
                        (client) => client.phone === numericValue && numericValue !== ''
                      );

                      if (existingClient) {
                        toast.warn(t('This phone number already exists. Please select the client.'));
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === ' ' || isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault(); // Bloqueia espaços e outros caracteres não numéricos
                      }
                    }}
                    required
                    disabled={!!selectedClient} // Desabilita se um cliente for selecionado
                  />
                </div>
              </div>
            </div>
          </div>
        );
        case 2:
          return (
            <div>
              <h4 className="form-section-title">{t('Car Details')}</h4>

              {/* Seleção de veículos */}
              <div className="col-12 mb-3">
                <label htmlFor="carSelection" className="form-label">{t('Select a Car')}</label>
                <Select
                  id="carSelection"
                  options={[
                    { value: 'new', label: t('New Vehicle') },
                    ...clientCars.map((car) => ({
                      value: car.id,
                      label: `${car.car_brand} ${car.car_model} (${car.car_year}) - ${car.car_plate} ${car.last_mileage ? `(${t('Mileage')}: ${car.last_mileage} km)` : ''}`,
                    })),
                  ]}
                  value={
                    selectedCar
                      ? clientCars.find((car) => car.id === selectedCar) 
                        ? { value: selectedCar, label: `${clientCars.find((car) => car.id === selectedCar).car_brand} ${clientCars.find((car) => car.id === selectedCar).car_model} (${clientCars.find((car) => car.id === selectedCar).car_year}) - ${clientCars.find((car) => car.id === selectedCar).car_plate}` }
                        : { value: 'new', label: t('New Vehicle') }
                      : { value: 'new', label: t('New Vehicle') }
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedCar(null); // Limpa a seleção de veículo
                      setFormData({
                        ...formData,
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                      });
                    } else {
                      setSelectedCar(selectedOption.value); // Define o veículo selecionado
                      const selectedCarDetails = clientCars.find((car) => car.id === selectedOption.value);
                      if (selectedCarDetails) {
                        setFormData({
                          ...formData,
                          carBrand: selectedCarDetails.car_brand,
                          carModel: selectedCarDetails.car_model,
                          carYear: selectedCarDetails.car_year,
                          carPlate: selectedCarDetails.car_plate,
                        });
                      }
                    }
                  }}
                  placeholder={t('Select or register a vehicle')}
                  isSearchable
                />
              </div>

              {/* Formulário para cadastrar/editar veículo */}
              <div className="row g-4">
                <div className="col-md-6">
                  <label className="form-label">{t('Brand')}</label>
                  <CreatableSelect
                    options={carBrands}
                    value={
                      formData.carBrand
                        ? carBrands.find((brand) => brand.value === formData.carBrand) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleBrandChange(newValue);
                      setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car brand')}
                    isClearable
                    isDisabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Model')}</label>
                  <CreatableSelect
                    options={carModels}
                    value={
                      formData.carModel
                        ? carModels.find((model) => model.value === formData.carModel) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleModelChange(newValue);
                      setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car model')}
                    isClearable
                    isDisabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Year')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carYear"
                    value={formData.carYear}
                    onChange={handleChange}
                    placeholder={t('Enter car year')}
                    required
                    disabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Plate')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="carPlate"
                    value={formData.carPlate}
                    onChange={handleChange}
                    placeholder={t('Enter car plate')}
                    required
                    disabled={selectedCar} // Desabilita o campo se veículo existente for selecionado
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Kilometer')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carMileage"
                    value={formData.carMileage || ''}
                    onChange={handleChange}
                    placeholder={t('Enter car mileage')}
                    required
                  />
                </div>
              </div>
            </div>
          );
      case 3:
        return (
          <div>
            <h4 className="form-section-title">{t('Problem Description')}</h4>
            <div className="form-group">
              <label className="form-label">{t('Description')}</label>
              <textarea
                className="form-control"
                name="carProblem"
                value={formData.carProblem}
                onChange={handleChange}
                rows="5"
                placeholder={t('Describe the issue with the car')}
                required
              ></textarea>
            </div>
          </div>
        );
        case 4:
          return (
            <div>
              <div>
                <h3 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#2c3e50' }}>
                  {t('Service Report')}
                </h3>

                <div id="report-preview" ref={reportRef} className="p-3">

                  {/* Detalhes do Cliente */}
                  <div className="border rounded p-3 mb-4" style={{ backgroundColor: '#e9ecef' }}>
                    <h5 className="mb-3" style={{ fontWeight: 'bold', color: '#34495e' }}>
                      {t('Client Information')}
                    </h5>
                    <p><strong>{t('Name')}:</strong> {formData.clientName || '-'}</p>
                    <p><strong>{t('phone')}:</strong> {formData.clientPhoneCountry} {formData.clientPhone || '-'}</p>
                    <p><strong>{t('Date')}:</strong> {new Date().toLocaleDateString()}</p>
                    <p><strong>{t('Ticket Number')}:</strong> #{ticketNumber}</p>
                  </div>

                  {/* Detalhes do Veículo */}
                  <div className="border rounded p-3 mb-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <h5 className="mb-3" style={{ fontWeight: 'bold', color: '#34495e' }}>
                      {t('Car Details')}
                    </h5>
                    <p><strong>{t('Brand')}:</strong> {formData.carBrand || '-'}</p>
                    <p><strong>{t('Model')}:</strong> {formData.carModel || '-'}</p>
                    <p><strong>{t('Year')}:</strong> {formData.carYear || '-'}</p>
                    <p><strong>{t('Plate')}:</strong> {formData.carPlate || '-'}</p>
                    <p><strong>{t('mileage')}:</strong> {formData.carMileage || '-'}</p>
                  </div>

                  {/* Descrição do Problema */}
                  <div className="border rounded p-3 mb-4" style={{ backgroundColor: '#ffffff' }}>
                    <h5 className="mb-3" style={{ fontWeight: 'bold', color: '#34495e' }}>
                      {t('Problem Description')}
                    </h5>
                    <p>{formData.carProblem || t('No description provided')}</p>
                  </div>
                </div>
              </div>
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('customerCare')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('customerCare')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* Progress Bar */}
            <div className="progress-bar-container mb-4">
              {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                  <div
                    className={`progress-step ${currentStep === step.id ? 'active' : ''} ${
                      currentStep > step.id ? 'completed' : ''
                    }`}
                    onClick={() => handleStepClick(step.id)}
                  >
                    <div className="step-icon">{step.number}</div> {/* Número da etapa */}
                    <div className="step-title">{step.title}</div>
                    <div className="step-description">{step.description}</div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`bs-stepper-line ${
                        currentStep > step.id ? 'completed' : ''
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <form>
              {renderStep()}
              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  disabled={currentStep === 1}
                >
                  {t('Previous')}
                </button>
                {currentStep === 4 ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {t('Submit')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setCurrentStep((prev) => prev + 1)}
                  >
                    {t('Next')}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default CustomerCare;