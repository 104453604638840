import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import API_BASE_URL from '../config';
import withAuth from '../hoc/withAuth';
import Timer from '../components/Timer';
import { toast, ToastContainer } from 'react-toastify';
import './Tickets.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPaperclip,faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import DatePicker, { } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import Select from 'react-select';
import BudgetPanel from '../components/BudgetPanel';

// Configure o Modal
Modal.setAppElement('#root');

function Tickets() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [userId, setUserId] = useState(null);
  const chatContainerRef = useRef(null);
  const [hasBudget, setHasBudget] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [budgetData, setBudgetData] = useState(null);
  const [filterStatus, setFilterStatus] = useState("All");
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [isFirstChat, setIsFirstChat] = useState(true);
  const [searchQuery, setSearchQuery] = useState(() => localStorage.getItem("ticketsSearchQuery") || "");
  const [mechanics, setMechanics] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [isDueDateModalOpen, setIsDueDateModalOpen] = useState(false);
  const [newDueDate, setNewDueDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [assignedMechanic, setAssignedMechanic] = useState(null);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [mediaToView, setMediaToView] = useState(null);
  const [imageWidth, setImageWidth] = useState('50%');
  const [contentExpanded, setContentExpanded] = useState(false);
  const [isGarantiaModalOpen, setIsGarantiaModalOpen] = useState(false);

  const handleGarantiaTicket = () => {
    // Abre o modal para confirmação
    setIsGarantiaModalOpen(true);
  };

  const confirmGarantiaTicket = async () => {
    if (!selectedTicket || !selectedTicket.id) {
      toast.error(t("No ticket selected."));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      // Faz a chamada à API para clonar o ticket e gerar o ticket garantia
      const response = await axios.post(
        `${API_BASE_URL}/api/tickets/${selectedTicket.id}/clone-garantia`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 201) {
        const newTicketId = response.data.newTicketId;
        toast.success(t(`Warranty ticket created successfully. New ticket: ${newTicketId}`));
      }
    } catch (error) {
      console.error("Error generating warranty ticket:", error);
      toast.error(t("Error generating warranty ticket."));
    } finally {
      setIsGarantiaModalOpen(false);
    }
  };

  const [checklist, setChecklist] = useState({
    nivelOleo: 0,       // exemplo de campo numérico (porcentagem do óleo)
    pneusCalibrados: false, // exemplo de campo booleano (checkbox)
    freios: false,      // outro exemplo de checkbox
    // adicione outros campos conforme necessário
  });

  const toggleImageWidth = () => {
    setImageWidth(prevWidth => (prevWidth === '100%' ? '50%' : '100%'));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) { // aplica apenas em dispositivos móveis
        if (window.scrollY > 50) { // valor de threshold (ajuste conforme necessário)
          setContentExpanded(true);
        } else {
          setContentExpanded(false);
        }
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isBudgetPanelOpen, setIsBudgetPanelOpen] = useState(false);
  const [budgetItems, setBudgetItems] = useState([]);

  const openBudget = () => {
    setIsBudgetPanelOpen(true);
  };

  const closeBudget = () => {
    setIsBudgetPanelOpen(false);
  };

  const fetchBudgetItems = async () => {
    try {
      // Lógica de GET /api/tickets/:ticketId/budget
    } catch (error) {
      console.error(error);
      toast.error('Erro ao buscar itens do orçamento.');
    }
  };

  const handleOpenBudget = () => {
    fetchBudgetItems();
    setIsBudgetPanelOpen(true);
  };

  const [timer, setTimer] = useState(null);

  const resultsPerPage = 10;

  const openReopenModal = () => {
    setIsReopenModalOpen(true);
  };

  const closeReopenModal = () => {
    setIsReopenModalOpen(false);
  };

  useEffect(() => {
    let interval = null;
  
    if (selectedTicket) {
      fetchTimers(selectedTicket.id);
  
      interval = setInterval(() => {
        fetchTimers(selectedTicket.id);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [selectedTicket]);

  useEffect(() => {
    if (selectedTicket && selectedTicket.id) {
      checkIfBudgetExists(selectedTicket.id);
    }
  }, [selectedTicket]);

  const checkIfBudgetExists = async (ticketId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${ticketId}/budget`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      if (!response.ok) {
        setHasBudget(false);
        return;
      }
  
      const data = await response.json();
      
      // Se o data tiver um `id`, significa que existe um orçamento
      if (data && data.id) {
        setHasBudget(true);
      } else {
        setHasBudget(false);
      }
    } catch (error) {
      console.error('Erro ao verificar se existe budget:', error);
      setHasBudget(false);
    }
  };

  const handleReopenTicket = async () => {
    if (!selectedTicket) {
      toast.error(t("No ticket selected."));
      return;
    }

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/reopen`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t("Error reopening ticket."));
      }

      toast.success(t("Ticket reopened successfully."));

      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        status: 2, // Assuming 'Pending' has status 2
      }));

      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id ? { ...ticket, status: 2, statusTranslation: t("Pending"), statusClassName: "pending" } : ticket
        )
      );

      closeReopenModal();
    } catch (error) {
      console.error("Erro ao reabrir o ticket:", error);
      toast.error(error.message || t("Error reopening ticket."));
    }
  };

  const filteredTickets = useMemo(() => {
    const query = searchQuery.toLowerCase();
    
    return tickets.filter((ticket) => {
      // Se ticket.statusClassName for null, convertemos para '' e depois .toLowerCase()
      const statusClass = (ticket.statusClassName ?? '').toLowerCase();
  
      const clientName = (ticket.client?.name ?? '').toLowerCase();
      const clientPhone = ticket.client?.phone ?? '';
      const carModel = (ticket.car?.model ?? '').toLowerCase();
      const carBrand = (ticket.car?.brand ?? '').toLowerCase();
      const carPlate = (ticket.car?.plate ?? '').toLowerCase();
      const ticketId = ticket.id.toString(); // Inclui o id do ticket
  
      // Filtra pelo status e pelos campos relevantes
      const matchStatus = (filterStatus === "All" || statusClass === filterStatus.toLowerCase());
      const matchQuery = (
        clientName.includes(query) ||
        clientPhone.includes(query) ||
        carModel.includes(query) ||
        carBrand.includes(query) ||
        carPlate.includes(query) ||
        ticketId.includes(query)
      );
    
      return matchStatus && matchQuery;
    });
  }, [tickets, searchQuery, filterStatus]);

  const sortedTickets = useMemo(() => {
    return [...filteredTickets].sort((a, b) => b.id - a.id);
  }, [filteredTickets]);

  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);
  
    // Ajustar a janela se não houver páginas suficientes à esquerda ou à direita
    if (currentPage === 1) {
      endPage = Math.min(totalPages, startPage + 2);
    } else if (currentPage === totalPages) {
      startPage = Math.max(1, endPage - 2);
    }
  
    // Caso o total de páginas seja menor que maxPagesToShow
    if (totalPages < maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    }
  
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  
    return pages;
  };

  // Em seguida, faça os cálculos que dependem de 'filteredTickets'
  const indexOfLastTicket = currentPage * resultsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - resultsPerPage;
  const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);
  const totalPages = Math.ceil(sortedTickets.length / resultsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const adjustTimePlusOneHour = (time) => {
    if (!time) return null;
    return moment(time).add(1, 'hour').format('DD-MM-YYYY HH:mm:ss');
  };

  useEffect(() => {
    function checkMobileView() {
      setIsMobileView(window.innerWidth < 768);
    }
    
    checkMobileView(); // Checa no carregamento
    window.addEventListener('resize', checkMobileView);
    
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  useEffect(() => {
    if (id) {
      const fetchTicket = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${API_BASE_URL}/api/tickets/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Erro ao buscar ticket.');
          }
  
          const ticket = await response.json();
          setSelectedTicket(ticket);
        } catch (error) {
          console.error('Erro ao buscar ticket:', error);
        }
      };

      fetchTicket();
    }
  }, [id]);

  // Função para abrir o modal
  const openDueDateModal = () => {
    setNewDueDate(selectedTicket.due_date ? new Date(selectedTicket.due_date) : null);
    setIsDueDateModalOpen(true);
  };

  // Função para fechar o modal
  const closeDueDateModal = () => {
    setIsDueDateModalOpen(false);
  };

  // Função para salvar a nova data de entrega
  const saveDueDate = async () => {
    if (!newDueDate) {
      toast.error(t('Por favor, selecione uma data.'));
      return;
    }
  
    // Formatar a data no formato YYYY-MM-DD HH:mm:ss
    const formattedDate = moment(newDueDate).format('YYYY-MM-DD HH:mm:ss');
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/due_date`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ due_date: formattedDate }),
      });
  
      const responseData = await response.json(); // Ler a resposta uma única vez
  
      if (!response.ok) {
        throw new Error(responseData.error || 'Erro ao atualizar a data de entrega.');
      }
  
      toast.success(t('Delivery date updated successfully.'));
  
      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        due_date: formattedDate,
      }));
  
      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id ? { ...ticket, due_date: formattedDate } : ticket
        )
      );
  
      closeDueDateModal();
    } catch (error) {
      console.error('Erro ao atualizar a data de entrega:', error);
      toast.error(error.message || t('Error updating the delivery date.'));
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/tickets`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error("Erro ao buscar tickets.");
        }
  
        const data = await response.json();
        const mappedTickets = data.map((ticket) => {
          const { translation, className } = mapStatus(ticket.status);
          return {
            ...ticket,
            statusTranslation: translation, // Texto traduzido
            statusClassName: className,    // Classe CSS fixa
          };
        });
  
        setTickets(mappedTickets);
      } catch (error) {
        console.error("Erro ao buscar tickets:", error);
      }
    };
  
    fetchTickets();
  }, []);

  useEffect(() => {
    let isMounted = true;
  
    const fetchMessages = async () => {
      if (!selectedTicket) return;
  
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/messages`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar mensagens.');
        }
  
        const messages = await response.json();
  
        const sortedMessages = messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  
        if (sortedMessages.length !== chatMessages.length ||
            sortedMessages.some((msg, index) => msg.id !== chatMessages[index]?.id)) {
          if (isMounted) {
            setChatMessages(sortedMessages);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar mensagens:', error);
      } finally {
        if (isMounted) {
          setTimeout(fetchMessages, 5000);
        }
      }
    };
  
    fetchMessages();
  
    return () => {
      isMounted = false;
    };
  }, [selectedTicket, chatMessages]);

  const mapStatus = (status) => {
    switch (status) {
      case 1:
        return { translation: t("Not Assigned"), className: "not-assigned" };
      case 2:
        return { translation: t("Pending"), className: "pending" };
      case 3:
        return { translation: t("Working"), className: "working" };
      case 4:
        return { translation: t("Finished"), className: "finished" };
      case 6:
        return { translation: t('reservation'), className: 'reservation' };
      default:
        return { translation: t("Unknown"), className: "unknown" };
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token não encontrado.');
          return;
        }
  
        const response = await fetch(`${API_BASE_URL}/user`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar dados do usuário.');
        }
  
        const user = await response.json();
        setUserId(user.id); // Define o userId no estado
        setUserRole(user.role); // Define o role no estado
      } catch (error) {
        console.error('Erro ao buscar o ID do usuário:', error);
      }
    };
  
    fetchUserData();
  }, []);

  // Função para buscar os timers quando um ticket é selecionado
  const fetchTimers = async (ticketId) => {
    try {
      const token = sessionStorage.getItem('token');
      console.log(`Buscando timer para o ticket ID: ${ticketId}`);
      const response = await fetch(`${API_BASE_URL}/api/tickets/${ticketId}/timers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log('Status da resposta do fetch:', response.status);
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Dados de erro da resposta:', errorData);
        throw new Error(errorData.error || 'Erro ao buscar timer.');
      }
  
      const data = await response.json();
      console.log('Timer buscado:', data);
  
      setTimer(data.timer);
    } catch (error) {
      console.error('Erro ao buscar timer:', error);
      toast.error(error.message || 'Erro ao buscar timer.');
    }
  };

  // Função para registrar ações do timer como mensagens no ticket
  const handleTimerAction = async (action) => {
    if (!selectedTicket) return;
  
    const timerId = timer ? timer.id : null;
  
    if (timerId) {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/timers/${timerId}/action`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ action }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Erro ao registrar ação do timer.');
        }
  
        toast.success(t('action_clicked_successfully', { action }));
        fetchTimers(selectedTicket.id); // Atualiza o timer após a ação
      } catch (error) {
        console.error('Erro ao registrar ação do timer:', error);
        toast.error(error.message || t('Error to record action of timer.'));
      }
    } else {
      // Se não há um timer e a ação for 'start', crie um novo timer
      if (action === 'start') {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/timers`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Erro ao criar timer.');
          }
  
          const data = await response.json();
          toast.success(t('Timer created succesfully, now click start again.'));
          fetchTimers(selectedTicket.id);
        } catch (error) {
          console.error('Erro ao criar timer:', error);
          toast.error(error.message || t('Error to create timer.'));
        }
      } else {
        toast.error(t('Timer not Found.'));
      }
    }
  };

  const handleCreateBudget = async () => {
    try {
      if (!selectedTicket) {
        toast.error("Nenhum ticket selecionado.");
        return;
      }
  
      const token = sessionStorage.getItem('token');

      const payload = {
        clientId: selectedTicket.client?.clientId || "",
        carId: selectedTicket.car?.carId || "",
        operatorId: userId || "",
        budgetItems: [],
        totals: {
          totalExclIva: "0.00",
          totalIva: "0.00",
          totalInclIva: "0.00"
        },
        ticket_id: selectedTicket.id
      };
  
      // Faz a requisição POST para criar o orçamento
      const response = await axios.post(`${API_BASE_URL}/api/budgets/new`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
  
      if (response.status === 201) {
        const newBudget = response.data;
        toast.success("Orçamento criado com sucesso!");
        // Atualiza os estados conforme necessário:
        setHasBudget(true);
        setIsBudgetPanelOpen(true);
        setBudgetData(newBudget);
      } else {
        throw new Error("Erro ao criar orçamento.");
      }
    } catch (error) {
      console.error("Erro ao criar orçamento:", error);
      toast.error(error.response?.data?.error || "Erro ao criar orçamento.");
    }
  };

  const handleCloseTicket = async () => {
    if (!selectedTicket) {
      toast.error(t("No ticket selected."));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/close`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to close the ticket.");
      }
  
      toast.success(t("Ticket closed successfully."));

      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        status: 4,
      }));

      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id ? { ...ticket, status: 4, statusTranslation: t("Finished"), statusClassName: "finished" } : ticket
        )
      );
    } catch (error) {
      console.error("Erro ao fechar o ticket:", error);
      toast.error(t("Error closing ticket. Please try again."));
    }
  };

  const isJsonString = (str) => {
    if (typeof str !== 'string') return false;
    const s = str.trim();
    return (s.startsWith('{') && s.endsWith('}')) || (s.startsWith('[') && s.endsWith(']'));
  };

  const renderMessage = (message) => {
    if (typeof message === 'string') {
      if (isJsonString(message)) {
        try {
          const parsedMessage = JSON.parse(message);
          switch (parsedMessage.key) {
            case 'ticket_created_from_budget':
              return t('ticket_created_from_budget', { budgetId: parsedMessage.budgetId });
            case 'warranty_ticket_generated_new':
              return t('warranty_ticket_generated_new', { fromTicketId: parsedMessage.fromTicketId});
            case 'warranty_ticket_generated_previous':
              return t('warranty_ticket_generated_previous', { newTicketId: parsedMessage.newTicketId});
            case 'user_reopen_ticket_message':
              return t('user_reopen_ticket_message', { user: parsedMessage.user });
            case 'user_closed_ticket_message':
              return t('user_closed_ticket_message', { user: parsedMessage.user });
            case 'mechanic_removed_message':
              return t('mechanic_removed_message', { mechanic: parsedMessage.mechanic });
            case 'mechanic_inserted_message':
              return t('mechanic_inserted_message', { mechanic: parsedMessage.mechanic });
            case 'mechanic_restarted_message':
              return t('mechanic_restarted_message', { mechanic: parsedMessage.mechanic });
            case 'mechanic_started_message':
              return t('mechanic_started_message', { mechanic: parsedMessage.mechanic });
            case 'mechanic_paused_message':
              return t('mechanic_paused_message', { mechanic: parsedMessage.mechanic });
            case 'mechanic_finished_message':
              return t('mechanic_finished_message', { 
                mechanic: parsedMessage.mechanic, 
                totalTime: parsedMessage.totalTime 
              });
            case 'user_restarted_message':
              return t('user_restarted_message', { user: parsedMessage.user });
            case 'user_started_message':
              return t('user_started_message', { user: parsedMessage.user });
            case 'user_paused_message':
              return t('user_paused_message', { user: parsedMessage.user });
            case 'user_finished_message':
              return t('user_finished_message', { user: parsedMessage.user, totalTime: parsedMessage.totalTime });
            case 'ticket_closed_successfully':
              return t('ticket_closed_successfully');
            case 'ticket_status_changed':
              return t('ticket_status_changed', { status: parsedMessage.status, user: parsedMessage.user });
            default:
              return message;
          }
        } catch (error) {
          console.error('Erro ao analisar mensagem JSON:', error);
          return message; // Retorna a mensagem original se não for JSON válido
        }
      }
      return message;
    }
    return message; // Retorna a mensagem original se não for uma string
  };

  // Função para determinar o estado atual do timer
  const getCurrentTimerState = () => {
    if (!timer) {
      return 'idle'; // Nenhum timer encontrado
    }

    if (timer.is_running) {
      return 'running'; // Timer em execução
    } else if (!timer.is_running && timer.total_time > 0) {
      return 'paused'; // Timer pausado
    }
    return 'idle'; // Estado padrão
  };

  const timerState = getCurrentTimerState();

  // Função para renderizar os botões de controle do timer
  const renderTimerControls = () => {

    switch (timerState) {
      case 'idle': // Nenhum timer em execução
        return (
          <button
            className="btn btn-success space"
            onClick={() => handleTimerAction('start')}
          >
            ▶
          </button>
        );
      case 'running': // Timer em execução
        return (
          <>
            <button
              className="btn btn-warning space"
              onClick={() => handleTimerAction('pause')}
            >
              ⏸
            </button>
          </>
        );
      case 'paused': // Timer pausado
        return (
          <>
            <button
              className="btn btn-primary space"
              onClick={() => handleTimerAction('resume')}
            >
              ▶
            </button>
            <button
              className="btn btn-danger space"
              onClick={() => handleTimerAction('stop')}
            >
              ⏹
            </button>
          </>
        );
      default:
        return null;
    }
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    // Definir limite de tamanho
    const maxSize = 100 * 1024 * 1024; // 100MB
    if (file.size > maxSize) {
      toast.error(t("O arquivo excede o tamanho máximo permitido de 100MB."));
      return;
    }

    const fileType = file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : null;

    if (!fileType) {
      toast.error(t("File not supported."));
      return;
    }

    // Verificar a duração do vídeo, se aplicável
    if (fileType === 'video') {
      try {
        const videoURL = URL.createObjectURL(file);
        const video = document.createElement('video');

        const duration = await new Promise((resolve, reject) => {
          video.preload = 'metadata';
          video.src = videoURL;
          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            resolve(video.duration);
          };
          video.onerror = () => {
            reject(new Error("Falha ao carregar o vídeo."));
          };
        });

        if (duration > 180) { // 3 minutos
          toast.error(t("O vídeo excede a duração máxima de 3 minutos."));
          return;
        }
      } catch (error) {
        console.error("Erro ao verificar a duração do vídeo:", error);
        toast.error(t("Falha ao processar o vídeo."));
        return;
      }
    }

    setIsUploading(true);

    window.onbeforeunload = (e) => {
      if (isUploading) {
        e.preventDefault();
        e.returnValue = "Um upload está em andamento. Por favor, não recarregue a página.";
      }
    };

    // Gerar uma mensagem temporária com a pré-visualização
    const tempId = `temp_${Date.now()}`;
    const previewUrl = URL.createObjectURL(file);
    const tempMessage = {
      id: tempId,
      sender: userId,
      senderName: 'You',
      message: fileType === 'image' 
        ? `<div class="uploading-preview">
             <img src="${previewUrl}" alt="uploading" />
             <div class="spinner"></div>
           </div>` 
        : `<div class="uploading-preview">
             <video src="${previewUrl}" controls />
             <div class="spinner"></div>
           </div>`,
      created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
      uploading: true,
      fileType,
    };

    // Adicionar a mensagem temporária ao chat
    setChatMessages(prevMessages => [...prevMessages, tempMessage]);

    // Iniciar o upload do arquivo
    const formData = new FormData();
    formData.append('file', file); // Use sempre 'file' como nome do campo

    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/upload-file`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Erro ao fazer upload do ${fileType}.`);
      }

      const data = await response.json();
      const fileUrl = data.fileUrl;

      // Gerar o conteúdo da mensagem com a URL do arquivo
      const newMessageContent = fileType === 'image' 
        ? `<img src="${fileUrl}" alt="uploaded" class="chat-media" />` 
        : `<video src="${fileUrl}" controls class="chat-media"></video>`;

      // Atualizar a mensagem temporária com o conteúdo final
      setChatMessages(prevMessages => 
        prevMessages.map(msg => 
          msg.id === tempId ? { ...msg, message: newMessageContent, uploading: false } : msg
        )
      );
      
      // Enviar a mensagem para o backend para persistência
      handleSendMessage(newMessageContent);

      toast.success(t(`${fileType.charAt(0).toUpperCase() + fileType.slice(1)} enviato con successo.`));
    } catch (error) {
      console.error(`Error to upload ${fileType}:`, error);
      toast.error(t(`Error to upload ${fileType}.`));

      // Remover a mensagem temporária em caso de erro
      setChatMessages(prevMessages => prevMessages.filter(msg => msg.id !== tempId));
    } finally {
      // Desativa o estado de upload e remove o aviso de recarregamento
      setIsUploading(false);
      window.onbeforeunload = null;
    }
  };

  const handleRemoveMechanic = async () => {
    if (!selectedTicket) {
      toast.error(t("No ticket selected."));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/remove-mechanic`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t('Erro ao remover mecânico.'));
      }
  
      toast.success(t('Mechanic successfully removed.'));

      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        status: 1,
        mechanic_id: null,
        mechanic: null,
      }));

      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id 
            ? { 
                ...ticket, 
                status: 1, 
                statusTranslation: t("Not Assigned"), 
                statusClassName: "not-assigned",
                mechanic_id: null,
                mechanic: null
              } 
            : ticket
        )
      );
    } catch (error) {
      console.error('Erro ao remover mecânico:', error);
      toast.error(error.message || t('Erro ao remover mecânico.'));
    }
  };


  useEffect(() => {
    const fetchMechanics = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/mechanics`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error(t("Error fetching mechanics."));
        }
  
        const data = await response.json();
        setMechanics(data);
      } catch (error) {
        console.error("Error fetching mechanics:", error);
        toast.error(error.message || t("Error fetching mechanics."));
      }
    };
  
    fetchMechanics();
  }, [t]);

  const handleAssignMechanic = async () => {
    if (!assignedMechanic) {
      toast.error(t("Please select a mechanic."));
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/assign-mechanic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ mechanic_id: assignedMechanic.id }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t("Error assigning mechanic."));
      }
  
      toast.success(t("Mechanic assigned successfully."));
  
      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === selectedTicket.id
            ? { ...ticket, mechanic: assignedMechanic.name, mechanic_id: assignedMechanic.id, statusTranslation: t("Pending"), statusClassName: "pending", status: 2 }
            : ticket
        )
      );
  
      setSelectedTicket(prevTicket => ({
        ...prevTicket,
        mechanic: assignedMechanic.name,
        mechanic_id: assignedMechanic.id,
        statusTranslation: t("pending"), 
        statusClassName: "pending",
        status: 2
      }));
  
      setIsAssignModalOpen(false);
    } catch (error) {
      console.error("Error assigning mechanic:", error);
      toast.error(error.message || t("Error assigning mechanic."));
    }
  };

  const handleSendMessage = async (message) => {
    if (!message.trim()) {
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/tickets/${selectedTicket.id}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ message }),
      });
  
      if (!response.ok) {
        throw new Error('Erro ao enviar mensagem.');
      }
  
      const data = await response.json();
  
      const newMessage = {
        id: data.id,
        sender: userId,
        senderName: 'You',
        message,
        created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
      };
  
      setChatMessages((prevMessages) => [...prevMessages, newMessage]);
  
      // Reseta o estado de primeira vez no chat
      if (isFirstChat) setIsFirstChat(false);
  
      // Atualiza o conteúdo do ticket selecionado na lista de tickets
      const updatedTickets = tickets.map((ticket) =>
        ticket.id === selectedTicket.id
          ? { ...ticket, content: [...(ticket.content || []), newMessage] }
          : ticket
      );
  
      setTickets(updatedTickets);
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };

  const handleMediaClick = (url, type) => {
    setMediaToView({ url, type });
    setIsMediaModalOpen(true);
  };

  // Estado para menu de contexto
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    message: null, // Mensagem selecionada
  });

  // Estado para modal de exclusão
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Referência para detectar clique fora do menu
  const contextMenuRef = useRef(null);

  const handleContextMenu = (e, message) => {
    e.preventDefault();
  
    // Se a mensagem não é do próprio usuário, não exibimos o menu
    //if (message.sender !== userId) return;
    // Converta msg.sender para number
    const senderId = parseInt(message.sender, 10);
    if (senderId === userId) {
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        message: message,
      });
    }
  };

  const handleLongPress = (message, e) => {
    e.preventDefault();
  
    // Se a mensagem não é do próprio usuário, não exibimos o menu
    if (message.sender !== userId) return;
  
    const touch = e.touches[0];
    setContextMenu({
      visible: true,
      x: touch.clientX,
      y: touch.clientY,
      message: message,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        setContextMenu((prev) => ({ ...prev, visible: false }));
      }
    };
  
    const handleScroll = () => {
      setContextMenu((prev) => ({ ...prev, visible: false }));
    };
  
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('scroll', handleScroll);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const confirmDelete = async () => {
    if (!contextMenu.message) {
      toast.error('No message selected.');
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        `${API_BASE_URL}/api/tickets/${selectedTicket.id}/messages/${contextMenu.message.id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Status da resposta:', response.status); // <== Adicione este log
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete message.');
      }
  
      setChatMessages((prev) => prev.filter((m) => m.id !== contextMenu.message.id));
      toast.success('Message deleted successfully.');
    } catch (error) {
      console.error('Erro ao excluir a mensagem:', error);
      toast.error(error.message || 'Error deleting the message.');
    } finally {
      setIsDeleteModalOpen(false);
      setContextMenu((prev) => ({ ...prev, visible: false }));
    }
  };

  const [hoveredTicketId, setHoveredTicketId] = useState(null); // Para rastrear o ticket sendo "hovered"
  const [isDeleteTicketModalOpen, setIsDeleteTicketModalOpen] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  const openDeleteModal = (ticketId) => {
    setTicketToDelete(ticketId);
    setIsDeleteTicketModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteTicketModalOpen(false);
    setTicketToDelete(null);
    setDeleteConfirmation('');
  };

  const handleDeleteTicket = async () => {
    if (deleteConfirmation !== 'elimina definitivamente') {
      toast.error(t('Please type "elimina definitivamente" to confirm.'));
      return;
    }

    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.put(`${API_BASE_URL}/api/tickets/${ticketToDelete}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success(t('Ticket deleted successfully.'));
        setTickets(prevTickets => prevTickets.filter(ticket => ticket.id !== ticketToDelete));
        closeDeleteModal();
      } else {
        toast.error(t('Error deleting ticket.'));
      }
    } catch (error) {
      console.error('Error deleting ticket:', error);
      toast.error(t('Error deleting ticket.'));
    }
  };

  const convertMinutesToHours = (minutes) => {
    const totalMinutes = Number(minutes);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${hours}h ${remainingMinutes > 0 ? remainingMinutes + 'm' : ''}`;
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="page-wrapper" style={{ height: "100vh" }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div style={{ height: "100%" }}>
        <div className="tickets-wrapper d-flex" style={{ height: "100%" }}>
        {!selectedTicket ? (
          <div className="tickets-sidebar" style={{ flex: "0 0 100%", overflowY: "auto" }}>
            <div className="tickets-sidebar-header">
              <div className="input-group input-group-sm mt-3">
                <span className="input-group-text bg-transparent">
                  <i className="bx bx-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Search by client name, phone, car model, brand, or plate")}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    localStorage.setItem("ticketsSearchQuery", e.target.value);
                    setCurrentPage(1);
                  }}
                />
                <div role="group" aria-label="Filter Status">
                  <button
                    type="button"
                    className={`filter-button mb-2 all ${filterStatus === 'All' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('All')}
                  >
                    {t("All Tickets")}
                  </button>
                  {userRole !== 'mechanic' && (
                    <button
                      type="button"
                      className={`filter-button mb-2 not-assigned ${filterStatus === 'Not-Assigned' ? 'active' : ''}`}
                      onClick={() => setFilterStatus('Not-Assigned')}
                    >
                      {t("Not Assigned")}
                    </button>
                  )}
                  <button
                    type="button"
                    className={`filter-button mb-2 pending ${filterStatus === 'Pending' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('Pending')}
                  >
                    {t("Pending")}
                  </button>
                  <button
                    type="button"
                    className={`filter-button mb-2 working ${filterStatus === 'Working' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('Working')}
                  >
                    {t("Working")}
                  </button>
                  <button
                    type="button"
                    className={`filter-button mb-2 finished ${filterStatus === 'Finished' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('Finished')}
                  >
                    {t("Finished")}
                  </button>
                </div>
              </div>
            </div>

            <div className="tickets-list mt-4">
              <div className="list-group list-group-flush">
                {currentTickets.length > 0 ? (
                  currentTickets
                    .sort((a, b) => b.id - a.id) // Ordena do maior para o menor (último ao primeiro)
                    .map((ticket) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        key={ticket.id}
                        href="#"
                        className={`list-group-item ${
                          selectedTicket?.id === ticket.id ? "active" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/tickets/${ticket.id}`);
                        }}
                        onMouseEnter={() => setHoveredTicketId(ticket.id)} // Altera o estado de hover
                        onMouseLeave={() => setHoveredTicketId(null)} // Limpa o estado de hover
                        style={{ position: 'relative' }}
                      >
                        <div className="d-flex">
                          <div className="ticket-user-badge">
                            #{ticket.id}
                          </div>
                          <div className="flex-grow-1 ms-2">
                            <h6 className="mb-0 ticket-title">{ticket.title}</h6>
                            <p className="mb-0 ticket-msg">
                              {ticket.message.slice(0, 30)}...
                            </p>
                            <p className={`ticket-status ${ticket.statusClassName}`}>
                              {ticket.statusTranslation}
                            </p>
                          </div>
                          <div className="ticket-time">{adjustTimePlusOneHour(ticket.time)}</div>
                          {userRole === 'admin' || userRole === 'receptionist' ? (
                            hoveredTicketId === ticket.id && (
                              <button
                                className="btn btn-danger btn-sm"
                                style={{ position: 'absolute', right: '10px', top: '10px' }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Impede que o click no botão abra o ticket
                                  openDeleteModal(ticket.id); // Abre o modal de exclusão
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            )
                          ) : null}
                        </div>
                      </a>
                    ))
                ) : (
                  <div className="alert alert-info text-center">{t("No tickets found.")}</div>
                )}
              </div>

              {/* Controles de Paginação */}
              {totalPages > 1 && (
                <div className="pagination-controls mt-3 d-flex justify-content-center align-items-center">
                  {/* Botão "Previous" */}
                  <button
                    className="btn btn-sm btn-outline-primary me-2"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    {t('Previous')}
                  </button>
                  
                  {/* Botões de Página com Janela Deslizante */}
                  <div className="page-numbers d-flex">
                    {getPageNumbers().map((page) => (
                      <button
                        key={page}
                        className={`btn btn-sm me-1 ${currentPage === page ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => paginate(page)}
                      >
                        {page}
                      </button>
                    ))}
                  </div>
                  
                  {/* Botão "Next" */}
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    {t('Next')}
                  </button>
                </div>
              )}
            </div>
          </div>
          ) : (
            <>
          {/* Main Ticket Details Section */}
          <div className="tickets-main" style={{ flex: "1", display: "flex", flexDirection: "column"}}>
            <div className="tickets-header d-flex flex-column flex-md-row align-items-center justify-content-between">
              {/* Botão Voltar */}
              <button className="btn btn-secondary mb-2 mb-md-0" onClick={() => setSelectedTicket(null)}>
                &larr; {t("Back to List")}
              </button>

              {/* Espaço vazio para alinhamento */}
              <div style={{ width: "100px" }}></div>

              {/* Botões de Ações */}
              <div className="d-flex flex-column flex-md-row gap-2">

                {(userRole === "admin" || userRole === "receptionist") && (
                  <>
                    {selectedTicket.mechanic && (
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={handleRemoveMechanic}
                      >
                        {t("Remove Mechanic")}
                      </button>
                    )}
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={openDueDateModal}
                    >
                      {t("Set Due Date")}
                    </button>
                  </>
                )}

                {(userRole === "admin" || userRole === "receptionist") && (selectedTicket.status === 1 || selectedTicket.status === 2 || selectedTicket.status === 3) && (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={handleCloseTicket}
                  >
                    {t("Close Ticket")}
                  </button>
                )}

                {(userRole === "admin" || userRole === "receptionist") && selectedTicket.status === 4 && (
                  <button
                    className="btn btn-success btn-sm"
                    onClick={openReopenModal}
                  >
                    {t("Reopen Ticket")}
                  </button>
                )}

                {!selectedTicket.mechanic ? (
                  <button className="btn btn-primary btn-sm" onClick={() => setIsAssignModalOpen(true)}>
                    {t('Assign Mechanic')}
                  </button>
                ) : (
                  <></>
                )}

                {hasBudget ? (
                  <button className="btn btn-primary btn-sm" onClick={() => setIsBudgetPanelOpen(!isBudgetPanelOpen)}>
                    {isBudgetPanelOpen ? t('Close Budget') : t('Open Budget')}
                  </button>
                ) : (
                  <button className="btn btn-success" onClick={handleCreateBudget}>
                    {t('Create Budget')}
                  </button>
                )}

              </div>
            </div>

            {/* Exibir os Timers */}

            <div className="ticket-timer-container">
              <div className="timer-section">
                <Timer
                  totalTime={timer ? timer.total_time : 0}
                  isRunning={timer ? timer.is_running : false}
                  startTime={timer ? timer.start_time : null}
                />
              </div>
              <div className="time-info-section">
                <div className="time-info-item">
                  <span className="time-label">{t("Estimated Time")}:</span>
                  <span className="time-value">
                    {selectedTicket.estimatedTime ? convertMinutesToHours(selectedTicket.estimatedTime) : 
                      <div className="estimated-time-section">
                      {["admin", "receptionist"].includes(userRole) ? (
                        <button 
                          className="btn btn-primary" 
                          onClick={() => navigate(`/calendar?booking=mechanic&fromTicket=${selectedTicket.id}`)}
                          
                        >
                          {t('Define Time')}
                        </button>
                      ) : (
                        <span>{t('Not Estimated')}</span>
                      )}
                    </div>
                  }
                  </span>
                </div>
                {renderTimerControls()}
              </div>
            </div>

            {selectedTicket ? (
              <>
                <div className="tickets-header d-flex align-items-center justify-content-between">
                  <div>
                    <h4 className="mb-1 font-weight-bold">
                      {selectedTicket.car.brand} {selectedTicket.car.model} - {selectedTicket.client.name}
                    </h4>
                    <p className={`mb-0 ticket-status ${mapStatus(selectedTicket.status).className}`}>
                      {mapStatus(selectedTicket.status).translation}
                    </p>
                    <p className="text-muted mb-0">
                      <strong>{t("Assigned Mechanic")}:</strong>{" "}
                      {selectedTicket.mechanic ? selectedTicket.mechanic : t("Not Assigned")}
                    </p>
                    <p className="text-muted mb-0">
                      <strong>{t("Opening Date")}:</strong> {adjustTimePlusOneHour(selectedTicket.dateOpened) || t("Not Available")}
                    </p>
                    <p className="text-muted mb-0">
                      <strong>{t("Due Date")}:</strong> {selectedTicket.due_date ? moment(selectedTicket.due_date).format('DD-MM-YYYY HH:mm:ss') : t("No due date")}
                    </p>
                  </div>
                </div>

                <div className={`tickets-content ${contentExpanded ? 'expanded' : ''}`} style={{ flex: "1", overflowY: "auto" }} ref={chatContainerRef}>
                {/* Exibe os dados do cliente e do veículo no topo do chat */}
                {selectedTicket && (
                  <div className="chat-header-msg">
                    <div className="chat-client-details">
                      <h5>{t("Client Information")}</h5>
                      <p><strong>{t("Name")}:</strong> {selectedTicket.client.name}</p>
                      <p><strong>{t("Phone")}:</strong> {selectedTicket.client.phone}</p>
                      {(userRole === "admin" && selectedTicket?.is_warranty === 0) && (
                        <button className="btn btn-info" onClick={handleGarantiaTicket}>
                          {t("Warranty Ticket")}
                        </button>
                      )}
                    </div>
                    <div className="chat-vehicle-details">
                      <h5>{t("Vehicle Information")}</h5>
                      <p><strong>{t("Brand/Model")}:</strong> {selectedTicket.car.brand} {selectedTicket.car.model}</p>
                      <p><strong>{t("Plate")}:</strong> {selectedTicket.car.plate}</p>
                      <p><strong>{t("Mileage")}:</strong> {selectedTicket.car.mileage || t("Not Available")}</p>
                    </div>
                    
                    <hr />
                  </div>
                )}
                
                {/* Renderização das mensagens do chat */}
                {chatMessages.length > 0 ? (
                  chatMessages.map((msg, index) => {
                    const adjustedTime = adjustTimePlusOneHour(msg.created_at);
                    const isSystemMessage = msg.sender === 'system';
                    return isSystemMessage ? (
                      <div key={index} className="system-message">
                        <div className="system-message-header">
                          <span className="system-message-time">
                            {adjustedTime || t("No date available")}
                          </span>
                        </div>
                        <div className="system-message-content">
                          {renderMessage(msg.message)}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className={`chat-message-container ${
                          Number(msg.sender) === userId ? "chat-right" : "chat-left"
                        }`}
                        onContextMenu={(e) => handleContextMenu(e, msg)}
                        onTouchStart={(e) => {
                          let timer;
                          const handleTouchStart = (ev) => {
                            // 700ms de toque para acionar o menu
                            timer = setTimeout(() => handleLongPress(msg, ev), 700);
                          };
                          const handleTouchEnd = () => clearTimeout(timer);
                          handleTouchStart(e);
                          handleTouchEnd();
                        }}
                      >
                        <div className="chat-message-header">
                          <strong>{Number(msg.sender) === userId ? t("You") : msg.senderName}</strong>
                          <span className={
                              Number(msg.sender) === userId ? "chat-time-right" : "chat-time"
                            }
                          >
                            {adjustedTime || t("No date available")}
                          </span>
                        </div>
                        <p
                        className="chat-message"
                        dangerouslySetInnerHTML={{
                          __html: renderMessage(msg.message).replace(/\n/g, "<br>"),
                        }}
                        onClick={(e) => {
                          const target = e.target;
                          if (target.tagName === 'IMG' || target.tagName === 'VIDEO') {
                            const url = target.src;
                            const type = target.tagName === 'IMG' ? 'image' : 'video';
                            handleMediaClick(url, type);
                          }
                        }}
                      ></p>
                      </div>
                    );
                  })
                ) : (
                  <div className="alert alert-info">{t("No messages for this ticket.")}</div>
                )}
              </div>
              
              <div className="chat-section">
                <div className="chat-input-container">
                  {/* Botão da Câmera */}
                  <label htmlFor="camera-upload" className="upload-button">
                    <FontAwesomeIcon icon={faCamera} />
                    <input
                      type="file"
                      id="camera-upload"
                      className="file-input"
                      accept="image/*"
                      capture="environment"
                      onChange={(e) => handleFileUpload(e.target.files[0], 'image')}
                    />
                  </label>

                  {/* Novo Botão de Upload de Arquivos */}
                  <label htmlFor="file-upload" className="upload-button">
                    <FontAwesomeIcon icon={faPaperclip} />
                    <input
                      type="file"
                      id="file-upload"
                      className="file-input"
                      accept="image/*,video/*"
                      onChange={(e) => handleFileUpload(e.target.files[0], 'file')}
                    />
                  </label>

                  {/* Input de Texto */}
                  <input
                    type="text"
                    id="chat-input"
                    className="form-control chat-input"
                    placeholder={t("Type a message...")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSendMessage(e.target.value);
                        e.target.value = "";
                      }
                    }}
                    autoComplete="off"
                  />

                  {/* Botão de Enviar */}
                  <button
                    className="btn send-button"
                    onClick={() => {
                      const input = document.getElementById("chat-input");
                      if (input && input.value.trim()) {
                        handleSendMessage(input.value.trim());
                        input.value = "";
                      } else {
                        toast.error(t("Message cannot be empty."));
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
              </>
            ) : (
              <div className="alert alert-info text-center mt-4">
                {t("Select a ticket to view details.")}
              </div>
            )}
          </div>

          {isBudgetPanelOpen && (
            <BudgetPanel
              ticketId={selectedTicket?.id}
              userRole={userRole}
              items={budgetItems}
              onItemsChange={setBudgetItems}
              onClose={closeBudget}
            />
          )}
          </>
        )}

        {contextMenu.visible && (
          <ul
            ref={contextMenuRef}
            className="context-menu"
            style={{ top: contextMenu.y, left: contextMenu.x }}
          >
            <li onClick={() => setIsDeleteModalOpen(true)}>
              {t('Delete')}
            </li>
          </ul>
        )}

        {isUploading && (
          <div className="upload-overlay">
            <div className="upload-message">
              <p>{t('upload_in_progress')}</p>
              <center><div className="spinner"></div></center>
            </div>
          </div>
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          contentLabel="Confirm Delete"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>{t('Confirm Deletion')}</h2>
          <p>{t('Are you sure you want to delete this message?')}</p>
          <button className="btn btn-primary" onClick={confirmDelete}>{t('Yes, Delete')}</button>
          <button className="btn btn-secondary space" onClick={() => setIsDeleteModalOpen(false)}>{t('Cancel')}</button>
         </Modal>
 
         <Modal
           isOpen={isGarantiaModalOpen}
           onRequestClose={() => setIsGarantiaModalOpen(false)}
           contentLabel={t("Confirm Warranty Ticket")}
           className="Modal"
           overlayClassName="Overlay"
         >
           <h2>{t("Confirm Warranty Ticket")}</h2>
           <p>{t("Are you sure you want to generate a warranty ticket?")}</p>
           <button className="btn btn-primary" onClick={confirmGarantiaTicket}>
             {t("Yes, generate")}
           </button>
           <button className="btn btn-secondary space" onClick={() => setIsGarantiaModalOpen(false)}>
             {t("Cancel")}
           </button>
         </Modal>

        {/* Modal para Atribuição de Mecânico */}
        <Modal
          isOpen={isAssignModalOpen}
          onRequestClose={() => setIsAssignModalOpen(false)}
          contentLabel="Atribuir Mecânico"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>{t('Assign Mechanic')}</h2>
          <div className="mb-3">
            <Select
              options={mechanics.map(mech => ({ value: mech.id, label: mech.name }))}
              value={assignedMechanic ? { value: assignedMechanic.id, label: assignedMechanic.name } : null}
              onChange={(selectedOption) => {
                const mechanic = mechanics.find(mech => mech.id === selectedOption.value);
                setAssignedMechanic(mechanic);
              }}
              placeholder={mechanics.length > 0 ? t('Select Mechanic') : t('No mechanics available')}
              isDisabled={mechanics.length === 0}
              noOptionsMessage={() => t('No mechanics available')}
            />
          </div>
          <button className="btn btn-primary" onClick={handleAssignMechanic}>{t('Assign')}</button>
          <button className="btn btn-secondary space" onClick={() => setIsAssignModalOpen(false)}>{t('Cancel')}</button>
        </Modal>

        {/* Modal de Confirmação para Reabrir Ticket */}
        <Modal
          isOpen={isReopenModalOpen}
          onRequestClose={closeReopenModal}
          contentLabel="Reopen Ticket Confirmation"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>{t("Reopen Ticket")}</h2>
          <p>{t("Are you sure you want to reopen this ticket?")}</p>
          <div className="d-flex justify-content-end gap-2">
            <button className="btn btn-secondary" onClick={closeReopenModal}>
              {t("No, Cancel")}
            </button>
            <button className="btn btn-success" onClick={handleReopenTicket}>
              {t("Yes, Reopen")}
            </button>
          </div>
        </Modal>

        <Modal 
          isOpen={isDeleteTicketModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Confirm Delete"
          className="Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-header">
            <h5 className="title">{t('Confirm Deletion')}</h5>
          </div>
          <div className="modal-body">
            <p>{t('To permanently delete this ticket, type')} <strong>{t('elimina definitivamente')}</strong> {t('below')}:</p>
            <input
              type="text"
              className="form-control"
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeDeleteModal}>{t('Cancel')}</button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDeleteTicket}
              disabled={deleteConfirmation !== 'elimina definitivamente'}
            >
              {t('Delete Ticket')}
            </button>
          </div>
        </Modal>

        {/* Modal para Definir Data de Entrega */}
        <Modal
          isOpen={isDueDateModalOpen}
          onRequestClose={closeDueDateModal}
          contentLabel={t("Set Due Date")}
          className="due-date-modal"
          overlayClassName="due-date-overlay"
        >
          <h2>{t("Set Due Date")}</h2>
          <div className="form-group">
            <label htmlFor="dueDate">{t("Select Due Date")}</label>
            <DatePicker
              selected={newDueDate}
              onChange={(date) => setNewDueDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-YYYY HH:mm:ss"
              className="form-control"
              locale="it"
              timeCaption="Ora"
              minDate={new Date()}
            />
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-secondary me-2" onClick={closeDueDateModal}>
              {t("Cancel")}
            </button>
            <button className="btn btn-primary" onClick={saveDueDate}>
              {t("Save")}
            </button>
          </div>
        </Modal>

        {isMediaModalOpen && mediaToView && (
          <Modal
            isOpen={isMediaModalOpen}
            onRequestClose={() => setIsMediaModalOpen(false)}
            contentLabel="Visualização de Mídia"
            className="media-modal-content"
            overlayClassName="media-modal-overlay"
            shouldCloseOnOverlayClick={true}
          >
            <button
              className="media-modal-close"
              onClick={() => setIsMediaModalOpen(false)}
              aria-label="Fechar Modal"
            >
              &times;
            </button>
            {console.log('Media to View:', mediaToView)} {/* Adicione este log */}
            {mediaToView.type === 'image' ? (
              <>
              <center>
                <img 
                  src={mediaToView.url}
                  alt="Visualização Completa"
                  onClick={toggleImageWidth}
                  style={{ width: imageWidth, cursor: 'zoom-in', transition: 'width 0.3s ease' }}
                />
              </center>
              </>
            ) : (
              <video src={mediaToView.url} controls />
            )}
          </Modal>
        )}
        
        </div>
      </div>
    </div>
  );
}

export default withAuth(Tickets, ['admin', 'mechanic', 'receptionist']);