import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import Select from 'react-select';
import './AutoRegister.css';
import { useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import 'jspdf-autotable';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AutoRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [existingClients, setExistingClients] = useState([]);
  const [clientCars, setClientCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);

  const countryOptions = [
    // Europa
    { value: 'it', label: '🇮🇹 Italia (+39)', dialCode: '+39' },
    { value: 'es', label: '🇪🇸 Spagna (+34)', dialCode: '+34' },
    { value: 'gb', label: '🇬🇧 Regno Unito (+44)', dialCode: '+44' },
    { value: 'fr', label: '🇫🇷 Francia (+33)', dialCode: '+33' },
    { value: 'ie', label: '🇮🇪 Irlanda (+353)', dialCode: '+353' },
    { value: 'pt', label: '🇵🇹 Portogallo (+351)', dialCode: '+351' },
    { value: 'nl', label: '🇳🇱 Paesi Bassi (+31)', dialCode: '+31' },
    { value: 'be', label: '🇧🇪 Belgio (+32)', dialCode: '+32' },
    { value: 'de', label: '🇩🇪 Germania (+49)', dialCode: '+49' },
    { value: 'ch', label: '🇨🇭 Svizzera (+41)', dialCode: '+41' },
    { value: 'se', label: '🇸🇪 Svezia (+46)', dialCode: '+46' },
    { value: 'no', label: '🇳🇴 Norvegia (+47)', dialCode: '+47' },
    { value: 'dk', label: '🇩🇰 Danimarca (+45)', dialCode: '+45' },
    { value: 'pl', label: '🇵🇱 Polonia (+48)', dialCode: '+48' },
    { value: 'at', label: '🇦🇹 Austria (+43)', dialCode: '+43' },
    { value: 'gr', label: '🇬🇷 Grecia (+30)', dialCode: '+30' },
    { value: 'fi', label: '🇫🇮 Finlandia (+358)', dialCode: '+358' },
    { value: 'cz', label: '🇨🇿 Repubblica Ceca (+420)', dialCode: '+420' },
    { value: 'hu', label: '🇭🇺 Ungheria (+36)', dialCode: '+36' },
    { value: 'ro', label: '🇷🇴 Romania (+40)', dialCode: '+40' },
    { value: 'bg', label: '🇧🇬 Bulgaria (+359)', dialCode: '+359' },
    { value: 'sk', label: '🇸🇰 Slovacchia (+421)', dialCode: '+421' },
    
    // África
    { value: 'za', label: '🇿🇦 Sud Africa (+27)', dialCode: '+27' },
    { value: 'eg', label: '🇪🇬 Egitto (+20)', dialCode: '+20' },
    { value: 'ng', label: '🇳🇬 Nigeria (+234)', dialCode: '+234' },
    { value: 'ke', label: '🇰🇪 Kenya (+254)', dialCode: '+254' },
    { value: 'ma', label: '🇲🇦 Marocco (+212)', dialCode: '+212' },
    { value: 'dz', label: '🇩🇿 Algeria (+213)', dialCode: '+213' },
    { value: 'tn', label: '🇹🇳 Tunisia (+216)', dialCode: '+216' },
    { value: 'gh', label: '🇬🇭 Ghana (+233)', dialCode: '+233' },
    { value: 'et', label: '🇪🇹 Etiopia (+251)', dialCode: '+251' },
    { value: 'ug', label: '🇺🇬 Uganda (+256)', dialCode: '+256' },
    { value: 'ci', label: '🇨🇮 Costa d\'Avorio (+225)', dialCode: '+225' },
    { value: 'sn', label: '🇸🇳 Senegal (+221)', dialCode: '+221' },
    { value: 'tz', label: '🇹🇿 Tanzania (+255)', dialCode: '+255' },
    { value: 'cm', label: '🇨🇲 Camerun (+237)', dialCode: '+237' },
    { value: 'zw', label: '🇿🇼 Zimbabwe (+263)', dialCode: '+263' },
    { value: 'ly', label: '🇱🇾 Libia (+218)', dialCode: '+218' },
    { value: 'ml', label: '🇲🇱 Mali (+223)', dialCode: '+223' },
    { value: 'ao', label: '🇦🇴 Angola (+244)', dialCode: '+244' },
    
    // América do Norte
    { value: 'ca', label: '🇨🇦 Canada (+1)', dialCode: '+1' },
    { value: 'mx', label: '🇲🇽 Messico (+52)', dialCode: '+52' },
    { value: 'us', label: '🇺🇸 Stati Uniti (+1)', dialCode: '+1' },
    { value: 'cu', label: '🇨🇺 Cuba (+53)', dialCode: '+53' },
    { value: 'do', label: '🇩🇴 Repubblica Dominicana (+1 809/829/849)', dialCode: '+1' },
    { value: 'jm', label: '🇯🇲 Giamaica (+1 876)', dialCode: '+1' },
    { value: 'bs', label: '🇧🇸 Bahamas (+1 242)', dialCode: '+1' },
    { value: 'tt', label: '🇹🇹 Trinidad e Tobago (+1 868)', dialCode: '+1' },
    { value: 'bb', label: '🇧🇧 Barbados (+1 246)', dialCode: '+1' },
    { value: 'lc', label: '🇱🇨 Santa Lucia (+1 758)', dialCode: '+1' },
    { value: 'gd', label: '🇬🇩 Grenada (+1 473)', dialCode: '+1' },
    { value: 'vc', label: '🇻🇨 Saint Vincent e Grenadine (+1 784)', dialCode: '+1' },
    { value: 'ag', label: '🇦🇬 Antigua e Barbuda (+1 268)', dialCode: '+1' },
    { value: 'dm', label: '🇩🇲 Dominica (+1 767)', dialCode: '+1' },
    { value: 'kn', label: '🇰🇳 Saint Kitts e Nevis (+1 869)', dialCode: '+1' },
    { value: 'bm', label: '🇧🇲 Bermuda (+1 441)', dialCode: '+1' },

    // America do Sul
    { value: 'br', label: '🇧🇷 Brasil (+55)', dialCode: '+55' },
    
  ];

  // Lista de marcas de carros
  const initialCarBrands = [
    {
      value: 'Toyota',
      label: 'Toyota',
      models: ['Corolla', 'Camry', 'RAV4', 'Highlander', 'Prius', 'Tacoma', 'Yaris', 'Avalon', '4Runner', 'Sequoia', 'Tundra', 'Sienna', 'Land Cruiser', 'C-HR', 'Supra', 'Venza', 'Matrix', 'FJ Cruiser', 'Hilux', 'Fortuner', 'Innova', 'Etios'],
    },
    {
      value: 'Ford',
      label: 'Ford',
      models: ['Fiesta', 'Focus', 'Mustang', 'Explorer', 'Ranger', 'Edge', 'Escape', 'Expedition', 'Bronco', 'Bronco Sport', 'F-150', 'F-250', 'F-350', 'Transit', 'Transit Connect', 'EcoSport', 'Taurus', 'Fusion', 'Maverick', 'Galaxy', 'Mondeo', 'C-Max', 'S-Max', 'Kuga', 'Puma', 'Flex', 'Thunderbird', 'Cortina', 'Falcon', 'Courier', 'Probe', 'Ka', 'Everest', 'Territory'],
    },
    {
      value: 'Chevrolet',
      label: 'Chevrolet',
      models: ['Cruze', 'Malibu', 'Silverado', 'Tahoe', 'Equinox', 'Camaro', 'Impala', 'Trailblazer', 'Suburban', 'Traverse', 'Colorado', 'Blazer', 'Corvette', 'Spark', 'Bolt EV', 'Sonic', 'Avalanche', 'Captiva', 'Orlando', 'HHR', 'Monte Carlo', 'Uplander', 'Astro', 'LUV', 'Cobalt', 'Venture', 'Express'],
    },
    {
      value: 'Honda',
      label: 'Honda',
      models: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Fit', 'HR-V', 'Odyssey', 'Passport', 'Ridgeline', 'Insight', 'Element', 'Prelude', 'S2000', 'Clarity', 'Del Sol', 'CR-Z', 'Brio', 'Amaze', 'City', 'Jazz', 'Shuttle', 'Stream', 'Freed', 'Legend', 'Elysion', 'Acty', 'Beat'],
    },
    {
      value: 'Nissan',
      label: 'Nissan',
      models: ['Altima', 'Sentra', 'Rogue', 'Murano', 'Frontier', 'Leaf', 
              'Maxima', 'Pathfinder', 'Armada', 'Versa', 'Titan', 'Kicks', 
              'GT-R', 'Juke', 'Qashqai', '370Z', 'Navara', 'X-Trail', 
              'Patrol', 'Terrano', 'Magnite', 'Sunny', 'Bluebird', 
              'Stagea', 'Cube', 'March', 'Note', 'Micra'],
    },
    {
      value: 'Volkswagen',
      label: 'Volkswagen',
      models: ['Golf', 'Passat', 'Jetta', 'Tiguan', 'Atlas', 
              'Beetle', 'Polo', 'Arteon', 'Touareg', 'ID.4', 
              'ID.3', 'ID.5', 'ID.Buzz', 'Taos', 'Amarok', 
              'Caddy', 'California', 'Transporter', 'Multivan', 
              'Caravelle', 'Up!', 'Scirocco', 'Corrado', 'Fox', 
              'Sharan', 'Touran', 'Eos', 'Routan', 'CrossFox', 
              'Saveiro', 'Voyage', 'Gol', 'Virtus', 'T-Cross', 
              'Nivus', 'Teramont'],
    },
    {
      value: 'BMW',
      label: 'BMW',
      models: ['1 Series', '2 Series', '3 Series', '4 Series', '5 Series', '6 Series', '7 Series', 
              '8 Series', 'X1', 'X2', 'X3', 'X4', 'X5', 'X6', 'X7', 'Z3', 'Z4', 'i3', 'i4', 
              'i7', 'i8', 'iX', 'iX3', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M8', 'X5 M', 
              'X6 M', 'X3 M', 'X4 M', 'Z8', 'ActiveHybrid 3', 'ActiveHybrid 5', 'ActiveHybrid 7', 
              '2 Series Active Tourer', '2 Series Gran Tourer', 'Gran Coupe', 'Gran Turismo'],
    },
    {
      value: 'Mercedes-Benz',
      label: 'Mercedes-Benz',
      models: ['C-Class', 'E-Class', 'GLC', 'GLE', 'A-Class', 'S-Class', 'GLA', 'GLS', 
              'CLS', 'AMG GT', 'G-Class', 'SL', 'SLK', 'CLA', 'B-Class', 'V-Class', 
              'Sprinter', 'Maybach S-Class', 'EQC', 'EQS', 'EQA', 'EQB', 'EQV'],
    },
    {
      value: 'Audi',
      label: 'Audi',
      models: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 
              'Q2', 'Q3', 'Q4', 'Q5', 'Q7', 'Q8', 
              'TT', 'TTS', 'TT RS', 
              'R8', 'RS3', 'RS4', 'RS5', 'RS6', 'RS7', 
              'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 
              'e-tron', 'e-tron GT', 'Q4 e-tron', 'Q8 e-tron', 
              'Allroad', 'Cabriolet', 'Coupe', 'Sportback', 'Avant'],
    },
    {
      value: 'Hyundai',
      label: 'Hyundai',
      models: ['Elantra', 'Sonata', 'Santa Fe', 'Tucson', 'Kona', 
              'Palisade', 'Veloster', 'Ioniq', 'Genesis', 
              'Accent', 'Creta', 'Venue', 'Verna', 'Getz', 
              'Atos', 'Terracan', 'Starex', 'Galloper', 'Matrix', 
              'Azera', 'Entourage', 'Grandeur', 'Equus', 'Trajet', 
              'Scoupe', 'H1', 'H100', 'Nexo'],
    },
    {
      value: 'Kia',
      label: 'Kia',
      models: ['Rio', 'Sportage', 'Sorento', 'Optima', 'Stinger', 
              'Seltos', 'Carnival', 'Soul', 'Telluride', 'Forte', 
              'Picanto', 'Ceed', 'Stonic', 'Niro', 'Carens', 
              'EV6', 'K900', 'Mohave', 'Rondo', 'Cadenza', 
              'Borrego', 'Sedona', 'Cerato', 'ProCeed', 'K5', 
              'K8', 'K3', 'XCeed', 'Spectra', 'Shuma'],
    },
    {
      value: 'Fiat',
      label: 'Fiat',
      models: ['500', 'Panda', 'Tipo', 'Punto', 'Doblo', 'Uno', 
              'Palio', 'Siena', 'Linea', 'Bravo', 'Brava', 
              'Marea', 'Strada', 'Toro', 'Fiorino', 'Ducato', 
              'Freemont', 'Multipla', 'Stilo', 'Idea', 'Albea', 
              'Tempra', 'Argenta', 'Croma', 'Ritmo', 'Regata', 
              'Sedici', 'Qubo', 'Ulysse', 'Seicento', 'Cinquecento', 
              'X1/9', '124 Spider', '850', '600', '1100', 
              'Campagnola', 'Barchetta', 'Topolino'],
    },
    {
      value: 'Peugeot',
      label: 'Peugeot',
      models: ['208', '308', '3008', '5008', '2008', '206', 
              '207', '405', '406', '407', '508', '607', 
              '806', '807', '1007', '3008 Hybrid', '4008', 
              'Partner', 'Boxer', 'Expert', 'Traveller', 'Rifter'],
    },
    {
      value: 'Renault',
      label: 'Renault',
      models: ['Clio', 'Megane', 'Captur', 'Kadjar', 'Talisman', 'Scenic', 
              'Kangoo', 'Trafic', 'Master', 'Zoe', 'Twizy', 'Fluence', 
              'Espace', 'Laguna', 'Modus', 'Latitude', 'Koleos', 
              'Arkana', 'Duster', 'Sandero', 'Logan', 'Symbol', 
              'Wind', 'Vel Satis', 'Avantime', 'Alaskan', '4', 
              '5', '9', '11', '21', '25', 'Fuego', 'Safrane', 'Express'],
    },
    {
      value: 'Opel',
      label: 'Opel',
      models: ["Astra", "Corsa", "Insignia", "Mokka", "Crossland", "Grandland", 
              "Zafira", "Vivaro", "Combo", "Movano", "Adam", "Karl", 
              "Meriva", "Antara", "Vectra", "Signum", "Omega", "Tigra", 
              "Agila", "Frontera", "Ampera", "Monza", "Kadett", "Ascona", 
              "Calibra", "Rekord", "Senator", "Diplomat", "GT", "Manta"],
    },
    {
      value: 'Skoda',
      label: 'Skoda',
      models: ["Fabia", "Octavia", "Superb", "Karoq", "Kodiaq", "Scala", 
              "Kamiq", "Enyaq iV", "Rapid", "Citigo", "Roomster", 
              "Yeti", "Felicia", "Favorit", "Forman", "120", "130", 
              "110", "100", "Citigo-e iV"],
    },
    {
      value: 'Mazda',
      label: 'Mazda',
      models: ["Mazda2", "Mazda3", "Mazda6", "CX-3", "CX-30", "CX-5", "CX-50",
              "CX-60", "CX-90", "MX-5 Miata", "MX-30", "RX-7", "RX-8", 
              "BT-50", "Mazda5", "Tribute", "B-Series", "Premacy", "Protege", 
              "626", "323", "Demio", "Familia", "Millenia", "MPV", "Cosmo"],
    },
    {
      value: 'Jaguar',
      label: 'Jaguar',
      models: ["E-PACE", "F-PACE", "I-PACE", "F-TYPE", "XE", "XF", "XJ", 
              "XK", "XKR", "XK8", "XJ6", "XJ8", "S-Type", "X-Type", 
              "XJ220", "XJR", "XJS", "MK2", "F-PACE SVR", "F-TYPE R"],
    },
    {
      value: 'Land Rover',
      label: 'Land Rover',
      models: ["Defender", "Discovery", "Discovery Sport", "Range Rover", "Range Rover Sport",
              "Range Rover Evoque", "Range Rover Velar", "Freelander", "LR2", "LR3", "LR4"],
    },
    {
      value: 'Porsche',
      label: 'Porsche',
      models: ["911", "Cayenne", "Macan", "Panamera", "Taycan", "718 Boxster", 
              "718 Cayman", "Boxster", "Cayman", "Carrera", "Turbo", 
              "GT3", "GT4", "Targa", "Spyder", "959", "924", 
              "928", "944", "968", "356", "Carrera GT"],
    },
    {
      value: 'Jeep',
      label: 'Jeep',
      models: ["Wrangler", "Grand Cherokee", "Cherokee", "Compass", "Renegade", 
              "Gladiator", "Patriot", "Commander", "Liberty", "Wagoneer", 
              "Grand Wagoneer", "CJ", "Scrambler", "J-Series", "Comanche", 
              "Trailhawk", "Trackhawk", "Overland", "Rubicon", "Laredo", 
              "Sahara", "Willys", "Freedom", "Mojave"],
    },
    {
      value: 'Alfa Romeo',
      label: 'Alfa Romeo',
      models: ["Giulia", "Stelvio", "Tonale", "Giulietta", "4C", "8C", "147", "156", 
              "159", "166", "Spider", "Brera", "GTV", "MiTo", "Alfa 33", "Alfa 75", 
              "Alfa 90", "Alfetta", "SZ", "RZ", "Montreal", "Alfa 145", "Alfa 146", 
              "Alfa 164", "Alfa 6", "Alfasud", "Alfasud Sprint", "GT", "Junior", 
              "Giulia Sprint", "Duetto", "Quadrifoglio"],
    },
    {
      value: 'Seat',
      label: 'Seat',
      models: ["Ibiza", "Leon", "Arona", "Ateca", "Tarraco", "Mii", 
              "Toledo", "Alhambra", "Cordoba", "Exeo", "Altea", 
              "Inca", "Marbella", "Ronda", "Malaga", "Fura", 
              "Panda", "600", "133"],
    },
    {
      value: 'Suzuki',
      label: 'Suzuki',
      models: ["Alto", "Swift", "Baleno", "Celerio", "Ignis", "Wagon R", "Ertiga", 
              "XL6", "Vitara", "Jimny", "S-Cross", "Kizashi", "Grand Vitara", 
              "SX4", "Dzire", "Splash", "Ritz", "Esteem", "Aerio", "Samurai", 
              "Sidekick", "X-90", "Equator", "Cappuccino", "Carry"],
    },
    {
      value: 'Volvo',
      label: 'Volvo',
      models: ["XC40", "XC60", "XC90", "S60", "S90", "V60", "V90", "C40 Recharge", 
              "V60 Cross Country", "V90 Cross Country", "XC40 Recharge", 
              "C30", "C70", "V40", "V50", "V70", "S40", "S80", "740", "760", 
              "780", "850", "940", "960", "Amazon", "PV444", "PV544", "P1800"],
    },
    {
      value: 'Lexus',
      label: 'Lexus',
      models: ["ES", "GS", "IS", "LS", "NX", "RX", "GX", "LX", 
              "UX", "RC", "LC", "CT", "HS", "RX L", "RXh", 
              "NXh", "LC Convertible", "IS F", "LFA", "SC"],
    },
    {
      value: 'Citroen',
      label: 'Citroen',
      models: ["C1", "C2", "C3", "C3 Aircross", "C4", "C4 Cactus", "C4 Picasso", "C5", 
              "C5 Aircross", "C5 X", "C6", "C8", "Berlingo", "Jumpy", "Spacetourer", 
              "Dispatch", "Relay", "DS3", "DS4", "DS5", "Saxo", "Xantia", "XM", "ZX", 
              "AX", "BX", "CX", "Evasion", "Dyane", "Mehari", "Ami", "Traction Avant", 
              "2CV", "Visa", "C15", "HY", "GS", "GSA", "C-Crosser", "C-Zero", "Metropolis", 
              "Pluriel", "Nemo", "C-Elysee"],
    },
    {
      value: 'Mitsubishi',
      label: 'Mitsubishi',
      models: ["Lancer", "Outlander", "Pajero", "Eclipse Cross", "ASX", "Mirage", "Triton", 
              "Montero", "Delica", "Galant", "Eclipse", "RVR", "i-MiEV", "Challenger", 
              "Endeavor", "Sigma", "FTO", "Starion", "GTO", "3000GT", "Shogun", 
              "Diamante", "Space Star", "Colt", "Carisma", "Raider", "Minicab", 
              "Canter", "Express", "Attrage", "Xforce"],
    },
    {
      value: 'Mini',
      label: 'Mini',
      models: ["Cooper", "Cooper S", "Cooper SE", "Cooper SD", "John Cooper Works", 
              "Countryman", "Countryman Plug-In Hybrid", "Clubman", "Paceman", 
              "Convertible", "Roadster", "Coupe", "Hatchback", "Cabrio", 
              "One", "One D", "One First", "Electric", "Mini E"],
    },
    {
      value: 'Smart',
      label: 'Smart',
      models: ["Fortwo", "Fortwo Cabrio", "Forfour", "Crossblade", "Roadster", 
               "Roadster Coupe", "EQ Fortwo", "EQ Forfour", "Smart #1"],
    },
    {
      value: 'Chrysler',
      label: 'Chrysler',
      models: ["300", "300C", "Pacifica", "Pacifica Hybrid", "Voyager", "Aspen", "PT Cruiser", 
              "Sebring", "Crossfire", "Town & Country", "200", "Concorde", "LHS", "LeBaron", 
              "New Yorker", "Imperial", "Fifth Avenue", "Cordoba", "Valiant", "Saratoga", 
              "Windsor", "Newport", "Laser"],
    },
    {
      value: 'Dodge',
      label: 'Dodge',
      models: ["Charger", "Challenger", "Durango", "Journey", "Grand Caravan", "Nitro", 
              "Caliber", "Avenger", "Viper", "Magnum", "Dakota", "Ram 1500", "Ram 2500", 
              "Ram 3500", "Stealth", "Intrepid", "Stratus", "Neon", "Spirit", 
              "Daytona", "Lancer", "Omni", "Dart", "Polara", "Coronet", "Monaco", 
              "Aspen", "Diplomat", "Caravan", "B-Series", "A100", "Custom 880", 
              "Power Wagon", "Raider", "Shadow", "Colt", "Raider", "Aries", "Mirada", 
              "St. Regis", "Aspen SE", "Demon", "Super Bee"],
    },
    {
      value: 'Iveco',
      label: 'Iveco',
      models: ["Daily", "Eurocargo", "Stralis", "Trakker", "S-Way", "PowerStar", 
              "EuroTrakker", "Massif", "Magirus", "TurboDaily", "Zeta", "LMV", 
              "EuroTech", "EuroStar", "Cursor", "EuroClass", "TurboStar", "TurboTech", 
              "Vertis", "Tector", "Citelis", "Urbanway", "Crealis", "Crossway", 
              "Evadys", "Eurobus", "Turboliner", "Irisbus", "Bus", "Camper"],
    },
    {
      value: 'Lancia',
      label: 'Lancia',
      models: ["Ypsilon", "Delta", "Fulvia", "Stratos", "Thema", "Kappa", "Beta", "Montecarlo", 
              "Gamma", "Dedra", "Lybra", "Thesis", "Prisma", "Flavia", "Aurelia", "Appia", 
              "Ardea", "Augusta", "Dilambda", "Astura", "Aprilia", "Phedra", "Zeta", "Voyager", 
              "Trevi", "Scorpion"],
    },
    {
      value: 'Dacia',
      label: 'Dacia',
      models: ["Logan", "Sandero", "Duster", "Spring", "Lodgy", "Dokker", "Solenza", 
              "1300", "1100", "Nova", "Pick-Up", "1304", "Double Cab", "Denem", 
              "SuperNova", "Stepway", "Bigster", "Manifesto"],
    },
    {
      value: 'dR',
      label: 'dR',
      models: ["DR1", "DR2", "DR3", "DR3 EV", "DR4", "DR5", "DR5.0", "DR6", 
              "DR6.0", "DR7", "DR F35", "DR EVO", "DR Zero"],
    },
    {
      value: 'Lamborghini',
      label: 'Lamborghini',
      models: ["Aventador", "Huracán", "Urus", "Gallardo", "Murciélago", 
              "Diablo", "Countach", "Sian", "Reventón", "Veneno", 
              "Centenario", "Miura", "Espada", "Jarama", "Islero", 
              "LM002", "Estoque", "350 GT", "400 GT", "Silhouette", 
              "Jalpa", "Bravo", "Marzal", "Egoista", "Asterion", 
              "Terzo Millennio"],
    },
    {
      value: 'Ferrari',
      label: 'Ferrari',
      models: ["488 GTB", "488 Spider", "812 Superfast", "812 GTS", 
              "California", "California T", "Portofino", "Portofino M", 
              "Roma", "SF90 Stradale", "SF90 Spider", "F8 Tributo", 
              "F8 Spider", "296 GTB", "296 GTS", "LaFerrari", 
              "LaFerrari Aperta", "F12berlinetta", "F12tdf", "GTC4Lusso", 
              "GTC4Lusso T", "Enzo", "Dino 246", "250 GTO", 
              "458 Italia", "458 Spider", "458 Speciale", "599 GTB Fiorano", 
              "599 GTO", "612 Scaglietti", "F50", "F40", "Testarossa", 
              "Mondial", "308", "328", "348", "355", "360 Modena", 
              "360 Spider", "430 Scuderia", "Challenge Stradale", "275 GTB", 
              "512 TR", "512 BB", "Superamerica", "FXX", "SP1 Monza", 
              "SP2 Monza", "296 GTB", "Purosangue"],
    },
    {
      value: 'Subaro',
      label: 'Subaro',
      models: ["Impreza", "Legacy", "Outback", "Forester", 
              "Crosstrek", "Ascent", "WRX", "WRX STI", 
              "BRZ", "XV", "Levorg", "Baja", 
              "Tribeca", "SVX", "Justy", "Alcyone", 
              "Rex", "Sambar", "Trezia", "Stella", 
              "Lucra", "Dias Wagon", "Exiga", "Gloria"],
    },
  ];

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [carBrands, setCarBrands] = useState(initialCarBrands);
  const [carModels, setCarModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);


  const success_noti = (message) => {
    toast.success(message || t('Customer and car successfully registered.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };
  
  const error_noti = (message) => {
    toast.error(message || t('An error occurred while registering.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  };

  useEffect(() => {
    if (selectedCar) {
      // Se um veículo foi selecionado do banco de dados
      const selectedCarDetails = clientCars.find((car) => car.id === selectedCar);
  
      if (selectedCarDetails) {
        const brand = carBrands.find((b) => b.value === selectedCarDetails.car_brand);
        if (brand) {
          setCarModels(
            brand.models.map((model) => ({ value: model, label: model }))
          );
        }
        // Define o modelo selecionado automaticamente
        setSelectedModel({
          value: selectedCarDetails.car_model,
          label: selectedCarDetails.car_model,
        });
      }
    } else if (selectedBrand) {
      // Caso contrário, atualiza os modelos para a marca selecionada
      const brand = carBrands.find((b) => b.value === selectedBrand.value);
      setCarModels(
        brand ? brand.models.map((model) => ({ value: model, label: model })) : []
      );
      setSelectedModel(null); 
    } else {
      setCarModels([]);
      setSelectedModel(null);
    }
  }, [selectedBrand, selectedCar, clientCars, carBrands]);

  const handleBrandChange = (newValue) => {
    setSelectedBrand(newValue);
    setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
  
    if (newValue && !carBrands.some((brand) => brand.value === newValue.value)) {
      setCarBrands([...carBrands, { value: newValue.value, label: newValue.label, models: [] }]);
    }
  };
  
  const handleModelChange = (newValue) => {
    setSelectedModel(newValue);
    setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
  
    if (newValue && selectedBrand) {
      const updatedBrands = carBrands.map((brand) => {
        if (brand.value === selectedBrand.value) {
          return {
            ...brand,
            models: brand.models.includes(newValue.value)
              ? brand.models
              : [...brand.models, newValue.value],
          };
        }
        return brand;
      });
      setCarBrands(updatedBrands);
    }
  };

  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhoneCountry: '+39',
    clientPhone: '',
    carBrand: '',
    carModel: '',
    carYear: '',
    carPlate: '',
    carMileage: '',
  });

  const steps = [
    { id: 1, title: t('Personal Information'), number: '1', description: t('Client details') },
    { id: 2, title: t('Car Details'), number: '2', description: t('Car information') },
  ];

  const fetchClientDetails = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/clients/details/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar detalhes do cliente.');
      }

      const clientDetails = await response.json();

      setFormData({
        clientName: clientDetails.name || '',
        clientEmail: clientDetails.email || '',
        clientPhoneCountry: clientDetails.phone_country || '',
        clientPhone: clientDetails.phone || '',
        carBrand: '',
        carModel: '',
        carYear: '',
        carPlate: '',
        carMileage: ''
      });
    } catch (err) {
      console.error('Erro:', err);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setExistingClients(data);
      } catch (err) {
        console.error('Erro ao buscar clientes existentes:', err);
      }
    };

    fetchClients();
  }, []);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    // Validação básica dos campos
    if (
      (!selectedClient && (!formData.clientName || !formData.clientPhone)) ||
      !formData.carBrand ||
      !formData.carModel ||
      !formData.carYear ||
      !formData.carPlate ||
      !formData.carMileage
    ) {
      error_noti(t('All fields are required.'));
      return;
    }

    // Construir o payload com base na seleção de cliente
    const payload = selectedClient
      ? {
          client_id: selectedClient,
          carBrand: formData.carBrand,
          carModel: formData.carModel,
          carYear: formData.carYear,
          carPlate: formData.carPlate,
          carMileage: formData.carMileage,
        }
      : {
          clientName: formData.clientName,
          clientPhoneCountry: formData.clientPhoneCountry,
          clientPhone: formData.clientPhone,
          carBrand: formData.carBrand,
          carModel: formData.carModel,
          carYear: formData.carYear,
          carPlate: formData.carPlate,
          carMileage: formData.carMileage,
        };

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/autoregister`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        success_noti(t('Customer and car successfully registered.'));
        setTimeout(() => navigate(`/autolist/`), 2000);
      } else {
        const data = await response.json();
        error_noti(data.error || t('An error occurred while registering.'));
      }
    } catch (err) {
      error_noti(t('Server connection error.'));
    }
  };

  const fetchClientCars = async (clientId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/clients/${clientId}/cars`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao buscar carros do cliente.');
      }
  
      const cars = await response.json();
      setClientCars(cars);
    } catch (err) {
      console.error('Erro ao buscar carros do cliente:', err);
      setClientCars([]);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ 
      ...formData, 
      clientPhoneCountry: selectedOption ? selectedOption.dialCode : '' 
    });
  };

  const getSelectedCountry = () => {
    const selected = countryOptions.find(option => option.dialCode === formData.clientPhoneCountry);
    return selected || countryOptions.find(option => option.value === 'it');
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h4 className="form-section-title">{t('Client details')}</h4>
            <div className="col-12 mb-3">
              <label htmlFor="clientSelection" className="form-label">{t('select_client')}</label>
              {existingClients.length > 0 ? (
                <Select
                  options={[
                    { value: 'new', label: t('new_client') },
                    ...existingClients.map((client) => ({
                      value: client.id,
                      label: `${client.name} - ${client.phone_country} ${client.phone}`,
                    })),
                  ]}
                  value={selectedClient ? { value: selectedClient, label: `${formData.clientName} - ${formData.clientPhoneCountry} ${formData.clientPhone}` } : null}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 'new') {
                      setSelectedClient(null);
                      setFormData({
                        clientName: '',
                        clientEmail: '',
                        clientPhoneCountry: '+39',
                        clientPhone: '',
                        carBrand: '',
                        carModel: '',
                        carYear: '',
                        carPlate: '',
                        carMileage: '',
                      });
                      setClientCars([]);
                    } else {
                      setSelectedClient(selectedOption.value);
                      fetchClientDetails(selectedOption.value);
                      fetchClientCars(selectedOption.value);
                    }
                  }}
                  placeholder={t('select_or_search_client')}
                  isSearchable
                />
              ) : (
                <p className="text-muted">{t('no_clients_found')}</p> // Mensagem quando não há clientes
              )}
            </div>
            <div className="row g-4">
            <div className="col-md-6">
              <label htmlFor="clientName" className="form-label">{t('name')}</label>
              <input
                type="text"
                className="form-control"
                id="clientName"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                required
                disabled={!!selectedClient}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="clientPhone" className="form-label">{t('phone')}</label>
              <div className="d-flex">
                <Select
                  options={countryOptions}
                  value={getSelectedCountry()}
                  onChange={handleSelectChange}
                  id='clientPhoneCountry'
                  name="clientPhoneCountry"
                  className="country-select"
                  classNamePrefix="select"
                  isSearchable
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '150px',
                      marginRight: '10px',
                    }),
                    option: (base, state) => ({
                      ...base,
                      display: 'flex',
                      alignItems: 'center',
                    }),
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  id="clientPhone"
                  name="clientPhone"
                  value={formData.clientPhone}
                  onChange={handleChange}
                  required
                  disabled={!!selectedClient}
                />
              </div>
            </div>
          </div>
        </div>
        );
        case 2:
          return (
            <div>
              <h4 className="form-section-title">{t('Car Details')}</h4>

              {/* Formulário para cadastrar/editar veículo */}
              <div className="row g-4">
                <div className="col-md-6">
                  <label className="form-label">{t('Brand')}</label>
                  <CreatableSelect
                    options={carBrands}
                    value={
                      formData.carBrand
                        ? carBrands.find((brand) => brand.value === formData.carBrand) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleBrandChange(newValue);
                      setFormData({ ...formData, carBrand: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car brand')}
                    isClearable
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Model')}</label>
                  <CreatableSelect
                    options={carModels}
                    value={
                      formData.carModel
                        ? carModels.find((model) => model.value === formData.carModel) || null
                        : null
                    }
                    onChange={(newValue) => {
                      handleModelChange(newValue);
                      setFormData({ ...formData, carModel: newValue ? newValue.value : '' });
                    }}
                    placeholder={t('Select or enter a car model')}
                    isClearable
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Year')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carYear"
                    value={formData.carYear}
                    onChange={handleChange}
                    placeholder={t('Enter car year')}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Plate')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="carPlate"
                    value={formData.carPlate}
                    onChange={handleChange}
                    placeholder={t('Enter car plate')}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('Kilometer')}</label>
                  <input
                    type="number"
                    className="form-control"
                    name="carMileage"
                    value={formData.carMileage || ''}
                    onChange={handleChange}
                    placeholder={t('Enter car mileage')}
                    required
                  />
                </div>
              </div>
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('vehicles')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('newVehicle')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* Progress Bar */}
            <div className="progress-bar-container mb-4">
              {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                  <div
                    className={`progress-step ${currentStep === step.id ? 'active' : ''} ${
                      currentStep > step.id ? 'completed' : ''
                    }`}
                    onClick={() => handleStepClick(step.id)}
                  >
                    <div className="step-icon">{step.number}</div>
                    <div className="step-title">{step.title}</div>
                    <div className="step-description">{step.description}</div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`bs-stepper-line ${
                        currentStep > step.id ? 'completed' : ''
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <form>
              {renderStep()}
              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  disabled={currentStep === 1}
                >
                  {t('Previous')}
                </button>
                {currentStep === 2 ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {t('Submit')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setCurrentStep((prev) => prev + 1)}
                  >
                    {t('Next')}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default AutoRegister;